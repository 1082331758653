import React from 'react';
import SettingsTabBarMenu from '../../../commons/settingstabbar/settingstabbar.component';
import AdminSettings from './admin-settings.component';
import '../settings.css';


const GeneralSettingsComponent = (props) => {
  return (
    <div className='DefaultLayout'>
      <Header history={props.history} />
      <Card />
    </div>
  );
};

const Header = (props) => {
  return (
    <div className='HeaderLayout'>
      <h3>Settings</h3>
      <SettingsTabBarMenu history={props.history} />
    </div>
  );
};

const Card = () => {
  return (
    <div className='CardContainer'>
      <h3>General Settings</h3>
      <AdminSettings />
    </div>
  );
};

export default GeneralSettingsComponent;

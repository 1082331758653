import React, { useEffect, useState } from "react";
import { Input, Button, Icon } from "semantic-ui-react";
import toastr from "toastr";
import { BASE_URL } from "../../../../config";

const MarketingExecutiveLevels = () => {
  const [level_1, setLevel1] = useState(0);
  const [level_2, setLevel2] = useState(0);
  const [level_3, setLevel3] = useState(0);
  const [level1_image, setLevel1Image] = useState("");
  const [level2_image, setLevel2Image] = useState("");
  const [level3_image, setLevel3Image] = useState("");
  const [levelImageType, setLevelImageType] = useState("");
  const [executiveSettingsID, setSettingsID] = useState("");
  const [noData, setNoData] = useState("");

  useEffect(() => {
    fetchMarketingExecutiveLevels();
  }, []);

  const fetchMarketingExecutiveLevels = () => {
    fetch(`${BASE_URL}/get-marketing-executive-levels`)
      .then((res) => res.json())
      .then((json) => {
        if (json.levels[0] === undefined) {
          setNoData("true");
          setLevel1(0);
          setLevel2(0);
          setLevel3(0);
          setLevel1Image({});
          setLevel2Image({});
          setLevel3Image({});
        } else {
          setNoData("false");
          setSettingsID(json.levels[0]._id);
          setLevel1(json.levels[0].level_1);
          setLevel2(json.levels[0].level_2);
          setLevel3(json.levels[0].level_3);
          setLevel1Image(json.levels[0].level1_image);
          setLevel2Image(json.levels[0].level2_image);
          setLevel3Image(json.levels[0].level3_image);
        }
      });
  };

  const saveMarketingExecutiveSettings = () => {
    fetch(`${BASE_URL}/create-marketing-executive-levels`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        level_1,
        level_2,
        level_3,
        level1_image,
        level2_image,
        level3_image,
        setting_type: "levels",
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        fetchMarketingExecutiveLevels();
        toastr.success("MarketingExecutive Settings Saved");
      });
  };

  const updateMarketingExecutiveSettings = () => {
    fetch(
      `${BASE_URL}/update-marketing-executive-levels?marketing_executive_settings_id=${executiveSettingsID}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          level_1,
          level_2,
          level_3,
          level1_image,
          level2_image,
          level3_image,
          setting_type: "levels",
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        fetchMarketingExecutiveLevels();
        toastr.success("Marketing Executive Settings Updated Successfuly");
      });
  };

  const handleChange = () => {
    document.getElementById("main_image_uploader").click();
  };

  const mainImageUploader = (event) => {
    const file = event.target.files[0];
    var regex = new RegExp("([a-zA-Z0-9s_\\.-:])+(.jpg|.png)$");
    if (regex.test(file.name)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        var image = new Image();

        image.src = e.target.result;

        // const scope = this;
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (height > 150 || width > 150) {
            alert("Height and Width must not exceed 150px.");
            return false;
          } else {
            alert("Selected Image Uploaded Succesfully.");
            if (levelImageType === "LEVEL1_IMAGE") {
              uploadFile(file, "LEVEL1_IMAGE");
            }
            if (levelImageType === "LEVEL2_IMAGE") {
              uploadFile(file, "LEVEL2_IMAGE");
            }
            if (levelImageType === "LEVEL3_IMAGE") {
              uploadFile(file, "LEVEL3_IMAGE");
            }
            return true;
          }
        };
      };
    } else {
      alert("JPG and PNG Images only allowed!");
      return false;
    }
  };

  const uploadFile = (file, imageType) => {
    var url = `https://api.cloudinary.com/v1_1/dgvup74b7/upload`;
    var xhr = new XMLHttpRequest();
    var fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.onreadystatechange = (e) => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var response = JSON.parse(xhr.responseText);
        if (imageType === "LEVEL1_IMAGE") {
          setLevel1Image(response.secure_url);
        }
        if (imageType === "LEVEL2_IMAGE") {
          setLevel2Image(response.secure_url);
        }
        if (imageType === "LEVEL3_IMAGE") {
          setLevel3Image(response.secure_url);
        }
      }
    };

    fd.append("upload_preset", "lomurso1");
    fd.append("tags", "browser_upload");
    fd.append("file", file);
    xhr.send(fd);
  };

  return (
    <div className="TabContainer">
      <div className="TurnoverRow">
        <div style={{ marginRight: 20 }}>
          <div className="customerFormRow">
            <input
              type="file"
              id="main_image_uploader"
              placeholder="Enter customer name"
              style={{ display: "none" }}
              accept="image/*"
              onChange={(e) => {
                setLevelImageType("LEVEL1_IMAGE");
                mainImageUploader(e);
              }}
            />
            <div>
              <div
                onClick={() => {
                  setLevelImageType("LEVEL1_IMAGE");
                  handleChange();
                }}
                style={{
                  height: 100,
                  width: 100,
                  border: "1px dashed #000",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {level1_image.length > 0 ? (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={level1_image}
                  />
                ) : (
                  <Icon
                    style={{
                      fontSize: 45,
                      marginTop: 25,
                      color: "#a7c777",
                    }}
                    name="add"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <Input
            action={{
              labelPosition: "right",
              icon: "inr",
              content: "Level 1 Requirement",
              className: "Level1",
            }}
            actionPosition="left"
            placeholder="Enter a Level..."
            value={level_1}
            className="inputMobile"
            onChange={(e) => setLevel1(e.target.value)}
          />
        </div>
        <div style={{ marginLeft: 10, alignSelf: "center" }}>
          <p>Order Points</p>
        </div>
      </div>

      <div className="TurnoverRow">
        <div style={{ marginRight: 20 }}>
          <div className="customerFormRow">
            <input
              type="file"
              id="main_image_uploader"
              placeholder="Enter customer name"
              style={{ display: "none" }}
              accept="image/*"
              onChange={(e) => {
                setLevelImageType("LEVEL2_IMAGE");
                mainImageUploader(e);
              }}
            />
            <div>
              <div
                onClick={() => {
                  setLevelImageType("LEVEL2_IMAGE");
                  handleChange();
                }}
                style={{
                  height: 100,
                  width: 100,
                  border: "1px dashed #000",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {level2_image.length > 0 ? (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={level2_image}
                  />
                ) : (
                  <Icon
                    style={{
                      fontSize: 45,
                      marginTop: 25,
                      color: "#a7c777",
                    }}
                    name="add"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <Input
            action={{
              labelPosition: "right",
              icon: "inr",
              content: "Level 2 Requirement",
              className: "Level2",
            }}
            actionPosition="left"
            placeholder="Enter a Level..."
            value={level_2}
            className="inputMobile"
            onChange={(e) => setLevel2(e.target.value)}
          />
        </div>
        <div style={{ marginLeft: 10, alignSelf: "center" }}>
          <p>Order Points</p>
        </div>
      </div>

      <div className="TurnoverRow">
        <div style={{ marginRight: 20 }}>
          <div className="customerFormRow">
            <input
              type="file"
              id="main_image_uploader"
              placeholder="Enter customer name"
              style={{ display: "none" }}
              accept="image/*"
              onChange={(e) => {
                setLevelImageType("LEVEL3_IMAGE");
                mainImageUploader(e);
              }}
            />
            <div>
              <div
                onClick={() => {
                  setLevelImageType("LEVEL3_IMAGE");
                  handleChange();
                }}
                style={{
                  height: 100,
                  width: 100,
                  border: "1px dashed #000",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {level3_image.length > 0 ? (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={level3_image}
                  />
                ) : (
                  <Icon
                    style={{
                      fontSize: 45,
                      marginTop: 25,
                      color: "#a7c777",
                    }}
                    name="add"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <Input
            action={{
              labelPosition: "right",
              icon: "inr",
              content: "Level 3 Requirement",
              className: "Level3",
            }}
            actionPosition="left"
            placeholder="Enter a Level..."
            value={level_3}
            className="inputMobile"
            onChange={(e) => setLevel3(e.target.value)}
          />
        </div>
        <div style={{ marginLeft: 10, alignSelf: "center" }}>
          <p>Order Points</p>
        </div>
      </div>
      {noData === "true" ? (
        <div className="SubmitBtnRow">
          <Button
            className="submitBtn"
            content="Submit"
            onClick={saveMarketingExecutiveSettings}
          />
        </div>
      ) : (
        <div className="SubmitBtnRow">
          <Button
            className="submitBtn"
            content="Update"
            onClick={updateMarketingExecutiveSettings}
          />
        </div>
      )}
    </div>
  );
};

export default MarketingExecutiveLevels;

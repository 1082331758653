import React from "react";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Icon, Image, Button, Table, Form, TextArea } from "semantic-ui-react";
import PAID from "../../../images/paid.png";
import UNPAID from "../../../images/Un-paid.png";
import { BASE_URL } from "../../../config";

const ViewInvoice = (props) => {
  return (
    <div className="invoiceDefaultLayout">
      <Card history={props.history} invoice_data={props} />
    </div>
  );
};

class Card extends React.Component {
  state = {
    invoice_settings: {},
    invoice_details: {},
    customer_address: {},
    customer_gst: "",
    coupon_details: {},
    membership_details: {},
    agent_details: {},
    productList: [],
    transactions: [],
    pendingCheques: [],
    driver_chalan: false,
    print_status: false
  };

  componentDidMount = () => {
    const { invoice_data } = this.props;
    const invoice_direct_print = invoice_data.invoice_no;
    if (invoice_direct_print) {
      this.setState({ driver_chalan: invoice_data.driver_challan, print_status: invoice_data.printDetails });
      const invoice_no = invoice_data.invoice_no;
      const cname = invoice_data.collection_name;

      fetch(`${BASE_URL}/get-invoice?invoiceNo=${invoice_no}&cname=${cname}`)
        .then((res) => res.json())
        .then((json) => {
          this.setState({
            invoice_details: json.invoice[0],
            customer_address: json.invoice[0].customer_address,
            customer_gst: json.invoice[0].customer_gst !== undefined ? json.invoice[0].customer_gst : "",
            productList: json.invoice[0].products,
            coupon_details:
              json.invoice[0].coupon_details !== null
                ? json.invoice[0].coupon_details
                : {},
            membership_details: json.invoice[0].membership_details,
            transactions:
              json.invoice[0].transactions !== undefined
                ? json.invoice[0].transactions
                : [],
            invoice_settings: json.invoice[0].company_details,
            agent_details: json.invoice[0].agentDetails,
          });
        });
    } else {
      const { location } = this.props.history;
      const data = location.pathname.split("/");
      const invoice_no = data[3];

      fetch(`${BASE_URL}/get-invoice?invoiceNo=${invoice_no}&cname=${data[2]}`)
        .then((res) => res.json())
        .then((json) => {
          this.setState({
            invoice_details: json.invoice[0],
            customer_address: json.invoice[0].customer_address,
            customer_gst: json.invoice[0].customer_gst !== undefined ? json.invoice[0].customer_gst : "",
            productList: json.invoice[0].products,
            coupon_details:
              json.invoice[0].coupon_details !== null
                ? json.invoice[0].coupon_details
                : {},
            membership_details: json.invoice[0].membership_details,
            transactions:
              json.invoice[0].transactions !== undefined
                ? json.invoice[0].transactions
                : [],
            invoice_settings: json.invoice[0].company_details,
            agent_details: json.invoice[0].agentDetails,
          });
        });

      fetch(
        `${BASE_URL}/get-cheques-by-id?invoiceNo=${invoice_no}&cname=${data[2]}`
      )
        .then((res) => res.json())
        .then((json) => {
          const pending_cheques = json.cheques.filter((cheque) =>
            Object.keys(cheque).some(
              (key) => cheque[key].toString().search("PENDING") !== -1
            )
          );
          this.setState({ pendingCheques: pending_cheques });
        });
    }
  };

  getProductsTotal = (products) => {
    const total_value = products.reduce(
      (sum, product, index) => sum + Number(product.quantity * product.price),
      0
    );
    return Number(total_value);
  };

  getCouponDiscount = () => {
    const { coupon_details, productList } = this.state;

    const discount =
      (this.getProductsTotal(productList) *
        Number(
          coupon_details.discount !== undefined ? coupon_details.discount : 0
        )) /
      100;

    return discount;
  };

  getTransactionsBalance = () => {
    const { transactions } = this.state;
    const paymentData = transactions.filter((transaction) =>
      Object.keys(transaction).some(
        (key) => transaction[key].toString().search("payment") !== -1
      )
    );
    const payments = paymentData.reduce(
      (sum, transaction, index) => sum + Number(transaction.transaction_amount),
      0
    );

    const refundData = transactions.filter((transaction) =>
      Object.keys(transaction).some(
        (key) => transaction[key].toString().search("refund") !== -1
      )
    );
    const refunds = refundData.reduce(
      (sum, transaction, index) => sum + Number(transaction.transaction_amount),
      0
    );

    const total_balance = payments - refunds;
    return Number(total_balance);
  };

  getBalanceDue = () => {
    const {
      productList,
      transactions,
      invoice_details,
      membership_details,
    } = this.state;

    const order_total =
      this.getProductsTotal(productList) +
      invoice_details.gst_amount +
      Number(invoice_details.delivery_fee) -
      this.getCouponDiscount() -
      (membership_details.discountAmt || 0);

    const due = order_total - this.getTransactionsBalance(transactions);

    return due;
  };

  _exportPdf = () => {
    const { invoice_details, invoice_settings } = this.state;
    html2canvas(document.querySelector("#capture")).then((canvas) => {
  
      const imgData = canvas.toDataURL("image/png");
      
      const pdf = new jsPDF("p", "mm", "a4");
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "PNG", 0, 0, width, height);
      pdf.addImage(invoice_settings.logo, "PNG",10,10);
      pdf.save(`${invoice_details.invoice_no}.pdf`);
    });
  };

  printInvoice = () => {
    this.setState({ print_status: true });
    setTimeout(function () {
      var printContents = document.getElementById("capture").innerHTML;
      var originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;

      window.print();

      document.body.innerHTML = originalContents;
      window.location.reload();
    }, 1000);
  };

  render() {
    const {
      invoice_settings,
      invoice_details,
      customer_address,
      customer_gst,
      productList,
      coupon_details,
      membership_details,
      agent_details,
      transactions,
      pendingCheques,
      driver_chalan,
      print_status
    } = this.state;

    return (
      <div
        className={
          this.props.invoice_data.invoice_no ? "" : "invoiceCardContainer"
        }
      >
        {this.props.invoice_data.invoice_no ? (
          ""
        ) : (
          <div className="invoicePageTitleRow">
            <div style={{ paddingTop: 10, paddingLeft: 20, display: "flex" }}>
              <Icon
                name="arrow left"
                style={{ paddingTop: 3, cursor: "pointer" }}
                onClick={() => this.props.history.push("/billing")}
              />
              <h3 style={{ marginTop: 0, marginLeft: 10 }}>View Invoice</h3>
            </div>
            <div>
              <Button className="PDFBtn" onClick={() => this._exportPdf()}>
                <Icon name="file pdf" /> Pdf
              </Button>
              <Button onClick={() => this.printInvoice()}>
                <Icon name="print" /> Print
              </Button>
              {invoice_details.customer_role === "MERCHANT" && (
                <Button
                  onClick={() => {
                    this.setState({ driver_chalan: true });
                    this.printInvoice();
                  }}
                >
                  <Icon name="print" /> Driver Challan
                </Button>
              )}
            </div>
          </div>
        )}

        <div className="Card" id="invoiceeData">
          <div className={
            this.props.invoice_data.invoice_no ? "" : "invoicePdfContainer"
          } id="capture">
            <div>
              <Image
                src={invoice_settings.logo}
                style={{ width: 100 }}
              />
            </div>
            <div className="section1">
              <div>
                <p className={print_status === true ? "section1TextP" : "section1Text"} style={{ fontSize: print_status === true ? 15 : 22 }}>
                  {invoice_settings.company_name},
                </p>
                <p className={print_status === true ? "section1TextP" : "section1Text"}>{invoice_settings.address_line1}</p>
                <p className={print_status === true ? "section1TextP" : "section1Text"}>
                  {invoice_settings.address_line2}{" "}
                  {invoice_settings.address_line3}
                </p>
                <p className={print_status === true ? "section1TextP" : "section1Text"}>Email: {invoice_settings.email}</p>
                <p className={print_status === true ? "section1TextP" : "section1Text"}>
                  Contact: +91-{invoice_settings.whatsapp_number}
                </p>
                <p className={print_status === true ? "section1TextP" : "section1Text"}>GSTIN: {invoice_settings.gst_no}</p>
              </div>
              {/* {invoice_details.payment_status === "fullypaid" ? (
                <div>
                  <Image src={PAID} style={print_status === true ? { width: 75 } : { width: 150, marginRight: 100 }} />
                </div>
              ) : (
                <div>
                  <Image
                    src={UNPAID}
                    style={print_status === true ? { width: 75 } : { width: 150, marginRight: 100 }}
                  />
                </div>
              )} */}
            </div>
            <div className={print_status === true ? "section2P" : "section2"}>
              <div>
                <p
                  className="section2Text"
                  style={{ fontSize: print_status === true ? 12 : 22, fontWeight: "bold" }}
                >
                  Invoice #{invoice_details.invoice_no}{" "}
                </p>
                <p className={print_status === true ? "section2TextP" : "section2Text"}>
                  Invoice Date:{" "}
                  {moment(invoice_details.invoice_date).format("DD/MM/YYYY")}
                </p>
              </div>
              <div>
                <p
                  className="section3Text"
                  style={{ fontSize: print_status === true ? 12 : 17, fontWeight: "bold" }}
                >
                  Invoiced To
                </p>
                <p className={print_status === true ? "section3TextP" : "section3Text"}>{invoice_details.customer_name}</p>
                <p className={print_status === true ? "section3TextP" : "section3Text"}>
                  {customer_address.detailedAddress} {customer_address.pincode}
                </p>
                {customer_gst.length > 0 ?
                  <p className={print_status === true ? "section3TextP" : "section3Text"}>GSTIN: {customer_gst}</p>

                  : ""
                }
              </div>
            </div>
            {/* <div className="section3"></div> */}
            <div className={print_status === true ? "section4P" : "section4"}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontSize: print_status === true ? 15 : 20, fontWeight: "600" }}>Products</p>
              </div>

              <div>
                <Table celled unstackable style={print_status === true ? { fontSize: 10 } : {}}>
                  <Table.Header>
                    <Table.Row textAlign="center">
                      <Table.HeaderCell style={{ backgroundColor: "#b6d7a8" }}
                        className={print_status === true ? "tableCell" : ""}>
                        S.no
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: "#b6d7a8" }}
                        className={print_status === true ? "tableCell" : ""}>
                        Products
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: "#b6d7a8" }}
                        className={print_status === true ? "tableCell" : ""}>
                        Quantity
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: "#b6d7a8" }}
                        className={print_status === true ? "tableCell" : ""}>
                        Price
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={
                          driver_chalan === true
                            ? { display: "none" }
                            : { backgroundColor: "#b6d7a8" }
                        }
                        className={print_status === true ? "tableCell" : ""}
                      >
                        Total
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {productList.map((product, idx) => (
                      <Table.Row textAlign="center">
                        <Table.Cell textAlign="center" className={print_status === true ? "tableCell" : ""}>{idx + 1}</Table.Cell>
                        <Table.Cell className={print_status === true ? "tableCell" : ""}>
                          {product.name} - {product.weight}Kg
                        </Table.Cell>
                        <Table.Cell className={print_status === true ? "tableCell" : ""}>{product.quantity}</Table.Cell>
                        <Table.Cell className={print_status === true ? "tableCell" : ""}>Rs. {product.price}</Table.Cell>
                        <Table.Cell
                          className={print_status === true ? "tableCell" : ""}
                          style={
                            driver_chalan === true
                              ? { display: "none" }
                              : { display: "" }
                          }
                        >
                          Rs. {product.quantity * product.price}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>

                <div
                  style={driver_chalan === true ? { display: "none" } : { display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ alignSelf: "flex-end" }}>
                    {invoice_details.customer_notes !== undefined && (
                      <div style={{ marginBottom: 20 }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontSize: print_status === true ? 15 : 17,
                              fontWeight: "600",
                              marginBottom: 10,
                            }}
                          >
                            Customer Notes
                          </p>
                        </div>

                        <Form>
                          <TextArea
                            cols="50"
                            maxLength="70"
                            value={invoice_details.customer_notes}
                            disabled
                          />
                        </Form>
                      </div>
                    )}

                    {invoice_details.pricing_terms !== undefined && (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontSize: print_status === true ? 15 : 17,
                              fontWeight: "600",
                              marginBottom: 10,
                            }}
                          >
                            Pricing Terms
                          </p>
                        </div>

                        <Form>
                          <TextArea
                            cols="50"
                            maxLength="70"
                            value={invoice_details.pricing_terms}
                            disabled
                          />
                        </Form>
                      </div>
                    )}
                  </div>

                  <div
                    style={driver_chalan === true ? { display: "none" } : { marginLeft: 10 }}
                  >
                    <Table celled unstackable collapsing style={print_status === true ? { fontSize: 10 } : {}}>
                      <Table.Body>
                        <Table.Row textAlign="right">
                          <Table.Cell className={print_status === true ? "tableCellbgP" : "tableCellbg"}>
                            Item Total
                          </Table.Cell>
                          <Table.Cell className={print_status === true ? "tableCellbgP" : "tableCellbg"}>
                            {`₹${this.getProductsTotal(productList)}`}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className={print_status === true ? "tableCellbgP" : "tableCellbg"}
                            style={{ color: "#cc0000" }}
                          >
                            Coupon(
                            {coupon_details.code !== undefined
                              ? coupon_details.code
                              : "NO COUPON"}
                            )
                          </Table.Cell>
                          <Table.Cell
                            className={print_status === true ? "tableCellbgP" : "tableCellbg"}
                            style={{ color: "#cc0000" }}
                          >
                            {`-₹${this.getCouponDiscount()}`}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className={print_status === true ? "tableCellbgP" : "tableCellbg"}
                            style={{ color: "#cc0000" }}
                          >
                            Membership({membership_details.level || "ZERO"})
                          </Table.Cell>
                          <Table.Cell
                            className={print_status === true ? "tableCellbgP" : "tableCellbg"}
                            style={{ color: "#cc0000" }}
                          >
                            {`-₹${membership_details.discountAmt || 0}`}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className={print_status === true ? "tableCellbgP" : "tableCellbg"}
                            style={{ fontWeight: 500 }}
                          >
                            Other Charges
                          </Table.Cell>
                          <Table.Cell
                            className={print_status === true ? "tableCellbgP" : "tableCellbg"}
                            style={{ fontWeight: 500 }}
                          >
                            {`₹${invoice_details.delivery_fee}`}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className={print_status === true ? "tableCellbgP" : "tableCellbg"}
                            style={{ fontWeight: 500 }}
                          >
                            {invoice_details.cgst}.00% CGST
                          </Table.Cell>
                          <Table.Cell
                            className={print_status === true ? "tableCellbgP" : "tableCellbg"}
                            style={{ fontWeight: 500 }}
                          >
                            {`₹${invoice_details.gst_amount / 2}`}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className={print_status === true ? "tableCellbgP" : "tableCellbg"}
                            style={{ fontWeight: 500 }}
                          >
                            {invoice_details.sgst}.00% SGST
                          </Table.Cell>
                          <Table.Cell
                            className={print_status === true ? "tableCellbgP" : "tableCellbg"}
                            style={{ fontWeight: 500 }}
                          >
                            {`₹${invoice_details.gst_amount / 2}`}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className={print_status === true ? "tableCellbgP" : "tableCellbg"}
                            style={{ color: "#cc0000" }}
                          >
                            Paid Amount
                          </Table.Cell>
                          <Table.Cell
                            className={print_status === true ? "tableCellbgP" : "tableCellbg"}
                            style={{ color: "#cc0000" }}
                          >
                            {`-₹${this.getTransactionsBalance()}`}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className={print_status === true ? "tableCellbgP" : "tableCellbg"}
                            style={{ color: "#6aa84f" }}
                          >
                            Total Balance Due
                          </Table.Cell>
                          <Table.Cell
                            className={print_status === true ? "tableCellbgP" : "tableCellbg"}
                            style={{ color: "#6aa84f" }}
                          >
                            {`₹${this.getBalanceDue()}`}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </div>
              </div>
            </div>

            {transactions.length > 0 && (
              <div className="section5">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontSize: print_status === true ? 15 : 20, fontWeight: "600" }}>
                    Transactions
                  </p>
                </div>
                <div>
                  <Table celled unstackable style={print_status === true ? { fontSize: 10 } : {}}>
                    <Table.Header>
                      <Table.Row textAlign="center">
                        <Table.HeaderCell
                          style={{ backgroundColor: "#b6d7a8" }}
                        >
                          Transaction Date
                        </Table.HeaderCell>

                        <Table.HeaderCell
                          style={{ backgroundColor: "#b6d7a8" }}
                        >
                          Remarks
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          style={{ backgroundColor: "#b6d7a8" }}
                        >
                          Amount
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {transactions.map((transaction, idx) => (
                        <Table.Row textAlign="center">
                          <Table.Cell>
                            {moment(transaction.transaction_date).format("l")}
                          </Table.Cell>
                          <Table.Cell>{transaction.payment_type}</Table.Cell>
                          <Table.Cell>
                            {transaction.transaction_type === "payment" ? (
                              <p>₹ {transaction.transaction_amount} (+)</p>
                            ) : (
                              <p>₹ {transaction.transaction_amount} (-)</p>
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              </div>
            )}

            {pendingCheques.length > 0 && (
              <div className="section5">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontSize: 20, fontWeight: "600" }}>
                    Pending cheque clearance
                  </p>
                </div>
                <div>
                  <Table celled unstackable>
                    <Table.Header>
                      <Table.Row textAlign="center">
                        <Table.HeaderCell
                          style={{ backgroundColor: "#b6d7a8" }}
                        >
                          Cheque No
                        </Table.HeaderCell>

                        <Table.HeaderCell
                          style={{ backgroundColor: "#b6d7a8" }}
                        >
                          Cheque Submitted Date
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          style={{ backgroundColor: "#b6d7a8" }}
                        >
                          Cheque Clearing Date
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          style={{ backgroundColor: "#b6d7a8" }}
                        >
                          Cheque Amount
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {pendingCheques.map((cheque, idx) => (
                        <Table.Row textAlign="center">
                          <Table.Cell># {cheque.cheque_no}</Table.Cell>
                          <Table.Cell>
                            {moment(cheque.cheque_submit_date).format("l")}
                          </Table.Cell>
                          <Table.Cell>
                            {moment(cheque.cheque_clearing_date).format("l")}
                          </Table.Cell>
                          <Table.Cell>₹ {cheque.cheque_amount}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              </div>
            )}

            {/* {invoice_details.customer_role === "MERCHANT" && (
              <div className="section5">
                {driver_chalan === true && (<div>
                  <p style={{ fontSize: 15, fontWeight: "600" }}>
                    Driver Details:
                </p>
                  <p>{agent_details.name || "-"}</p>
                  <p>{agent_details.TruckNumber || "-"}</p>
                </div>)}
              </div>
            )} */}

            {driver_chalan === true && (
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: 'space-between'
                }}
              >
                {agent_details && (
                  <div>
                    <p className="driverChallanTextH">
                      Driver Details:
                    </p>
                    <p className="driverChallanText">Transporter: {agent_details.name || "-"}</p>
                    <p className="driverChallanText">Vehicle No: {agent_details.TruckNumber || "-"}</p>
                  </div>)}
                <div>
                  <p className="driverChallanTextH">Received Goods In Good Condition</p>
                  <p className="driverChallanText">Reciever Name: </p>
                  <p className="driverChallanText">Reciever Signature: </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ViewInvoice;

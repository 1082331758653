import React, { useState, useEffect } from "react";
import {
  Icon,
  Image,
  Progress,
  Label,
  Grid,
  Responsive,
} from "semantic-ui-react";
import PROFILE_PIC from "../../../../images/pic.png";
import "./merchant-profile.css";
import MerchantProfileDetails from "./merchant-profile-details.component";
import { BASE_URL } from "../../../../config";

const MerchantProfilePage = (props) => {
  return (
    <div className="DefaultLayout">
      <Header />
      <Card history={props.history} />
    </div>
  );
};

const Header = (props) => {
  return (
    <div className="HeaderLayout">
      <h3 style={{ paddingLeft: 30 }}>Merchant Profile</h3>
    </div>
  );
};

const Card = (props) => {
  const { location } = props.history;
  const data = location.pathname.split("/");
  const merchant = data[2];

  const [merchantData, setMerchantData] = useState({});
  const [levelsData, setLevelsData] = useState({});
  const [currentLevel, setCurrentLevel] = useState({});
  const [currentLevelPercentage, setCurrentLevelPercentage] = useState(0);

  useEffect(() => {
    fetch(`${BASE_URL}/get-merchant-by-id?phoneNumber=${merchant}`)
      .then((res) => res.json())
      .then((json) => {
        setMerchantData(json.user);
        setCurrentLevel(
          json.user.currentLevel !== undefined ? json.user.currentLevel : {}
        );
        getPercentageValue(
          json.user.currentLevel !== undefined ? json.user.currentLevel : {}
        );
      });
    fetchMerchantLevels();
  }, []);

  const fetchMerchantLevels = () => {
    fetch(`${BASE_URL}/get-merchant-levels`)
      .then((res) => res.json())
      .then((json) => {
        if (json.levels.length > 0) {
          setLevelsData(json.levels[0]);
        }
      });
  };

  const getPercentageValue = (currentLevel) => {
    const stage0 = 10;
    const stage1 = 30;
    const stage2 = 70;
    const stage3 = 100;
    const stage4 = 150;
    const stage5 = 200;

    if (currentLevel.currentLevelName === "GOLD") {
      setCurrentLevelPercentage(stage5);
    } else if (currentLevel.currentLevelName === "SILVER") {
      const currentLevelValue = Number(currentLevel.currentLevelTurnOver);

      const nextTargetValue = Number(levelsData.level_1);

      const currentTurnOver =
        merchantData.turnOver !== undefined ? merchantData.turnOver : 0;

      const stageValue = nextTargetValue - currentLevelValue;

      const stagePercent1 = Math.round((stageValue * 10) / 100);

      const stagePercent2 = Math.round((stageValue * 25) / 100);

      const stagePercent3 = Math.round((stageValue * 50) / 100);

      const stagePercent4 = Math.round((stageValue * 75) / 100);

      const stagePercent5 = Math.round((stageValue * 90) / 100);

      const stageTurnOver = currentTurnOver - currentLevelValue;

      if (stageTurnOver <= stagePercent1) {
        setCurrentLevelPercentage(stage0);
      } else if (
        stageTurnOver >= stagePercent1 &&
        stageTurnOver <= stagePercent2
      ) {
        setCurrentLevelPercentage(stage1);
      } else if (
        stageTurnOver >= stagePercent2 &&
        stageTurnOver <= stagePercent3
      ) {
        setCurrentLevelPercentage(stage2);
      } else if (
        stageTurnOver >= stagePercent3 &&
        stageTurnOver <= stagePercent4
      ) {
        setCurrentLevelPercentage(stage3);
      } else if (
        stageTurnOver >= stagePercent4 &&
        stageTurnOver <= stagePercent5
      ) {
        setCurrentLevelPercentage(stage4);
      } else if (stageTurnOver >= stagePercent5) {
        setCurrentLevelPercentage(stage5);
      } else {
        setCurrentLevelPercentage(stage0);
      }
    } else if (currentLevel.currentLevelName === "BRONZE") {
      const currentLevelValue = Number(currentLevel.currentLevelTurnOver);

      const nextTargetValue = Number(levelsData.level_2);

      const currentTurnOver =
        merchantData.turnOver !== undefined ? merchantData.turnOver : 0;

      const stageValue = nextTargetValue - currentLevelValue;

      const stagePercent1 = Math.round((stageValue * 10) / 100);

      const stagePercent2 = Math.round((stageValue * 25) / 100);

      const stagePercent3 = Math.round((stageValue * 50) / 100);

      const stagePercent4 = Math.round((stageValue * 75) / 100);

      const stagePercent5 = Math.round((stageValue * 90) / 100);

      const stageTurnOver = currentTurnOver - currentLevelValue;

      if (stageTurnOver <= stagePercent1) {
        setCurrentLevelPercentage(stage0);
      } else if (
        stageTurnOver >= stagePercent1 &&
        stageTurnOver <= stagePercent2
      ) {
        setCurrentLevelPercentage(stage1);
      } else if (
        stageTurnOver >= stagePercent2 &&
        stageTurnOver <= stagePercent3
      ) {
        setCurrentLevelPercentage(stage2);
      } else if (
        stageTurnOver >= stagePercent3 &&
        stageTurnOver <= stagePercent4
      ) {
        setCurrentLevelPercentage(stage3);
      } else if (
        stageTurnOver >= stagePercent4 &&
        stageTurnOver <= stagePercent5
      ) {
        setCurrentLevelPercentage(stage4);
      } else if (stageTurnOver >= stagePercent5) {
        setCurrentLevelPercentage(stage5);
      } else {
        setCurrentLevelPercentage(stage0);
      }
    } else {
      const currentLevelValue = Number(currentLevel.currentLevelTurnOver);

      const nextTargetValue = Number(levelsData.level_3);

      const currentTurnOver =
        merchantData.turnOver !== undefined ? merchantData.turnOver : 0;

      const stageValue = nextTargetValue - currentLevelValue;

      const stagePercent1 = Math.round((stageValue * 10) / 100);

      const stagePercent2 = Math.round((stageValue * 25) / 100);

      const stagePercent3 = Math.round((stageValue * 50) / 100);

      const stagePercent4 = Math.round((stageValue * 75) / 100);

      const stagePercent5 = Math.round((stageValue * 90) / 100);

      const stageTurnOver = currentTurnOver - currentLevelValue;

      if (stageTurnOver <= stagePercent1) {
        setCurrentLevelPercentage(stage0);
      } else if (
        stageTurnOver >= stagePercent1 &&
        stageTurnOver <= stagePercent2
      ) {
        setCurrentLevelPercentage(stage1);
      } else if (
        stageTurnOver >= stagePercent2 &&
        stageTurnOver <= stagePercent3
      ) {
        setCurrentLevelPercentage(stage2);
      } else if (
        stageTurnOver >= stagePercent3 &&
        stageTurnOver <= stagePercent4
      ) {
        setCurrentLevelPercentage(stage3);
      } else if (
        stageTurnOver >= stagePercent4 &&
        stageTurnOver <= stagePercent5
      ) {
        setCurrentLevelPercentage(stage4);
      } else if (stageTurnOver >= stagePercent5) {
        setCurrentLevelPercentage(stage5);
      } else {
        setCurrentLevelPercentage(stage0);
      }
    }
  };

  return (
    <div>
      <div className="CardContainer" style={{ backgroundColor: "#f8ffff" }}>
        <div style={{ margin: 30 }}>
          <Grid>
            <Grid.Column width={4}>
              <Responsive minWidth={767}>
                <Image src={PROFILE_PIC} inline circular size="small" />
                {merchantData.currentLevel !== undefined ? (
                  <Image
                    src={merchantData.currentLevel.currentLevelImg}
                    inline
                    circular
                    size="mini"
                    className="LevelImg"
                  />
                ) : (
                  <Image
                    src={levelsData.level0_image}
                    inline
                    circular
                    size="mini"
                    className="LevelImg"
                  />
                )}
              </Responsive>
            </Grid.Column>

            <Responsive maxWidth={767}>
              <Grid.Column>
                <Image src={PROFILE_PIC} inline circular size="small" />
                {merchantData.currentLevel !== undefined ? (
                  <Image
                    src={merchantData.currentLevel.currentLevelImg}
                    inline
                    circular
                    size="mini"
                    className="LevelImg"
                  />
                ) : (
                  ""
                )}
              </Grid.Column>
            </Responsive>

            <Grid.Column width={6}>
              <Responsive minWidth={767}>
                <h2>{merchantData.name}</h2>
                <div style={{ display: "flex" }}>
                  <Label as="a" basic color="teal">
                    {merchantData.role}
                  </Label>
                </div>
                <div style={{ marginTop: 10 }}>
                  <p>
                    Administrative Officer at
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      {merchantData.companyName}
                    </span>
                  </p>
                </div>
                <div style={{ marginTop: 10 }}>
                  <Label>
                    <Icon name="phone" color="green" />{" "}
                    {merchantData.phoneNumber}
                  </Label>
                  <Label>
                    <Icon name="map pin" color="red" /> {merchantData.address}
                  </Label>
                </div>
              </Responsive>
            </Grid.Column>

            <Responsive maxWidth={767}>
              <Grid.Column width={6}>
                <h2>{merchantData.name}</h2>
                <div style={{ display: "flex" }}>
                  <Label as="a" basic color="teal">
                    {merchantData.role}
                  </Label>
                </div>
                <div style={{ marginTop: 10 }}>
                  <p>
                    Administrative Officer at
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      {merchantData.companyName}
                    </span>
                  </p>
                </div>
                <div style={{ marginTop: 10 }}>
                  <Label>
                    <Icon name="phone" color="green" />{" "}
                    {merchantData.phoneNumber}
                  </Label>
                  <Label>
                    <Icon name="map pin" color="red" /> {merchantData.address}
                  </Label>
                </div>
              </Grid.Column>
            </Responsive>

            {currentLevel.currentLevelName &&
              <Grid.Column width={4}>
                <Responsive minWidth={767}>
                  <div style={{ marginTop: 50, justifyContent: "center" }}>
                    <div
                      style={{ justifyContent: "space-between", display: "flex" }}
                    >
                      <div>
                        {merchantData.turnOver >= levelsData.level_1 ? (
                          <div style={{ marginLeft: 180 }}>
                            <Image
                              src={levelsData.level1_image}
                              inline
                              circular
                              size="mini"
                            />
                          </div>
                        ) : merchantData.turnOver >= levelsData.level_2 ? (
                          <Image
                            src={levelsData.level2_image}
                            inline
                            circular
                            size="mini"
                          />
                        ) : merchantData.turnOver >= levelsData.level_3 ? (
                          <Image
                            src={levelsData.level3_image}
                            inline
                            circular
                            size="mini"
                          />
                        ) : (
                          <Image
                            src={levelsData.level0_image}
                            inline
                            circular
                            size="mini"
                          />
                        )}
                      </div>

                      <div>
                        {currentLevel.currentLevelName === "GOLD" ? (
                          <React.Fragment></React.Fragment>
                        ) : currentLevel.currentLevelName === "SILVER" ? (
                          <Image
                            src={levelsData.level1_image}
                            inline
                            circular
                            size="mini"
                          />
                        ) : currentLevel.currentLevelName === "BRONZE" ? (
                          <Image
                            src={levelsData.level2_image}
                            inline
                            circular
                            size="mini"
                          />
                        ) : (
                          <Image
                            src={levelsData.level3_image}
                            inline
                            circular
                            size="mini"
                          />
                        )}
                      </div>
                    </div>
                    <div>
                      <Progress
                        percent={currentLevelPercentage}
                        size="tiny"
                        color="yellow"
                        active
                      >
                        {currentLevel.currentLevelName === "GOLD" ? (
                          <p
                            style={{
                              textAlign: "center",
                              fontSize: 14,
                              color: "#707070",
                            }}
                          >
                            Yay! You have reached the Final level
                          </p>
                        ) : currentLevel.currentLevelName === "SILVER" ? (
                          <p
                            style={{
                              textAlign: "center",
                              fontSize: 14,
                              color: "#707070",
                            }}
                          >
                            {levelsData.level_1} Kgs to GOLD Level
                          </p>
                        ) : currentLevel.currentLevelName === "BRONZE" ? (
                          <p
                            style={{
                              textAlign: "center",
                              fontSize: 14,
                              color: "#707070",
                            }}
                          >
                            {levelsData.level_2} Kgs to SILVER Level
                          </p>
                        ) : (
                          <p
                            style={{
                              textAlign: "center",
                              fontSize: 14,
                              color: "#707070",
                            }}
                          >
                            {levelsData.level_3} Kgs to BRONZE Level
                          </p>
                        )}
                      </Progress>
                    </div>
                  </div>
                </Responsive>
              </Grid.Column>
            }


            <Responsive maxWidth={767}>
              <Grid.Column>
                <div style={{ marginTop: 50, justifyContent: "center" }}>
                  <div
                    style={{ justifyContent: "space-between", display: "flex" }}
                  >
                    <div>
                      {merchantData.turnOver >= levelsData.level_1 ? (
                        <div style={{ marginLeft: 150 }}>
                          <Image
                            src={levelsData.level1_image}
                            inline
                            circular
                            size="mini"
                            style={{ width: "15%" }}
                          />
                        </div>
                      ) : merchantData.turnOver >= levelsData.level_2 ? (
                        <Image
                          src={levelsData.level2_image}
                          inline
                          circular
                          size="mini"
                          style={{ width: "15%" }}
                        />
                      ) : merchantData.turnOver >= levelsData.level_3 ? (
                        <Image
                          src={levelsData.level3_image}
                          inline
                          circular
                          size="mini"
                          style={{ width: "15%" }}
                        />
                      ) : (
                        <Image
                          src={levelsData.level0_image}
                          inline
                          circular
                          size="mini"
                          style={{ width: "15%" }}
                        />
                      )}
                    </div>

                    <div>
                      {currentLevel.currentLevelName === "GOLD" ? (
                        <React.Fragment></React.Fragment>
                      ) : currentLevel.currentLevelName === "SILVER" ? (
                        <Image
                          src={levelsData.level1_image}
                          inline
                          circular
                          size="mini"
                          style={{ width: "15%" }}
                        />
                      ) : currentLevel.currentLevelName === "BRONZE" ? (
                        <Image
                          src={levelsData.level2_image}
                          inline
                          circular
                          size="mini"
                          style={{ width: "15%" }}
                        />
                      ) : (
                        <Image
                          src={levelsData.level3_image}
                          inline
                          circular
                          size="mini"
                          style={{ width: "15%" }}
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <Progress
                      percent={currentLevelPercentage}
                      size="tiny"
                      color="yellow"
                      active
                    >
                      {currentLevel.currentLevelName === "GOLD" ? (
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: 14,
                            color: "#707070",
                          }}
                        >
                          Yay! You have reached the Final level
                        </p>
                      ) : currentLevel.currentLevelName === "SILVER" ? (
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: 14,
                            color: "#707070",
                          }}
                        >
                          {levelsData.level_1} Turn over to GOLD Level
                        </p>
                      ) : currentLevel.currentLevelName === "BRONZE" ? (
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: 14,
                            color: "#707070",
                          }}
                        >
                          {levelsData.level_2} Turn over to SILVER Level
                        </p>
                      ) : (
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: 14,
                            color: "#707070",
                          }}
                        >
                          {levelsData.level_3} Turn over to BRONZE Level
                        </p>
                      )}
                    </Progress>
                  </div>
                </div>
              </Grid.Column>
            </Responsive>
          </Grid>
        </div>
      </div>
      <div
        className="CardContainer"
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        <MerchantProfileDetails merchantDetails={merchantData} />
      </div>
    </div>
  );
};

export default MerchantProfilePage;

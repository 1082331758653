import React, { useEffect, useState } from "react";
import {
  Table,
  Label,
  Modal,
  Icon,
  Checkbox,
  Button,
  Input,
  Form,
  TextArea,
  Dropdown,
  Select
} from "semantic-ui-react";
import _ from "lodash";
import toastr from "toastr";
import { Link } from "react-router-dom";
import {
  HEADING_DATA,
  TABLE_DATA,
  CUSTOM_DATA
} from "./merchant-couponcode-constant";
import { BASE_URL } from "../../../../../config";

const MerchantCustomCouponCode = () => {
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);

  const [couponsList, setCouponsList] = useState();
  const [users, setUsers] = useState([]);
  const [customCouponList, setCustomCouponList] = useState([]);

  useEffect(() => {
    getCouponCodes();

    getUsers();
  }, []);

  const getUsers = () => {
    fetch(`${BASE_URL}/get-all-users`)
      .then(res => res.json())
      .then(json => {
        setUsers(json.users);
      });
  };

  const getCouponCodes = () => {
    fetch(`${BASE_URL}/get-merchant-couponcodes`)
      .then(res => res.json())
      .then(json => {
        setCouponsList(json.merchantCouponCodes);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleSort = clickedColumn => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setDirection("ascending");
      return;
    }

    setDirection(direction === "ascending" ? "descending" : "ascending");
  };

  const handleUserChange = phone => {
    // const { users } = this.state;
    const user = users.filter(user => user.phoneNumber.indexOf(phone) !== -1);
    const coupons = user[0].coupons;
    setCustomCouponList(coupons);
    // this.setState({ userCoupons: coupons });
  };

  return (
    <div className="TabContainer">
      <div className="AddMemberRow">
        <h3>Custom Coupon Codes</h3>
        <Select
          placeholder="Select your user"
          options={users.map(user => ({
            key: user._id,
            value: user.phoneNumber,
            text: user.phoneNumber + `${" - "}` + user.name
          }))}
          onChange={(event, data) => handleUserChange(data.value)}
          style={{ width: 250 }}
        />
      </div>

      <div>
        <Table
          sortable
          unstackable
          celled
          basic="very"
          className="CustomTableLayout"
          id="merchant-table"
        >
          <Table.Header>
            <Table.Row>
              {CUSTOM_DATA.map(heading => (
                <Table.HeaderCell
                  sorted={column === heading ? direction : null}
                  onClick={handleSort(heading)}
                >
                  {heading === "Checkbox" ? (
                    <Checkbox />
                  ) : (
                    <span>{heading}</span>
                  )}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {customCouponList !== undefined ? (
              customCouponList.map((coupon, index) => (
                <Table.Row>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>{coupon.description}</Table.Cell>
                  <Table.Cell>{coupon.startDate}</Table.Cell>
                  <Table.Cell>{coupon.endDate}</Table.Cell>
                  <Table.Cell>{coupon.code}</Table.Cell>
                  <Table.Cell>{coupon.discount}</Table.Cell>
                  {/* <Table.Cell>
                    <Button
                      style={{ fontSize: 13, backgroundColor: "#f3f3f3" }}
                      onClick={editCoupon(coupon)}
                    >
                      <Icon name="edit" />
                      Edit
                    </Button>

                    <Button
                      style={{ fontSize: 13, backgroundColor: "#f4cccc" }}
                      onClick={deleteCoupon(coupon._id)}
                    >
                      <Icon name="trash" /> Delete
                    </Button>
                  </Table.Cell> */}
                </Table.Row>
              ))
            ) : (
              <p>No Coupon Codes Found</p>
            )}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default MerchantCustomCouponCode;

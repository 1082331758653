import React, { useState } from "react";
import { Label, List, Tab, Table, Modal } from "semantic-ui-react";
import moment from "moment";

const CustomerProfileDetails = (props) => {
  const panes = [
    {
      menuItem: "Customer Details",
      render: () => (
        <Tab.Pane style={{ marginLeft: -10 }}>
          <CustomerDetails userDetails={props.customerDetails} />
        </Tab.Pane>
      ),
    },
    // {
    //   menuItem: "Gst Details",
    //   render: () => (
    //     <Tab.Pane style={{ marginLeft: -10 }}>
    //       <GSTDetails userDetails={props.customerDetails} />
    //     </Tab.Pane>
    //   ),
    // },
    {
      menuItem: "Billing Details",
      render: () => (
        <Tab.Pane style={{ marginLeft: -10 }}>
          <BillingDetails userDetails={props.customerDetails} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Order History",
      render: () => (
        <Tab.Pane style={{ marginLeft: -10 }}>
          <OrderHistory userDetails={props.customerDetails} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div>
      <Tab
        menu={{
          fluid: true,
          vertical: true,
          tabular: true,
          pointing: true,
        }}
        panes={panes}
      />
    </div>
  );
};

const CustomerDetails = (props) => {
  const { userDetails } = props;

  return (
    <List divided animated>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{userDetails.name}</p>
        </List.Content>
        <List.Content>Customer Name: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{userDetails.email}</p>
        </List.Content>
        <List.Content>Customer email: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{userDetails.address}</p>
        </List.Content>
        <List.Content>Address: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <h4>{userDetails.gstNo}</h4>
        </List.Content>
        <List.Content>GST No: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{moment(userDetails.createdAt).format("ll")}</p>
        </List.Content>
        <List.Content>Registration Date: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{moment(userDetails.createdAt).format("ll")}</p>
        </List.Content>
        <List.Content>Account Activated on :</List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <Label color="green">active</Label>
        </List.Content>
        <List.Content>Account Status :</List.Content>
      </List.Item>
    </List>
  );
};

const GSTDetails = (props) => {
  const { userDetails } = props;

  return (
    <List divided animated>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{userDetails.gstNo}</p>
        </List.Content>
        <List.Content>GST details: </List.Content>
      </List.Item>
    </List>
  );
};

const BillingDetails = (props) => {
  const { userDetails } = props;
  const [showorderDetailsModal, setShowOrderDetailsModal] = useState(false);
  const close = () => {
    setShowOrderDetailsModal(false);
  };
  return (
    <List divided animated>
      <List.Item className="DetailListItems">
        <List.Content>List of Transactions : </List.Content>
        <Table
          sortable
          unstackable
          celled
          basic="very"
          className="CustomTableLayout"
        >
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>Order ID</Table.HeaderCell>
              <Table.HeaderCell>Details</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {userDetails.ordersData.map((order, index) => (
              <Table.Row textAlign="center">
                <Table.Cell>#{order.order_id}</Table.Cell>
                <Table.Cell>
                  <Table>
                    <Table.Header>
                      <Table.Row textAlign="center">
                        <Table.HeaderCell>Sno</Table.HeaderCell>
                        <Table.HeaderCell>Transaction Date</Table.HeaderCell>
                        <Table.HeaderCell>Transaction ID</Table.HeaderCell>
                        <Table.HeaderCell>Transaction Amount</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {order.transactions.map((transaction, idx) => (
                        <Table.Row textAlign="center">
                          <Table.Cell>{idx + 1}</Table.Cell>
                          <Table.Cell>
                            {transaction.transaction_date}
                          </Table.Cell>
                          <Table.Cell>{transaction.transaction_id}</Table.Cell>
                          <Table.Cell>
                            {transaction.transaction_amount}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </List.Item>

      <div>
        <Modal size={"tiny"} open={showorderDetailsModal} onClose={close}>
          <div className="modal-container">
            <h3 className="orderdetailsheading">Order Details</h3>
            <div>
              <div style={{ display: "flex" }}>
                <p style={{ width: "30%" }}>Order ID:</p>
                <p style={{ paddingLeft: 5, fontWeight: "700" }}>#08142678</p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ width: "30%" }}>Name:</p>
                <p style={{ paddingLeft: 5, fontWeight: "700" }}>Akhil</p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ width: "30%" }}>User role:</p>
                <p style={{ paddingLeft: 5 }}>merchant</p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ width: "30%" }}>Mobile number:</p>
                <p style={{ paddingLeft: 5, fontWeight: "700" }}>8985747557</p>
              </div>
              <div style={{ display: "flex", paddingBottom: 10 }}>
                <p style={{ width: "30%" }}>Address:</p>
                <p style={{ paddingLeft: 5, width: "70%" }}>
                  620, Spaces, EA tower 2, 50l, Whites Road, Express Estate,
                  Royapettah, Chennai, 600014
                </p>
              </div>
              <div style={{ display: "flex", paddingBottom: 10 }}>
                <p style={{ width: "30%" }}>Products:</p>
                <p style={{ paddingLeft: 5, width: "70%" }}>
                  1) Basmati rice: 1 x 5Kg - ₹400
                </p>
              </div>
              <div style={{ display: "flex", paddingBottom: 10 }}>
                <p style={{ width: "30%" }}>Order Status:</p>
                <p style={{ paddingLeft: 5, width: "70%" }}>DELIVERED</p>
              </div>
              <div style={{ display: "flex", paddingBottom: 10 }}>
                <p style={{ width: "30%" }}>Delivered Date:</p>
                <p style={{ paddingLeft: 5, width: "70%" }}>02-07-20</p>
              </div>
              <div style={{ display: "flex", paddingBottom: 10 }}>
                <p style={{ width: "30%" }}>Payment Type:</p>
                <p style={{ paddingLeft: 5, width: "70%" }}>CASH</p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ width: "30%" }}>Total price:</p>
                <p style={{ paddingLeft: 5 }}>₹ 3000</p>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </List>
  );
};

const OrderHistory = (props) => {
  const { userDetails } = props;
  const [size, setSize] = useState("");
  const [orderDetails, setOrderDetails] = useState({});
  const [showorderDetailsModal, setShowOrderDetailsModal] = useState(false);

  const openOrder = (size, order) => {
    setOrderDetails(order);
    setSize(size);
    setShowOrderDetailsModal(true);
  };
  const close = () => {
    setShowOrderDetailsModal(false);
  };

  return (
    <List divided animated>
      <List.Item className="DetailListItems">
        <List.Content>Completed Orders : </List.Content>
        <Table
          sortable
          unstackable
          celled
          color="grey"
          className="CustomTableLayout"
        >
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>Order Id </Table.HeaderCell>
              <Table.HeaderCell>Name </Table.HeaderCell>
              <Table.HeaderCell>Products </Table.HeaderCell>
              <Table.HeaderCell>Delivered Address </Table.HeaderCell>
              <Table.HeaderCell>Payment type </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {userDetails.ordersData.map((order, index) => (
              <Table.Row textAlign="center" key={index}>
                <Table.Cell>
                  <p
                    className="maintenanceCost"
                    onClick={() => openOrder("tiny", order)}
                  >
                    #{order.order_id}
                  </p>
                </Table.Cell>
                <Table.Cell>{order.customer_name}</Table.Cell>
                <Table.Cell>
                  {order.products.map((product, idx) => (
                    <p>
                      {idx + 1}. {product.name} : {product.weight} kg x{" "}
                      {product.quantity}
                    </p>
                  ))}
                </Table.Cell>
                <Table.Cell>{order.customer_address}</Table.Cell>
                <Table.Cell>Cash</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </List.Item>
      <div>
        <Modal size={size} open={showorderDetailsModal} onClose={close}>
          <div className="modal-container">
            <h3 className="orderdetailsheading">Order Details</h3>
            <div>
              <div style={{ display: "flex" }}>
                <p style={{ width: "30%" }}>Order ID:</p>
                <p style={{ paddingLeft: 5, fontWeight: "700" }}>
                  #{orderDetails.order_id}
                </p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ width: "30%" }}>Name:</p>
                <p style={{ paddingLeft: 5, fontWeight: "700" }}>
                  {orderDetails.customer_name}
                </p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ width: "30%" }}>User role:</p>
                <p style={{ paddingLeft: 5 }}>{orderDetails.customer_role}</p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ width: "30%" }}>Mobile number:</p>
                <p style={{ paddingLeft: 5, fontWeight: "700" }}>
                  {orderDetails.customer_phone}
                </p>
              </div>
              <div style={{ display: "flex", paddingBottom: 10 }}>
                <p style={{ width: "30%" }}>Address:</p>
                <p style={{ paddingLeft: 5, width: "70%" }}>
                  {orderDetails.customer_address}
                </p>
              </div>

              <div style={{ display: "flex", paddingBottom: 10 }}>
                <p style={{ width: "30%" }}>Payment Type:</p>
                <p style={{ paddingLeft: 5, width: "70%" }}>CASH</p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ width: "30%" }}>Total price:</p>
                <p style={{ paddingLeft: 5 }}>₹ {orderDetails.total_amount}</p>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </List>
  );
};

export default CustomerProfileDetails;

import React, { useState } from "react";
import { Icon, Input, Button } from "semantic-ui-react";
import toastr from "toastr";
import { BASE_URL } from "../../../config";
import UniquecodeTab from "../../commons/unicodetab/uniquecodetab.component";
import SpinWheelAmount from "./spinwheelamount.component";
import csv from "csv";

const UniquecodesComponent = (props) => {
  return (
    <div className="DefaultLayout">
      <Header history={props.history} />
      <Card history={props.history} />
    </div>
  );
};

const Header = (props) => {
  return (
    <div className="HeaderLayout">
      <div className="addProductRow">
        <h3>Diwali Offer Settings </h3>
      </div>
      <UniquecodeTab history={props.history} />
    </div>
  );
};

const Card = () => {
  const [uniqueCodes, setUniqueCodes] = useState("");

  const saveUniqueCode = () => {
    fetch(`${BASE_URL}/create-uniquecode`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uniqueCodes,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === 200) {
          toastr.success("Unique Codes Uploaded Successfully");
          window.location.reload(true);
        }
      });
  };

  const onChangeHandler = (e) => {
    const files = e.target.files;

    var file = files[0];

    const reader = new FileReader();
    reader.onload = () => {
      csv.parse(reader.result, (err, data) => {
        var userList = [];

        for (var i = 0; i < data.length; i++) {
          const unique_code = data[i][0];
          const weight = data[i][1];
          const cashback = data[i][2];
          const date = data[i][3];
          const newUser = {
            unique_code: unique_code,
            weight: weight,
            cashback: cashback,
            expiry_date: date,
            usage_status: false,
          };
          userList.push(newUser);
          setUniqueCodes(userList);
        }
      });
    };

    reader.readAsBinaryString(file);
  };

  return (
    <div className="CardContainer">
      <div className="productsView">
        <div>
          <h3>Unique Codes</h3>
          <div className="customerFormRow">
            <p className="customerFormTitle">Upload File:</p>

            <Input
              type="file"
              name="file"
              accept=".csv"
              onChange={onChangeHandler}
            />
          </div>
          <div style={{ textAlign: "center", marginTop: 30 }}>
            <Button
              className="addCustomersubmitBtn"
              content="Submit"
              onClick={() => saveUniqueCode()}
            />
          </div>
        </div>
        <SpinWheelAmount />
      </div>
    </div>
  );
};

export default UniquecodesComponent;

export const HEADING_DATA_DRIVER_FEEDBACK = [
    "S.No",
    "Delivery Agent Details",
    "Rating",
    "Message"
];

export const HEADING_DATA_CUSTOMER_FEEDBACK = [
    "S.No",
    "Merchant Details",
    "Rating",
    "Message"
];

export const HEADING_DATA_PRODUCT_FEEDBACK = [
    "#Order ID",
    "Customer Details",
    "Rating",
    "Message"
];
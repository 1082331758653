import React, { Component } from "react";
import { Button, Modal, Icon } from "semantic-ui-react";

class OrderDetailsModal extends Component {
  state = { open: false };

  show = size => () => this.setState({ size, open: true });
  close = () => this.setState({ open: false });

  render() {
    const { open, size } = this.state;
    const { orderdetails } = this.props;

    return (
      <div>
        <p className="orderId" onClick={this.show("tiny")}>
          #{orderdetails.order_id}
        </p>

        <Modal size={size} open={open} onClose={this.close}>
          <div className="modal-container">
            <h3 className="orderdetailsheading">Order Details</h3>
            <div>
              <div style={{ display: "flex" }}>
                <p style={{ width: "30%" }}>Order ID:</p>
                <p style={{ paddingLeft: 5, fontWeight: "700" }}>
                  #{orderdetails.order_id}
                </p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ width: "30%" }}>Name:</p>
                <p style={{ paddingLeft: 5, fontWeight: "700" }}>
                  {orderdetails.name}
                </p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ width: "30%" }}>User role:</p>
                <p style={{ paddingLeft: 5 }}>{orderdetails.user_role}</p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ width: "30%" }}>Mobile number:</p>
                <p style={{ paddingLeft: 5, fontWeight: "700" }}>
                  {orderdetails.phone_number}
                </p>
              </div>
              <div style={{ display: "flex", paddingBottom: 10 }}>
                <p style={{ width: "30%" }}>Address:</p>
                <p style={{ paddingLeft: 5, width: "50%" }}>
                  {orderdetails.shipping_address.detailedAddress}
                </p>
              </div>

              <div style={{ display: "flex" }}>
                <p style={{ width: "30%" }}>Total price:</p>
                <p style={{ paddingLeft: 5 }}>
                  {" "}
                  <Icon name="rupee sign" size="small" />
                  {orderdetails.order_total}
                </p>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default OrderDetailsModal;
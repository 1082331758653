import React from "react";
import { Label, List, Tab, Table } from "semantic-ui-react";
import moment from "moment";

const VendorProfileDetails = (props) => {
  const panes = [
    {
      menuItem: "Vendor Details",
      render: () => (
        <Tab.Pane style={{ marginLeft: -10 }}>
          <VendorDetails userDetails={props.vendorDetails} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "GST Details",
      render: () => (
        <Tab.Pane style={{ marginLeft: -10 }}>
          <GSTDetails userDetails={props.vendorDetails} />
        </Tab.Pane>
      ),
    },

    {
      menuItem: "Billing Details",
      render: () => (
        <Tab.Pane style={{ marginLeft: -10 }}>
          <BillingDetails userDetails={props.vendorDetails} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Purchase History",
      render: () => (
        <Tab.Pane style={{ marginLeft: -10 }}>
          <PurchaseHistory userDetails={props.vendorDetails} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div>
      <Tab
        menu={{
          fluid: true,
          vertical: true,
          tabular: true,
          pointing: true,
        }}
        panes={panes}
      />
    </div>
  );
};

const VendorDetails = (props) => {
  const { userDetails } = props;

  return (
    <List divided animated>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <h4>#VEND00001</h4>
        </List.Content>
        <List.Content>Vendor ID: </List.Content>
      </List.Item>

      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{userDetails.name}</p>
        </List.Content>
        <List.Content>Vendor Name: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{userDetails.email}</p>
        </List.Content>
        <List.Content>Vendor email: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{userDetails.vendorType}</p>
        </List.Content>
        <List.Content>Vendor Type: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{userDetails.address}</p>
        </List.Content>
        <List.Content>Address: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{moment(userDetails.createdAt).format("ll")}</p>
        </List.Content>
        <List.Content>Registration Date: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{moment(userDetails.createdAt).format("ll")}</p>
        </List.Content>
        <List.Content>Account Activated on :</List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <Label color="green">active</Label>
        </List.Content>
        <List.Content>Account Status :</List.Content>
      </List.Item>
    </List>
  );
};

const GSTDetails = (props) => {
  const { userDetails } = props;

  return (
    <List divided animated>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{userDetails.gstNo}</p>
        </List.Content>
        <List.Content>GST details: </List.Content>
      </List.Item>
    </List>
  );
};

const BillingDetails = (props) => {
  const { userDetails } = props;

  const total_transactions =
    userDetails.transactions !== undefined ? userDetails.transactions : [];

  const transactions = total_transactions.filter((transaction) =>
    Object.keys(transaction).some(
      (key) => transaction[key].toString().search("unpaid") !== -1
    )
  );

  const getTotalPurchaseValue = () => {
    const total_value = total_transactions.reduce(
      (sum, transaction, index) =>
        sum +
        (Number(transaction.amount_given) + Number(transaction.balance_amount)),
      0
    );

    return total_value;
  };

  const getTotalpaidValue = () => {
    const total_paid = total_transactions.reduce(
      (sum, transaction, index) => sum + Number(transaction.amount_given),
      0
    );

    return total_paid;
  };

  const getTotalBalanceValue = () => {
    const balance = total_transactions.reduce(
      (sum, transaction, index) => sum + Number(transaction.balance_amount),
      0
    );

    const total_balance = getTotalpaidValue() - balance;

    return total_balance;
  };

  return (
    <List divided animated>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <h4>₹ {getTotalPurchaseValue().toLocaleString("en-IN")}</h4>
        </List.Content>
        <List.Content>Total Purchase Value: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <Label>₹ {getTotalpaidValue().toLocaleString("en-IN")}</Label>
        </List.Content>
        <List.Content>Total Paid: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <Label color="red">
            ₹ {getTotalBalanceValue().toLocaleString("en-IN")}
          </Label>
        </List.Content>
        <List.Content>Payment Balance: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content>Payment Balance in Purchase : </List.Content>
        <Table
          sortable
          unstackable
          celled
          color="grey"
          className="CustomTableLayout"
        >
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>Purchase Id </Table.HeaderCell>
              <Table.HeaderCell> Date </Table.HeaderCell>
              <Table.HeaderCell>Amount paid </Table.HeaderCell>
              <Table.HeaderCell>Balance </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {transactions.map((transaction, index) => (
              <Table.Row textAlign="center" key={index}>
                <Table.Cell>#{transaction.purchase_id}</Table.Cell>
                <Table.Cell>
                  {moment(transaction.date).format("DD-MM-YYYY")}
                </Table.Cell>
                <Table.Cell>₹ {transaction.amount_given}</Table.Cell>
                <Table.Cell>₹ {transaction.balance_amount}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </List.Item>
    </List>
  );
};

const PurchaseHistory = (props) => {
  const { userDetails } = props;

  const transactions =
    userDetails.transactions !== undefined ? userDetails.transactions : [];

  return (
    <List divided animated>
      <List.Item className="DetailListItems">
        <List.Content>Purchase History : </List.Content>
        <Table
          sortable
          unstackable
          celled
          color="grey"
          className="CustomTableLayout"
        >
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>Purchase Id </Table.HeaderCell>
              <Table.HeaderCell>Date </Table.HeaderCell>
              <Table.HeaderCell>Amount Paid </Table.HeaderCell>
              <Table.HeaderCell>Payment type </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {transactions.map((transaction, index) => (
              <Table.Row textAlign="center" key={index}>
                <Table.Cell>#{transaction.purchase_id}</Table.Cell>
                <Table.Cell>
                  {moment(transaction.date).format("DD-MM-YYYY")}
                </Table.Cell>
                <Table.Cell>₹ {transaction.amount_given}</Table.Cell>
                <Table.Cell>₹ {transaction.balance_amount}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </List.Item>
    </List>
  );
};

export default VendorProfileDetails;

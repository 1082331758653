import React, { useEffect, useState } from "react";
import { Input, Button } from "semantic-ui-react";
import toastr from "toastr";
import { BASE_URL } from "../../../../config";

const AdminSettings = () => {
  const [site_title, setSiteTitle] = useState("");
  const [tagline, setTagLine] = useState("");
  const [admin_email, setAdminEmail] = useState("");
  const [otp_contact_person, setOtpContactPerson] = useState("");
  const [generalSettingsID, setSettingsID] = useState("");
  const [noData, setNoData] = useState("");

  useEffect(() => {
    fetchGeneralSettings();
  }, []);

  const fetchGeneralSettings = () => {
    fetch(`${BASE_URL}/get-general-settings`)
      .then((res) => res.json())
      .then((json) => {
        if (json.general_settings[0] === undefined) {
          setNoData("true");
          setSiteTitle("");
          setTagLine("");
          setAdminEmail("");
          setOtpContactPerson("");
        } else {
          setNoData("false");
          setSettingsID(json.general_settings[0]._id);
          setSiteTitle(json.general_settings[0].site_title);
          setTagLine(json.general_settings[0].tagline);
          setAdminEmail(json.general_settings[0].admin_email);
          setOtpContactPerson(json.general_settings[0].otp_contact_person);
        }
      });
  };

  const saveGeneralSettings = () => {
    fetch(`${BASE_URL}/create-general-settings`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        site_title,
        tagline,
        admin_email,
        otp_contact_person
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        fetchGeneralSettings();
        toastr.success("General Settings Saved");
      });
  };

  const updateGeneralSettings = () => {
    fetch(
      `${BASE_URL}/update-general-settings?general_settings_id=${generalSettingsID}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          site_title,
          tagline,
          admin_email,
          otp_contact_person
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        fetchGeneralSettings();
        toastr.success("General Settings Updated Successfuly");
      });
  };

  return (
    <div>
      <div className="SettingsFormRow">
        <p className="SettingsFormTitle">Site Title:</p>
        <Input
          placeholder="Enter Company Name"
          style={{ width: "50%" }}
          value={site_title}
          onChange={(e) => setSiteTitle(e.target.value)}
        />
      </div>
      <div className="SettingsFormRow">
        <p className="SettingsFormTitle">Tagline:</p>
        <Input
          placeholder="Enter Tagline"
          style={{ width: "50%" }}
          value={tagline}
          onChange={(e) => setTagLine(e.target.value)}
        />
      </div>
      <div className="SettingsFormRow">
        <p className="SettingsFormTitle">Admin Email:</p>
        <Input
          placeholder="Enter Email"
          style={{ width: "50%" }}
          value={admin_email}
          onChange={(e) => setAdminEmail(e.target.value)}
        />
      </div>
      <div className="SettingsFormRow">
        <p className="SettingsFormTitle">OTP Person Contact:</p>
        <Input
          placeholder="Enter Mobile Number"
          style={{ width: "50%" }}
          value={otp_contact_person}
          onChange={(e) => setOtpContactPerson(e.target.value)}
          maxLength="10"
        />
      </div>
      {noData === "true" ? (
        <div className="SubmitBtnRow">
          <Button
            className="submitBtn"
            content="Save Changes"
            onClick={saveGeneralSettings}
          />
        </div>
      ) : (
        <div className="SubmitBtnRow">
          <Button
            className="submitBtn"
            content="Update Changes"
            onClick={updateGeneralSettings}
          />
        </div>
      )}
    </div>
  );
};

export default AdminSettings;

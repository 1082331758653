import moment from "moment";
import { BASE_URL } from "../../../config";

export const getOrders = () =>
  fetch(`${BASE_URL}/get-all-orders`).then((res) => res.json());

export const getDeliveryAgents = () =>
  fetch(`${BASE_URL}/get-drivers`).then((res) => res.json());

export const getDeliveryVehicles = () =>
  fetch(`${BASE_URL}/get-assets`).then((res) => res.json());

export const updateDeliveryAgent = (data) =>
  fetch(`${BASE_URL}/assign-delivery-agent`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      agent: data.agent,
      order_id: data.orderid,
      status: "DRIVER_ASSIGNED",
    }),
  }).then((res) => res.json());

export const updateDeliveryVehicle = (data) =>
  fetch(`${BASE_URL}/assign-delivery-vehicle`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      vehicle: data.vehicle,
      order_id: data.orderid,
      status: "OUT_FOR_DELIVERY",
    }),
  }).then((res) => res.json());

export const updateOrderStatus = (data) =>
  fetch(`${BASE_URL}/update-order-status`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());

export const cancelOrderRequest = (data) =>
  fetch(`${BASE_URL}/cancel-order-request?order_ID=${data.id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ status: "CANCELLED", reason: data.reason })
  }).then(res => res.json());

export const getPercentage = (members) => {
  var date = new Date();
  var prevFirstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  var prevLastDay = new Date(date.getFullYear(), date.getMonth(), 0);
  var currFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var currLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const prevMon = members.filter(function (x) {
    return (
      (x.ordered_date > moment(prevFirstDay).format()) &
      (x.ordered_date < moment(prevLastDay).format())
    );
  });
  const currMon = members.filter(function (x) {
    return (
      (x.ordered_date > moment(currFirstDay).format()) &
      (x.ordered_date < moment(currLastDay).format())
    );
  });

  var perc = ((prevMon.length - currMon.length) / prevMon.length) * 100;

  if (perc === -Infinity) {
    return -100;
  } else {
    return perc;
  }
};

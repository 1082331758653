export const HEADING_DATA = [
  'S.No',
  'Asset Name',
  'Asset Type',
  'Purchase Value',
  'Mileage',
  'Maintenance Cost',
  'Expenses',
  'Assigned',
  'Actions',
];


export const TABLE_DATA = [
  {
    sno: ' 1',
    name: 'Tata ACE',
    asset_type: 'Vehicle',
    purchase_value: '4.7 Lakh',
    maintenance_cost: '₹ 10,000',

  },
  {
    sno: ' 2',
    name: 'System Tables ',
    asset_type: 'Furniture',
    purchase_value: '1 Lakh',
    maintenance_cost: '₹ 1,000',
  },
  {
    sno: ' 3',
    name: 'Laptops',
    asset_type: 'Electronics',
    purchase_value: '2 Lakh',
    maintenance_cost: '₹ 1,000',
  },
  {
    sno: ' 4',
    name: 'Paddy Separators',
    asset_type: 'Machinery',
    purchase_value: '1 Lakh',
    maintenance_cost: '₹ 10,000',
  },
];

export const ORDER_DETAILS = [
  {
    order_id: '08142678',
    name: "Akhil",
    phoneNumber: "8985747557",
    userRole: "merchant",
    products : [
      {
        "amount" : "400",
        "quantity" : 1,
        "product_id" : "5e11bc006a63de0385496f2d",
        "price" : "400",
        "weight" : "5",
        "main_image" : "https://res.cloudinary.com/dgvup74b7/image/upload/v1578220413/jgiddrfhgwwwrmkz3oxc.jpg",
        "name" : "Basmati rice"
      }
    ],
    status: "DELIVERED",   /* "ORDER_REQUESTED", "INVOICE_GENERATED", "DRIVER_ASSIGNED", "VEHICLE_ASSIGNED", "OUT_OF_DELIVERY", "DELIVERED" */
    order_total: 3000,
    delivery_address : {
      addressType : "WORK",
      detailedAddress : "620, Spaces, EA tower 2, 50l, Whites Road, Express Estate, Royapettah, Chennai, 600014",
      pincode : "600014"
    },
    ordered_date: "02-07-20"
  },
]
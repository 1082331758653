import React, { useEffect, useState } from "react";
import { Input, Button } from "semantic-ui-react";
import toastr from "toastr";
import { BASE_URL } from "../../../config";

const SpinWheelAmount = () => {
  const [cashback_amount1, setCashbackAmount1] = useState("");
  const [cashback_amount2, setCashbackAmount2] = useState("");
  const [cashback_amount3, setCashbackAmount3] = useState("");
  const [cashback_amount4, setCashbackAmount4] = useState("");
  const [cashback_amount5, setCashbackAmount5] = useState("");
  const [cashback_amount6, setCashbackAmount6] = useState("");
  const [wheelSettingsID, setSettingsID] = useState("");
  const [noData, setNoData] = useState("");

  useEffect(() => {
    fetchWheelSettings();
  }, []);

  const fetchWheelSettings = () => {
    fetch(`${BASE_URL}/get-wheel-settings`)
      .then((res) => res.json())
      .then((json) => {
        if (json.wheel_settings[0] === undefined) {
          setNoData("true");
          setCashbackAmount1("");
          setCashbackAmount2("");
          setCashbackAmount3("");
          setCashbackAmount4("");
          setCashbackAmount5("");
          setCashbackAmount6("");
        } else {
          setNoData("false");
          setSettingsID(json.wheel_settings[0]._id);
          setCashbackAmount1(json.wheel_settings[0].cashback_amount1);
          setCashbackAmount2(json.wheel_settings[0].cashback_amount2);
          setCashbackAmount3(json.wheel_settings[0].cashback_amount3);
          setCashbackAmount4(json.wheel_settings[0].cashback_amount4);
          setCashbackAmount5(json.wheel_settings[0].cashback_amount5);
          setCashbackAmount6(json.wheel_settings[0].cashback_amount6);
        }
      });
  };

  const saveWheelSettings = () => {
    fetch(`${BASE_URL}/create-wheel-settings`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cashback_amount1,
        cashback_amount2,
        cashback_amount3,
        cashback_amount4,
        cashback_amount5,
        cashback_amount6,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        fetchWheelSettings();
        toastr.success("Wheel Settings Saved");
      });
  };

  const updateWheelSettings = () => {
    fetch(
      `${BASE_URL}/update-wheel-settings?wheel_settings_id=${wheelSettingsID}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cashback_amount1,
          cashback_amount2,
          cashback_amount3,
          cashback_amount4,
          cashback_amount5,
          cashback_amount6,
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        fetchWheelSettings();
        toastr.success("Wheel Settings Updated Successfuly");
      });
  };

  return (
    <div style={{ marginTop: 30 }}>
      <h3>Spin Wheel Amounts</h3>
      <div className="customerFormRow">
        <p className="customerFormTitle">1st Amount in Wheel:</p>
        <Input
          placeholder="Enter 1st Amount for Wheel"
          style={{ width: "20%" }}
          value={cashback_amount1}
          maxLength="3"
          onChange={(e) => setCashbackAmount1(e.target.value)}
        />
      </div>
      <div className="customerFormRow">
        <p className="customerFormTitle">2st Amount in Wheel:</p>
        <Input
          placeholder="Enter 2st Amount for Wheel"
          style={{ width: "20%" }}
          value={cashback_amount2}
          maxLength="3"
          onChange={(e) => setCashbackAmount2(e.target.value)}
        />
      </div>
      <div className="customerFormRow">
        <p className="customerFormTitle">3st Amount in Wheel:</p>
        <Input
          placeholder="Enter 3st Amount for Wheel"
          style={{ width: "20%" }}
          value={cashback_amount3}
          maxLength="3"
          onChange={(e) => setCashbackAmount3(e.target.value)}
        />
      </div>
      <div className="customerFormRow">
        <p className="customerFormTitle">4st Amount in Wheel:</p>
        <Input
          placeholder="Enter 4st Amount for Wheel"
          style={{ width: "20%" }}
          value={cashback_amount4}
          maxLength="3"
          onChange={(e) => setCashbackAmount4(e.target.value)}
        />
      </div>
      <div className="customerFormRow">
        <p className="customerFormTitle">5st Amount in Wheel:</p>
        <Input
          placeholder="Enter 5st Amount for Wheel"
          style={{ width: "20%" }}
          value={cashback_amount5}
          maxLength="3"
          onChange={(e) => setCashbackAmount5(e.target.value)}
        />
      </div>
      <div className="customerFormRow">
        <p className="customerFormTitle">6st Amount in Wheel:</p>
        <Input
          placeholder="Enter 6st Amount for Wheel"
          style={{ width: "20%" }}
          value={cashback_amount6}
          maxLength="3"
          onChange={(e) => setCashbackAmount6(e.target.value)}
        />
      </div>
      {noData === "true" ? (
        <div className="SubmitBtnRow">
          <Button
            className="submitBtn"
            content="Save Changes"
            onClick={saveWheelSettings}
          />
        </div>
      ) : (
        <div className="SubmitBtnRow">
          <Button
            className="submitBtn"
            content="Update Changes"
            onClick={updateWheelSettings}
          />
        </div>
      )}
    </div>
  );
};

export default SpinWheelAmount;

import React, { useEffect, useState } from "react";
import {
  Table,
  Label,
  Icon,
  Checkbox,
  Button,
  Input,
  Select,
} from "semantic-ui-react";
import toastr from "toastr";
import { HEADING_DATA } from "./customerbilling.constant";
import { BASE_URL } from "../../../config";
import TableExport from "tableexport";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

const MerchantBillingTable = () => {
  const today = moment();
  const [invoices, setInvoices] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState(
    moment.range(today.clone().subtract(7, "days"), today.clone())
  );
  const [companies, setCompanies] = useState([]);
  const [collectionName, setCollectionName] = useState({});
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    fetch(`${BASE_URL}/get-companies`)
      .then((res) => res.json())
      .then((json) => {
        if(json.companies.length > 0) {
          setCompanies(json.companies);
          setCollectionName(`${json.companies[0].collection_name}_invoices`);
          fetch(
            `${BASE_URL}/get-invoices?cname=${`${json.companies[0].collection_name}_invoices`}`
          )
            .then((res) => res.json())
            .then((json) => {
              setInvoices(json.invoices);
            });
        }
      });
  }, []);

  const onSelect = (value, states) => {
    fetch(
      `${BASE_URL}/get-allinvoices-by-range?startDate=${value.start.format(
        "DD-MM-YYYY"
      )}&endDate=${value.end.format("DD-MM-YYYY")}&cname=${collectionName}`
    )
      .then((res) => res.json())
      .then((json) => {
        setValue(value);
        setOpen(!isOpen);
        setInvoices(json.result);
      });
  };

  const onToggle = () => {
    setOpen(!isOpen);
  };

  const invoices_table = invoices.filter((invoice) =>
    Object.keys(invoice).some(
      (key) => invoice[key].toString().search(searchString.length > 0 ? searchString : "MERCHANT") !== -1
    )
  );

  const merchant_invoices = invoices_table.filter((invoice) =>
    Object.keys(invoice).some(
      (key) => invoice[key].toString().search("fullypaid") !== -1
    )
  );

  const handleChangeCompany = (id) => {
    const data = companies.filter((company) => company._id === id);
    const company = data[0];
    setCollectionName(`${company.collection_name}_invoices`);
    fetch(`${BASE_URL}/get-invoices?cname=${`${company.collection_name}_invoices`}`)
      .then((res) => res.json())
      .then((json) => {
        setInvoices(json.invoices);
      });
  };

  return (
    <div>
      <div className="BillingTitleRow">
        <div style={{ marginTop: 10 }}>
          <Button
            icon
            labelPosition="right"
            style={{
              backgroundColor: "#d9ead3",
              color: "#000",
              marginBottom: 15,
            }}
            onClick={() => {
              toastr.success("Exported Buttons Enabled Successfully");
              const table = new TableExport(
                document.getElementById("merchant-invoices"),
                {
                  formats: ["csv"],
                  exportButtons: true,
                  position: "top",
                }
              );
              const link = document.createElement("a");
              link.setAttribute(
                "download",
                table.getExportData()["merchant-invoices"].csv.filename
              );
              link.click();
            }}
          >
            Export
            <Icon name="share square icon" />
          </Button>
        </div>

        <div style={{ marginTop: 10 }}>
          <Select
            placeholder={companies.length > 0 ? companies[0].company_name : 'Select Company'}
            options={companies.map((company) => ({
              key: company._id,
              value: company._id,
              text: company.company_name,
            }))}
            style={{ margin: "auto" }}
            onChange={(event, data) => handleChangeCompany(data.value)}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          Select Range:{" "}
          <input
            type="text"
            onClick={onToggle}
            style={{ width: 170 }}
            value={`${value.start.format("DD-MM-YYYY")} - ${value.end.format(
              "DD-MM-YYYY"
            )}`}
          />
          {isOpen && (
            <DateRangePicker
              value={value}
              onSelect={onSelect}
              singleDateRange={true}
            />
          )}
        </div>
        {/* <div style={{ marginTop: 10 }}>
          <Button.Group size="tiny">
            <Button>Open</Button>
            <Button.Or />
            <Button>Cancel</Button>
            <Button.Or />
            <Button>Download</Button>
          </Button.Group>
        </div> */}
        <div style={{ marginTop: 10 }}>
          <Input
            placeholder="Quick Search..."
            icon="search"
            style={{ marginRight: 10 }}
            onChange={(e) => setSearchString(e.target.value)}
          />
        </div>
      </div>
      <Table
        sortable
        unstackable
        celled
        basic="very"
        className="CustomTableLayout"
        id="merchant-invoices"
      >
        <Table.Header>
          <Table.Row>
            {HEADING_DATA.map((heading) => (
              <Table.HeaderCell>
                {heading === "Checkbox" ? <Checkbox /> : <span>{heading}</span>}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {merchant_invoices.map((invoice, index) => (
            <Table.Row>
              {/* <Table.cell>r</Table.cell> */}
              <Table.Cell>{index + 1}</Table.Cell>
              <Table.Cell>#{invoice.order_id}</Table.Cell>
              <Table.Cell>{invoice.customer_name}</Table.Cell>
              <Table.Cell>INR {invoice.total_amount}</Table.Cell>
              <Table.Cell>{invoice.invoice_no}</Table.Cell>
              <Table.Cell>COMPLETED</Table.Cell>
              <Table.Cell>CASH</Table.Cell>
              <Table.Cell>
                <Label color="grey">
                  <a
                    onClick={() =>
                      window.open(
                        `/#/view-invoice/${invoice.company_details.collection_name}_invoices/${invoice.invoice_no}`,
                        "_blank"
                      )
                    }
                  >
                    view
                  </a>
                </Label>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default MerchantBillingTable;

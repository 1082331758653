import React, { useEffect, useState } from "react";
import {
  Table,
  Label,
  Modal,
  Icon,
  Checkbox,
  Button,
  Input,
  Form,
  TextArea,
  Dropdown,
  Tab,
} from "semantic-ui-react";
import _ from "lodash";
import toastr from "toastr";
import { Link } from "react-router-dom";
import { HEADING_DATA, TABLE_DATA } from "./merchant-couponcode-constant";
import { BASE_URL } from "../../../../../config";
import MerchantCustomCouponCode from './custom-couponcode.component';

const couponSettings = [
  {
    menuItem: 'Coupon Codes',
    render: () => (
      <Tab.Pane attached={false}>
        <MerchantCouponCode />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Custom Coupon Codes',
    render: () => (
      <Tab.Pane attached={false}>
        <MerchantCustomCouponCode />
      </Tab.Pane>
    ),
  },
  
];

const MerchantCouponCode = () => {
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [code, setCode] = useState("");
  const [discount, setDiscount] = useState("");
  const [usageLimit, setUsageLimit] = useState("");
  const [selectedCouponID, setSelectedCouponID] = useState("");
  const [couponsList, setCouponsList] = useState();
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    getCouponCodes();

    fetch(`${BASE_URL}/get-all-users`)
      .then(res => res.json())
      .then(json => {
        setUsers(json.users);
      });
  }, []);

  const getCouponCodes = () => {
    fetch(`${BASE_URL}/get-merchant-couponcodes`)
      .then(res => res.json())
      .then(json => {
        setCouponsList(json.merchantCouponCodes);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const close = () => {
    setOpenAdd(false);
    setOpenDelete(false);
    setOpenEdit(false);
  };
  const deleteCoupon = Id => () => {
    setSelectedCouponID(Id);
    setOpenDelete(true);
  };
  const editCoupon = coupon => () => {
    setOpenEdit(true);
    setSelectedCouponID(coupon._id);
    setDescription(coupon.description);
    setStartDate(coupon.startDate);
    setEndDate(coupon.endDate);
    setCode(coupon.code);
    setDiscount(coupon.discount);
  };

  const handleSort = clickedColumn => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setDirection("ascending");
      return;
    }

    setDirection(direction === "ascending" ? "descending" : "ascending");
  };

  const handleSaveCoupon = () => {
    if ((description, startDate, endDate, code, discount)) {
      fetch(`${BASE_URL}/create-merchant-couponcodes`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          description,
          startDate,
          endDate,
          code,
          discount,
          selectedUsers,
        })
      })
        .then(res => res.json())
        .then(json => {
          close();
          getCouponCodes();
        });
    } else {
      toastr.error("Please fill all the details.");
    }
  };

  const handleUpdateCoupon = () => {
    fetch(
      `${BASE_URL}/update-merchant-couponcode?merchant_couponcode_id=${selectedCouponID}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          description,
          startDate,
          endDate,
          code,
          discount
        })
      }
    )
      .then(res => res.json())
      .then(json => {
        getCouponCodes();
        close();
      });
  };

  const handleDelete = () => {
    fetch(
      `${BASE_URL}/delete-merchant-couponcode?merchantCouponCodeID=${selectedCouponID}`,
      {
        method: "DELETE"
      }
    )
      .then(res => res.json())
      .then(json => {
        close();
        getCouponCodes();
      });
  };

  return (
    <div className="TabContainer">
      <div className="AddMemberRow">
        <h3>Merchant Coupon Codes</h3>
        <Button className="addMerchantBtn" onClick={() => setOpenAdd(true)}>
          <Icon name="plus" /> Add Coupon Code
        </Button>
      </div>

      {/* <Tab
        menu={{ borderless: true, pointing: true }}
        panes={couponSettings}
      /> */}

      <div>
        <Table
          sortable
          unstackable
          celled
          basic="very"
          className="CustomTableLayout"
          id="merchant-table"
        >
          <Table.Header>
            <Table.Row>
              {HEADING_DATA.map(heading => (
                <Table.HeaderCell
                  sorted={column === heading ? direction : null}
                  onClick={handleSort(heading)}
                >
                  {heading === "Checkbox" ? (
                    <Checkbox />
                  ) : (
                    <span>{heading}</span>
                  )}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {couponsList !== undefined ? (
              couponsList.map((coupon, index) => (
                <Table.Row>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>{coupon.description}</Table.Cell>
                  <Table.Cell>{coupon.startDate}</Table.Cell>
                  <Table.Cell>{coupon.endDate}</Table.Cell>
                  <Table.Cell>{coupon.code}</Table.Cell>
                  <Table.Cell>{coupon.discount}</Table.Cell>
                  <Table.Cell>
                    <Button
                      style={{ fontSize: 13, backgroundColor: "#f3f3f3" }}
                      onClick={editCoupon(coupon)}
                    >
                      <Icon name="edit" />
                      Edit
                    </Button>

                    <Button
                      style={{ fontSize: 13, backgroundColor: "#f4cccc" }}
                      onClick={deleteCoupon(coupon._id)}
                    >
                      <Icon name="trash" /> Delete
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <p>No Coupon Codes Found</p>
            )}
          </Table.Body>
        </Table>
      </div>

      <div>
        <Modal size="small" open={openAdd} onClose={close}>
          <Modal.Header>
            <span>Add Couponcode</span>
          </Modal.Header>
          <Modal.Content>
            <div className="merchantFormRow">
              <p className="merchantFormTitle">Coupon description:</p>
              <Form>
                <TextArea
                  cols="50"
                  maxLength="100"
                  style={{ paddingRight: 50 }}
                  onChange={evt => setDescription(evt.target.value)}
                />
              </Form>
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -50,
                  zIndex: 0,
                  color: "grey"
                }}
              >
                {description.length}/100
              </p>
            </div>

            <div className="merchantFormRow">
              <p className="merchantFormTitle">Start Date :</p>
              <input
                onChange={evt => setStartDate(evt.target.value)}
                type="date"
              />
            </div>

            <div className="merchantFormRow">
              <p className="merchantFormTitle">Expiry Date :</p>
              <input
                onChange={evt => setEndDate(evt.target.value)}
                type="date"
              />
            </div>
            <div className="merchantFormRow">
              <p className="merchantFormTitle">Code:</p>
              <Input
                placeholder="Enter Code"
                style={{ width: "50%" }}
                maxLength="10"
                onChange={evt => setCode(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey"
                }}
              >
                {code.length}/10
              </p>
            </div>
            <div className="merchantFormRow">
              <p className="merchantFormTitle">Discount % :</p>
              <Input
                placeholder="Enter Code"
                style={{ width: "50%" }}
                maxLength="3"
                onChange={evt => setDiscount(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey"
                }}
              >
                {discount.length}/3
              </p>
            </div>
            <div className="merchantFormRow">
              <p className="merchantFormTitle">Select user :</p>
              <Dropdown
                style={{ width: 400 }}
                placeholder="Select user"
                fluid
                multiple
                selection
                options={users.map(user => ({
                  key: user._id,
                  value: user.phoneNumber,
                  text: user.phoneNumber + `${" - "}` + user.name,
                  email: user.email
                }))}
                onChange={(event, data) => {
                  setSelectedUsers(data.value);
                }}
              />
            </div>
            {/* <div className="merchantFormRow">
              <p className="merchantFormTitle">Useage limit : </p>
              <Input
                placeholder="Enter limit"
                style={{ width: "50%" }}
                onChange={evt => setUsageLimit(evt.target.value)}
              />
            </div> */}
          </Modal.Content>
          <Modal.Actions style={{ textAlign: "center" }}>
            <Button className="addMerchantCancelBtn" onClick={close}>
              Cancel
            </Button>
            <Button
              className="addMerchantsubmitBtn"
              content="Submit"
              onClick={handleSaveCoupon}
            />
          </Modal.Actions>
        </Modal>
      </div>
      <div>
        <Modal size={"tiny"} open={openDelete} onClose={close}>
          <Modal.Header>Delete Coupon </Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete coupon ?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button className="addCustomerCancelBtn" onClick={close}>
              No
            </Button>
            <Button
              className="addCustomersubmitBtn"
              icon="checkmark"
              labelPosition="right"
              content="Yes"
              onClick={handleDelete}
            />
          </Modal.Actions>
        </Modal>
      </div>
      <div>
        <Modal size={"small"} open={openEdit} onClose={close}>
          <Modal.Header>Edit Coupon </Modal.Header>
          <Modal.Content>
            <div className="merchantFormRow">
              <p className="merchantFormTitle">Coupon description:</p>
              <Form>
                <TextArea
                  cols="50"
                  maxLength="100"
                  style={{ paddingRight: 50 }}
                  value={description}
                  onChange={evt => setDescription(evt.target.value)}
                />
              </Form>
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -50,
                  zIndex: 0,
                  color: "grey"
                }}
              >
                {description.length}/100
              </p>
            </div>

            <div className="merchantFormRow">
              <p className="merchantFormTitle">Start Date :</p>
              <input
                value={startDate}
                onChange={evt => setStartDate(evt.target.value)}
                type="date"
              />
            </div>

            <div className="merchantFormRow">
              <p className="merchantFormTitle">Expiry Date :</p>
              <input
                value={endDate}
                onChange={evt => setEndDate(evt.target.value)}
                type="date"
              />
            </div>
            <div className="merchantFormRow">
              <p className="merchantFormTitle">Code:</p>
              <Input
                placeholder="Enter Code"
                style={{ width: "50%" }}
                maxLength="10"
                value={code}
                onChange={evt => setCode(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey"
                }}
              >
                {code.length}/10
              </p>
            </div>
            <div className="merchantFormRow">
              <p className="merchantFormTitle">Discount % :</p>
              <Input
                placeholder="Enter Code"
                style={{ width: "50%" }}
                maxLength="3"
                value={discount}
                onChange={evt => setDiscount(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey"
                }}
              >
                {discount.length}/3
              </p>
            </div>
            {/* <div className="merchantFormRow">
              <p className="merchantFormTitle">Useage limit : </p>
              <Input
                placeholder="Enter limit"
                style={{ width: "50%" }}
                onChange={evt => setUsageLimit(evt.target.value)}
              />
            </div> */}
          </Modal.Content>
          <Modal.Actions style={{ textAlign: "center" }}>
            <Button className="addCustomerCancelBtn" onClick={close}>
              Cancel
            </Button>
            <Button
              className="addCustomersubmitBtn"
              onClick={handleUpdateCoupon}
              content="Update"
            />
          </Modal.Actions>
        </Modal>
      </div>
     
     <div>
       <MerchantCustomCouponCode />
     </div>

    </div>
  );
};

export default MerchantCouponCode;

import React from "react";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Icon, Image, Button, Table, Form, TextArea } from "semantic-ui-react";
import { BASE_URL } from "../../../config";

const ViewEstimate = (props) => {
  return (
    <div className="invoiceDefaultLayout">
      <Card history={props.history} />
    </div>
  );
};

class Card extends React.Component {
  state = {
    invoice_settings: {},
    invoice_details: {},
    customer_address: {},
    coupon_details: {},
    membership_details: {},
    productList: [],
    transactions: [],
    print_status: false,
  };

  componentDidMount = () => {
    const { location } = this.props.history;
    const data = location.pathname.split("/");
    const invoice_no = data[3];

    fetch(`${BASE_URL}/get-estimate?estimateNo=${invoice_no}&cname=${data[2]}`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          invoice_details: json.estimate[0],
          customer_address: json.estimate[0].customer_address,
          productList: json.estimate[0].products,
          coupon_details:
            json.estimate[0].coupon_details !== null
              ? json.estimate[0].coupon_details
              : {},
          membership_details: json.estimate[0].membership_details,
          transactions:
            json.estimate[0].transactions !== undefined
              ? json.estimate[0].transactions
              : [],
          invoice_settings: json.estimate[0].company_details,
        });
      });
  };

  getProductsTotal = (products) => {
    const total_value = products.reduce(
      (sum, product, index) => sum + Number(product.quantity * product.price),
      0
    );
    return Number(total_value);
  };

  getCouponDiscount = () => {
    const { coupon_details, productList } = this.state;

    const discount =
      (this.getProductsTotal(productList) *
        Number(
          coupon_details.discount !== undefined ? coupon_details.discount : 0
        )) /
      100;

    return discount;
  };

  getTransactionsBalance = () => {
    const { transactions } = this.state;
    const paymentData = transactions.filter((transaction) =>
      Object.keys(transaction).some(
        (key) => transaction[key].toString().search("payment") !== -1
      )
    );
    const payments = paymentData.reduce(
      (sum, transaction, index) => sum + Number(transaction.transaction_amount),
      0
    );

    const refundData = transactions.filter((transaction) =>
      Object.keys(transaction).some(
        (key) => transaction[key].toString().search("refund") !== -1
      )
    );
    const refunds = refundData.reduce(
      (sum, transaction, index) => sum + Number(transaction.transaction_amount),
      0
    );

    const total_balance = payments - refunds;
    return Number(total_balance);
  };

  getBalanceDue = () => {
    const { productList, transactions, invoice_details, membership_details } =
      this.state;

    const order_total =
      this.getProductsTotal(productList) +
      invoice_details.gst_amount +
      Number(invoice_details.delivery_fee) -
      this.getCouponDiscount() -
      (membership_details.discountAmt || 0);

    const due = order_total - this.getTransactionsBalance(transactions);

    return due;
  };

  _exportPdf = () => {
    const { invoice_details, invoice_settings } = this.state;
    html2canvas(document.querySelector("#capture")).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.addImage(invoice_settings.invoice_logo, "PNG", 10, 10);
      pdf.save(`${invoice_details.invoice_no}.pdf`);
    });
  };

  printInvoice = () => {
    this.setState({ print_status: true });
    setTimeout(function () {
      var printContents = document.getElementById("capture").innerHTML;
      var originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;

      window.print();

      document.body.innerHTML = originalContents;
      window.location.reload();
      // this.props.history.push("/orderrequests");
    }, 1000);
  };

  render() {
    const {
      invoice_settings,
      invoice_details,
      customer_address,
      productList,
      coupon_details,
      membership_details,
      transactions,
      print_status,
    } = this.state;

    return (
      <div className="invoiceCardContainer">
        <div className="invoicePageTitleRow">
          <div style={{ paddingTop: 10, paddingLeft: 20, display: "flex" }}>
            <Icon
              name="arrow left"
              style={{ paddingTop: 3, cursor: "pointer" }}
              onClick={() => this.props.history.push("/estimates")}
            />
            <h3 style={{ marginTop: 0, marginLeft: 10 }}>View Estimate</h3>
          </div>
          <div>
            <Button className="PDFBtn" onClick={() => this._exportPdf()}>
              <Icon name="file pdf" /> Pdf
            </Button>
            <Button onClick={() => this.printInvoice()}>
              <Icon name="print" /> Print
            </Button>
          </div>
        </div>

        <div className="Card">
          <div className="invoicePdfContainer" id="capture">
            <div>
              <Image src={invoice_settings.logo} style={{ width: 100 }} />
            </div>
            <div className="section1">
              <div>
                <p
                  className={
                    print_status === true ? "section1TextP" : "section1Text"
                  }
                  style={{ fontSize: print_status === true ? 15 : 22 }}
                >
                  {invoice_settings.company_name},
                </p>
                <p
                  className={
                    print_status === true ? "section1TextP" : "section1Text"
                  }
                >
                  {invoice_settings.address_line1}
                </p>
                <p
                  className={
                    print_status === true ? "section1TextP" : "section1Text"
                  }
                >
                  {invoice_settings.address_line2}{" "}
                  {invoice_settings.address_line3}
                </p>
                <p
                  className={
                    print_status === true ? "section1TextP" : "section1Text"
                  }
                >
                  Email: {invoice_settings.email}
                </p>
                {/* <p className={print_status === true ? "section1TextP" : "section1Text"}>
                  Website: {invoice_settings.website}
                </p> */}
                <p
                  className={
                    print_status === true ? "section1TextP" : "section1Text"
                  }
                  style={{ marginBottom: 30 }}
                >
                  Conatct: +91-{invoice_settings.whatsapp_number}
                </p>
                <p
                  className={
                    print_status === true ? "section1TextP" : "section1Text"
                  }
                >
                  GSTIN: {invoice_settings.gst_no}
                </p>
                {/* <p className="section1Text">CIN: {invoice_settings.cin_no}</p>
                <p className="section1Text">PAN: {invoice_settings.pan_no}</p> */}
              </div>
            </div>
            <div className={print_status === true ? "section2P" : "section2"}>
              <div>
                <p
                  className="section2Text"
                  style={{
                    fontSize: print_status === true ? 12 : 22,
                    fontWeight: "bold",
                  }}
                >
                  Estimate #{invoice_details.estimate_no}{" "}
                </p>
                <p
                  className={
                    print_status === true ? "section2TextP" : "section2Text"
                  }
                >
                  Estimate Date:{" "}
                  {moment(invoice_details.estimate_date).format("DD/MM/YYYY")}
                </p>
              </div>
              <div>
                <p
                  className="section3Text"
                  style={{
                    fontSize: print_status === true ? 12 : 17,
                    fontWeight: "bold",
                  }}
                >
                  Estimated To
                </p>
                <p
                  className={
                    print_status === true ? "section3TextP" : "section3Text"
                  }
                >
                  {invoice_details.customer_name}
                </p>
                <p
                  className={
                    print_status === true ? "section3TextP" : "section3Text"
                  }
                >
                  {invoice_details.customer_address}
                </p>
                {/* <p className="section3Text">{customer_address.pincode}.</p> */}
              </div>
            </div>
            <div className="section3"></div>
            <div className={print_status === true ? "section4P" : "section4"}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p
                  style={{
                    fontSize: print_status === true ? 15 : 20,
                    fontWeight: "600",
                  }}
                >
                  Products
                </p>
              </div>

              <div>
                <Table
                  celled
                  unstackable
                  style={print_status === true ? { fontSize: 10 } : {}}
                >
                  <Table.Header>
                    <Table.Row textAlign="center">
                      <Table.HeaderCell
                        style={{ backgroundColor: "#b6d7a8" }}
                        className={print_status === true ? "tableCell" : ""}
                      >
                        S.no
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={{ backgroundColor: "#b6d7a8" }}
                        className={print_status === true ? "tableCell" : ""}
                      >
                        Products
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={{ backgroundColor: "#b6d7a8" }}
                        className={print_status === true ? "tableCell" : ""}
                      >
                        Quantity
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={{ backgroundColor: "#b6d7a8" }}
                        className={print_status === true ? "tableCell" : ""}
                      >
                        Price
                      </Table.HeaderCell>

                      <Table.HeaderCell
                        style={{ backgroundColor: "#b6d7a8" }}
                        className={print_status === true ? "tableCell" : ""}
                      >
                        Total
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {productList.map((product, idx) => (
                      <Table.Row textAlign="center">
                        <Table.Cell
                          textAlign="center"
                          className={print_status === true ? "tableCell" : ""}
                        >
                          {idx + 1}
                        </Table.Cell>
                        <Table.Cell
                          className={print_status === true ? "tableCell" : ""}
                        >
                          {product.name} - {product.weight}Kg
                        </Table.Cell>
                        <Table.Cell
                          className={print_status === true ? "tableCell" : ""}
                        >
                          {product.quantity}
                        </Table.Cell>

                        <Table.Cell
                          className={print_status === true ? "tableCell" : ""}
                        >
                          Rs. {product.price}
                        </Table.Cell>
                        <Table.Cell
                          className={print_status === true ? "tableCell" : ""}
                        >
                          Rs. {product.quantity * product.price}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ alignSelf: "flex-end" }}>
                    {invoice_details.customer_notes !== undefined && (
                      <div style={{ marginBottom: 20 }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontSize: 17,
                              fontWeight: "600",
                              marginBottom: 10,
                            }}
                          >
                            Customer Notes
                          </p>
                        </div>

                        <Form>
                          <TextArea
                            cols="50"
                            maxLength="70"
                            value={invoice_details.customer_notes}
                            disabled
                          />
                        </Form>
                      </div>
                    )}

                    {invoice_details.pricing_terms !== undefined && (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontSize: 17,
                              fontWeight: "600",
                              marginBottom: 10,
                            }}
                          >
                            Pricing Terms
                          </p>
                        </div>

                        <Form>
                          <TextArea
                            cols="50"
                            maxLength="70"
                            value={invoice_details.pricing_terms}
                            disabled
                          />
                        </Form>
                      </div>
                    )}
                  </div>

                  <div>
                    <Table
                      celled
                      unstackable
                      collapsing
                      style={print_status === true ? { fontSize: 10 } : {}}
                    >
                      <Table.Body>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className={
                              print_status === true
                                ? "tableCellbgP"
                                : "tableCellbg"
                            }
                          >
                            Item Total
                          </Table.Cell>
                          <Table.Cell
                            className={
                              print_status === true
                                ? "tableCellbgP"
                                : "tableCellbg"
                            }
                          >
                            ₹ {this.getProductsTotal(productList)}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className={
                              print_status === true
                                ? "tableCellbgP"
                                : "tableCellbg"
                            }
                            style={{ color: "#cc0000" }}
                          >
                            Coupon Applied(
                            {coupon_details.code !== undefined
                              ? coupon_details.code
                              : "NO COUPON"}
                            )
                          </Table.Cell>
                          <Table.Cell
                            className={
                              print_status === true
                                ? "tableCellbgP"
                                : "tableCellbg"
                            }
                            style={{ color: "#cc0000" }}
                          >
                            -₹ {this.getCouponDiscount()}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className={
                              print_status === true
                                ? "tableCellbgP"
                                : "tableCellbg"
                            }
                            style={{ color: "#cc0000" }}
                          >
                            Membership Level(
                            {membership_details.level || "ZERO"})
                          </Table.Cell>
                          <Table.Cell
                            className={
                              print_status === true
                                ? "tableCellbgP"
                                : "tableCellbg"
                            }
                            style={{ color: "#cc0000" }}
                          >
                            -₹ {membership_details.discountAmt || 0.0}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className={
                              print_status === true
                                ? "tableCellbgP"
                                : "tableCellbg"
                            }
                            style={{ fontWeight: 500 }}
                          >
                            Other Charges
                          </Table.Cell>
                          <Table.Cell
                            className={
                              print_status === true
                                ? "tableCellbgP"
                                : "tableCellbg"
                            }
                            style={{ fontWeight: 500 }}
                          >
                            ₹ {invoice_details.delivery_fee}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className={
                              print_status === true
                                ? "tableCellbgP"
                                : "tableCellbg"
                            }
                            style={{ fontWeight: 500 }}
                          >
                            {invoice_details.cgst}.00% CGST
                          </Table.Cell>
                          <Table.Cell
                            className={
                              print_status === true
                                ? "tableCellbgP"
                                : "tableCellbg"
                            }
                            style={{ fontWeight: 500 }}
                          >
                            ₹ {invoice_details.gst_amount / 2}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className={
                              print_status === true
                                ? "tableCellbgP"
                                : "tableCellbg"
                            }
                            style={{ fontWeight: 500 }}
                          >
                            {invoice_details.sgst}.00% SGST
                          </Table.Cell>
                          <Table.Cell
                            className={
                              print_status === true
                                ? "tableCellbgP"
                                : "tableCellbg"
                            }
                            style={{ fontWeight: 500 }}
                          >
                            ₹ {invoice_details.gst_amount / 2}
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row textAlign="right">
                          <Table.Cell
                            className={
                              print_status === true
                                ? "tableCellbgP"
                                : "tableCellbg"
                            }
                            style={{ color: "#6aa84f" }}
                          >
                            Total Estimation Amount
                          </Table.Cell>
                          <Table.Cell
                            className={
                              print_status === true
                                ? "tableCellbgP"
                                : "tableCellbg"
                            }
                            style={{ color: "#6aa84f" }}
                          >
                            ₹ {this.getBalanceDue()}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewEstimate;

import React, { useState, useEffect } from "react";
import { Grid, Button, Icon, Dropdown, Input } from "semantic-ui-react";
import ComparsionDetailsPage from "./comparsionDetails.component";
import { BASE_URL } from "../../../../config";
import { join } from "lodash";

const selectPlaceholder = [
  { key: "af", value: "af", text: "Select Merchant 1" },
  { key: "ax", value: "ax", text: "Select Merchant 2" },
  { key: "al", value: "al", text: "Select Merchant 3" },
];
const merchantOptions = [
  { key: "af", value: "af", text: "Muruga Vel" },
  { key: "ax", value: "ax", text: "Saravana Vel" },
  { key: "al", value: "al", text: "Shanmuga Vel" },
];

const MerchantsComparisonPage = (props) => {
  return (
    <div>
      <div className="DefaultLayout">
        <Header history={props.history} />
        <Card />
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <div className="HeaderLayout">
      <div className="AddMemberRow">
        <h3>Merchants Comparison</h3>
      </div>
    </div>
  );
};

const Card = () => {
  const [comparedMerchants, setComparedMerchants] = useState([]);
  const [merchant1, setMerchant1] = useState("");
  const [merchant2, setMerchant2] = useState("");
  const [merchants, setMerchants] = useState([]);
  const [compare, setCompare] = useState(false);

  useEffect(() => {
    fetch(`${BASE_URL}/get-merchants`)
      .then((res) => res.json())
      .then((json) => {
        setMerchants(json.merchants);
      });
  }, []);

  const compareMerch = () => {
    fetch(
      `${BASE_URL}/compare-merchants?merchant1=${merchant1}&merchant2=${merchant2}`
    )
      .then((res) => res.json())
      .then((json) => {
        setMerchant1('');
        setMerchant2('');
        setComparedMerchants(json.compared_merchants);
      });
  };

  return (
    <div className="CardContainer">
      <div style={{ margin: 20 }}>
        <h1>Compare Merchants</h1>
      </div>
      <div
        style={{ marginTop: 10, marginBottom: 20, padding: 10 }}
        className="gridLayout"
      >
        <Grid centered container columns={3} divided>
          <Grid.Row>
            <Grid.Column>
              <div style={{ margin: 20 }}>
                <Dropdown
                  placeholder="Select Merchant"
                  fluid
                  search
                  selection
                  options={merchants.map((merchant, index) => ({
                    key: index,
                    text: merchant.name,
                    value: merchant._id,
                  }))}
                  value={merchant1}
                  onChange={(event, data) => setMerchant1(data.value)}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <div style={{ margin: 20 }}>
                <Dropdown
                  placeholder="Select Merchant"
                  fluid
                  search
                  selection
                  options={merchants.map((merchant, index) => ({
                    key: index,
                    text: merchant.name,
                    value: merchant._id,
                  }))}
                  value={merchant2}
                  onChange={(event, data) => setMerchant2(data.value)}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div style={{ margin: 30, marginLeft: "60vw" }}>
          <Button
            icon
            labelPosition="right"
            style={{
              backgroundColor: "#d9ead3",
              color: "#000",
            }}
            onClick={() => {
              compareMerch();
              setCompare(!compare);
            }}
          >
            Compare Now
            <Icon name="check" />
          </Button>
        </div>
      </div>

      {comparedMerchants.length > 0 && (
        <React.Fragment>
          <ComparsionDetailsPage merchantData={comparedMerchants} />
        </React.Fragment>
      )}
    </div>
  );
};

export default MerchantsComparisonPage;

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Responsive } from "semantic-ui-react";
import styled from "@emotion/styled";
import Navbar from "../components/commons/navbar/navbar.component";
import Sidebar from "../components/commons/sidebar/sidebar.component";

const RouterDiv = styled.div`
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
`;

const ComponentDiv = styled.div`
  margin: 0;
  padding: 0;
  margin-top: 70px;
  margin-left: 240px;
  width: calc(100vw - 240px);
  height: 100vh;
`;

const ComponentMobileDiv = styled.div`
  margin: 0;
  padding: 0;
  margin-top: 60px;
  width: 100vw;
  height: 100vh;
`;

const IsAuth = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(matchProps) =>
      auth.isAuthenticated === true ? (
        <RouterDiv>
          <Responsive maxWidth={768}>
            <Navbar />
          </Responsive>
          <Responsive minWidth={768}>
            <Sidebar />
          </Responsive>
          <Responsive maxWidth={768}>
            <ComponentMobileDiv>
              <Component {...matchProps} />
            </ComponentMobileDiv>
          </Responsive>
          <Responsive minWidth={768}>
            <Navbar />
          </Responsive>
          <Responsive minWidth={768}>
            <ComponentDiv>
              <Component {...matchProps} />
            </ComponentDiv>
          </Responsive>
        </RouterDiv>
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

IsAuth.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(IsAuth);

import React, { useState, useEffect } from "react";
import { Card, Statistic } from "semantic-ui-react";
import { getPercentage, getTurnoverPercentage } from "./customers.api";
import { BASE_URL } from "../../../config";
import "./customers.css";

const CustomersHeader = () => {
  const [customers, setCustomers] = useState([]);
  const [turnover, setTurnover] = useState(0);
  const [cusPerc, setCusPerc] = useState(0);
  const [turnoverPerc, setTurnoverPerc] = useState(0);

  useEffect(() => {
    fetch(`${BASE_URL}/get-customers`)
      .then((res) => res.json())
      .then((json) => {
        const percentage = getPercentage(json.customers);
        setCusPerc(percentage);
        setCustomers(json.customers);
      });
    fetch(`${BASE_URL}/get-invoices`)
      .then((res) => res.json())
      .then((json) => {
        const customer_invoices = json.invoices.filter(
          (invoice) =>
            invoice.customer_role.toString().search("CUSTOMER") !== -1
        );
        const customer_turnover = customer_invoices.reduce(
          (sum, invoice, index) => sum + Number(invoice.total_amount),
          0
        );
        const percentage = getTurnoverPercentage(customer_invoices);
        const turnover = customer_turnover.toLocaleString("en-IN");
        setTurnoverPerc(percentage);
        setTurnover(turnover);
      });
  }, []);

  return (
    <div style={{ marginTop: 20 }}>
      <Card.Group>
        <Card className="AllCustomersCard">
          <Card.Content>
            <div className="customerCardContainer">
              <div>
                <Card.Header className="AllCustomersTitle">
                  All Customers
                </Card.Header>
                {Math.sign(cusPerc) === -1 ? (
                  <span style={{ color: "#21ba45", fontWeight: 600 }}>
                    +{Math.abs(cusPerc) || 0}%
                  </span>
                ) : (
                  <span style={{ color: "#db2828", fontWeight: 600 }}>
                    -{cusPerc || 0}%
                  </span>
                )}
                <span style={{ color: "#00000066", marginLeft: 5 }}>
                  this month
                </span>
              </div>

              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="tiny">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      {customers.length}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
        <Card className="CustomersSalesCard">
          <Card.Content>
            <div className="customerCardContainer">
              <div>
                <Card.Header className="CustomersSalesTitle">
                  Turnover
                </Card.Header>
                {Math.sign(turnoverPerc) === -1 ? (
                  <span style={{ color: "#21ba45", fontWeight: 600 }}>
                    +{Math.abs(turnoverPerc) || 0}%
                  </span>
                ) : (
                  <span style={{ color: "#db2828", fontWeight: 600 }}>
                    -{turnoverPerc || 0}%
                  </span>
                )}
                <span style={{ color: "#00000066", marginLeft: 5 }}>
                  this month
                </span>
              </div>
              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="tiny">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      ₹{turnover}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
      </Card.Group>
    </div>
  );
};

export default CustomersHeader;

import React, { useState, useEffect } from "react";
import { Card, Statistic } from "semantic-ui-react";
import { BASE_URL } from "../../../config";
import "./products.css";

const ProductsPageHeader = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch(`${BASE_URL}/get-products`)
      .then((res) => res.json())
      .then((json) => {
        setProducts(json.products);
      });
  }, []);

  return (
    <div style={{ marginTop: 20 }}>
      <Card.Group>
        <Card className="AllProductsCard">
          <Card.Content>
            <div className="customerCardContainer">
              <div>
                <Card.Header className="AllCustomersTitle">
                  All Products
                </Card.Header>
                <span style={{ color: "#21ba45", fontWeight: 600 }}></span>
                {/* <span style={{ color: '#00000066', marginLeft: 5 }}>
                  in this month
                </span> */}
              </div>

              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="tiny">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      {products.length}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
        <Card className="PopularProductCard">
          <Card.Content>
            <div className="customerCardContainer">
              <div>
                <Card.Header className="CustomersSalesTitle">
                  Popular Product
                </Card.Header>
                <span style={{ color: "#21ba45", fontWeight: 600 }}></span>
                {/* <span style={{ color: '#00000066', marginLeft: 5 }}>
                  in this month
                </span> */}
              </div>
              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="mini">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      Brown Rice
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
        <Card className="LowFeedbackProductCard">
          <Card.Content>
            <div className="customerCardContainer">
              <div>
                <Card.Header className="CustomersOrdersTitle">
                  Low Feedback Product
                </Card.Header>
                <span style={{ color: "#db2828", fontWeight: 500 }}></span>
                {/* <span style={{ color: '#00000066', marginLeft: 5 }}>
                  in this month
                </span> */}
              </div>
              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="mini">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      Samba Rice
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
      </Card.Group>
    </div>
  );
};

export default ProductsPageHeader;

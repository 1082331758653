export const HEADING_DATA = [
  "S.No",
  "Logo",
  "Company Name",
  "Website",
  "Email",
  "GST",
  "CIN",
  "PAN",
  "Address",
  "Actions",
];

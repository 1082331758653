import React from "react";
import { Icon, Input, Button } from "semantic-ui-react";
import toastr from "toastr";
import TableExport from "tableexport";
import CompaniesTable from "./companiesTable.component";

const Companies = (props) => {
  return (
    <div className="DefaultLayout">
      <Header history={props.history} />
      <Card history={props.history} />
    </div>
  );
};

const Header = (props) => {
  return (
    <div className="HeaderLayout">
      <div className="AddMemberRow">
        <h3>Companies</h3>
        <Button
          className="addCustomerBtn"
          onClick={() => props.history.push("/add-company")}
        >
          <Icon name="plus" /> Add Company
        </Button>
      </div>
    </div>
  );
};

const Card = (props) => {
  const [searchString, setSearchString] = React.useState("");

  return (
    <div className="CardContainer">
      <div className="PageTitleRow">
        <Input
          placeholder="Quick Search..."
          icon="search"
          style={{ marginTop: 10 }}
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
        <div>
          <Button
            icon
            labelPosition="right"
            onClick={() => {
              toastr.success("Exported Successfully");
              const table = new TableExport(
                document.getElementById("company-table"),
                {
                  formats: ["csv"],
                  exportButtons: false,
                }
              );
              const link = document.createElement("a");
              link.setAttribute(
                "href",
                "data:text/csv;charset=utf-8," +
                  table.getExportData()["company-table"].csv.data
              );
              link.setAttribute(
                "download",
                table.getExportData()["company-table"].csv.filename
              );
              link.click();
            }}
            style={{
              backgroundColor: "#d9ead3",
              color: "#000",
            }}
          >
            Export
            <Icon name="share square" />
          </Button>
        </div>
      </div>
      <CompaniesTable searchString={searchString} history={props.history} />
    </div>
  );
};

export default Companies;

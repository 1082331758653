import React, { useState, useEffect } from "react";
import { Card, Statistic } from "semantic-ui-react";
import { getPercentage, getPurchasedPercentage, getPurchasesPercentage } from "./vendor.api";
import { BASE_URL } from "../../../config";
import "./vendors.css";

const VendorsHeader = () => {
  const [vendors, setVendors] = useState([]);
  const [vendorPerc, setVendorPerc] = useState(0);
  const [purchasedPerc, setPurchasedPerc] = useState(0);
  const [purchasesPerc, setPurchasesPerc] = useState(0);

  useEffect(() => {
    fetch(`${BASE_URL}/get-vendors`)
      .then((res) => res.json())
      .then((json) => {
        const percentage = getPercentage(json.vendors);
        const purchased_percentage = getPurchasedPercentage(json.vendors);
        const purchases_percentage = getPurchasesPercentage(json.vendors);
        setVendorPerc(percentage);
        setPurchasedPerc(purchased_percentage);
        setPurchasesPerc(purchases_percentage);
        setVendors(json.vendors);
      });
  }, []);

  const getPurchasedValue = () => {
    const datas = vendors.map((vendor) =>
      vendor.transactions.reduce(
        (sum, transaction, index) =>
          sum +
          Number(
            transaction.amount_given !== undefined
              ? transaction.amount_given
              : 0
          ),
        0
      )
    );

    const value = datas.reduce((sum, data, index) => sum + data, 0);

    const purchased_value = value.toLocaleString("en-IN");

    return purchased_value;
  };

  const getPurchases = () => {
    const datas = vendors.map((vendor) =>
      vendor.transactions.reduce((sum, transaction, index) => sum + index, 0)
    );

    const value = datas.reduce((sum, data, index) => sum + (data + 1), 0);

    return Number(value);
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Card.Group>
        <Card className="AllVendorsCard">
          <Card.Content>
            <div className="cardContainer">
              <div>
                <Card.Header className="AllVendorsTitle">
                  All Vendors
                </Card.Header>
                {Math.sign(vendorPerc) === -1 ? (
                  <span style={{ color: "#21ba45", fontWeight: 600 }}>
                    +{Math.abs(vendorPerc) || 0}%
                  </span>
                ) : (
                  <span style={{ color: "#db2828", fontWeight: 600 }}>
                    -{vendorPerc || 0}%
                  </span>
                )}
                <span style={{ color: "#00000066", marginLeft: 5 }}>
                  this month
                </span>
              </div>

              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="tiny">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      {vendors.length}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
        <Card className="PurchaseValueCard">
          <Card.Content>
            <div className="cardContainer">
              <div>
                <Card.Header className="PurchaseValueTitle">
                  Purchased Value
                </Card.Header>
                {Math.sign(purchasedPerc) === -1 ? (
                  <span style={{ color: "#21ba45", fontWeight: 600 }}>
                    +{Math.abs(purchasedPerc) || 0}%
                  </span>
                ) : (
                  <span style={{ color: "#db2828", fontWeight: 600 }}>
                    -{purchasedPerc || 0}%
                  </span>
                )}
                <span style={{ color: "#00000066", marginLeft: 5 }}>
                  this month
                </span>
              </div>
              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="tiny">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      ₹ {getPurchasedValue()}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
        <Card className="PurchasesCard">
          <Card.Content>
            <div className="cardContainer">
              <div>
                <Card.Header className="PurchasesTitle">Purchases</Card.Header>
                {Math.sign(purchasesPerc) === -1 ? (
                  <span style={{ color: "#21ba45", fontWeight: 600 }}>
                    +{Math.abs(purchasesPerc) || 0}%
                  </span>
                ) : (
                  <span style={{ color: "#db2828", fontWeight: 600 }}>
                    -{purchasesPerc || 0}%
                  </span>
                )}
                <span style={{ color: "#00000066", marginLeft: 5 }}>
                  this month
                </span>
              </div>
              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="tiny">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      {getPurchases()}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
      </Card.Group>
    </div>
  );
};

// const CardTitle = {
//   fontSize: 14,
//   fontWeight: "bold",
//   color: "#22252f",
//   textAlign: "left",
//   paddingTop: 5
// };

// const CardUI = {
//   width: 270,
//   borderRadius: 20
// };

// const CardUI2 = {
//   width: 350,
//   borderRadius: 20,
// };

// const CardValue = {
//   fontSize: 20,
//   fontWeight: 'bold',
//   textAlign: 'left',
//   paddingTop: 5,
// };

export default VendorsHeader;

import React, { PureComponent } from "react";
import { Select } from "semantic-ui-react";
import Chart from "react-apexcharts";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { SAMPLE_CHART_DATA } from "./charts.constant";
import { BASE_URL } from "../../../../config";

const moment = extendMoment(originalMoment);

const memberOptions = [
  { key: "month", value: "month", text: "This Month" },
  {
    key: "financialyear",
    value: "financial_year",
    text: "This Financial Year",
  },
  { key: "calenderyear", value: "calender_year", text: "This Calender Year" },
];

const chartTypeOptions = [
  { key: "area", value: "area", text: "Area Chart" },
  { key: "bar", value: "bar", text: "Bar Chart" },
  { key: "line", value: "line", text: "Line Chart" },
];

export default class CancelledOrdersChart extends PureComponent {
  constructor(props) {
    super(props);
    const today = moment();

    this.state = {
      isOpen: false,
      chart_type: "area",
      reportsData: [],
      value: moment.range(today.clone().subtract(7, "days"), today.clone()),
      orderSeries: [
        {
          name: "Cancelled Orders",
          data: SAMPLE_CHART_DATA,
        },
      ],
      options: {
        chart: {
          id: "cancelled-orders-reports",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        xaxis: {
          type: "datetime",
          min: new Date().getTime(),
          tickAmount: 6,
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },
        },
      },
    };
  }

  componentDidMount = () => {
    fetch(`${BASE_URL}/get-cancelled-orders-reports`)
      .then((res) => res.json())
      .then((json) => {
        const newSeries = [];
        json.reports.map((element) =>
          newSeries.push([element.date, element.count])
        );
        this.setState({
          orderSeries: [{ data: newSeries }],
          options: {
            xaxis: {
              min: new Date(
                json.reports.length > 0 ? json.reports[0].date : "01 Apr 2020"
              ).getTime(),
            },
          },
          reportsData: json.reports,
        });
      });
  };

  handleChange = (value) => {
    switch (value) {
      case "month":
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.setState({
          options: {
            xaxis: {
              min: new Date(firstDay).getTime(),
              max: new Date(lastDay).getTime(),
            },
          },
        });
        break;
      case "financial_year":
        var fiscalyear = "";
        var today = new Date();
        if (today.getMonth() + 1 <= 3) {
          fiscalyear = today.getFullYear() - 1 + "-" + today.getFullYear();
        } else {
          fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1);
        }
        const financialYear = fiscalyear.split("-");
        const fyFirstDay = `01 Apr ${financialYear[0]}`;
        const fyLastDay = `31 Mar ${financialYear[1]}`;
        this.setState({
          options: {
            xaxis: {
              min: new Date(fyFirstDay).getTime(),
              max: new Date(fyLastDay).getTime(),
            },
          },
        });
        break;
      case "calender_year":
        const yearFirstDay = "1/1/" + new Date().getFullYear();
        const yearLastDay = "12/31/" + new Date().getFullYear();
        this.setState({
          options: {
            xaxis: {
              min: new Date(yearFirstDay).getTime(),
              max: new Date(yearLastDay).getTime(),
            },
          },
        });
        break;
    }
  };

  handleChartChange = (chart_type) => {
    this.setState({ chart_type, options: { chart: { type: chart_type } } });
  };

  render() {
    const { chart_type } = this.state;
    const x = window.matchMedia("(max-width: 700px)");

    return (
      <div id="chart">
        <div className="ReportHeaderRow">
          <h3>Cancelled Orders</h3>
          <Select
            placeholder="Select Chart Type"
            options={chartTypeOptions}
            className="RangeDropdown"
            onChange={(event, data) => this.handleChartChange(data.value)}
          />
          <Select
            placeholder="Select Time Range"
            options={memberOptions}
            className="RangeDropdown"
            onChange={(event, data) => this.handleChange(data.value)}
          />
        </div>
        <div id="chart-timeline">
          <Chart
            options={this.state.options}
            series={this.state.orderSeries}
            type={chart_type}
            width={x.matches ? "330":"350"}
            height="250"
          />
        </div>
      </div>
    );
  }
}

import React from "react";
import EstimatesTable from "./estimates_table.component";

const Estimates = (props) => {
  return (
    <div className="DefaultLayout">
      <Header history={props.history} />
      <Card history={props.history} />
    </div>
  );
};

const Header = () => {
  return (
    <div className="HeaderLayout">
      <div className="AddMemberRow">
        <h3>Estimates</h3>
      </div>
    </div>
  );
};

const Card = (props) => {
  return (
    <div className="CardContainer">
      <EstimatesTable history={props.history} />
    </div>
  );
};

export default Estimates;

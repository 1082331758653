import React, { useState } from "react";
import { Menu } from "semantic-ui-react";
import "../tabbar/tabbar.css";

const UniquecodeTab = props => {
  const [activeItem, setActiveItem] = useState(props.history.location.pathname);

  return (
    <Menu pointing borderless className="tabbarMobile">
      <Menu.Item
        name="spinuniquecodes"
        active={activeItem === "/spinuniquecodes"}
        content="Unique codes"
        onClick={() => props.history.push("/spinuniquecodes")}
      />
       <Menu.Item
        name="cashbackwinners"
        active={activeItem === "/spinuniquecodes/cashbackwinners"}
        content="Cashback Winners"
        onClick={() => props.history.push("/spinuniquecodes/cashbackwinners")}
      />
  
      <Menu.Item
        name="Notifications"
        active={activeItem === "/spinuniquecodes/notifications"}
        content="Notifications"
        onClick={() => props.history.push("/spinuniquecodes/notifications")}
      />
     
    </Menu>
  );
};

export default UniquecodeTab;

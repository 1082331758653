import React, { useState, useEffect } from "react";
import { Card } from "semantic-ui-react";
import ProductSalesChart from "./productSalesChart.component";
import { BASE_URL } from "../../../../config";
import "./chart.css";

const ProductSales = (props) => {
    return (
        <div className="ReportLayout">
            <SalesChart history={props.history} />
        </div>
    );
};

const SalesChart = (props) => {
    const { location } = props.history;
    const data = location.pathname.split("/");
    const merchant = data[2];
    const [products, setProducts] = useState([]);
    const [merchantData, setMerchantData] = useState({});

    useEffect(() => {
        const { location } = props.history;
        const data = location.pathname.split("/");
        const merchant = data[2];

        fetch(`${BASE_URL}/get-products`).then((res) => res.json()).then((json) => {
            setProducts(json.products)
        });

        fetch(`${BASE_URL}/get-merchant-by-id?phoneNumber=${merchant}`)
            .then((res) => res.json())
            .then((json) => {
                setMerchantData(json.user);
            });
    }, []);

    return (
        <div>
            <div style={{ paddingLeft: 40, paddingTop: 20 }}>
                <h3>{merchantData.name} Product Sales</h3>
            </div>
            <div style={{ padding: 20 }}>
                {products.length > 0 &&
                    <Card.Group className="chatsRowContainer">
                        {products.map((product) => (
                            <Card className="CustomerReportCard">
                                <ProductSalesChart productDetails={product} merchantPhone={merchant} />
                            </Card>
                        ))}

                    </Card.Group>
                }
            </div>
        </div>
    )
}

export default ProductSales;

import React, { useState } from "react";
import { Input, Button, Modal, TextArea, Form, Image } from "semantic-ui-react";
import toastr from "toastr";
import JOE_PIC from "../../../images/joe.jpg";
import { BASE_URL } from "../../../config";

const AddDriverModal = (props) => {
  const [name, setName] = useState("");
  const [licenseNo, setLicenseNo] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [bookDetails, setBookDetails] = useState("");
  const [open, setOpen] = useState(props.openModal);

  const close = () => {
    setOpen(false);
    props.onModalClose(false, "DRIVER");
  };

  const saveDriver = () => {
    if ((name, licenseNo, mobileNumber)) {
      fetch(`${BASE_URL}/create-driver`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          licenseNo,
          mobileNumber,
          email,
          bookDetails,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.status == 400) {
            toastr.error("Mobile number already exists.");
          } else {
            toastr.success("New Driver Created Successfully!");
            props.onModalClose(false, "SAVE");
            close();
          }
        });
    } else {
      toastr.error("Please fill all the fields!");
    }
  };

  return (
    <Modal size="small" open={open} onClose={close}>
      <Modal.Header>
        {" "}
        <Image
          src={JOE_PIC}
          inline
          circular
          size="mini"
          style={{ marginRight: 20 }}
        />
        <span>Add Driver</span>
      </Modal.Header>
      <Modal.Content style={{ marginBottom: 20 }}>
        <div className="driverFormRow">
          <p className="titleDriverform">Driver name:</p>
          <Input
            placeholder="Enter driver name"
            style={{ width: "50%" }}
            maxLength="20"
            onChange={(evt) => setName(evt.target.value)}
          />
          <p
            style={{
              alignSelf: "center",
              marginLeft: -40,
              marginTop: 10,
              zIndex: 0,
              color: "grey",
            }}
          >
            {name.length}/20
          </p>
        </div>
        <div className="driverFormRow">
          <p className="titleDriverform">License No: </p>
          <Input
            placeholder="Enter License no"
            style={{ width: "50%" }}
            maxLength="16"
            onChange={(evt) => setLicenseNo(evt.target.value)}
          />
          <p
            style={{
              alignSelf: "center",
              marginLeft: -40,
              marginTop: 10,
              zIndex: 0,
              color: "grey",
            }}
          >
            {licenseNo.length}/16
          </p>
        </div>
        <div className="driverFormRow">
          <p className="titleDriverform">Mobile number:</p>
          <Input
            placeholder="Enter mobile number"
            style={{ width: "50%" }}
            maxLength="10"
            onChange={(evt) => setMobileNumber(evt.target.value)}
          />
          <p
            style={{
              alignSelf: "center",
              marginLeft: -40,
              marginTop: 10,
              zIndex: 0,
              color: "grey",
            }}
          >
            {mobileNumber.length}/10
          </p>
        </div>
        <div className="driverFormRow">
          <p className="titleDriverform">Email:</p>
          <Input
            placeholder="Enter Email"
            style={{ width: "50%" }}
            maxLength="25"
            onChange={(evt) => setEmail(evt.target.value)}
          />
          <p
            style={{
              alignSelf: "center",
              marginLeft: -40,
              marginTop: 10,
              zIndex: 0,
              color: "grey",
            }}
          >
            {email.length}/25
          </p>
        </div>
        <div className="driverFormRow">
          <p className="titleDriverform">Book details:</p>
          <Form style={{ width: "50%" }}>
            <TextArea
              placeholder="Enter Book Details"
              cols="50"
              rows={4}
              maxLength="100"
              style={{ paddingRight: 50 }}
              onChange={(evt) => setBookDetails(evt.target.value)}
            />
          </Form>
          <p
            style={{
              alignSelf: "center",
              marginLeft: -50,
              marginTop: 50,
              zIndex: 0,
              color: "grey",
            }}
          >
            {bookDetails.length}/100
          </p>
        </div>
      </Modal.Content>
      <Modal.Actions style={{ textAlign: "center" }}>
        <Button className="addCustomerCancelBtn" onClick={close}>
          Cancel
        </Button>
        <Button
          className="addCustomersubmitBtn"
          onClick={() => saveDriver(props.history)}
          content="Submit"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default AddDriverModal;

export const HEADING_DATA = [
  'S.No',
  'Vendor Type',
  'Type of the Firm',
  'Actions'
];

export const TABLE_DATA = [
  {
    vendortype: 'Farmer Vendor',
    firm: 'Partnership co',
  },
  {
    vendortype: 'Paddy Vendor',
    firm: 'Proprietorship',
  },
  {
    vendortype: 'Machine Vendor',
    firm: 'Others',
  },
  {
    vendortype: 'Software Vendor',
    firm: 'Pvt Ltd',
  },
];

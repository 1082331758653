import React, { Component } from 'react';
import { Grid, Checkbox, Responsive } from 'semantic-ui-react';

const AdminSmsNotificationOptions = () => {
  return (
    <React.Fragment>
      <Grid.Row>
        <Grid.Column>
          <Checkbox label='When Admin Logs In' />
        </Grid.Column>
        <Grid.Column>
          <Checkbox label='Customer Signup' />
        </Grid.Column>
        <Grid.Column>
          <Checkbox label='Customer New Order' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Checkbox label='Merchant Signup' />
        </Grid.Column>
        <Grid.Column>
          <Checkbox label='Merchant New Order' />
        </Grid.Column>
        <Grid.Column>
          <Checkbox label='Order Rejected by Admin' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Checkbox label='New Product Feedback' />
        </Grid.Column>
        <Grid.Column>
          <Checkbox label='New Payment Received' />
        </Grid.Column>
        <Grid.Column>
          <Checkbox label='New Invoice Generated' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Checkbox label='Order Cancelled' />
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );
};

export class SmsAdminNotifications extends Component {
  render() {
    return (
      <div>
        <div style={{ margin:20 }}>
          <h4>Admin Notifications</h4>
        </div>

        <div style={{ margin: 20 }}>
          <div>
            <Responsive maxWidth={768}>
              <Grid columns={1} divided>
                <AdminSmsNotificationOptions />
              </Grid>
            </Responsive>
            <Responsive minWidth={768}>
              <Grid columns={3} divided>
                <AdminSmsNotificationOptions />
              </Grid>
            </Responsive>
          </div>
        </div>
      </div>
    );
  }
}

export default SmsAdminNotifications;

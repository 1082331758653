import React, { useEffect, useState } from "react";
import { Table, Modal, Icon, Checkbox, Button, Input } from "semantic-ui-react";
import _ from "lodash";
import toastr from "toastr";
import { HEADING_DATA } from "./rawmaterials.constant";
import { BASE_URL } from "../../../../../config";

const RawMaterialsComponent = () => {
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [materialsList, setMaterialsList] = useState();
  const [material_name, setMaterialName] = useState("");
  const [selectedMaterialID, setSelectedMaterialID] = useState("");

  useEffect(() => {
    getMaterials();
  }, []);

  const getMaterials = () => {
    fetch(`${BASE_URL}/get-raw-materials`)
      .then((res) => res.json())
      .then((json) => {
        setMaterialsList(json.vendor_materials);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSort = (clickedColumn) => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setDirection("ascending");
      return;
    }
    setDirection(direction === "ascending" ? "descending" : "ascending");
  };

  const editMaterial = (material) => () => {
    setOpenEdit(true);
    setSelectedMaterialID(material._id);
    setMaterialName(material.material_name);
  };

  const deleteMaterial = (Id) => () => {
    setSelectedMaterialID(Id);
    setOpenDelete(true);
  };

  const close = () => {
    setOpenAdd(false);
    setOpenDelete(false);
    setOpenEdit(false);
  };

  const handleSaveMaterial = () => {
    if (material_name) {
      fetch(`${BASE_URL}/create-raw-material`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          material_name,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          toastr.success('New raw Material Added Successfully!');
          getMaterials();
          close();
        });
    } else {
      toastr.error("Please fill all the details.");
    }
  };

  const handleUpdateOffer = () => {
    fetch(
      `${BASE_URL}/update-raw-material?vendor_material_id=${selectedMaterialID}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          material_name,
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        toastr.success('Raw Material Updated Successdfully');
        getMaterials();
        close();
      });
  };

  const handleDelete = () => {
    fetch(
      `${BASE_URL}/delete-raw-material?vendor_material_id=${selectedMaterialID}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => res.json())
      .then((json) => {
        close();
        getMaterials();
      });
  };

  return (
    <div className="TabContainer">
      <div className="AddMemberRow">
        <h3>Raw Materials</h3>
        <Button className="addMerchantBtn" onClick={() => setOpenAdd(true)}>
          <Icon name="plus" /> Add Raw Materials
        </Button>
      </div>

      <div>
        <Table
          sortable
          unstackable
          celled
          basic="very"
          className="CustomTableLayout"
          id="merchant-table"
        >
          <Table.Header>
            <Table.Row>
              {HEADING_DATA.map((heading) => (
                <Table.HeaderCell
                  sorted={column === heading ? direction : null}
                  onClick={handleSort(heading)}
                >
                  {heading === "Checkbox" ? (
                    <Checkbox />
                  ) : (
                    <span>{heading}</span>
                  )}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {materialsList !== undefined ? (
              materialsList.map((material, index) => (
                <Table.Row>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>{material.material_name}</Table.Cell>
                  <Table.Cell>
                    <Button
                      style={{ fontSize: 13, backgroundColor: "#f3f3f3" }}
                      onClick={editMaterial(material)}
                    >
                      <Icon name="edit" />
                      Edit
                    </Button>

                    <Button
                      style={{ fontSize: 13, backgroundColor: "#f4cccc" }}
                      onClick={deleteMaterial(material._id)}
                    >
                      <Icon name="trash" /> Delete
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <p>No Materials Found</p>
            )}
          </Table.Body>
        </Table>
      </div>

      <div>
        <Modal size="small" open={openAdd} onClose={close}>
          <Modal.Header>
            <span>Add Raw Material</span>
          </Modal.Header>
          <Modal.Content>
            <div className="merchantFormRow">
              <p className="merchantFormTitle">Material Name:</p>
              <Input
                placeholder="Enter Name"
                style={{ width: "50%" }}
                maxLength="20"
                onChange={(evt) => setMaterialName(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {material_name.length}/20
              </p>
            </div>
          </Modal.Content>
          <Modal.Actions style={{ textAlign: "center" }}>
            <Button className="addMerchantCancelBtn" onClick={close}>
              Cancel
            </Button>
            <Button
              className="addMerchantsubmitBtn"
              content="Submit"
              onClick={handleSaveMaterial}
            />
          </Modal.Actions>
        </Modal>
      </div>

      <div>
        <Modal size="small" open={openEdit} onClose={close}>
          <Modal.Header>
            <span>Edit Raw Material</span>
          </Modal.Header>
          <Modal.Content>
            <div className="merchantFormRow">
              <p className="merchantFormTitle">Material Name:</p>
              <Input
                value={material_name}
                style={{ width: "50%" }}
                maxLength="20"
                onChange={(evt) => setMaterialName(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {material_name.length}/20
              </p>
            </div>
          </Modal.Content>
          <Modal.Actions style={{ textAlign: "center" }}>
            <Button className="addMerchantCancelBtn" onClick={close}>
              Cancel
            </Button>
            <Button
              className="addMerchantsubmitBtn"
              content="Submit"
              onClick={handleUpdateOffer}
            />
          </Modal.Actions>
        </Modal>
      </div>
      <div>
        <Modal size={"tiny"} open={openDelete} onClose={close}>
          <Modal.Header>Delete Raw Material </Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete material ?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button className="addCustomerCancelBtn" onClick={close}>
              No
            </Button>
            <Button
              className="addCustomersubmitBtn"
              icon="checkmark"
              labelPosition="right"
              content="Yes"
              onClick={handleDelete}
            />
          </Modal.Actions>
        </Modal>
      </div>
    </div>
  );
};

export default RawMaterialsComponent;

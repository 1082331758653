import React from "react";
import AllStatementsTable from "./allstatements_table.component";
import StatementsHeader from "./statements_header.component";

const Statements = () => {
    return (
        <div className="DefaultLayout">
            <Header />
            <Card />
        </div>
    );
};

const Header = () => {
    return (
        <div className="HeaderLayout">
            <StatementsHeader />
        </div>
    );
};

const Card = () => {
    return (
        <div className="CardContainer">
            <div>
            <AllStatementsTable />
            </div>
        </div>
    );
};

export default Statements;

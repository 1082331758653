import React, { useEffect, useState } from "react";
import {
  Table,
  Checkbox,
  Label,
  Select,
  Input,
  Button,
  Icon,
} from "semantic-ui-react";
import _ from "lodash";
import { HEADING_DATA } from "./estimates.constant";
import { BASE_URL } from "../../../config";
import moment from "moment";
import toastr from "toastr";
import TableExport from "tableexport";
import { TailSpin } from "react-loader-spinner";

const EstimatesTable = (props) => {
  const [estimates, setEstimates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);
  const [collectionName, setCollectionName] = useState("");
  const [searchString, setSearchString] = React.useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(`${BASE_URL}/get-companies`)
      .then((res) => res.json())
      .then((json) => {
        if (json.companies.length > 0) {
          setCompanies(json.companies);
          setCollectionName(`${json.companies[0].collection_name}_estimates`);
          fetch(
            `${BASE_URL}/get-estimates?cname=${json.companies[0].collection_name}_estimates`
          )
            .then((res) => res.json())
            .then((json) => {
              setEstimates(json.estimates);
              setLoading(false);
            });
        }
      });
  }, []);

  const handleSort = (clickedColumn) => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setEstimates(_.sortBy(estimates, [clickedColumn]));
      setDirection("ascending");

      return;
    }

    setEstimates(estimates.reverse());
    setDirection(direction === "ascending" ? "descending" : "ascending");
  };

  const handleChangeCompany = (id) => {
    const data = companies.filter((company) => company._id === id);
    const company = data[0];
    setCollectionName(`${company.collection_name}_estimates`);
    fetch(
      `${BASE_URL}/get-estimates?cname=${company.collection_name}_estimates`
    )
      .then((res) => res.json())
      .then((json) => {
        setEstimates(json.estimates);
      });
  };

  const estimates_table = estimates.filter((estimate) =>
    Object.keys(estimate).some(
      (key) => estimate[key].toString().search(searchString) !== -1
    )
  );

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "30rem",
          }}
        >
          <TailSpin ariaLabel="loading-indicator" />
        </div>
      ) : (
        <div>
          <div className="PageTitleRow">
            <Input
              placeholder="Quick Search..."
              icon="search"
              style={{ marginTop: 10 }}
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
            <div style={{ marginTop: 10 }}>
              <Select
                placeholder={
                  companies.length > 0
                    ? companies[0].company_name
                    : "Select Company"
                }
                options={companies.map((company) => ({
                  key: company._id,
                  value: company._id,
                  text: company.company_name,
                }))}
                style={{ margin: "auto" }}
                onChange={(event, data) => handleChangeCompany(data.value)}
              />
            </div>
            <div>
              <Button
                icon
                labelPosition="right"
                style={{
                  backgroundColor: "#d9ead3",
                  color: "#000",
                  marginBottom: 15,
                }}
                onClick={() => {
                  toastr.success("Exported Buttons Enabled Successfully");
                  const table = new TableExport(
                    document.getElementById("estimates-table"),
                    {
                      formats: ["csv"],
                      exportButtons: true,
                      position: "top",
                      ignoreCols: [6],
                    }
                  );
                  const link = document.createElement("a");
                  link.setAttribute(
                    "download",
                    table.getExportData()["estimates-table"].csv.filename
                  );
                  link.click();
                }}
              >
                Export
                <Icon name="share square icon" />
              </Button>
            </div>
          </div>

          <Table
            sortable
            unstackable
            celled
            basic="very"
            className="CustomTableLayout"
            id="estimates-table"
          >
            <Table.Header>
              <Table.Row>
                {HEADING_DATA.map((heading) => (
                  <Table.HeaderCell
                    sorted={column === heading ? direction : null}
                    onClick={handleSort(heading)}
                  >
                    {heading === "Checkbox" ? (
                      <Checkbox />
                    ) : (
                      <span>{heading}</span>
                    )}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {estimates_table.reverse().map((estimate, index) => (
                <Table.Row>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>
                    {moment(estimate.estimate_date).format("DD-MM-YYYY")}
                  </Table.Cell>
                  <Table.Cell>{estimate.estimate_no}</Table.Cell>
                  <Table.Cell>Mr. {estimate.customer_name}</Table.Cell>
                  <Table.Cell>+91 {estimate.customer_phone}</Table.Cell>
                  <Table.Cell>
                    <Icon name="rupee" />
                    {estimate.total_amount}
                  </Table.Cell>
                  <Table.Cell>
                    {/* <Label color="grey">
                  <a
                    onClick={() =>
                      window.open(
                        `/#/edit-estimate/${estimate.company_details.collection_name}/${estimate.estimate_no}`,
                        "_blank"
                      )
                    }
                  >
                    edit
                  </a>
                </Label> */}
                    <Label color="grey">
                      <a
                        onClick={() =>
                          window.open(
                            `/#/view-estimate/${collectionName}/${estimate.estimate_no}`,
                            "_blank"
                          )
                        }
                      >
                        view
                      </a>
                    </Label>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </>
  );
};

export default EstimatesTable;

import React from 'react';
import SettingsTabBarMenu from '../../../commons/settingstabbar/settingstabbar.component';
import MarketingExecutiveLevels from './marketing-executive-levels.component';
import '../settings.css';

const MarketingExecutiveSettings = (props) => {
  return (
    <div className='DefaultLayout'>
      <Header history={props.history} />
      <Card />
    </div>
  );
};

const Header = (props) => {
  return (
    <div className='HeaderLayout'>
      <h3>Settings</h3>
      <SettingsTabBarMenu history={props.history} />
    </div>
  );
};

const Card = () => {
  return (
    <div className='CardContainer'>
      <h3>Marketing Executive Levels:</h3>
      <MarketingExecutiveLevels />
    </div>
  );
};
export default MarketingExecutiveSettings;

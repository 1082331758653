export const HEADING_DATA = [
  "Sno",
  "Company Name",
  "Challan No",
  "Challan Date",
  "Name",
  "Vehicle No",
  "Final Amount",
  "Actions"
];

import React, { Component } from 'react';
import { Grid, Checkbox, Responsive } from 'semantic-ui-react';

const DriverEmailNotificationOptions = () => {
  return (
    <React.Fragment>
      <Grid.Row>
        <Grid.Column>
          <Checkbox label='Driver Signup' />
        </Grid.Column>
        <Grid.Column>
          <Checkbox label='Tasks Assigned' />
        </Grid.Column>
        <Grid.Column>
          <Checkbox label='Order Delivered' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Checkbox label='Received Payout' />
        </Grid.Column>
        <Grid.Column>
          <Checkbox label='Feedback Received' />
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );
};

export class EmailDriverNotifications extends Component {
  render() {
    return (
      <div>
        <div style={{ marginBottom: 20, marginTop: 40, marginLeft: 20 }}>
          <h4>Driver Notifications</h4>
        </div>

        <div style={{ margin: 20 }}>
          <div>
            <Responsive maxWidth={768}>
              <Grid columns={1} divided>
                <DriverEmailNotificationOptions />
              </Grid>
            </Responsive>
            <Responsive minWidth={768}>
              <Grid columns={3} divided>
                <DriverEmailNotificationOptions />
              </Grid>
            </Responsive>
          </div>
        </div>
      </div>
    );
  }
}

export default EmailDriverNotifications;

import React, { useState, useEffect } from "react";
import { Card, Statistic } from "semantic-ui-react";
import { getPercentage } from "./marketing.api";
import { BASE_URL } from "../../../config";
import './marketing-executive.css';

const colors = ['yellow', 'grey', 'blue'];

const MarketingExecutiveHeader = () => {
  const [executives, setExecutives] = useState([]);
  const [executivePerc, setExecutivePerc] = useState(0);

  useEffect(() => {
    fetch(`${BASE_URL}/get-marketing-executives`)
      .then((res) => res.json())
      .then((json) => {
        const percentage = getPercentage(json.executives);
        setExecutivePerc(percentage);
        setExecutives(json.executives);
      });
  }, []);

  return (
    <div style={{ marginTop: 20 }}>
      <Card.Group>
        <Card className='AllExecutivesCard'>
          <Card.Content>
            <div className='headercardContainer'>
              <div>
                <Card.Header className='AllExecutivesTitle'>
                  All Executives
                </Card.Header>
                {Math.sign(executivePerc) === -1 ? (
                  <span style={{ color: "#21ba45", fontWeight: 600 }}>
                    +{Math.abs(executivePerc) || 0}%
                  </span>
                ) : (
                  <span style={{ color: "#db2828", fontWeight: 600 }}>
                    -{executivePerc || 0}%
                  </span>
                )}
                <span style={{ color: '#00000066', marginLeft: 5 }}>
                  this month
                </span>
              </div>

              <Card.Description>
                <div className='MembershipRow'>
                  <Statistic inverted size='tiny'>
                    <Statistic.Value style={{ color: '#22252f' }}>
                      {executives.length}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
        <Card className='NewLeadsCard'>
          <Card.Content>
            <div className='headercardContainer'>
              <div>
                <Card.Header className='NewLeadsTitle'>
                  Total Stores Visited
                </Card.Header>
                <span style={{ color: "#db2828", fontWeight: 500 }}>-5% </span>

                <span style={{ color: '#00000066', marginLeft: 5 }}>
                  this month
                </span>
              </div>
              <Card.Description>
                <div className='MembershipRow'>
                  <Statistic inverted size='tiny'>
                    <Statistic.Value style={{ color: '#22252f' }}>
                      80
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
        <Card className='ConversionCard'>
          <Card.Content>
            <div className='headercardContainer'>
              <div>
                <Card.Header className='ConversionTitle'>
                  Conversion
                </Card.Header>
                <span style={{ color: "#21ba45", fontWeight: 600 }}>+50%</span>
                <span style={{ color: '#00000066', marginLeft: 5 }}>
                  this month
                </span>
              </div>
              <Card.Description>
                <div className='MembershipRow'>
                  <Statistic inverted size='tiny'>
                    <Statistic.Value style={{ color: '#22252f' }}>
                      10
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
      </Card.Group>
    </div>
  );
};

export default MarketingExecutiveHeader;

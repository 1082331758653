import React from "react";
import moment from "moment";
import {
  Icon,
  Image,
  Button,
  Popup,
  Input,
  Table,
  Dropdown,
  Checkbox,
  Form,
  TextArea,
  Select,
} from "semantic-ui-react";
import toastr from "toastr";
import PAID from "../../../images/paid.png";
import { BASE_URL } from "../../../config";

const EstimateGeneration = (props) => {
  return (
    <div className="invoiceDefaultLayout">
      <Card history={props.history} userData={props.history.location.state} />
    </div>
  );
};

class Card extends React.Component {
  state = {
    estimate_settings: {},
    estimate_no: 0,
    estimate_date: "",
    customer_name: "",
    customer_phone: "",
    customer_address: "",
    transaction_type: "",
    transaction_id: "",
    transaction_date: "",
    transaction_amount: "",
    balance_amount: "",
    product_name: "",
    product_weight: "",
    product_quantity: "",
    product_price: "",
    customer_notes: "",
    pricing_terms: "",
    delivery_charges: "",
    coupon_code: "",
    coupon_discount: 0,
    gst_amount: "",
    order_total: "",
    cust_notes_status: false,
    pricing_terms_status: false,
    addPaymentModal: false,
    addPaymentStatus: false,
    addProductModal: false,
    productsList: [],
    transactions: [],
    products: [],
    product_pricing: [],
    customers: [],
    companies: [],
    cgst: 0,
    sgst: 0,
    cgstOptions: [],
    sgstOptions: [],
  };

  componentDidMount = () => {
    this.setState({ estimate_date: Date.now() });
    fetch(`${BASE_URL}/get-companies`)
      .then((res) => res.json())
      .then((json) => {
        if (json.companies.length > 0) {
          this.getEstimateNo(`${json.companies[0].collection_name}_estimates`);
          this.setState({
            estimate_settings: json.companies[0],
            customer_notes: json.companies[0].default_customer_notes,
            pricing_terms: json.companies[0].default_pricing_terms,
            delivery_charges: json.companies[0].delivery_charges,
            cgstOptions: json.companies[0].cgst,
            sgstOptions: json.companies[0].sgst,
            cgst: json.companies[0].cgst[0],
            sgst: json.companies[0].sgst[0],
            companies: json.companies,
          });
        }
      });
    fetch(`${BASE_URL}/get-merchants`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ customers: json.merchants });
      });
    fetch(`${BASE_URL}/get-products`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ products: json.products });
      });
  };

  generateEstimate = () => {
    const {
      estimate_no,
      estimate_date,
      estimate_settings,
      customer_name,
      customer_phone,
      customer_address,
      productsList,
      delivery_charges,
      coupon_code,
      coupon_discount,
      transactions,
      cust_notes_status,
      customer_notes,
      pricing_terms_status,
      pricing_terms,
      cgst,
      sgst,
    } = this.state;

    if (customer_name.length === 0 && customer_phone.length === 0) {
      toastr.error("Please enter customer details to generate invoice!");
    } else if (productsList.length === 0) {
      toastr.error("Please add atleast one product to generate invoice!");
    } else {
      fetch(`${BASE_URL}/create-estimate`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          estimate_no,
          estimate_date,
          company_details: estimate_settings,
          products: productsList,
          delivery_charges,
          coupon_details: {
            code: coupon_code,
            discount: coupon_discount,
          },
          gst_amount: this.getGstAmount(productsList),
          cgst,
          sgst,
          order_total: this.getOrderTotal(productsList),
          customer_name,
          customer_phone,
          customer_address,
          payment_status: "fullypaid",
          transactions,
          cust_notes_status,
          customer_notes,
          pricing_terms_status,
          pricing_terms,
          role: "MERCHANT",
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          toastr.success("Customer Estimate Generated Successfully!");
          this.props.history.push("/estimates");
        });
    }
  };

  showAddProductModal = () => () => this.setState({ addProductModal: true });
  addProductClose = () => this.setState({ addProductModal: false });

  addProduct = () => {
    const { product_name, product_quantity, product_price, product_weight } =
      this.state;
    this.setState({
      productsList: this.state.productsList.concat([
        {
          name: product_name,
          quantity: product_quantity,
          weight: product_weight,
          price: product_price,
        },
      ]),
    });
  };

  removeProduct = (idx) => () => {
    this.setState({
      productsList: this.state.productsList.filter((s, sidx) => idx !== sidx),
    });
  };

  getTransactionsBalance = (transactions) => {
    const paymentData = transactions.filter((transaction) =>
      Object.keys(transaction).some(
        (key) => transaction[key].toString().search("payment") !== -1
      )
    );
    const payments = paymentData.reduce(
      (sum, transaction, index) => sum + Number(transaction.transaction_amount),
      0
    );

    const refundData = transactions.filter((transaction) =>
      Object.keys(transaction).some(
        (key) => transaction[key].toString().search("refund") !== -1
      )
    );
    const refunds = refundData.reduce(
      (sum, transaction, index) => sum + Number(transaction.transaction_amount),
      0
    );

    const total_balance = payments - refunds;
    return Number(total_balance);
  };

  getProductsTotal = (products) => {
    const total_value = products.reduce(
      (sum, product, index) => sum + Number(product.quantity * product.price),
      0
    );
    return Number(total_value);
  };

  getCouponDiscount = () => {
    const { coupon_discount, productsList } = this.state;

    const discount =
      (coupon_discount / 100) * this.getProductsTotal(productsList);

    return discount;
  };

  getGstAmount = (products) => {
    const { cgst, sgst } = this.state;
    const gst =
      (this.getProductsTotal(products) * (Number(cgst) + Number(sgst))) / 100;

    return Math.round(gst);
  };

  getBalanceDue = (products, transactions) => {
    const { delivery_charges } = this.state;

    const order_total =
      this.getProductsTotal(products) +
      this.getGstAmount(products) +
      Number(delivery_charges) -
      this.getCouponDiscount();

    const due = order_total - this.getTransactionsBalance(transactions);

    return due;
  };

  getOrderTotal = (products) => {
    const { delivery_charges } = this.state;

    const order_total =
      this.getProductsTotal(products) +
      this.getGstAmount(products) +
      Number(delivery_charges) -
      this.getCouponDiscount();

    return order_total;
  };

  getEstimateNo = (value) => {
    fetch(`${BASE_URL}/get-estimate-no?cname=${value}`)
      .then((res) => res.json())
      .then((json) => {
        if (json.estimates.length > 0) {
          this.setState({ estimate_no: json.estimates.length + 1 });
        } else {
          this.setState({ estimate_no: 1001 });
        }
      });
  };

  handleChangeCompany = (id) => {
    const data = this.state.companies.filter((company) => company._id === id);
    const company = data[0];

    this.getEstimateNo(`${company.collection_name}_estimates`);
    this.setState({
      estimate_settings: company,
    });
  };

  render() {
    const {
      estimate_settings,
      estimate_no,
      estimate_date,
      customer_name,
      customer_address,
      transactions,
      customers,
      products,
      product_pricing,
      productsList,
      customer_notes,
      pricing_terms,
      delivery_charges,
      coupon_code,
      cust_notes_status,
      pricing_terms_status,
      companies,
      cgst,
      sgst,
      cgstOptions,
      sgstOptions,
    } = this.state;

    return (
      <div className="invoiceCardContainer">
        <div className="invoicePageTitleRow">
          <div style={{ paddingTop: 10, paddingLeft: 20, display: "flex" }}>
            <Icon
              name="arrow left"
              style={{ paddingTop: 3, cursor: "pointer" }}
              onClick={() => this.props.history.push("/orderrequests")}
            />
            <h3 style={{ marginTop: 0, marginLeft: 10 }}>
              Estimate Generation
            </h3>
          </div>
          <div>
            <Select
              placeholder="Select Company"
              options={companies.map((company) => ({
                key: company._id,
                value: company._id,
                text: company.company_name,
              }))}
              style={{ margin: "auto" }}
              onChange={(event, data) => this.handleChangeCompany(data.value)}
            />
          </div>
          <div>
            <Button
              className="invoiceSetBtn"
              onClick={() => this.props.history.push("/companies")}
            >
              <Icon name="setting" /> Estimate Settings
            </Button>
          </div>

          <div>
            <Button
              className="generateSaveBtn"
              onClick={() => this.generateEstimate()}
            >
              <Icon name="save" /> Generate Estimate
            </Button>
          </div>
        </div>

        <div className="Card">
          <div className="invoiceContainer">
            <div>
              <Image src={estimate_settings.logo} style={{ width: 100 }} />
            </div>
            <div className="section1">
              <div>
                <p className="section1Text" style={{ fontSize: 22 }}>
                  {estimate_settings.company_name || "Company Name"},
                </p>
                <p className="section1Text">
                  {estimate_settings.address_line1 || "Address line1,"}
                </p>
                <p className="section1Text">
                  {estimate_settings.address_line2 || "Address line2,"}{" "}
                  {estimate_settings.address_line3 || "Address line3,"}
                </p>
                <p className="section1Text">
                  Email: {estimate_settings.email || "sample@gmail.com"}
                </p>

                <p className="section1Text" style={{ marginBottom: 30 }}>
                  Contact: +91-
                  {estimate_settings.whatsapp_number || "9999999999"}
                </p>
                <p className="section1Text">
                  GSTIN: {estimate_settings.gst_no || "No GST Number"}
                </p>
              </div>
              <div>
                <Image src={PAID} style={{ width: 150, marginRight: 100 }} />
              </div>
            </div>
            <div className="section2">
              <div>
                <p
                  className="section2Text"
                  style={{ fontSize: 22, fontWeight: "bold" }}
                >
                  Estimate #{estimate_no}{" "}
                </p>
                <p className="section2Text">
                  Estimate Date: {moment(estimate_date).format("DD/MM/YYYY")}
                  {/* <Popup
                    on="click"
                    pinned
                    style={{ textAlign: "center" }}
                    trigger={
                      <Icon
                        name="pencil"
                        style={{
                          fontSize: 15,
                          color: "#93c47d",
                          marginLeft: 10,
                        }}
                      />
                    }
                  >
                    <div>
                      <p>Edit date:</p>
                      <input
                        type="date"
                        placeholder="Estimate number"
                        className="datepickerinput"
                        onChange={(e) =>
                          this.setState({ estimate_date: e.target.value })
                        }
                      />
                    </div>
                  </Popup> */}
                </p>
              </div>
              {customer_name !== "" ? (
                <div>
                  <p className="section3Text selectionHeading">
                    <Popup
                      on="click"
                      pinned
                      className="selectionMerchantPopUpContainer"
                      trigger={
                        <Icon
                          name="pencil"
                          style={{
                            fontSize: 18,
                            color: "#93c47d",
                            marginLeft: 5,
                            cursor: "pointer",
                          }}
                        />
                      }
                    >
                      <div>
                        <p className="selectionHeading">Select Merchant:</p>
                        <Dropdown
                          placeholder="Select Merchant"
                          fluid
                          search
                          selection
                          style={{ cursor: "pointer" }}
                          options={customers.map((customer, index) => ({
                            key: index,
                            text: customer.name,
                            value: customer,
                          }))}
                          onChange={(event, data) =>
                            this.setState({
                              customer_name: data.value.name,
                              customer_phone: data.value.phoneNumber,
                              customer_address: data.value.address,
                            })
                          }
                        />
                      </div>
                    </Popup>
                    Estimated To
                  </p>
                  <p
                    className="section3Text"
                    style={{ fontSize: 22, fontWeight: "bold" }}
                  >
                    {customer_name}
                  </p>
                  <p
                    className="section3Text"
                    style={{ fontSize: 16, fontWeight: "500" }}
                  >
                    {customer_address}
                  </p>
                </div>
              ) : (
                <div style={{ alignSelf: "center" }}>
                  <p className="section3Text selectionHeading">
                    <Popup
                      on="click"
                      pinned
                      className="selectionMerchantPopUpContainer"
                      trigger={
                        <Icon
                          name="add"
                          style={{
                            fontSize: 32,
                            color: "#93c47d",
                            marginLeft: 5,
                            cursor: "pointer",
                          }}
                        />
                      }
                    >
                      <div>
                        <p className="selectionHeading">Select Merchant:</p>
                        <Dropdown
                          placeholder="Select Merchant"
                          fluid
                          search
                          selection
                          style={{ cursor: "pointer" }}
                          options={customers.map((customer, index) => ({
                            key: index,
                            text: customer.name,
                            value: customer,
                          }))}
                          onChange={(event, data) =>
                            this.setState({
                              customer_name: data.value.name,
                              customer_phone: data.value.phoneNumber,
                              customer_address: data.value.address,
                            })
                          }
                        />
                      </div>
                    </Popup>
                  </p>
                </div>
              )}
            </div>

            <div className="section3"></div>
            <div className="section4">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontSize: 20, fontWeight: "600" }}>Products</p>
                <div>
                  <Popup
                    on="click"
                    pinned
                    position="left center"
                    style={{ textAlign: "center" }}
                    trigger={
                      <Icon
                        name="pencil"
                        style={{
                          fontSize: 20,
                          color: "#93c47d",
                          marginLeft: 5,
                          cursor: "pointer",
                        }}
                      />
                    }
                  >
                    <div className="mb-15">
                      <p className="selectionHeading">Select Product:</p>
                      <Dropdown
                        placeholder="Select Product"
                        fluid
                        search
                        selection
                        className="selectionProductDropdownWidth"
                        options={products.map((product, index) => ({
                          key: index,
                          text: product.name,
                          value: product,
                        }))}
                        onChange={(event, data) =>
                          this.setState({
                            product_name: data.value.name,
                            product_pricing: data.value.pricing,
                          })
                        }
                      />
                    </div>
                    <div className="mb-15">
                      <p className="selectionHeading">Select Weight:</p>
                      <Dropdown
                        placeholder="Select Pricing"
                        fluid
                        search
                        selection
                        className="selectionProductDropdownWidth"
                        options={product_pricing.map((pricing, index) => ({
                          key: index,
                          text: `${pricing.weight} Kg`,
                          value: pricing,
                        }))}
                        onChange={(event, data) =>
                          this.setState({
                            product_weight: data.value.weight,
                            product_price: data.value.merchantPrice,
                          })
                        }
                      />
                    </div>
                    <div className="mb-15">
                      <p className="selectionHeading">Amount:</p>
                      <Input
                        value={this.state.product_price}
                        onChange={(e) =>
                          this.setState({ product_price: e.target.value })
                        }
                        className="selectionProductDropdownWidth"
                        disabled
                      />
                    </div>
                    <div className="mb-15">
                      <p className="selectionHeading">Quantity:</p>
                      <Input
                        placeholder="Enter Quantity"
                        className="selectionProductDropdownWidth"
                        onChange={(e) =>
                          this.setState({ product_quantity: e.target.value })
                        }
                      />
                    </div>
                    <div>
                      <Button onClick={this.addProduct}>Submit</Button>
                    </div>
                  </Popup>
                </div>
              </div>
              <div>
                <Table celled unstackable>
                  <Table.Header>
                    <Table.Row textAlign="center">
                      <Table.HeaderCell style={{ backgroundColor: "#b6d7a8" }}>
                        S.no
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: "#b6d7a8" }}>
                        Products
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: "#b6d7a8" }}>
                        Quantity
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: "#b6d7a8" }}>
                        Price
                      </Table.HeaderCell>
                      
                      <Table.HeaderCell style={{ backgroundColor: "#b6d7a8" }}>
                        Total
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {productsList.map((product, idx) => (
                      <Table.Row textAlign="center">
                        <Table.Cell textAlign="center">{idx + 1}</Table.Cell>
                        <Table.Cell>
                          {product.name} - {product.weight}Kg
                        </Table.Cell>
                        <Table.Cell>{product.quantity}</Table.Cell>
                        <Table.Cell>Rs. {product.price}</Table.Cell>
                        <Table.Cell>
                          Rs. {product.quantity * product.price}
                          <Icon
                            name="times circle"
                            className="cancelRowBtn"
                            color="red"
                            onClick={this.removeProduct(idx)}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ alignSelf: "flex-end" }}>
                    <div style={{ marginBottom: 20 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 17,
                            fontWeight: "600",
                            marginBottom: 10,
                          }}
                        >
                          Customer Notes
                        </p>
                        <Checkbox
                          label="Enable"
                          checked={cust_notes_status}
                          onChange={() =>
                            this.setState({
                              cust_notes_status: !cust_notes_status,
                            })
                          }
                        />
                      </div>

                      <Form>
                        <TextArea
                          placeholder="Thanks for your business."
                          cols="50"
                          maxLength="70"
                          value={customer_notes}
                          onChange={(e) =>
                            this.setState({ customer_notes: e.target.value })
                          }
                        />
                      </Form>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 17,
                            fontWeight: "600",
                            marginBottom: 10,
                          }}
                        >
                          Pricing Terms
                        </p>
                        <Checkbox
                          label="Enable"
                          checked={pricing_terms_status}
                          onChange={() =>
                            this.setState({
                              pricing_terms_status: !pricing_terms_status,
                            })
                          }
                        />
                      </div>

                      <Form>
                        <TextArea
                          placeholder="Thanks for your business."
                          cols="50"
                          maxLength="70"
                          value={pricing_terms}
                          onChange={(e) =>
                            this.setState({ pricing_terms: e.target.value })
                          }
                        />
                      </Form>
                    </div>
                  </div>

                  <div>
                    <Table celled unstackable collapsing>
                      <Table.Body>
                        <Table.Row textAlign="right">
                          <Table.Cell className="tableCellbg">
                            Item Total
                          </Table.Cell>
                          <Table.Cell className="tableCellbg">
                            ₹ {this.getProductsTotal(productsList)}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ color: "#cc0000" }}
                          >
                            <Popup
                              on="click"
                              pinned
                              style={{ textAlign: "center" }}
                              trigger={
                                <Icon
                                  name="pencil"
                                  style={{
                                    fontSize: 15,
                                    color: "#93c47d",
                                    marginLeft: 5,
                                  }}
                                />
                              }
                            >
                              <div>
                                <p style={{ marginBottom: 5 }}>
                                  Edit Coupon Code:
                                </p>
                                <Input
                                  placeholder="Coupon code"
                                  onChange={(e) =>
                                    this.setState({
                                      coupon_code: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div>
                                <p style={{ marginBottom: 5 }}>
                                  Edit Coupon discount:
                                </p>
                                <Input
                                  placeholder="Coupon discount Perc"
                                  onChange={(e) =>
                                    this.setState({
                                      coupon_discount: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </Popup>
                            Coupon Applied({coupon_code || "No Coupon"})
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ color: "#cc0000" }}
                          >
                            -₹ {this.getCouponDiscount() || 0}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            Other Charges
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            ₹ {delivery_charges}
                            <Popup
                              on="click"
                              pinned
                              style={{ textAlign: "center" }}
                              trigger={
                                <Icon
                                  name="pencil"
                                  style={{
                                    fontSize: 15,
                                    color: "#93c47d",
                                    marginLeft: 5,
                                    position: "absolute",
                                    right: 85,
                                  }}
                                />
                              }
                            >
                              <div>
                                <p style={{ marginBottom: 5 }}>
                                  Edit Other Charges:
                                </p>
                                <Input
                                  placeholder="Other Charges"
                                  onChange={(e) =>
                                    this.setState({
                                      delivery_charges: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </Popup>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            {cgst}.00% CGST
                            {/* <Popup
                              on="click"
                              pinned
                              style={{ textAlign: "center" }}
                              trigger={
                                <Icon
                                  name="pencil"
                                  style={{
                                    fontSize: 15,
                                    color: "#93c47d",
                                    marginLeft: 5,
                                  }}
                                />
                              }
                            >
                              <div>
                                <p>Select Percentage:</p>
                                <Dropdown
                                  placeholder="Select Percentage"
                                  fluid
                                  search
                                  selection
                                  options={cgstOptions.map((cgst, index) => ({
                                    key: index,
                                    text: cgst,
                                    value: cgst,
                                  }))}
                                  onChange={(event, data) =>
                                    this.setState({
                                      cgst: data.value,
                                    })
                                  }
                                />
                              </div>
                            </Popup> */}
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            ₹ {this.getGstAmount(productsList) / 2}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            {sgst}.00% SGST
                            {/* <Popup
                              on="click"
                              pinned
                              style={{ textAlign: "center" }}
                              trigger={
                                <Icon
                                  name="pencil"
                                  style={{
                                    fontSize: 15,
                                    color: "#93c47d",
                                    marginLeft: 5,
                                  }}
                                />
                              }
                            >
                              <div>
                                <p>Select Percentage:</p>
                                <Dropdown
                                  placeholder="Select Percentage"
                                  fluid
                                  search
                                  selection
                                  options={sgstOptions.map((sgst, index) => ({
                                    key: index,
                                    text: sgst,
                                    value: sgst,
                                  }))}
                                  onChange={(event, data) =>
                                    this.setState({
                                      sgst: data.value,
                                    })
                                  }
                                />
                              </div>
                            </Popup> */}
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            ₹ {this.getGstAmount(productsList) / 2}
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ color: "#6aa84f" }}
                          >
                            Total Estimation
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ color: "#6aa84f" }}
                          >
                            ₹ {this.getBalanceDue(productsList, transactions)}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EstimateGeneration;

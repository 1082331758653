import React, { useState, useEffect } from "react";
import {
  Label,
  List,
  Tab,
  Table,
  Modal,
  Button,
  Input,
  Popup,
  Icon,
  Rating
} from "semantic-ui-react";
import moment from "moment";
import { BASE_URL } from "../../../../config";

const DriverProfileDetails = props => {
  const panes = [
    {
      menuItem: "Driver Details",
      render: () => (
        <Tab.Pane style={{ marginLeft: -10 }}>
          <DriverDetails userDetails={props.driverDetails} />
        </Tab.Pane>
      )
    },
    // {
    //   menuItem: "License Details",
    //   render: () => (
    //     <Tab.Pane style={{ marginLeft: -10 }}>
    //       <LicenseDetails userDetails={props.driverDetails} />
    //     </Tab.Pane>
    //   ),
    // },

    // {
    //   menuItem: "Vehicle Details",
    //   render: () => (
    //     <Tab.Pane style={{ marginLeft: -10 }}>
    //       <VehicleDetails userDetails={props.driverDetails} />
    //     </Tab.Pane>
    //   ),
    // },
    {
      menuItem: "Payout",
      render: () => (
        <Tab.Pane style={{ marginLeft: -10 }}>
          <Payout userDetails={props.driverDetails} />
        </Tab.Pane>
      )
    },
    {
      menuItem: "Order History",
      render: () => (
        <Tab.Pane style={{ marginLeft: -10 }}>
          <OrderHistory userDetails={props.driverDetails} />
        </Tab.Pane>
      )
    }
  ];

  return (
    <div>
      <Tab
        menu={{
          fluid: true,
          vertical: true,
          tabular: true,
          pointing: true
        }}
        panes={panes}
      />
    </div>
  );
};

const DriverDetails = props => {
  const { userDetails } = props;
  return (
    <List divided animated>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{userDetails.name}</p>
        </List.Content>
        <List.Content>Customer Name: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{userDetails.email}</p>
        </List.Content>
        <List.Content>Customer email: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{userDetails.licenseNo}</p>
        </List.Content>
        <List.Content>License No: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{moment(userDetails.createdAt).format("ll")}</p>
        </List.Content>
        <List.Content>Registration Date: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{moment(userDetails.createdAt).format("ll")}</p>
        </List.Content>
        <List.Content>Account Activated on :</List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <Label color="green">active</Label>
        </List.Content>
        <List.Content>Account Status :</List.Content>
      </List.Item>
    </List>
  );
};

const LicenseDetails = props => {
  const { userDetails } = props;

  return (
    <List divided animated>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{userDetails.licenseNo}</p>
        </List.Content>
        <List.Content>License No: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>May, 2021</p>
        </List.Content>
        <List.Content>Expiry: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>5 years</p>
        </List.Content>
        <List.Content>Experience: </List.Content>
      </List.Item>
    </List>
  );
};

const VehicleDetails = () => {
  return (
    <List divided animated>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <h4>Yes</h4>
        </List.Content>
        <List.Content>Owned: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <Label color="yellow">TN12AA4500</Label>
        </List.Content>
        <List.Content>Vehicle No: </List.Content>
      </List.Item>
    </List>
  );
};

const Payout = props => {
  const { userDetails } = props;
  const [daOrders, setDAOrders] = useState([]);

  useEffect(() => {
    if (userDetails.orders.length > 0) {
      setDAOrders(userDetails.orders);
    }
  }, [userDetails]);
  return (
    <List divided animated>
      <List.Item className="DetailListItems">
        <List.Content>Payout : </List.Content>
        {/* <Table
          sortable
          unstackable
          celled
          color="grey"
          className="CustomTableLayout"
        >
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>Month</Table.HeaderCell>
              <Table.HeaderCell>Transaction Date</Table.HeaderCell>
              <Table.HeaderCell>Transaction ID</Table.HeaderCell>
              <Table.HeaderCell>Totsl Earings </Table.HeaderCell>
              <Table.HeaderCell>Payment Status </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          {userDetails.payouts !== undefined && (
            <Table.Body>
              {userDetails.payouts.map((payout, index) => (
                <Table.Row textAlign="center">
                  <Table.Cell>
                    {moment(payout.monthRange).format("MMM-YYYY")}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(payout.transactionDate).format("DD-MM-YYYY")}
                  </Table.Cell>
                  <Table.Cell>{payout.transactionId}</Table.Cell>
                  <Table.Cell>{payout.totalEarnings}</Table.Cell>
                  <Table.Cell>{payout.paymentStatus}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table> */}
         <Table
          sortable
          unstackable
          celled
          color="grey"
          className="CustomTableLayout"
        >
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>Order Id</Table.HeaderCell>
              <Table.HeaderCell>Company name</Table.HeaderCell>
              <Table.HeaderCell>Order Delivered</Table.HeaderCell>
              <Table.HeaderCell>Deliver Earnings</Table.HeaderCell>
              {/* <Table.HeaderCell>Total Earings </Table.HeaderCell>
              <Table.HeaderCell>Payment Status</Table.HeaderCell> */}
            </Table.Row>
          </Table.Header>

          {daOrders.length > 0 && (
            <Table.Body>
              {daOrders.map((order, index) => (
                <Table.Row textAlign="center">
                  <Table.Cell>
                    #{order.order_id}
                  </Table.Cell>
                  <Table.Cell>{order.company_name || "-"}</Table.Cell>
                  <Table.Cell>
                    {moment(order.updated_time).format("DD-MM-YYYY hh:mm A")}
                  </Table.Cell>
                  <Table.Cell>{order.delivery_agent_fee}</Table.Cell>
                  {/* <Table.Cell>{payout.totalEarnings}</Table.Cell>
                  <Table.Cell>{payout.paymentStatus}</Table.Cell> */}
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </List.Item>
    </List>
  );
};
const OrderHistory = props => {
  const { userDetails } = props;

  const [showorderDetailsModal, setShowOrderDetailsModal] = useState(false);
  const [addVechicelDetails, setAddVechicelDetails] = useState(false);
  const [daOrders, setDAOrders] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [ modalData, setModalData] = useState({});

  useEffect(() => {
    if (userDetails.orders.length > 0) {
      setDAOrders(userDetails.orders);
    }
  }, [userDetails]);

  const handleShowMore = () => {
    return setShowMore({ showMore: !showMore });
  };

  const close = () => {
    setShowOrderDetailsModal(false);
    setAddVechicelDetails(false);
  };
  const renderProducts = order => (
    <React.Fragment>
      {order.products.length > 2 ? (
        <React.Fragment>
          {showMore === false ? (
            <React.Fragment>
              {order.products.slice(1, 3).map((product, index) => (
                <React.Fragment>
                  <p>
                    {index + 1}) {product.name}: {product.quantity} x{" "}
                    {product.weight}Kg
                  </p>
                </React.Fragment>
              ))}
              <p
                style={{ color: "#6d9eeb", cursor: "pointer" }}
                onClick={handleShowMore}
              >
                Show more <Icon name="long arrow alternate down" size="small" />
              </p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {order.products.map((product, index) => (
                <React.Fragment>
                  <p>
                    {index + 1}) {product.name}: {product.quantity} x{" "}
                    {product.weight}Kg
                  </p>
                </React.Fragment>
              ))}
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {order.products.map((product, index) => (
            <React.Fragment>
              <p>
                {index + 1}) {product.name}: {product.quantity} x{" "}
                {product.weight}Kg
              </p>
            </React.Fragment>
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  );
  return (
    <List divided animated>
      <List.Item className="DetailListItems">
        {/* <List.Content>Completed Orders : </List.Content> */}
        <div className="OHSectionOne">
          {/* <div >
            <span>Select Date:</span>
            <input type="date" className="OHSelectDate" />
          </div> */}
          {/* <div>
            <Button
              color="green"
              onClick={() => window.open("/#/dashboard", "_blank")}
            >
              Driver Expenses
            </Button>
          </div>
          <div>
            <p onClick={() => window.open("/#/customers", "_blank")}>
              Vehicle Details: TATA ACE(TN02A7589)
            </p>
          </div> */}
          {/* <div style={{ display: "flex" }}>
            <p>Starting - 1389 KM</p>
            <p style={{ marginLeft: 10 }}>Ending - 1445 KM</p>
          </div> */}
        </div>
        <Table
          sortable
          unstackable
          celled
          color="grey"
          className="CustomTableLayout"
        >
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>Order Id </Table.HeaderCell>
              <Table.HeaderCell>Name </Table.HeaderCell>
              <Table.HeaderCell>Products </Table.HeaderCell>
              <Table.HeaderCell> Phonenumber </Table.HeaderCell>
              <Table.HeaderCell> Received Cash / Feedback </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {daOrders.length > 0 && (
              <>
                {daOrders.map(order => (
                  <Table.Row textAlign="center">
                    <Table.Cell>
                      <p
                        className="maintenanceCost"
                        onClick={() => {
                          setShowOrderDetailsModal(true);
                          setModalData(order);
                        }}
                      >
                        #{order.order_id}
                      </p>
                    </Table.Cell>
                    <Table.Cell>{order.name}</Table.Cell>
                    <Table.Cell>{renderProducts(order)}</Table.Cell>
                    <Table.Cell>{order.phone_number || "-"}</Table.Cell>
                    <Table.Cell>
                      {" "}
                      <p>RC: ₹ {order.received_cash || "- -"}</p>{" "}
                      <p>
                        FB: {order.merchant_rating || "- -"}
                        <Icon
                          name="star"
                          color="yellow"
                          style={{ marginLeft: 2 }}
                        />{" "}
                      </p>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </>
            )}
          </Table.Body>
        </Table>
      </List.Item>

      <div>
        <Modal size={"tiny"} open={showorderDetailsModal} onClose={close}>
          <div className="modal-container">
            <h3 className="orderdetailsheading">Order Details</h3>
            <div>
              <div style={{ display: "flex" }}>
                <p style={{ width: "30%" }}>Order ID:</p>
                <p style={{ paddingLeft: 5, fontWeight: "700" }}>#{modalData.order_id}</p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ width: "30%" }}>Name:</p>
                <p style={{ paddingLeft: 5, fontWeight: "700" }}>{modalData.name}</p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ width: "30%" }}>User role:</p>
                <p style={{ paddingLeft: 5 }}>{modalData.user_role}</p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ width: "30%" }}>Mobile number:</p>
                <p style={{ paddingLeft: 5, fontWeight: "700" }}>{modalData.phone_number}</p>
              </div>
              <div style={{ display: "flex", paddingBottom: 10 }}>
                <p style={{ width: "30%" }}>Address:</p>
                <p style={{ paddingLeft: 5, width: "70%" }}>
                  {modalData.shipping_address !== undefined ? modalData.shipping_address.detailedAddress : "-"}
                </p>
              </div>
             
              <div style={{ display: "flex", paddingBottom: 10 }}>
                <p style={{ width: "30%" }}>Order Status:</p>
                <p style={{ paddingLeft: 5, width: "70%" }}>{modalData.status}</p>
              </div>
              <div style={{ display: "flex", paddingBottom: 10 }}>
                <p style={{ width: "30%" }}>Delivered Date:</p>
                
                <p style={{ paddingLeft: 5, width: "70%" }}>{moment(modalData.updated_time).format("DD MMM YYYY hh:mm A")}</p>
              </div>
              <div style={{ display: "flex", paddingBottom: 10 }}>
                <p style={{ width: "30%" }}>Payment Status:</p>
                <p style={{ paddingLeft: 5, width: "70%" }}>{modalData.payment_status}</p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ width: "30%" }}>Total price:</p>
                <p style={{ paddingLeft: 5 }}>₹ {modalData.order_total}</p>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      <div>
        <Modal size={"small"} open={addVechicelDetails} onClose={close}>
          <div className="modal-container">
            <h3 className="orderdetailsheading">Add Vechicel Details</h3>
            <div>
              <div className="driverFormRow">
                <span className="titleDriverform">Select Date:</span>
                <input type="date" style={{}} />
              </div>
              <div className="driverFormRow">
                <p className="titleDriverform">Starting KM Value:</p>
                <Input
                  placeholder="Enter Starting KM"
                  style={{ width: "50%" }}
                />
              </div>
              <div className="driverFormRow">
                <p className="titleDriverform">Starting Spedometer Image:</p>
                <Input
                  type="file"
                  placeholder="Enter customer name"
                  style={{ width: "50%" }}
                />
              </div>
              <div className="driverFormRow">
                <p className="titleDriverform">Ending KM Value:</p>
                <Input
                  placeholder="Enter Starting KM"
                  style={{ width: "50%" }}
                />
              </div>
              <div className="driverFormRow">
                <p className="titleDriverform">Ending Spedometer Image:</p>
                <Input
                  type="file"
                  placeholder="Enter customer name"
                  style={{ width: "50%" }}
                />
              </div>
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Button className="addCustomerCancelBtn">Cancel</Button>
                <Button
                  className="addCustomersubmitBtn"
                  content="Submit"
                  style={{ marginLeft: 50 }}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </List>
  );
};

const AddReceivedCash = () => {
  return (
    <Popup
      on="click"
      pinned
      style={{ textAlign: "center" }}
      trigger={<Button className="addDriverBtn">Add RC</Button>}
    >
      <div>
        <p>Add Received Cash by Merchant:</p>
        <Input placeholder="Enter Received Cash" />
      </div>
      <Button
        style={{ fontSize: 12, marginTop: 10, backgroundColor: "#c9daf8" }}
      >
        Submit
      </Button>
    </Popup>
  );
};

const AddFeedBack = () => {
  return (
    <Popup
      on="click"
      pinned
      style={{ textAlign: "center" }}
      trigger={
        <Button className="addTransaction" style={{ marginTop: 10 }}>
          Add FB
        </Button>
      }
    >
      <div>
        <p>Add Rating For Merchant:</p>
        <Rating icon="star" defaultRating={1} maxRating={5} />
      </div>
      <Button
        style={{ fontSize: 12, marginTop: 10, backgroundColor: "#c9daf8" }}
      >
        Submit
      </Button>
    </Popup>
  );
};

export default DriverProfileDetails;

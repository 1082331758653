import React, { useState, useEffect } from "react";
import { Card, Statistic } from "semantic-ui-react";
import { getPercentage } from "./dashboard.api";
import { BASE_URL } from "../../../config";

const DashboardMemberDetails = (props) => {
  const role = props.userDetails.role;
  const [customers, setCustomers] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [marketingExec, setMarketingExec] = useState([]);
  const [cusPerc, setCusPerc] = useState(0);
  const [merPerc, setMerPerc] = useState(0);
  const [execPerc, setExecPerc] = useState(0);

  useEffect(() => {
    fetch(`${BASE_URL}/get-customers`)
      .then((res) => res.json())
      .then((json) => {
        const percentage = getPercentage(json.customers);
        setCusPerc(percentage);
        setCustomers(json.customers);
      });
    fetch(`${BASE_URL}/get-merchants`)
      .then((res) => res.json())
      .then((json) => {
        const percentage = getPercentage(json.merchants);
        setMerPerc(percentage);
        setMerchants(json.merchants);
      });
    fetch(`${BASE_URL}/get-marketing-executives`)
      .then((res) => res.json())
      .then((json) => {
        const percentage = getPercentage(json.executives);
        setExecPerc(percentage);
        setMarketingExec(json.executives);
      });
  }, []);

  // console.log(cusPerc, merPerc === NaN ? 0 : merPerc, execPerc);

  return (
    <div style={{ marginTop: 20 }}>
      <Card.Group>
        <Card className="AllCustomersStatsiticsCard">
          <Card.Content>
            <div className="customerCardContainer">
              <div>
                <Card.Header className="AllCustomersTitle">
                  All Customers
                </Card.Header>
                {Math.sign(cusPerc) === -1 ? (
                  <span style={{ color: "#21ba45", fontWeight: 600 }}>
                    +{Math.abs(cusPerc) || 0}%
                  </span>
                ) : (
                  <span style={{ color: "#db2828", fontWeight: 600 }}>
                    -{Math.abs(cusPerc) || 0}%
                  </span>
                )}

                <span style={{ color: "#00000066", marginLeft: 5 }}>
                  in this month
                </span>
              </div>

              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="tiny">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      {customers.length}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
        {(role === 1 || role === 2 || role === 5 )&& <Card className="AllMerchantsStatsiticsCard">
          <Card.Content>
            <div className="customerCardContainer">
              <div>
                <Card.Header className="CustomersSalesTitle">
                  All Merchants
                </Card.Header>
                {Math.sign(merPerc) === -1 ? (
                  <span style={{ color: "#21ba45", fontWeight: 600 }}>
                    +{Math.abs(merPerc) || 0}%
                  </span>
                ) : (
                  <span style={{ color: "#db2828", fontWeight: 600 }}>
                    -{Math.abs(merPerc) || 0}%
                  </span>
                )}
                <span style={{ color: "#00000066", marginLeft: 5 }}>
                  in this month
                </span>
              </div>
              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="tiny">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      {merchants.length}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
}
        
        {role === 5 && (
          <Card className="AllExecutivesStatsiticsCard">
            <Card.Content>
              <div className="customerCardContainer">
                <div>
                  <Card.Header className="CustomersOrdersTitle">
                    All Marketing Executives
                  </Card.Header>
                  {Math.sign(execPerc) === -1 ? (
                    <span style={{ color: "#21ba45", fontWeight: 600 }}>
                      +{Math.abs(execPerc) || 0}%
                    </span>
                  ) : (
                    <span style={{ color: "#db2828", fontWeight: 600 }}>
                      -{Math.abs(execPerc) || 0}%
                    </span>
                  )}
                  <span style={{ color: "#00000066", marginLeft: 5 }}>
                    in this month
                  </span>
                </div>
                <Card.Description>
                  <div className="MembershipRow">
                    <Statistic inverted size="tiny">
                      <Statistic.Value style={{ color: "#22252f" }}>
                        {marketingExec.length}
                      </Statistic.Value>
                    </Statistic>
                  </div>
                </Card.Description>
              </div>
            </Card.Content>
          </Card>
        )}
      </Card.Group>
    </div>
  );
};

export default DashboardMemberDetails;

import React, { useState, useEffect } from "react";
import AssetsHeader from "./assets_header.component";
import {
  Select,
  Icon,
  Input,
  Button,
  Modal,
  Popup,
  TextArea,
  Form,
} from "semantic-ui-react";
import toastr from "toastr";
import AssetsTable from "./assets_table.component";
import TableExport from "tableexport";
import { BASE_URL } from "../../../config";

const memberOptions = [
  { key: "af", value: "af", text: "Tata Ace" },
  { key: "ax", value: "ax", text: "Acer Laptop" },
  { key: "al", value: "al", text: "System Table" },
  { key: "az", value: "az", text: "Asus Rog Laptop" },
];

const AssetTypes = [
  { value: "vehicle", text: "Vehicle" },
  { value: "furniture", text: "Furniture" },
  { value: "electronics", text: "Electronics" },
  { value: "machinery", text: "Machinery" },
];

const Assets = () => {
  return (
    <div className="DefaultLayout">
      <Header />
      <Card />
    </div>
  );
};

const Header = (props) => {
  const [asset_name, setAssetName] = useState("");
  const [asset_type, setAssetType] = useState("");
  const [purchase_value, setPurchaseValue] = useState("");
  const [assetType, setNewAssetType] = useState("");
  const [maintenanceCost, setMaintenanceCost] = useState("");
  const [maintenanceReason, setMaintenanceReason] = useState("");
  const [maintenanceDate, setMaintenanceDate] = useState("");
  const [selectedAssetID, setAssetID] = useState("");
  const [assets, setAssets] = useState([]);
  const [newAssets, setNewAssets] = useState([]);
  const [assetTypes, setAssetTypes] = useState([]);
  const [showAddNewAsset, setShowAddNewAsset] = useState(false);
  const [showMaintenanceCostModal, setShowMaintenanceCostModal] = useState(
    false
  );
  const [openTypeModal, setOpenTypeModal] = useState(false);

  const close = () => {
    setShowAddNewAsset(false);
    setShowMaintenanceCostModal(false);
  };

  const typeModalClose = () => {
    setOpenTypeModal(false);
  };

  useEffect(() => {
    fetchAssets();
    fetchAssetTypes();
  }, []);

  const fetchAssets = () => {
    fetch(`${BASE_URL}/get-assets`)
      .then((res) => res.json())
      .then((json) => {
        setAssets(json.assets);
      });
  };

  const fetchAssetTypes = () => {
    fetch(`${BASE_URL}/get-asset-types`)
      .then((res) => res.json())
      .then((json) => {
        setAssetTypes(json.asset_types);
      });
  };

  const saveAsset = () => {
    if (asset_name === "") {
      toastr.error("Please Enter Asset Name");
    } else if (asset_type === "") {
      toastr.error("Please Enter Asset Type");
    } else if (purchase_value === "") {
      toastr.error("Please Enter Purchase Value");
    }
    else {
      fetch(`${BASE_URL}/create-asset`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          asset_name,
          asset_type,
          purchase_value,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          toastr.success('New asset saved successfully!')
          window.location.reload(true);
          setShowAddNewAsset(false);
        });
    }
  };

  const saveAssetType = () => {
    if (assetType) {
      fetch(`${BASE_URL}/create-asset-type`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          assetType,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          toastr.success('Asset Type Saved');
          fetchAssetTypes();
          typeModalClose();
        });
    } else {
      toastr.error("Please fill all the fields!");
    }
  };

  const updateAssetMaintenance = () => {
    if ((maintenanceDate, maintenanceCost, maintenanceReason, selectedAssetID)) {
      fetch(
        `${BASE_URL}/update-asset-maintenance?asset_id=${selectedAssetID}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            maintenanceDate,
            maintenanceCost,
            maintenanceReason,
          }),
        }
      )
        .then((res) => res.json())
        .then((json) => {
          toastr.success('New Maintainance Data Added');
          window.location.reload(true);
        });
    } else {
      toastr.error("Please fill all the fields!");
    }
  };

  return (
    <div className="HeaderLayout">
      <div className="AddMemberRow">
        <h3>Assets</h3>
        <div>
          <Button
            className="addMiantenanceCostBtn"
            onClick={() => setShowMaintenanceCostModal(true)}
          >
            <Icon name="plus" /> Add Maintenance Cost
          </Button>
          <Button
            className="addAssetBtn"
            onClick={() => setShowAddNewAsset(true)}
          >
            <Icon name="plus" /> Add New Asset
          </Button>
        </div>
      </div>
      <AssetsHeader />
      {/* ADD NEW ASSET MODAL */}
      <div>
        <Modal size="small" open={showAddNewAsset} onClose={close}>
          <Modal.Header>
            <span>Add New Asset</span>
          </Modal.Header>
          <Modal.Content>
            <div className="vendorFormRow">
              <p className="titleVendorform">Asset name:</p>
              <Input
                placeholder="Enter asset name"
                style={{ width: "50%" }}
                maxLength="20"
                onChange={(evt) => setAssetName(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {asset_name.length}/20
              </p>
            </div>
            <div className="vendorFormRow">
              <p className="titleVendorform">Asset type:</p>
              <Select
                placeholder="Select asset type"
                options={assetTypes.map((type) => ({
                  key: type._id,
                  value: type.assetType,
                  text: type.assetType,
                }))}
                onChange={(event, data) => setAssetType(data.value)}
                style={{ width: "30%" }}
              />

              <Popup
                on="click"
                pinned
                style={{ textAlign: "center" }}
                open={openTypeModal}
                onClose={typeModalClose}
                trigger={
                  <Button
                    className="creatVTBtn"
                    onClick={() => setOpenTypeModal(true)}
                  >
                    <Icon name="plus" /> Create{" "}
                  </Button>
                }
              >
                <div>
                  <p>Create Asset Type:</p>
                  <Input
                    placeholder="Create asset type"
                    maxLength="20"
                    onChange={(evt) => setNewAssetType(evt.target.value)}
                  />
                </div>
                <Button
                  style={{
                    fontSize: 12,
                    marginTop: 10,
                    backgroundColor: "#c9daf8",
                  }}
                  onClick={() => saveAssetType()}
                >
                  {" "}
                  Submit{" "}
                </Button>
              </Popup>
            </div>
            <div className="vendorFormRow">
              <p className="titleVendorform">Purchase Value:</p>
              <Input
                placeholder="Enter purchase value"
                style={{ width: "50%" }}
                maxLength="20"
                onChange={(evt) => setPurchaseValue(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {purchase_value.length}/20
              </p>
            </div>
          </Modal.Content>
          <Modal.Actions style={{ textAlign: "center" }}>
            <Button
              className="cancelBtn"
              onClick={() => {
                setShowAddNewAsset(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="submitBtn"
              content="Submit"
              onClick={() => saveAsset()}
            />
          </Modal.Actions>
        </Modal>
      </div>
      {/* MAINTENANCE COST MODAL */}
      <div>
        <Modal size="small" open={showMaintenanceCostModal} onClose={close}>
          <Modal.Header>
            <span>Add Maintenance Cost</span>
          </Modal.Header>
          <Modal.Content>
            <div className="vendorFormRow">
              <p className="titleVendorform">Select Asset Type:</p>
              <Select
                placeholder="All Assets Type"
                options={assetTypes.map((type) => ({
                  key: type._id,
                  value: type.assetType,
                  text: type.assetType,
                }))}
                onChange={(event, data) => {
                  const assets_data = assets.filter((asset) =>
                    Object.keys(asset).some(
                      (key) => asset[key].toString().search(data.value) !== -1
                    )
                  );
                  setNewAssets(assets_data);
                }}
              />
            </div>
            <div className="vendorFormRow">
              <p className="titleVendorform">Select Asset:</p>
              <Select
                placeholder="All Assets"
                options={newAssets.map((asset) => ({
                  key: asset._id,
                  value: asset._id,
                  text: asset.asset_name,
                }))}
                onChange={(event, data) => setAssetID(data.value)}
              />
            </div>
            <div className="vendorFormRow">
              <p className="titleVendorform">Date:</p>
              <Input
                type="date"
                onChange={(evt) => setMaintenanceDate(evt.target.value)}
              />
            </div>
            <div className="vendorFormRow">
              <p className="titleVendorform">Maintenance Cost:</p>
              <Input
                placeholder="Enter maintenance cost"
                style={{ width: "50%" }}
                onChange={(evt) => setMaintenanceCost(evt.target.value)}
              />
            </div>
            <div className="vendorFormRow">
              <p className="titleVendorform">Reason For Maintenance:</p>
              <Form style={{ width: "50%" }}>
                <TextArea
                  placeholder="Enter Reason"
                  cols="50"
                  rows={4}
                  maxLength="100"
                  style={{ paddingRight: 50 }}
                  onChange={(evt) => setMaintenanceReason(evt.target.value)}
                />
              </Form>
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -50,
                  marginTop: 50,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {maintenanceReason.length}/100
              </p>
            </div>
          </Modal.Content>
          <Modal.Actions style={{ textAlign: "center" }}>
            <Button
              className="cancelBtn"
              onClick={() => {
                setShowMaintenanceCostModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="submitBtn"
              content="Submit"
              onClick={() => updateAssetMaintenance()}
            />
          </Modal.Actions>
        </Modal>
      </div>
    </div>
  );
};

const Card = () => {
  const [searchString, setSearchString] = React.useState("");

  return (
    <div className="CardContainer">
      <div className="PageTitleRow">
        {/* <div style={{ marginTop: 10 }}>
          <Select placeholder="All Assets" options={memberOptions} />
        </div> */}
        <Input
          placeholder="Quick Search..."
          icon="search"
          style={{ marginTop: 10 }}
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
        <div style={{ marginTop: 10 }}>
          <Button
            icon
            labelPosition="right"
            onClick={() => {
              toastr.success("Exported Successfully");
              const table = new TableExport(
                document.getElementById("asset-table"),
                {
                  formats: ["csv"],
                  exportButtons: false,
                }
              );
              const link = document.createElement("a");
              link.setAttribute(
                "href",
                "data:text/csv;charset=utf-8," +
                table.getExportData()["asset-table"].csv.data
              );
              link.setAttribute(
                "download",
                table.getExportData()["asset-table"].csv.filename
              );
              link.click();
            }}
            style={{
              backgroundColor: "#d9ead3",
              color: "#000",
            }}
          >
            Export
            <Icon name="share square" />
          </Button>

          {/* <Button className="addTransaction">
            <Icon name="plus" /> Transactions
          </Button> */}
        </div>
      </div>
      <AssetsTable searchString={searchString} />
    </div>
  );
};

export default Assets;

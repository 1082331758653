import React, { useEffect, useState } from "react";
import { Input, Button } from "semantic-ui-react";
import toastr from "toastr";
import { BASE_URL } from "../../../../config";

const DriverPayoutSettings = () => {
  const [payout_applicable_amount, setPayoutApplicableAmt] = useState(0);
  const [payout_amount, setPayoutAmt] = useState(0);
  const [payoutSettingsID, setSettingsID] = useState("");
  const [noData, setNoData] = useState("");

  useEffect(() => {
    fetchPayoutSettings();
  }, []);

  const fetchPayoutSettings = () => {
    fetch(`${BASE_URL}/get-driver-payouts`)
      .then((res) => res.json())
      .then((json) => {
        if (json.driver_payouts[0] === undefined) {
          setNoData("true");
          setPayoutApplicableAmt(0);
          setPayoutAmt(0);
        } else {
          setNoData("false");
          setSettingsID(json.driver_payouts[0]._id);
          setPayoutApplicableAmt(
            json.driver_payouts[0].payout_applicable_amount
          );
          setPayoutAmt(json.driver_payouts[0].payout_amount);
        }
      });
  };

  const savePayoutSettings = () => {
    fetch(`${BASE_URL}/create-driver-payouts`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payout_applicable_amount,
        payout_amount,
        setting_type: "driver_payouts",
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        fetchPayoutSettings();
        toastr.success("Payout Settings Saved");
      });
  };

  const updatePayoutSettings = () => {
    fetch(
      `${BASE_URL}/update-driver-payouts?payout_settings_id=${payoutSettingsID}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          payout_applicable_amount,
          payout_amount,
          setting_type: "driver_payouts",
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        fetchPayoutSettings();
        toastr.success("Payout Settings Updated Successfuly");
      });
  };

  return (
    <React.Fragment>
      <h4>Driver Payout Settings</h4>
      <div>
        <div className="TurnoverRow">
          <div>
            <Input
              action={{
                labelPosition: "right",
                icon: "inr",
                content: "Driver Payout only for above",
              }}
              actionPosition="left"
              className="inputMobile"
              placeholder="Enter a Level..."
              value={payout_applicable_amount}
              onChange={(e) => setPayoutApplicableAmt(e.target.value)}
            />
          </div>
          <div style={{ marginLeft: 10, alignSelf: "center" }}>
            <p>per delivery</p>
          </div>
        </div>
        <div className="TurnoverRow">
          <div>
            <Input
              action={{
                labelPosition: "right",
                icon: "inr",
                content: "Driver payout",
              }}
              actionPosition="left"
              className="inputMobile"
              placeholder="Enter a Level..."
              value={payout_amount}
              onChange={(e) => setPayoutAmt(e.target.value)}
            />
          </div>
          <div style={{ marginLeft: 10, alignSelf: "center" }}>
            <p>per delivery</p>
          </div>
        </div>
        {noData === "true" ? (
          <div className="SubmitBtnRow">
            <Button
              className="submitBtn"
              content="Submit"
              onClick={savePayoutSettings}
            />
          </div>
        ) : (
          <div className="SubmitBtnRow">
            <Button
              className="submitBtn"
              content="Update"
              onClick={updatePayoutSettings}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default DriverPayoutSettings;

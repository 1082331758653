import React from 'react';
import './dashboard.css';
import DashboardStatisticsContainer from './dashboard_statistics.component';
import DashboardZendeskContainer from './dashboard_zendesk.component';
import { Responsive } from 'semantic-ui-react';

const Dashboard = () => {
  return (
    <div className='DefaultLayout'>
      <DashboardStatistics />
      {/* <DashboardZendesk /> */}
    </div>
  );
};

const DashboardZendesk = () => {
  return (
    <div className='ProfileContainer'>
      <Responsive minWidth={767}>
        <DashboardZendeskContainer />
      </Responsive>
    </div>
  );
};

const DashboardStatistics = () => {
  return (
    <div className='DashboardCardLayout'>
      <DashboardStatisticsContainer />
    </div>
  );
};

export default Dashboard;

import React, { useState } from 'react';
import toastr from 'toastr';
import { Icon, Card, Button, Select } from 'semantic-ui-react';
import WalkInDeliveryTable from './walkInDeliveryTable.component';
import TableExport from 'tableexport';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
import DateRangePicker from 'react-daterange-picker';
import DatePicker from 'react-date-picker';

const moment = extendMoment(originalMoment);

const OrderType = [
  { key: 'af', value: 'PENDING', text: 'Pending Orders' },
  { key: 'ax', value: 'DELIVERED', text: 'Completed Orders' },
  { key: 'al', value: '', text: 'All Orders' },
];

const WalkInDelivery = (props) => {
  return (
    <div className="DefaultLayout">
      <Header />
      <WalkinDeliveryCard history={props.history} />
      <div style={{ marginLeft: '80%', marginTop: '50px' }}></div>
    </div>
  );
};

const Header = () => {
  return (
    <div className="HeaderLayout">
      <div style={{ paddingLeft: 20, paddingBottom: 20 }}>
        <h3>Walk-In Delivery</h3>
      </div>
    </div>
  );
};

const WalkinDeliveryCard = (props) => {
  const today = moment();
  const [searchString, setSearchString] = useState('PENDING');
  const [isOpen, setOpen] = useState(false);
  // const [value, setValue] = useState(
  //   moment.range(today.clone().subtract(4, "days"), today.clone())
  // );
  const [dateValue, setDateValue] = useState(new Date());
  const [grandTotalValue, setGrandTotalValue] = useState();

  const handleDateChange = (date) => {
    setDateValue(date);
  };

  return (
    <div className="CardContainer">
      <div className="PageTitleRow">
        <div style={{ marginTop: 10 }}>
          Select Date:{' '}
          <DatePicker
            onChange={(val) => handleDateChange(val)}
            value={dateValue}
          />
        </div>

        {}

        <Select
          placeholder="Order Type"
          value={searchString}
          options={OrderType}
          style={{ margin: 'auto' }}
          onChange={(event, data) => setSearchString(data.value)}
        />
        <div style={{ marginTop: 10 }}>
          <Card>
            <Card.Content>
              <p style={{ fontSize: '16px', fontWeight: 'bold' }}>
                Grand Total: {grandTotalValue}
              </p>
            </Card.Content>
          </Card>
          {/* <Button
            icon
            labelPosition="right"
            style={{
              backgroundColor: "#d9ead3",
              color: "#000",
              marginBottom: 15,
            }}
            onClick={() => {
              toastr.success("Exported Buttons Enabled Successfully");
              const table = new TableExport(
                document.getElementById("walk-in-deliveries"),
                {
                  formats: ["csv"],
                  exportButtons: true,
                  position: "top",
                }
              );
              const link = document.createElement("a");
              link.setAttribute(
                "download",
                table.getExportData()["walk-in-deliveries"].csv.filename
              );
              link.click();
            }}
          >
            Export
            <Icon name="share square icon" />
          </Button> */}
        </div>
      </div>
      <WalkInDeliveryTable
        dateValue={dateValue}
        setDateValue={setDateValue}
        grandTotalValue={grandTotalValue}
        setGrandTotalValue={setGrandTotalValue}
        history={props.history}
        searchString={searchString}
      />
    </div>
  );
};

export default WalkInDelivery;

import React, { useEffect, useState } from "react";
import {
  Table,
  Label,
  Icon,
  Checkbox,
  Button,
  Input,
  Modal,
  Select,
} from "semantic-ui-react";
import toastr from "toastr";
import { PEND_PAY_HEADING_DATA } from "./customerbilling.constant";
import { BASE_URL } from "../../../config";
import TableExport from "tableexport";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

const PendingPayments = () => {
  const today = moment();
  const [invoices, setInvoices] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [size, setSize] = useState("");
  const [open, setModalOpen] = useState("");
  const [cancelModalOpen, setCancelModalOpen] = useState("");
  const [transaction_id, setTransactionId] = useState("");
  const [transaction_date, setTransactionDate] = useState("");
  const [transaction_amount, setTransactionAmount] = useState("");
  const [transaction_type, setTransactionType] = useState("payment");
  const [invoiceStatus, setInvoiceStatus] = useState("open");
  const [selectedInvoices, setSelectedInvoices] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState(
    moment.range(today.clone().subtract(7, "days"), today.clone())
  );
  const [companies, setCompanies] = useState([]);
  const [collectionName, setCollectionName] = useState({});
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    getInvoices();
  }, []);

  const getInvoices = () => {
    fetch(`${BASE_URL}/get-companies`)
      .then((res) => res.json())
      .then((json) => {
        if (json.companies.length > 0) {
          setCompanies(json.companies);
          setCollectionName(`${json.companies[0].collection_name}_invoices`);
          fetch(
            `${BASE_URL}/get-invoices?cname=${`${json.companies[0].collection_name}_invoices`}`
          )
            .then((res) => res.json())
            .then((json) => {
              setInvoices(json.invoices);
            });
        }
      });
  };

  const openPaymentModal = (size, invoice) => () => {
    setSize(size);
    setModalOpen(true);
    setInvoice(invoice);
  };

  const openCancelModal = (size, invoice) => () => {
    setSize(size);
    setCancelModalOpen(true);
    setInvoice(invoice);
    setSelectedInvoices(invoice.invoice_no);
  };

  const close = () => {
    setModalOpen(false);
    setCancelModalOpen(false);
  };

  const handleCreditRePayment = () => {
    fetch(
      `${BASE_URL}/update-credit-transaction?invoice_no=${invoice.invoice_no}&cname=${collectionName}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          transaction_id,
          transaction_date,
          transaction_type,
          transaction_amount,
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        close();
        toastr.success("Credit Transaction Updated Succesfully!");
        getInvoices();
      });
  };

  const cancelInvoice = () => {
    fetch(`${BASE_URL}/cancel-invoice?cname=${collectionName}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        selectedInvoices,
        // cname: company.collection_name
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        toastr.success("Invoice Cancelled Successfully!");
        getInvoices();
        close();
      });
  };

  const onSelect = (value, states) => {
    fetch(
      `${BASE_URL}/get-allinvoices-by-range?startDate=${value.start.format(
        "DD-MM-YYYY"
      )}&endDate=${value.end.format("DD-MM-YYYY")}&cname=${collectionName}`
    )
      .then((res) => res.json())
      .then((json) => {
        setValue(value);
        setOpen(!isOpen);
        setInvoices(json.result);
      });
  };
  const onToggle = () => {
    setOpen(!isOpen);
  };

  const invoices_data = invoices.filter((invoice) =>
    Object.keys(invoice).some(
      (key) => invoice[key].toString().search(searchString.length > 0 ? searchString : "DELIVERED") !== -1
    )
  );

  const invoices_table = invoices_data.filter((invoice) =>
    Object.keys(invoice).some(
      (key) => invoice[key].toString().search("unpaid") !== -1
    )
  );
  console.log("🚀 ~ file: pendingpayments.component.jsx ~ line 156 ~ PendingPayments ~ invoices_table", invoices_table)

  const renderDays = (startDate, endDate) => {
    var date1 = new Date(startDate);
    var date2 = new Date(endDate);

    var Difference_In_Time = date2.getTime() - date1.getTime();

    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    return Math.round(Difference_In_Days);
  };

  const handleChangeCompany = (id) => {
    const data = companies.filter((company) => company._id === id);
    const company = data[0];
    setCollectionName(`${company.collection_name}_invoices`);
    fetch(`${BASE_URL}/get-invoices?cname=${`${company.collection_name}_invoices`}`)
      .then((res) => res.json())
      .then((json) => {
        setInvoices(json.invoices);
      });
  };

  return (
    <div>
      <div className="BillingTitleRow">
        <div style={{ marginTop: 10 }}>
          <Button
            icon
            labelPosition="right"
            style={{
              backgroundColor: "#d9ead3",
              color: "#000",
              marginBottom: 15,
            }}
            onClick={() => {
              toastr.success("Exported Buttons Enabled Successfully");
              const table = new TableExport(
                document.getElementById("pending-invoices"),
                {
                  formats: ["csv"],
                  exportButtons: true,
                  position: "top",
                }
              );
              const link = document.createElement("a");
              link.setAttribute(
                "download",
                table.getExportData()["pending-invoices"].csv.filename
              );
              link.click();
            }}
          >
            Export
            <Icon name="share square icon" />
          </Button>
        </div>
        <div style={{ marginTop: 10 }}>
          <Select
            placeholder={companies.length > 0 ? companies[0].company_name : 'Select Company'}
            options={companies.map((company) => ({
              key: company._id,
              value: company._id,
              text: company.company_name,
            }))}
            style={{ margin: "auto" }}
            onChange={(event, data) => handleChangeCompany(data.value)}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          Select Range:{" "}
          <input
            type="text"
            onClick={onToggle}
            style={{ width: 170 }}
            value={`${value.start.format("DD-MM-YYYY")} - ${value.end.format(
              "DD-MM-YYYY"
            )}`}
          />
          {isOpen && (
            <DateRangePicker
              value={value}
              onSelect={onSelect}
              singleDateRange={true}
            />
          )}
        </div>
        <div style={{ marginTop: 10 }}>
          <Button.Group size="tiny">
            <Button onClick={() => setInvoiceStatus("open")}>Open</Button>
            <Button.Or />
            <Button onClick={() => setInvoiceStatus("cancel")}>Cancel</Button>
          </Button.Group>
        </div>
        <div style={{ marginTop: 10 }}>
          <Input
            placeholder="Quick Search..."
            icon="search"
            style={{ marginRight: 10 }}
            onChange={(e) => setSearchString(e.target.value)}
          />
        </div>
      </div>
      <Table
        sortable
        unstackable
        celled
        basic="very"
        className="CustomTableLayout"
        id="pending-invoices"
      >
        <Table.Header>
          <Table.Row>
            {PEND_PAY_HEADING_DATA.map((heading) => (
              <Table.HeaderCell>
                {heading === "Checkbox" ? <Checkbox /> : <span>{heading}</span>}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {invoices_table.map((invoice, index) => (
            <Table.Row>
              {/* <Table.cell>r</Table.cell> */}
              <Table.Cell>{index + 1}</Table.Cell>
              <Table.Cell>#{invoice.order_id}</Table.Cell>
              <Table.Cell>{invoice.customer_name}</Table.Cell>
              <Table.Cell>INR {invoice.total_amount}</Table.Cell>
              <Table.Cell>{invoice.invoice_no}</Table.Cell>
              <Table.Cell>DELIVERED</Table.Cell>
              <Table.Cell>
                <Label color="grey">
                  <a
                    onClick={() =>
                      window.open(
                        `/#/view-invoice/${invoice.company_details.collection_name}_invoices/${invoice.invoice_no}`,
                        "_blank"
                      )
                    }
                  >
                    view
                  </a>
                </Label>
              </Table.Cell>
              {invoiceStatus === "open" ? (
                <Table.Cell>
                  {renderDays(Date.now(), invoice.credit_details.dueDate) <=
                    0 ? (
                    <div>
                      <p
                        style={{
                          color:
                            renderDays(
                              Date.now(),
                              invoice.credit_details.dueDate
                            ) >= 10
                              ? "green"
                              : renderDays(
                                Date.now(),
                                invoice.credit_details.dueDate
                              ) >= 5
                                ? "orange"
                                : "red",
                          alignSelf: "center",
                        }}
                      >
                        Over Due
                      </p>
                      <Button
                        color="green"
                        onClick={openPaymentModal("small", invoice)}
                      >
                        Pay Now
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <p
                        style={{
                          color:
                            renderDays(
                              Date.now(),
                              invoice.credit_details.dueDate
                            ) >= 10
                              ? "green"
                              : renderDays(
                                Date.now(),
                                invoice.credit_details.dueDate
                              ) >= 5
                                ? "orange"
                                : "red",
                          alignSelf: "center",
                        }}
                      >
                        {renderDays(Date.now(), invoice.credit_details.dueDate)}{" "}
                        days remaining
                      </p>
                      <Button
                        color="green"
                        onClick={openPaymentModal("small", invoice)}
                      >
                        Pay Now
                      </Button>
                    </div>
                  )}
                </Table.Cell>
              ) : (
                <Table.Cell>
                  <Button
                    color="red"
                    onClick={openCancelModal("mini", invoice)}
                  >
                    Cancel
                  </Button>
                </Table.Cell>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Modal size={size} open={open} onClose={close}>
        <Modal.Header>Credit Re-Payment</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to pay for this order? </p>
          <div style={{ display: "flex", marginTop: 20 }}>
            <h5 className="pendingPaymentsForm">Transaction Date</h5>
            <Input
              type="date"
              onChange={(e) => setTransactionDate(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", marginTop: 20 }}>
            <h5 className="pendingPaymentsForm">Amount:</h5>
            <Input onChange={(e) => setTransactionAmount(e.target.value)} />
          </div>
          <div style={{ display: "flex", marginTop: 20 }}>
            <h5 className="pendingPaymentsForm">Transaction Id:</h5>
            <Input onChange={(evt) => setTransactionId(evt.target.value)} />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button className="addCustomerCancelBtn" onClick={close}>
            No
          </Button>
          <Button
            className="addCustomersubmitBtn"
            icon="checkmark"
            labelPosition="right"
            content="Yes"
            onClick={() => handleCreditRePayment()}
          />
        </Modal.Actions>
      </Modal>
      <Modal size={size} open={cancelModalOpen} onClose={close}>
        <Modal.Header>Cancel Invoice</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to cancel invoice #{invoice.invoice_no}?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button className="addCustomerCancelBtn" onClick={close}>
            No
          </Button>
          <Button
            className="addCustomersubmitBtn"
            icon="checkmark"
            labelPosition="right"
            content="Yes"
            onClick={() => cancelInvoice()}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default PendingPayments;

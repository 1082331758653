import React from "react";
import { Icon, Button, Tab } from "semantic-ui-react";
import PayoutsHeader from "./payouts-header.component";
import DriversPayoutTable from "./drivers-payout-table.component";
import VendorsPayoutTable from "./vendors-payout-table.component";
import MarketingExecutivePayoutTable from "./marketing-executive-table.component";

const PayoutsComponent = () => {
  return (
    <div className="DefaultLayout">
      <Header />
      <Card />
    </div>
  );
};

const payoutPanes = [
  {
    menuItem: "Driver Payout",
    render: () => (
      <Tab.Pane attached={false}>
        <DriversPayoutTable />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Vendor Payout",
    render: () => (
      <Tab.Pane attached={false}>
        <VendorsPayoutTable />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Marketing Executive Payout",
    render: () => (
      <Tab.Pane attached={false}>
        <MarketingExecutivePayoutTable />
      </Tab.Pane>
    ),
  },
];

const Header = () => {
  return (
    <div className="HeaderLayout">
      <h3>Payouts</h3>
      <PayoutsHeader />
    </div>
  );
};

const Card = () => {
  return (
    <div className="CardContainer">
      <Tab menu={{ borderless: true, pointing: true }} panes={payoutPanes} />
    </div>
  );
};

export default PayoutsComponent;

import React, { useEffect, useState } from "react";
import {
  Icon,
  Button,
  Table,
  Select,
  Checkbox,
  Input,
  Label,
  Modal,
} from "semantic-ui-react";
import toastr from "toastr";
import TableExport from "tableexport";
import moment from "moment";
import { HEADING_DATA } from "./drivers-table.constant";
import { BASE_URL } from "../../../config";

const DriverOptions = [
  { key: "af", value: "af", text: "Arun kumar" },
  { key: "ax", value: "ax", text: "Bala Subramanium" },
  { key: "al", value: "al", text: "Chandra Sekar" },
];

const DriversPayoutTable = () => {
  const [orders, setOrders] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [monthlyOrders, setMonthlyOrders] = useState([]);
  const [driverDetails, setDriverDetails] = useState({});
  const [payoutData, setPayoutData] = useState({});
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [transaction_id, setTransactionId] = useState("");
  const [transaction_date, setTransactionDate] = useState("");
  const [size, setSize] = useState("");
  const [open, setModalOpen] = useState(false);

  useEffect(() => {
    // fetchAllOrders();
    fetchDrivers();
  }, []);

  // const fetchAllOrders = () => {
  //   getOrders().then((res) => {
  //     setOrders(res.orders);
  //   });
  // };

  const fetchDrivers = () => {
    fetch(`${BASE_URL}/get-drivers`)
      .then((res) => res.json())
      .then((json) => {
        setDrivers(json.drivers);
      });
  };

  const handleOpen = (size) => () => {
    setSize(size);
    setModalOpen(true);
  };

  const handleClose = () => setModalOpen(false);

  const handleAgent = (phoneNumber) => {
    const data = drivers.filter(
      (driver) => driver.phoneNumber.indexOf(phoneNumber) !== -1
    );
    const driverData = data[0];
    const ordersData = data[0].orders !== undefined ? data[0].orders : [];
    setOrders(ordersData);
    setDriverDetails(driverData);
    setMonthlyOrders([]);
    setTotalEarnings(0);
  };

  const sortByMonth = (e) => {
    const monthRange = e.target.value;

    const completedOrders = orders.filter((order) =>
      Object.keys(order).some(
        (key) => order[key].toString().search("DELIVERED") !== -1
      )
    );


    const data = completedOrders.filter(
      (order) => order.month_range.indexOf(monthRange) !== -1
    );


    const earnings = data.reduce(
      (sum, order, index) => sum + order.delivery_agent_fee,
      0
    );

    const driver_payouts =
      driverDetails.payouts !== undefined ? driverDetails.payouts : [];

    const payoutDatas = driver_payouts.filter(
      (order) => order.monthRange.indexOf(monthRange) !== -1
    );

    const ordersData = data;
    if (ordersData.length > 0) {
      setMonthlyOrders(ordersData);
      setPayoutData(payoutDatas[0]);
      setTotalEarnings(earnings);
      setSelectedMonth(monthRange);
    } else {
      setMonthlyOrders(ordersData);
      setPayoutData(payoutDatas[0]);
      setTotalEarnings(earnings);
      setSelectedMonth(monthRange);
      toastr.error("There is no orders for this month!");
    }
  };

  const renderTotalTurnOver = (orders) => {
    const completedOrders = orders.filter((order) =>
      Object.keys(order).some(
        (key) => order[key].toString().search("DELIVERED") !== -1
      )
    );

    return completedOrders.reduce(
      (sum, order, index) => sum + order.delivery_agent_fee,
      0
    );
  };

  const handlepay = () => {
    fetch(
      `${BASE_URL}/update-driver-payout-data?phoneNumber=${driverDetails.phoneNumber}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          selectedMonth,
          totalEarnings,
          transaction_date,
          transaction_id,
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        toastr.success("Delivery Agent Fee Paid Successfully!");
        handleClose();
        window.location.reload();
      });
  };

  return (
    <div>
      <div className="exprtBtnRow">
        <div style={{ marginTop: 10 }}>
          {drivers.length > 0 ? (
            <Select
              placeholder="Select Driver"
              options={drivers.map((driver) => ({
                key: driver._id,
                value: driver.phoneNumber,
                text: driver.name,
              }))}
              onChange={(event, data) => handleAgent(data.value)}
            />
          ) : (
            "Please Add Driver"
          )}
        </div>

        {orders.length > 0 ? (
          <div style={{ marginTop: 10 }}>
            Select Month:{" "}
            <Input type="month" onChange={(e) => sortByMonth(e)} />
          </div>
        ) : (
          ""
        )}
        <div style={{ marginTop: 10 }}>
          <Button
            content="Total Earnings"
            icon="inr"
            className="totalEarningsBtn"
            label={{
              as: "a",
              basic: true,
              content: `Rs. ${
                totalEarnings || renderTotalTurnOver(monthlyOrders)
              }`,
            }}
            labelPosition="right"
          />
        </div>

        {monthlyOrders.length > 0 ? (
          <div style={{ marginTop: 10 }}>
            {payoutData === undefined ? (
              <Button color="green" onClick={handleOpen('small')}>
                Pay Now
              </Button>
            ) : (
              <div>
                Txn Date: {moment(payoutData.transactionDate).format("ll")},{""}{" "}
                Txn Id: {payoutData.transactionId},{""}{" "}
                <Label color="green">{payoutData.paymentStatus}</Label>
              </div>
            )}
          </div>
        ) : (
          ""
        )}

        <div style={{ marginTop: 10 }}>
          <Button
            icon
            labelPosition="right"
            style={{
              backgroundColor: "#d9ead3",
              color: "#000",
              marginBottom: 15,
            }}
            onClick={() => {
              toastr.success("Exported Buttons Enabled Successfully");
              const table = new TableExport(
                document.getElementById("driver-payouts"),
                {
                  formats: ["csv"],
                  exportButtons: true,
                  position: "top",
                }
              );
              const link = document.createElement("a");
              link.setAttribute(
                "download",
                table.getExportData()["driver-payouts"].csv.filename
              );
              link.click();
            }}
          >
            Export
            <Icon name="share square icon" />
          </Button>
        </div>
      </div>
      <div>
        {monthlyOrders.length > 0 ? (
          <Table
            sortable
            unstackable
            celled
            basic="very"
            className="CustomTableLayout"
            id="driver-payouts"
          >
            <Table.Header>
              <Table.Row>
                {HEADING_DATA.map((heading) => (
                  <Table.HeaderCell>
                    {heading === "Checkbox" ? (
                      <Checkbox />
                    ) : (
                      <span>{heading}</span>
                    )}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {monthlyOrders.map((order, index) => (
                <Table.Row>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>#{order.order_id}</Table.Cell>
                  <Table.Cell>{driverDetails.name}</Table.Cell>
                  <Table.Cell>{driverDetails.phoneNumber}</Table.Cell>
                  <Table.Cell>{order.delivery_agent_fee}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ) : (
          <Table
            sortable
            unstackable
            celled
            basic="very"
            className="CustomTableLayout"
          >
            <Table.Header>
              <Table.Row>
                {HEADING_DATA.map((heading) => (
                  <Table.HeaderCell>
                    {heading === "Checkbox" ? (
                      <Checkbox />
                    ) : (
                      <span>{heading}</span>
                    )}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {orders.map((order, index) => (
                <Table.Row>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>#{order.order_id}</Table.Cell>
                  <Table.Cell>{driverDetails.name}</Table.Cell>
                  <Table.Cell>{driverDetails.phoneNumber}</Table.Cell>
                  <Table.Cell>{order.delivery_agent_fee}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
        <Modal size={size} open={open} onClose={handleClose}>
          <Modal.Header>Delivery Agent Fee</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to pay Delivery Agent fee For this month?{" "}
            </p>
            <div style={{ display: "flex", marginTop: 20 }}>
              <h5 className="pendingPaymentsForm">Month:</h5>
              <Input
                value={moment(selectedMonth).format("MMM YYYY")}
                disabled
              />
            </div>
            <div style={{ display: "flex", marginTop: 20 }}>
              <h5 className="pendingPaymentsForm">Total Earnings:</h5>
              <Input value={totalEarnings} disabled />
            </div>
            <div style={{ display: "flex", marginTop: 20 }}>
              <h5 className="pendingPaymentsForm">Transaction Date</h5>
              <Input
                type="date"
                onChange={(e) => setTransactionDate(e.target.value)}
              />
            </div>
            <div style={{ display: "flex", marginTop: 20 }}>
              <h5 className="pendingPaymentsForm">Transaction Id:</h5>
              <Input onChange={(e) => setTransactionId(e.target.value)} />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={handleClose}>
              No
            </Button>
            <Button
              positive
              icon="checkmark"
              labelPosition="right"
              content="Yes"
              onClick={() => handlepay()}
            />
          </Modal.Actions>
        </Modal>
      </div>
    </div>
  );
};

export default DriversPayoutTable;

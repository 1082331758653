import React, { useState, useEffect } from "react";
import moment from "moment";
import toastr from "toastr";
import {
  Divider,
  Input,
  Grid,
  Button,
  Icon,
  Popup,
  Dropdown,
  Form,
  Radio,
} from "semantic-ui-react";
import { BASE_URL } from "../../../config";

const CreateWeighBridge = (props) => {
  const [date, setDate] = useState(new Date());
  const [weigh_challan_date, setWeighChallanDate] = useState(new Date());
  const [product_name, setproductName] = useState("");
  const [kgsPerBag, setKgsPerbag] = useState(73);
  const [name, setName] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [vehicle_no, setVehicleNo] = useState("");
  const [weigh_challan_no, setWeighChallanNo] = useState("");
  const [purchase_no, setPurchaseNo] = useState("");
  const [vehicle_weight, setVehicleWeight] = useState(0);
  const [total_weight, setTotalWeight] = useState(0);
  const [stock_weight, setStockWeight] = useState(0);
  const [place, setPlace] = useState("");
  const [total_bags, setTotalBags] = useState(0);
  const [quality1_bags, setQuality1Bags] = useState(0);
  const [quality1_price, setQuality1Price] = useState(0);
  const [quality1_reason, setQuality1Reason] = useState("");
  const [quality2_bags, setQuality2Bags] = useState(0);
  const [quality2_price, setQuality2Price] = useState(0);
  const [quality2_reason, setQuality2Reason] = useState("");
  const [labour_charges, setLabourCharges] = useState(0);
  const [payment_mode, setPaymentMode] = useState("");
  const [products, setProducts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [clientWeighTickets, setClientWeighTickets] = useState([]);

  useEffect(() => {
    fetch(`${BASE_URL}/get-weight-ticket-no`)
      .then((res) => res.json())
      .then((json) => {
        if (json.weigh_tickets.length > 0) {
          // setWeighChallanNo(Number(json.weigh_tickets[0].weigh_challan_no) + 1);
          setPurchaseNo(Number(json.weigh_tickets[0].purchase_no) + 1);
        } else {
          // setWeighChallanNo(1001);
          setPurchaseNo(15000);
        }
      });
    fetchCompanies();
    fetchVendors();
    fetchClientTickets();
  }, []);

  const fetchClientTickets = () => {
    fetch(`${BASE_URL}/get-client-weight-tickets`)
      .then((res) => res.json())
      .then((json) => {
        function custom_sort(a, b) {
          return new Date(b.Date).getTime() - new Date(a.Date).getTime();
        }
        const changeOrder = json.client_weigh_tickets.sort(custom_sort);
        console.log(
          "🚀 ~ file: createWeighBridge.component.jsx ~ line 71 ~ .then ~ changeOrder",
          changeOrder
        );
        setClientWeighTickets(changeOrder);
      });
  };

  const fetchCompanies = () => {
    fetch(`${BASE_URL}/get-companies`)
      .then((res) => res.json())
      .then((json) => {
        setCompanies(json.companies);
        setCompanyName(
          json.companies.length > 0 ? json.companies[0].company_name : ""
        );
      });
  };

  const fetchVendors = () => {
    fetch(`${BASE_URL}/get-vendors`)
      .then((res) => res.json())
      .then((json) => {
        setVendors(json.vendors);
      });
  };

  const toTrunc = (value, n) => {
    const x = (value.toString() + ".0").split(".");
    return parseFloat(x[0] + "." + x[1].substr(0, n));
  };

  const toFixedNum = (num) => {
    const value = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    return Number(value);
  };

  const getTotalValue = () => {
    // Quality 1
    const quality1_v1 = quality1_bags * kgsPerBag;
    const quality1_v2 = quality1_v1 / (kgsPerBag * 2);
    const quality1_final_price = quality1_v2 * quality1_price;

    // Quality 2
    const quality2_v1 = quality2_bags * kgsPerBag;
    const quality2_v2 = quality2_v1 / (kgsPerBag * 2);
    const quality2_final_price = quality2_v2 * quality2_price;

    const final_price = Math.trunc(quality1_final_price + quality2_final_price);
    return final_price;
  };

  const getFinalValue = () => {
    const final_value = getTotalValue() - labour_charges;
    return final_value;
  };

  const createTicket = () => {
    if (company_name === "") {
      toastr.error("Please Select company or Add Company to Proceed!");
    } else {
      fetch(`${BASE_URL}/create-weight-ticket`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          date,
          weigh_date: weigh_challan_date,
          company_name,
          product_name,
          name,
          vehicle_no,
          weigh_challan_no,
          purchase_no,
          vehicle_weight,
          total_weight,
          stock_weight,
          place,
          total_bags,
          quality1_bags,
          quality1_price,
          quality1_reason,
          quality2_bags,
          quality2_price,
          quality2_reason,
          total_value: getTotalValue(),
          labour_charges,
          payment_mode,
          final_value: getFinalValue(),
          selectedKgsPerBag: kgsPerBag,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          toastr.success("Weigh Ticket Generated Successfully!");
          props.history.push(`/view-weigh-bridge-ticket/${weigh_challan_no}`);
        });
    }
  };

  const handleKgsBagChange = (e, { value }) => setKgsPerbag(value);

  return (
    <div className="DefaultLayout">
      <div className="HeaderLayout">
        <div style={{ paddingTop: 10, paddingLeft: 20, display: "flex" }}>
          <Icon
            name="arrow left"
            style={{ paddingTop: 3, cursor: "pointer" }}
            onClick={() => props.history.push("/weigh-bridge")}
          />
          <h3 style={{ marginTop: 0, marginLeft: 10 }}>
            Create Weigh Bridge Ticket
          </h3>
        </div>
      </div>
      <div className="CardContainer">
        <div style={{ marginLeft: 100, marginRight: 100 }}>
          <div className="PageTitleRow">
            <div style={{ margin: "auto" }}>
              {companies.length > 0 ? (
                <Dropdown
                  placeholder="Select Company"
                  fluid
                  search
                  selection
                  value={company_name}
                  options={companies.map((company) => ({
                    key: company._id,
                    value: company.company_name,
                    text: company.company_name,
                  }))}
                  onChange={(event, data) => setCompanyName(data.value)}
                  style={{ width: 200 }}
                />
              ) : (
                <Button
                  className="addCustomerBtn"
                  onClick={() => props.history.push("/add-company")}
                >
                  <Icon name="plus" /> Add Company
                </Button>
              )}
            </div>
          </div>
          <div className="PageTitleRow">
            <div>
              <h4>Date: {moment(date).format("DD-MM-YYYY")}</h4>
            </div>
            <div>
              <h4>
                Weigh Date: {moment(weigh_challan_date).format("DD-MM-YYYY")}
                {/* <Popup
                  on="click"
                  pinned
                  style={{ textAlign: "center" }}
                  trigger={
                    <Icon
                      name="pencil"
                      style={{
                        fontSize: 15,
                        color: "#93c47d",
                        marginLeft: 10,
                      }}
                    />
                  }
                >
                  <div>
                    <p>Edit date:</p>
                    <input
                      type="date"
                      placeholder="Select Date"
                      className="datepickerinput"
                      onChange={(e) =>
                        setWeighChallanDate(e.target.value)
                      }
                    />
                  </div>
                </Popup> */}
              </h4>
            </div>
          </div>
          <div className="PageTitleRow">
            <div>
              <h4>Purchase No: #{purchase_no}</h4>
            </div>
            <div className="FieldsRow">
              <p className="LabelText">Product Name: </p>
              <Input
                placeholder="Ex: Ast-16"
                style={{ width: 250 }}
                maxLength="10"
                value={product_name}
                onChange={(e) => setproductName(e.target.value)}
              />
            </div>
          </div>
          <Divider />
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <div className="FieldsRow">
                  <p className="LabelText">Supplier Name: </p>
                  <Input
                    placeholder="Enter Supplier Name"
                    style={{ width: 250 }}
                    maxLength="25"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="FieldsRow">
                  <p className="LabelText">Vehicle No: </p>
                  <Input
                    placeholder="Ex: TN72Y6606"
                    style={{ width: 250 }}
                    maxLength="10"
                    value={vehicle_no}
                    onChange={(e) => setVehicleNo(e.target.value)}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <div className="FieldsRow">
                  <p className="LabelText">Ticket No: </p>
                  {/* <Input
                    placeholder="Enter Ticket No"
                    style={{ width: 250 }}
                    maxLength="10"
                    value={weigh_challan_no}
                  /> */}
                  <Dropdown
                    placeholder="Select Ticket"
                    fluid
                    search
                    selection
                    options={clientWeighTickets.map((ticket) => ({
                      key: ticket._id,
                      value: ticket,
                      text: ticket.TicketNumber,
                    }))}
                    onChange={(event, data) => {
                      const ticket = data.value;
                      const total_bags = Number(ticket.NetWeight) / kgsPerBag;
                      const final_bags_value = toFixedNum(total_bags);
                      setWeighChallanNo(Number(ticket.TicketNumber));
                      setWeighChallanDate(ticket.Date);
                      setproductName(ticket.Materialname);
                      setName(ticket.SupplierName);
                      setVehicleNo(ticket.VehicleNumber);
                      setVehicleWeight(ticket.EmptyWeight);
                      setTotalWeight(ticket.LoadedWeight);
                      setStockWeight(ticket.NetWeight);
                      setTotalBags(final_bags_value);
                    }}
                    style={{ width: 200 }}
                  />
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="FieldsRow">
                  <p className="LabelText">Total Bags: </p>
                  <Input
                    placeholder="Enter Bags"
                    style={{ width: 250 }}
                    maxLength="10"
                    value={total_bags}
                    disabled
                  />
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <div className="FieldsRow">
                  <p className="LabelText">Vehicle Weight: </p>
                  <Input
                    placeholder="Enter Vehicle Weight"
                    style={{ width: 250 }}
                    maxLength="10"
                    value={vehicle_weight}
                    onChange={(e) => setVehicleWeight(e.target.value)}
                  />
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="FieldsRow">
                  <p className="LabelText">Quality 1 Bags: </p>
                  <Input
                    placeholder="Enter Quality 1 Bags"
                    style={{ width: 250 }}
                    maxLength="10"
                    value={quality1_bags}
                    onChange={(e) => {
                      setQuality1Bags(e.target.value);
                      setQuality2Bags((total_bags - e.target.value).toFixed(2));
                    }}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <div className="FieldsRow">
                  <p className="LabelText">Total Weight: </p>
                  <Input
                    placeholder="Enter Total Weight"
                    style={{ width: 250 }}
                    maxLength="10"
                    value={total_weight}
                    onChange={(e) => {
                      const total_weight = e.target.value;
                      const stock_weight = total_weight - vehicle_weight;
                      const total_bags = stock_weight / kgsPerBag;
                      const final_bags_value = toFixedNum(total_bags);
                      setTotalWeight(e.target.value);
                      setStockWeight(stock_weight);
                      setTotalBags(final_bags_value);
                    }}
                  />
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="FieldsRow">
                  <p className="LabelText">Quality 1 Price: </p>
                  <Input
                    placeholder="Enter Quality 1 Price"
                    style={{ width: 250 }}
                    maxLength="10"
                    value={quality1_price}
                    onChange={(e) => setQuality1Price(e.target.value)}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <div className="FieldsRow">
                  <p className="LabelText">Stock Weight: </p>
                  <Input
                    placeholder="Enter Stock Weight"
                    style={{ width: 250 }}
                    maxLength="10"
                    value={stock_weight}
                    disabled
                  />
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="FieldsRow">
                  <p className="LabelText">Quality 1 Reason: </p>
                  <Input
                    placeholder="Enter quality reason 1"
                    style={{ width: 250 }}
                    maxLength="10"
                    value={quality1_reason}
                    onChange={(e) => setQuality1Reason(e.target.value)}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <div className="FieldsRow">
                  <p className="LabelText">Place: </p>
                  <Input
                    placeholder="Ex: OFFICE"
                    style={{ width: 250 }}
                    maxLength="10"
                    value={place}
                    onChange={(e) => setPlace(e.target.value)}
                  />
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="FieldsRow">
                  <p className="LabelText">Quality 2 Bags: </p>
                  <Input
                    placeholder="Quality 2 Bags"
                    style={{ width: 250 }}
                    maxLength="10"
                    value={quality2_bags}
                    onChange={(e) => setQuality2Bags(e.target.value)}
                    disabled
                  />
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <div className="FieldsRow">
                  <p className="LabelText">Total Value: </p>
                  <Input
                    placeholder="Enter Total Value"
                    style={{ width: 250 }}
                    maxLength="10"
                    value={getTotalValue()}
                    disabled
                  />
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="FieldsRow">
                  <p className="LabelText">Quality 2 Price: </p>
                  <Input
                    placeholder="Quality 2 Price"
                    style={{ width: 250 }}
                    maxLength="10"
                    value={quality2_price}
                    onChange={(e) => setQuality2Price(e.target.value)}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <div className="FieldsRow">
                  <p className="LabelText">Labour Charges: </p>
                  <Input
                    placeholder="Enter Labour Charges"
                    style={{ width: 250 }}
                    maxLength="10"
                    value={labour_charges}
                    onChange={(e) => setLabourCharges(e.target.value)}
                  />
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="FieldsRow">
                  <p className="LabelText">Quality 2 Reason: </p>
                  <Input
                    placeholder="Enter quality reason 2"
                    style={{ width: 250 }}
                    maxLength="10"
                    value={quality2_reason}
                    onChange={(e) => setQuality2Reason(e.target.value)}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <div className="FieldsRow">
                  <p className="LabelText">Final Value: </p>
                  <Input
                    placeholder="Enter Final Value"
                    style={{ width: 250 }}
                    maxLength="10"
                    value={getFinalValue()}
                    disabled
                  />
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="FieldsRow">
                  <p className="LabelText">Payment Mode: </p>
                  <Input
                    placeholder="Enter Payment Mode"
                    style={{ width: 250 }}
                    maxLength="10"
                    value={payment_mode}
                    onChange={(e) => setPaymentMode(e.target.value)}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <div className="PageTitleRow">
            <div style={{ margin: "auto" }}>
              <Button color="green" onClick={() => createTicket()}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateWeighBridge;

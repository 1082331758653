import React, { useEffect, useState } from "react";
import {
  Table,
  Modal,
  Icon,
  Checkbox,
  Button,
  Input,
  Form,
  TextArea,
  Select,
} from "semantic-ui-react";
import _ from "lodash";
import toastr from "toastr";
import { HEADING_DATA } from "./vendors.constant";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../config";

const VendorsTable = (props) => {
  const [name, setName] = useState("");
  const [vendorType, setVendorType] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [material_type, setMaterialType] = useState("");
  const [amount_given, setAmountGiven] = useState("");
  const [balance_amount, setBalanceAmount] = useState("");
  const [selectedVendor, setVendorID] = useState("");
  const [size, setSize] = useState("");
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openTransaction, setOpenTransaction] = useState(false);
  const [edit, setEdit] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);
  const [vendorTypes, setVendorTypes] = useState([]);
  const [rawMaterials, setRawMaterials] = useState([]);

  useEffect(() => {
    fetchVendors();
    fetchVendorTypes();
    fetchRawMaterials();
  }, []);

  const fetchVendors = () => {
    fetch(`${BASE_URL}/get-vendors`)
      .then((res) => res.json())
      .then((json) => {
        setVendors(json.vendors);
      });
  };

  const fetchVendorTypes = () => {
    fetch(`${BASE_URL}/get-vendor-types`)
      .then((res) => res.json())
      .then((json) => {
        setVendorTypes(json.vendor_types);
      });
  };

  const fetchRawMaterials = () => {
    fetch(`${BASE_URL}/get-raw-materials`)
      .then((res) => res.json())
      .then((json) => {
        setRawMaterials(json.vendor_materials);
      });
  };

  const handleSort = (clickedColumn) => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setVendors(_.sortBy(vendors, [clickedColumn]));
      setDirection("ascending");

      return;
    }

    setVendors(vendors.reverse());
    setDirection(direction === "ascending" ? "descending" : "ascending");
  };

  const editVendor = (size, vendor) => () => {
    setSize(size);
    setOpenEdit(true);
    setVendorID(vendor._id);
    setName(vendor.name);
    setVendorType(vendor.vendorType);
    setEmail(vendor.email);
    setPhoneNumber(vendor.phoneNumber);
    setAddress(vendor.address);
    setGstNo(vendor.gstNo);
  };

  const handleUpdate = () => {
    fetch(`${BASE_URL}/update-vendor?vendor_id=${selectedVendor}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        vendorType,
        gstNo,
        phoneNumber,
        email,
        address,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        toastr.success('Vendor Updated Successfully!');
        fetchVendors();
        close();
      });
  };

  const handleTransaction = () => {
    if (material_type === "") {
      toastr.error("Please Select Material Type");
    } else if (amount_given === "") {
      toastr.error("Please Enter Amount Given");
    } else if (balance_amount === "") {
      toastr.error("Please Enter Balance Amount");
    } else {
      fetch(`${BASE_URL}/update-vendor-transaction?vendor_id=${selectedVendor}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          vendor_name: name,
          material_type,
          amount_given,
          balance_amount,
          date: new Date(),
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          toastr.success('New transaction updated successfully!')
          fetchVendors();
          close();
        });
    }

  };

  const deleteVendor = (size, ID) => () => {
    setSize(size);
    setOpenDelete(true);
    setVendorID(ID);
  };

  const addVendorTransaction = (size, vendor) => () => {
    setSize(size);
    setOpenTransaction(true);
    setName(vendor.name);
    setVendorID(vendor._id);
  };

  const handleDelete = () => {
    fetch(`${BASE_URL}/delete-vendor?vendor_id=${selectedVendor}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((json) => {
        fetchVendors();
        close();
      });
  };

  const close = () => {
    setOpenEdit(false);
    setOpenDelete(false);
    setOpenTransaction(false);
  };

  const vendors_table = vendors.filter((vendor) =>
    Object.keys(vendor).some(
      (key) => vendor[key].toString().search(props.searchString) !== -1
    )
  );

  return (
    <div>
      <Table
        sortable
        unstackable
        celled
        basic="very"
        className="CustomTableLayout"
        id="vendor-table"
      >
        <Table.Header>
          <Table.Row>
            {HEADING_DATA.map((heading) => (
              <Table.HeaderCell
                sorted={column === heading ? direction : null}
                onClick={handleSort(heading)}
              >
                {heading === "Checkbox" ? <Checkbox /> : <span>{heading}</span>}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {vendors_table.map((vendor, index) => (
            <Table.Row>
              <Table.Cell>{index + 1}</Table.Cell>
              <Table.Cell>
                <a
                  onClick={() =>
                    props.history.push({
                      pathname: `/profile-vendor/${vendor.phoneNumber}`,
                    })
                  }
                >
                  {vendor.name}{" "}
                </a>
              </Table.Cell>
              <Table.Cell>{vendor.vendorType}</Table.Cell>
              <Table.Cell>{vendor.phoneNumber}</Table.Cell>
              <Table.Cell>{vendor.email}</Table.Cell>
              <Table.Cell>
                {vendor.transactions !== undefined && vendor.transactions.length > 0
                  ? vendor.transactions[vendor.transactions.length - 1]
                    .material_type
                  : "-"}
              </Table.Cell>
              <Table.Cell>
                {vendor.transactions !== undefined && vendor.transactions.length > 0
                  ? `₹ ${vendor.transactions[vendor.transactions.length - 1]
                    .amount_given
                  }`
                  : "-"}
              </Table.Cell>
              <Table.Cell>
                <Button
                  style={{ fontSize: 13, backgroundColor: "#f3f3f3" }}
                  onClick={editVendor("small", vendor)}
                >
                  <Icon name="edit" />
                  Edit
                </Button>

                <Button
                  style={{ fontSize: 13, backgroundColor: "#f4cccc" }}
                  onClick={deleteVendor("mini", vendor._id)}
                >
                  <Icon name="trash" /> Delete
                </Button>
                <Button
                  style={{ fontSize: 13, backgroundColor: "#d9ead3" }}
                  onClick={addVendorTransaction("small", vendor)}
                >
                  <Icon name="plus" /> Add Transaction
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Modal size={size} open={openEdit} onClose={close}>
        <Modal.Header>Edit Vendor </Modal.Header>
        <Modal.Content>
          <div className="vendorFormRow">
            <p className="titleVendorform">Vendor name:</p>
            <Input
              value={name}
              style={{ width: "50%" }}
              maxLength="20"
              onChange={(evt) => setName(evt.target.value)}
            />
            <p
              style={{
                alignSelf: "center",
                marginLeft: -40,
                marginTop: 10,
                zIndex: 0,
                color: "grey",
              }}
            >
              {name.length}/20
            </p>
          </div>
          <div className="vendorFormRow">
            <p className="titleVendorform">Vendor type:</p>
            <Select
              placeholder="Select vendor type"
              options={vendorTypes.map((type) => ({
                key: type._id,
                value: type.vendor_type,
                text: type.vendor_type,
              }))}
              onChange={(event, data) => setVendorType(data.value)}
              style={{ width: "30%" }}
            />
          </div>
          <div className="vendorFormRow">
            <p className="titleVendorform">Mobile number:</p>
            <Input
              value={phoneNumber}
              style={{ width: "50%" }}
              maxLength="10"
              onChange={(evt) => setPhoneNumber(evt.target.value)}
            />
            <p
              style={{
                alignSelf: "center",
                marginLeft: -40,
                marginTop: 10,
                zIndex: 0,
                color: "grey",
              }}
            >
              {phoneNumber.length}/10
            </p>
          </div>
          <div className="vendorFormRow">
            <p className="titleVendorform">Email:</p>
            <Input
              value={email}
              style={{ width: "50%" }}
              maxLength="25"
              onChange={(evt) => setEmail(evt.target.value)}
            />
            <p
              style={{
                alignSelf: "center",
                marginLeft: -40,
                marginTop: 10,
                zIndex: 0,
                color: "grey",
              }}
            >
              {email.length}/25
            </p>
          </div>
          <div className="vendorFormRow">
            <p className="titleVendorform">Address:</p>
            <Form style={{ width: "50%" }}>
              <TextArea
                value={address}
                cols="50"
                rows={4}
                maxLength="100"
                style={{ paddingRight: 50 }}
                onChange={(evt) => setAddress(evt.target.value)}
              />
            </Form>
            <p
              style={{
                alignSelf: "center",
                marginLeft: -50,
                marginTop: 50,
                zIndex: 0,
                color: "grey",
              }}
            >
              {address.length}/100
            </p>
          </div>
          <div className="vendorFormRow">
            <p className="titleVendorform">GST IN: </p>
            <Input
              value={gstNo}
              style={{ width: "50%" }}
              maxLength="15"
              onChange={(evt) => setGstNo(evt.target.value)}
            />
            <p
              style={{
                alignSelf: "center",
                marginLeft: -40,
                marginTop: 10,
                zIndex: 0,
                color: "grey",
              }}
            >
              {gstNo.length}/15
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions style={{ textAlign: "center" }}>
          <Button className="addCustomerCancelBtn" onClick={close}>
            Cancel
          </Button>
          <Button
            className="addCustomersubmitBtn"
            onClick={() => handleUpdate()}
            content="Update"
          />
        </Modal.Actions>
      </Modal>

      <Modal size={size} open={openTransaction} onClose={close}>
        <Modal.Header>Add Vendor Transaction</Modal.Header>
        <Modal.Content>
          <div className="vendorFormRow">
            <p className="titleVendorform">Vendor name:</p>
            <Input value={name} style={{ width: "50%" }} disabled />
          </div>
          <div className="vendorFormRow">
            <p className="titleVendorform">Raw Material:</p>
            <Select
              placeholder="Select raw material"
              options={rawMaterials.map((type) => ({
                key: type._id,
                value: type.material_name,
                text: type.material_name,
              }))}
              onChange={(event, data) => setMaterialType(data.value)}
              style={{ width: "30%" }}
            />
          </div>

          <div className="vendorFormRow">
            <p className="titleVendorform">Amount Given: </p>
            <Input
              placeholder="Enter amount"
              style={{ width: "50%" }}
              onChange={(evt) => setAmountGiven(evt.target.value)}
            />
          </div>
          <div className="vendorFormRow">
            <p className="titleVendorform">Amount Balance: </p>
            <Input
              placeholder="Enter amount"
              style={{ width: "50%" }}
              onChange={(evt) => setBalanceAmount(evt.target.value)}
            />
          </div>
        </Modal.Content>
        <Modal.Actions style={{ textAlign: "center" }}>
          <Button className="addCustomerCancelBtn" onClick={close}>
            Cancel
          </Button>
          <Button
            className="addCustomersubmitBtn"
            onClick={() => handleTransaction()}
            content="Add Transaction"
          />
        </Modal.Actions>
      </Modal>

      <Modal size={size} open={openDelete} onClose={close}>
        <Modal.Header>Delete Vendor </Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete vendor ?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button className="addCustomerCancelBtn" onClick={close}>
            No
          </Button>
          <Button
            className="addCustomersubmitBtn"
            icon="checkmark"
            labelPosition="right"
            content="Yes"
            onClick={() => handleDelete()}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default VendorsTable;

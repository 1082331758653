import React, { Component } from "react";
import { Grid, Form, Button } from "semantic-ui-react";
import { BASE_URL } from "../../../../config";
import toastr from "toastr";

class SmsLeadsTemplates extends Component {
  state = {
    won_cashback: "",
    credited_cashback: "",
    templateID: "",
    no_data: false,
  };

  componentDidMount = () => {
    this.fetchTemplates();
  };

  fetchTemplates = () => {
    fetch(`${BASE_URL}/get-sms-templates`)
      .then((res) => res.json())
      .then((json) => {
        if (json.sms_templates[0] === undefined) {
          this.setState({
            no_data: true,
          });
        } else {
          this.setState({
            won_cashback: json.sms_templates[0].won_cashback,
            credited_cashback: json.sms_templates[0].credited_cashback,
            templateID: json.sms_templates[0]._id,
            no_data: false,
          });
        }
      });
  };

  saveSMSTemplates = () => {
    const { won_cashback, credited_cashback } = this.state;

    fetch(`${BASE_URL}/create-sms-templates`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        won_cashback,
        credited_cashback,
        notification_type: "sms-templates",
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        toastr.success("SMS Template Created Successfully");
      });
  };

  updateSmsTemplates = () => {
    const { won_cashback, credited_cashback, templateID } = this.state;

    fetch(
      `${BASE_URL}/update-sms-templates?diwali_notifications_id=${templateID}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          won_cashback,
          credited_cashback,
          notification_type: "sms-templates",
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        toastr.success("SMS Template Updated Successfully");
      });
  };

  render() {
    const { won_cashback, credited_cashback, no_data } = this.state;

    return (
      <div>
        <div style={{ marginTop: 50 }}>
          <div className="headerRow">
            <h4 style={{ alignSelf: "center" }}>SMS Templates</h4>
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column>
                <Form>
                  <Form.TextArea
                    label="When cashback won - [customer_name, cashback_amount]"
                    placeholder="Enter the message.."
                    value={won_cashback}
                    onChange={(e) =>
                      this.setState({
                        won_cashback: e.target.value,
                      })
                    }
                  />
                </Form>
              </Grid.Column>
              <Grid.Column>
                <Form>
                  <Form.TextArea
                    label="When cashback credited - [customer_name, cashback_amount]"
                    placeholder="Enter the message.."
                    value={credited_cashback}
                    onChange={(e) =>
                      this.setState({
                        credited_cashback: e.target.value,
                      })
                    }
                  />
                </Form>
              </Grid.Column>
            </Grid.Row>
            {no_data === true ? (
              <Grid.Row>
                <Grid.Column>
                  <Button color="green" onClick={() => this.saveSMSTemplates()}>
                    Save
                  </Button>
                </Grid.Column>
              </Grid.Row>
            ) : (
              <Grid.Row>
                <Grid.Column>
                  <Button
                    color="green"
                    onClick={() => this.updateSmsTemplates()}
                  >
                    update
                  </Button>
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </div>
      </div>
    );
  }
}

export default SmsLeadsTemplates;

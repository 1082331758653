import React, { useState, useEffect } from "react";
import { Card, Statistic } from "semantic-ui-react";
import { getPercentage, getTurnoverPercentage } from "./merchant.api";
import { BASE_URL } from "../../../config";
import "./merchants.css";

const MerchantsHeader = () => {
  const [merchants, setMerchnats] = useState([]);
  const [turnover, setTurnover] = useState(0);
  const [merPerc, setMerPerc] = useState(0);
  const [turnoverPerc, setTurnoverPerc] = useState(0);

  useEffect(() => {
    fetch(`${BASE_URL}/get-merchants`)
      .then((res) => res.json())
      .then((json) => {
        const percentage = getPercentage(json.merchants);
        setMerPerc(percentage);
        setMerchnats(json.merchants);
      });
    fetch(`${BASE_URL}/get-invoices`)
      .then((res) => res.json())
      .then((json) => {
        const merchant_invoices = json.invoices.filter(
          (invoice) =>
            invoice.customer_role.toString().search("MERCHANT") !== -1
        );
        const merchant_turnover = merchant_invoices.reduce(
          (sum, invoice, index) => sum + Number(invoice.total_amount),
          0
        );
        const percentage = getTurnoverPercentage(merchant_invoices);
        const turnover = merchant_turnover.toLocaleString("en-IN");
        setTurnoverPerc(percentage);
        setTurnover(turnover);
      });
  }, []);

  return (
    <div style={{ marginTop: 20 }}>
      <Card.Group>
        <Card className="AllMerchantsCard">
          <Card.Content>
            <div className="merchantCardContainer">
              <div>
                <Card.Header className="AllMerchantsTitle">
                  All Merchants
                </Card.Header>
                {Math.sign(merPerc) === -1 ? (
                  <span style={{ color: "#21ba45", fontWeight: 600 }}>
                    +{Math.abs(merPerc) || 0}%
                  </span>
                ) : (
                  <span style={{ color: "#db2828", fontWeight: 600 }}>
                    -{merPerc || 0}%
                  </span>
                )}
                <span style={{ color: "#00000066", marginLeft: 5 }}>
                  this month
                </span>
              </div>

              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="tiny">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      {merchants.length}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
        <Card className="MerchantsSalesCard">
          <Card.Content>
            <div className="merchantCardContainer">
              <div>
                <Card.Header className="MerchantsSalesTitle">
                  Turnover
                </Card.Header>
                {Math.sign(turnoverPerc) === -1 ? (
                  <span style={{ color: "#21ba45", fontWeight: 600 }}>
                    +{Math.abs(turnoverPerc) || 0}%
                  </span>
                ) : (
                  <span style={{ color: "#db2828", fontWeight: 600 }}>
                    -{turnoverPerc || 0}%
                  </span>
                )}
                <span style={{ color: "#00000066", marginLeft: 5 }}>
                  this month
                </span>
              </div>
              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="tiny">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      ₹{turnover}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
      </Card.Group>
    </div>
  );
};

export default MerchantsHeader;

import React, { useState, useEffect } from "react";
import {
  Icon,
  Input,
  Button,
  Table,
  Checkbox,
  Popup,
  Dropdown,
} from "semantic-ui-react";
import TableExport from "tableexport";
import toastr from "toastr";
import _ from "lodash";
import {
  HEADING_DATA,
  BAG_WEIGHT,
  CASHBACK_STATUS_DATA,
} from "./cashbackwinners.constant";
import UniquecodeTab from "../../../commons/unicodetab/uniquecodetab.component";
import { BASE_URL } from "../../../../config";

const LeadsComponent = (props) => {
  return (
    <div className="DefaultLayout">
      <Header history={props.history} />
      <Card history={props.history} />
    </div>
  );
};

const Header = (props) => {
  return (
    <div className="HeaderLayout">
      <div className="addProductRow">
        <h3>Diwali Offer Settings </h3>
      </div>
      <UniquecodeTab history={props.history} />
    </div>
  );
};

const Card = (props) => {
  const [searchString, setSearchString] = useState("");
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);
  const [leads, setLeads] = useState([]);

  useEffect(() => {
    fetchLeads();
  }, []);

  const fetchLeads = () => {
    fetch(`${BASE_URL}/get-leads`)
      .then((res) => res.json())
      .then((json) => {
        setLeads(json.leads);
      });
  };

  const handleSort = (clickedColumn) => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setLeads(_.sortBy(leads, [clickedColumn]));
      setDirection("ascending");

      return;
    }

    setLeads(leads.reverse());
    setDirection(direction === "ascending" ? "descending" : "ascending");
  };

  const updatePaymentStatus = (value, lead) => {
    fetch(`${BASE_URL}/update-leads?leads_id=${lead._id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payment_status: value,
        lead_details: lead
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        fetchLeads();
        toastr.success("Payment Status Updated Successfuly");
      });
  };

  const leads_table = leads.filter((lead) =>
    Object.keys(lead).some(
      (key) => lead[key].toString().search(searchString) !== -1
    )
  );

  return (
    <div className="CardContainer">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: 20,
          marginLeft: 20,
        }}
      >
        <div>
          <Button
            icon
            labelPosition="right"
            onClick={() => {
              toastr.success("Exported Successfully");
              const table = new TableExport(
                document.getElementById("leads-table"),
                {
                  formats: ["csv"],
                  exportButtons: false,
                }
              );
              const link = document.createElement("a");
              link.setAttribute(
                "href",
                "data:text/csv;charset=utf-8," +
                  table.getExportData()["leads-table"].csv.data
              );
              link.setAttribute(
                "download",
                table.getExportData()["leads-table"].csv.filename
              );
              link.click();
            }}
            style={{
              backgroundColor: "#d9ead3",
              color: "#000",
            }}
          >
            Export
            <Icon name="share square" />
          </Button>
        </div>
        <div style={{ width: 200 }}>
          <Dropdown
            placeholder="Select Bag Weight"
            fluid
            search
            selection
            options={BAG_WEIGHT.map((weight, index) => ({
              key: index,
              text: weight.name,
              value: weight.value,
            }))}
            onChange={(event, data) => setSearchString(data.value)}
          />
        </div>

        <Input
          icon="search"
          placeholder="Search by name"
          onChange={(e) => setSearchString(e.target.value)}
        />
      </div>
      <div>
        <Table
          sortable
          unstackable
          celled
          basic="very"
          className="CustomTableLayout"
          id="leads-table"
        >
          <Table.Header>
            <Table.Row>
              {HEADING_DATA.map((heading) => (
                <Table.HeaderCell
                  sorted={column === heading ? direction : null}
                  onClick={handleSort(heading)}
                >
                  {heading === "Checkbox" ? (
                    <Checkbox />
                  ) : (
                    <span>{heading}</span>
                  )}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {leads_table.map((lead, index) => (
              <Table.Row>
                <Table.Cell>{index + 1}</Table.Cell>
                <Table.Cell>{lead.name}</Table.Cell>
                <Table.Cell>{lead.phoneNumber}</Table.Cell>
                <Table.Cell>{lead.email}</Table.Cell>
                <Table.Cell>{lead.weight} Kg</Table.Cell>
                <Table.Cell>Rs. {lead.cashback}</Table.Cell>
                <Table.Cell>
                  <div>
                    <p
                      className="section3Text"
                      style={{ fontSize: 17, fontWeight: "bold" }}
                    >
                      {lead.payment_status}
                      <Popup
                        on="click"
                        pinned
                        style={{ textAlign: "center" }}
                        trigger={
                          <Icon
                            name="pencil"
                            style={{
                              fontSize: 15,
                              color: "#93c47d",
                              marginLeft: 5,
                            }}
                          />
                        }
                      >
                        <div>
                          <p>Update status:</p>
                          <Dropdown
                            placeholder="Select Status"
                            fluid
                            search
                            selection
                            options={CASHBACK_STATUS_DATA.map(
                              (status, index) => ({
                                key: index,
                                text: status.name,
                                value: status.value,
                              })
                            )}
                            onChange={(event, data) =>
                              updatePaymentStatus(data.value, lead)
                            }
                          />
                        </div>
                      </Popup>
                    </p>
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default LeadsComponent;

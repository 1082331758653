import React, { useEffect, useState } from "react";
import {
  Table,
  Modal,
  Icon,
  Checkbox,
  Button,
  Input,
  Form,
  TextArea,
} from "semantic-ui-react";
import _ from "lodash";
import toastr from "toastr";
import { HEADING_DATA } from "./drivers.constant";
import { BASE_URL } from "../../../config";

const DriversTable = (props) => {
  const [name, setName] = useState("");
  const [licenseNo, setLicenseNo] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [bookDetails, setBookDetails] = useState("");
  const [selectedDriver, setDriverID] = useState("");
  const [size, setSize] = useState("");
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);

  useEffect(() => {
    fetchDrivers();
  }, []);

  const fetchDrivers = () => {
    fetch(`${BASE_URL}/get-drivers`)
      .then((res) => res.json())
      .then((json) => {
        setDrivers(json.drivers);
      });
  };

  const handleSort = (clickedColumn) => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setDrivers(_.sortBy(drivers, [clickedColumn]));
      setDirection("ascending");

      return;
    }

    setDrivers(drivers.reverse());
    setDirection(direction === "ascending" ? "descending" : "ascending");
  };

  const editDriver = (size, driver) => () => {
    setSize(size);
    setOpen(true);
    setEdit(true);
    setDriverID(driver._id);
    setName(driver.name);
    setLicenseNo(driver.licenseNo);
    setEmail(driver.email);
    setPhoneNumber(driver.phoneNumber);
    setBookDetails(driver.bookDetails);
  };

  const handleUpdate = () => {
    fetch(`${BASE_URL}/update-driver?driver_id=${selectedDriver}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        licenseNo,
        phoneNumber,
        email,
        bookDetails,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        toastr.success('Driver updated successfully!');
        fetchDrivers();
        close();
      });
  };

  const deleteDriver = (size, ID) => () => {
    setSize(size);
    setOpen(true);
    setDriverID(ID);
  };

  const handleDelete = () => {
    fetch(`${BASE_URL}/delete-driver?driver_id=${selectedDriver}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((json) => {
        fetchDrivers();
        close();
      });
  };

  const close = () => {
    setOpen(false);
    setEdit(false);
  };

  const drivers_table = drivers.filter((driver) =>
    Object.keys(driver).some(
      (key) => driver[key].toString().search(props.searchString) !== -1
    )
  );

  const getPayoutAmount = (driver) => {
    const value = driver.payouts.reduce(
      (sum, payout, index) => sum + Number(payout.totalEarnings),
      0
    );

    return Number(value);
  };

  return (
    <div>
      <Table
        sortable
        unstackable
        celled
        basic="very"
        className="CustomTableLayout"
        id="driver-table"
      >
        <Table.Header>
          <Table.Row>
            {HEADING_DATA.map((heading) => (
              <Table.HeaderCell
                sorted={column === heading ? direction : null}
                onClick={handleSort(heading)}
              >
                {heading === "Checkbox" ? <Checkbox /> : <span>{heading}</span>}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {drivers_table.map((driver, index) => (
            <Table.Row>
              <Table.Cell>{index + 1}</Table.Cell>
              <Table.Cell>
                <a
                  onClick={() =>
                    props.history.push({
                      pathname: `/profile-driver/${driver.phoneNumber}`,
                    })
                  }
                >
                  {driver.name}{" "}
                </a>
              </Table.Cell>
              <Table.Cell>{driver.licenseNo}</Table.Cell>
              <Table.Cell>{driver.phoneNumber}</Table.Cell>
              <Table.Cell>{driver.email}</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>
                {driver.payouts !== undefined ? getPayoutAmount(driver) : "-"}
              </Table.Cell>
              <Table.Cell>
                <Button
                  style={{ fontSize: 13, backgroundColor: "#d9ead3" }}
                  onClick={editDriver("small", driver)}
                >
                  <Icon name="edit" />
                  Edit
                </Button>

                <Button
                  style={{ fontSize: 13, backgroundColor: "#f4cccc" }}
                  onClick={deleteDriver("mini", driver._id)}
                >
                  <Icon name="trash" /> Delete
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {edit === true ? (
        <Modal size={size} open={open} onClose={close}>
          <Modal.Header>Edit Driver </Modal.Header>
          <Modal.Content style={{ marginBottom: 20 }}>
            <div className="driverFormRow">
              <p className="titleDriverform">Driver name:</p>
              <Input
                value={name}
                style={{ width: "50%" }}
                maxLength="20"
                onChange={(evt) => setName(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {name.length}/20
              </p>
            </div>
            <div className="driverFormRow">
              <p className="titleDriverform">License No: </p>
              <Input
                value={licenseNo}
                style={{ width: "50%" }}
                maxLength="16"
                onChange={(evt) => setLicenseNo(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {licenseNo.length}/16
              </p>
            </div>
            <div className="driverFormRow">
              <p className="titleDriverform">Mobile number:</p>
              <Input
                value={phoneNumber}
                style={{ width: "50%" }}
                maxLength="10"
                onChange={(evt) => setPhoneNumber(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {phoneNumber.length}/10
              </p>
            </div>
            <div className="driverFormRow">
              <p className="titleDriverform">Email:</p>
              <Input
                value={email}
                style={{ width: "50%" }}
                maxLength="25"
                onChange={(evt) => setEmail(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {email.length}/25
              </p>
            </div>
            <div className="driverFormRow">
              <p className="titleDriverform">Book details:</p>
              <Form style={{ width: "50%" }}>
                <TextArea
                  value={bookDetails}
                  cols="50"
                  rows={4}
                  maxLength="100"
                  style={{ paddingRight: 50 }}
                  onChange={(evt) => setBookDetails(evt.target.value)}
                />
              </Form>
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -50,
                  marginTop: 50,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {bookDetails.length}/100
              </p>
            </div>
          </Modal.Content>
          <Modal.Actions style={{ textAlign: "center" }}>
            <Button className="addCustomerCancelBtn" onClick={close}>
              Cancel
            </Button>
            <Button
              className="addCustomersubmitBtn"
              onClick={() => handleUpdate()}
              content="Update"
            />
          </Modal.Actions>
        </Modal>
      ) : (
        <Modal size={size} open={open} onClose={close}>
          <Modal.Header>Delete Driver </Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete driver ?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button className="addCustomerCancelBtn" onClick={close}>
              No
            </Button>
            <Button
              className="addCustomersubmitBtn"
              icon="checkmark"
              labelPosition="right"
              content="Yes"
              onClick={() => handleDelete()}
            />
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
};

export default DriversTable;

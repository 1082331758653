export const TABLE_DATA = [
  {
    sno: ' 1',
    order_id: '#88816199',
    name: 'Saravan Vel',
    amount: '₹ 20,000',
    invoice_no: '#1010',
    order_status: 'completed',
    transaction_id: 'pay_Ef8swfvp4A5QvdL4',
    gst_no: '18AABCT3518Q1ZV',
  },
  {
    sno: ' 2',
    order_id: '#88816200',
    name: 'Sanmuga Vel',
    amount: '₹ 10,000',
    invoice_no: '#1011',
    order_status: 'completed',
    transaction_id: 'pay_Ef8swfvp4A5QvdP4',
    gst_no: '48AABCT3518Q1AS',
  },
  {
    sno: ' 3',
    order_id: '#88816201',
    name: 'Muruga Vel',
    amount: '₹ 5,000',
    invoice_no: '#1012',
    order_status: 'completed',
    transaction_id: 'pay_Ef8swfvp4A5QvasL4',
    gst_no: '85AABCT351Q5DSP',
  },
];

export const HEADING_DATA = [
  'S.No',
  'Order ID',
  'Name',
  'Amount',
  'Invoice No',
  'Order Status',
  'Transaction ID',
  'Invoice',
];

export const CUS_HEADING_DATA = [
  'S.No',
  'Order ID',
  'Name',
  'Amount',
  'Invoice No',
  'Payment Status',
  'Transaction ID',
  'Invoice',
];

export const PEND_PAY_HEADING_DATA = [
  'S.No',
  'Order ID',
  'Name',
  'Amount',
  'Invoice No',
  'Order Status',
  'Invoice',
  'Actions'
];

export const RPA_HEADING_DATA = [
  'S.No',
  'Order ID',
  'Name',
  'Amount',
  'Invoice No',
  'Order Status',
  'Transaction ID',
  'Paid Amount',
  'Actions'
];

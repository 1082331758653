import React from 'react';
import SettingsTabBarMenu from '../../../commons/settingstabbar/settingstabbar.component';

const AssetSettings = (props) => {
  return (
    <div className='DefaultLayout'>
      <Header history={props.history} />
      <Card />
    </div>
  );
};

const Header = (props) => {
  return (
    <div className='HeaderLayout'>
      <h3>Settings</h3>
      <SettingsTabBarMenu history={props.history} />
    </div>
  );
};

const Card = () => {
  return (
    <div className='CardContainer'>
      <h3>Asset Settings</h3>
    </div>
  );
};
export default AssetSettings;

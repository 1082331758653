import React, { useState, useEffect } from "react";
import { Tab, Table, Modal } from "semantic-ui-react";

const MerchantProfileOrderHistory = (props) => {
  const orderHistoryPanes = [
    {
      menuItem: "Completed Orders",
      render: () => (
        <Tab.Pane attached={false}>
          <ComPletedOrderTable ordersData={props.orders} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Ongoing Orders",
      render: () => (
        <Tab.Pane attached={false}>
          <OngoingOrderTable ordersData={props.orders} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Cancelled Orders",
      render: () => (
        <Tab.Pane attached={false}>
          <CancelledOrderTable ordersData={props.orders} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div>
      <Tab
        menu={{ pointing: true, borderless: true }}
        panes={orderHistoryPanes}
      />
    </div>
  );
};

const ComPletedOrderTable = (props) => {
  const [orders, setOrders] = useState([]);
  
  useEffect(() => {
    const { ordersData } = props;
    setOrders(ordersData);
  }, []);

  const orders_data = [].filter((order) =>
    Object.keys(order).some(
      (key) => (order[key] === null ? "" : order[key]).search("DELIVERED") !== -1
    )
  );

  return (
    <React.Fragment>
      <Table
        sortable
        unstackable
        celled
        color="grey"
        className="CustomTableLayout"
      >
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell>Order Id </Table.HeaderCell>
            <Table.HeaderCell>Name </Table.HeaderCell>
            <Table.HeaderCell>Products </Table.HeaderCell>
            <Table.HeaderCell>Delivered Address </Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {orders_data.map((order, index) => (
            <Table.Row textAlign="center" key={index}>
              <Table.Cell>
                <p>#{order.order_id}</p>
              </Table.Cell>
              <Table.Cell>{order.name}</Table.Cell>
              <Table.Cell>
                {order.products.map((product, idx) => (
                  <p>
                    {idx + 1}. {product.name} : {product.weight} kg x{" "}
                    {product.quantity}
                  </p>
                ))}
              </Table.Cell>
              <Table.Cell>{order.shipping_address.detailedAddress}</Table.Cell>
              <Table.Cell>₹ {order.order_total}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};

const OngoingOrderTable = (props) => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const { ordersData } = props;
    setOrders(ordersData);
  }, []);

  const status_map = [
    "ORDER_REQUESTED",
    "INVOICE_GENERATED",
    "DRIVER_ASSIGNED",
    "OUT_FOR_DELIVERY",
    "OTP_VERIFIED",
  ];

  const active_orders = orders.filter(
    (order) => status_map.indexOf(order.status) !== -1
  );

  return (
    <Table
      sortable
      unstackable
      celled
      color="grey"
      className="CustomTableLayout"
    >
      <Table.Header>
        <Table.Row textAlign="center">
          <Table.HeaderCell>Order Id </Table.HeaderCell>
          <Table.HeaderCell>Name </Table.HeaderCell>
          <Table.HeaderCell>Products </Table.HeaderCell>
          <Table.HeaderCell>Delivered Address </Table.HeaderCell>
          <Table.HeaderCell>Amount</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {active_orders.map((order, index) => (
          <Table.Row textAlign="center" key={index}>
            <Table.Cell>
              <p>#{order.order_id}</p>
            </Table.Cell>
            <Table.Cell>{order.name}</Table.Cell>
            <Table.Cell>
              {order.products.map((product, idx) => (
                <p>
                  {idx + 1}. {product.name} : {product.weight} kg x{" "}
                  {product.quantity}
                </p>
              ))}
            </Table.Cell>
            <Table.Cell>{order.shipping_address.detailedAddress}</Table.Cell>
            <Table.Cell>₹ {order.order_total}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

const CancelledOrderTable = (props) => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const { ordersData } = props;
    setOrders(ordersData);
  }, []);

  const cancelled_orders = orders.filter(
    (order) => order.status.toString().search("CANCELLED") !== -1
  );

  return (
    <Table
      sortable
      unstackable
      celled
      color="grey"
      className="CustomTableLayout"
    >
      <Table.Header>
        <Table.Row textAlign="center">
          <Table.HeaderCell>Order Id </Table.HeaderCell>
          <Table.HeaderCell>Name </Table.HeaderCell>
          <Table.HeaderCell>Products </Table.HeaderCell>
          <Table.HeaderCell>Delivered Address </Table.HeaderCell>
          <Table.HeaderCell>Amount</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {cancelled_orders.map((order, index) => (
          <Table.Row textAlign="center" key={index}>
            <Table.Cell>
              <p>#{order.order_id}</p>
            </Table.Cell>
            <Table.Cell>{order.name}</Table.Cell>
            <Table.Cell>
              {order.products.map((product, idx) => (
                <p>
                  {idx + 1}. {product.name} : {product.weight} kg x{" "}
                  {product.quantity}
                </p>
              ))}
            </Table.Cell>
            <Table.Cell>{order.shipping_address.detailedAddress}</Table.Cell>
            <Table.Cell>₹ {order.order_total}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default MerchantProfileOrderHistory;

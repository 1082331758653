import React from "react";
import SettingsTabBarMenu from "../../../commons/settingstabbar/settingstabbar.component";
import { Tab } from "semantic-ui-react";
import VendorTypesTable from "./vendortypes-table.component";
import RawMaterialsComponent from "./rawmaterials/rawmaterials.component";

const VendorSettings = (props) => {
  return (
    <div className="DefaultLayout">
      <Header history={props.history} />
      <Card />
    </div>
  );
};

const vendorSettingsPanes = [
  {
    menuItem: "Vendor Types",
    render: () => (
      <Tab.Pane attached={false}>
        <VendorTypesTable />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Raw Materials",
    render: () => (
      <Tab.Pane attached={false}>
        <RawMaterialsComponent />
      </Tab.Pane>
    ),
  },
];

const Header = (props) => {
  return (
    <div className="HeaderLayout">
      <h3>Settings</h3>
      <SettingsTabBarMenu history={props.history} />
    </div>
  );
};

const Card = () => {
  return (
    <div className="CardContainer">
      <h3>Vendor Settings</h3>
      <Tab
        menu={{ borderless: true, pointing: true }}
        panes={vendorSettingsPanes}
      />
    </div>
  );
};

export default VendorSettings;

import React, { useEffect, useState } from "react";
import { Table, Icon, Checkbox, Button, Select } from "semantic-ui-react";
import { HEADING_DATA } from "./statements.constant";
import { BASE_URL } from "../../../config";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

const AllStatementsTable = () => {
  const today = moment();
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [collectionName, setCollectionName] = useState({});
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState(
    moment.range(today.clone().subtract(4, "days"), today.clone())
  );

  useEffect(() => {
    fetch(`${BASE_URL}/get-companies`)
      .then((res) => res.json())
      .then((json) => {
        if (json.companies.length > 0) {
          setCompanies(json.companies);
          setCollectionName(`${json.companies[0].collection_name}_invoices`);
        }
      });

    fetch(`${BASE_URL}/get-all-merchant-statements`)
      .then((res) => (res.json()))
      .then((json) => {
        setUsers(json.users);
      })
  }, []);


  const onToggle = () => {
    setOpen(!isOpen);
  };

  const onSelect = (value, states) => {
    const start = value.start.format("YYYY-MM-DD");
    const end = value.end.format("YYYY-MM-DD");

    fetch(`${BASE_URL}/get-all-merchant-statements`)
      .then((res) => (res.json()))
      .then((json) => {
        const data = json.users.map((user) => {
          user.stmtData = user.stmtData.filter((stmt) => {
            return new Date(stmt.date) >= new Date(start) && new Date(stmt.date) <= new Date(end);
          })
          return user;
        })
        setUsers(data);
      })
    setValue(value);
    setOpen(!isOpen);
  };

  const handleChangeCompany = (id) => {
    const data = companies.filter((company) => company._id === id);
    const company = data[0];
    setCollectionName(`${company.collection_name}_invoices`);
    fetch(`${BASE_URL}/get-all-merchant-statements`)
      .then((res) => (res.json()))
      .then((json) => {
        const data = json.users.map((user) => {
          user.stmtData = user.stmtData.filter((stmt) => {
            return stmt.cname === `${company.collection_name}_invoices`;
          })
          return user;
        })
        setUsers(data);
      })
  };

  const getTotalDebited = (statements) => {
    const debitData = statements.filter((stmt) => (stmt.amount_type === 'Debit'));
    const debitAmt = debitData.reduce((sum, debit, index) => sum + Number(debit.amount), 0);
    return debitAmt;
  }

  const getTotalCredited = (statements) => {
    const creditData = statements.filter((stmt) => (stmt.amount_type === 'Credit'));
    const creditAmt = creditData.reduce((sum, credit, index) => sum + Number(credit.amount), 0);
    return creditAmt;
  }

  const getTotalMerchantsDebitedAmt = () => {
    const data = users.map((user) => {
      const debitData = user.stmtData.filter((stmt) => (stmt.amount_type === 'Debit'));
      const debitAmt = debitData.reduce((sum, debit, index) => sum + Number(debit.amount), 0);
      return debitAmt;
    }
    );

    const total_debited = data.reduce((sum, data, index) => sum + data, 0);
    return total_debited;
  }

  const getTotalMerchantsCreditedAmt = () => {
    const data = users.map((user) => {
      const debitData = user.stmtData.filter((stmt) => (stmt.amount_type === 'Credit'));
      const debitAmt = debitData.reduce((sum, debit, index) => sum + Number(debit.amount), 0);
      return debitAmt;
    }
    );

    const total_debited = data.reduce((sum, data, index) => sum + data, 0);
    return total_debited;
  }

  const getClosingBalance = () => {
    const balance_amount = getTotalMerchantsDebitedAmt() - getTotalMerchantsCreditedAmt();
    return balance_amount;
  }

  const printInvoice = () => {
    setTimeout(function () {
      var printContents = document.getElementById("capture").innerHTML;
      var originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;

      window.print();

      document.body.innerHTML = originalContents;
      window.location.reload();
    }, 1000);
  };

  return (
    <div>
      <div className="BillingTitleRow">
        <div style={{ marginTop: 10 }}>
          <Select
            placeholder={companies.length > 0 ? companies[0].company_name : 'Select Company'}
            options={companies.map((company) => ({
              key: company._id,
              value: company._id,
              text: company.company_name,
            }))}
            style={{ margin: "auto" }}
            onChange={(event, data) => handleChangeCompany(data.value)}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          Select Range:{" "}
          <input
            type="text"
            onClick={onToggle}
            style={{ width: 170 }}
            value={`${value.start.format("DD-MM-YYYY")} - ${value.end.format(
              "DD-MM-YYYY"
            )}`}
          />
          {isOpen && (
            <DateRangePicker
              value={value}
              onSelect={onSelect}
              singleDateRange={true}
            />
          )}
        </div>
        <div style={{ marginTop: 10 }}>
          <Button
            icon
            labelPosition="right"
            style={{ backgroundColor: "#d9ead3", color: "#000", marginBottom: 15 }}
            onClick={() => printInvoice()}
          >
            Print
            <Icon name="share square icon" />
          </Button>
        </div>

      </div>

      <div id="capture">
        {/* <div style={{ textAlign: 'center'}}>
          <h4>{`${value.start.format("DD-MM-YYYY")} - ${value.end.format("DD-MM-YYYY")}`}</h4>
        </div> */}
        <Table
          sortable
          unstackable
          celled
          basic="very"
          id="all-invoices"
        >
          <Table.Header>
            <Table.Row>
              {HEADING_DATA.map((heading) => (
                <Table.HeaderCell textAlign="center">
                  {heading === "Checkbox" ? <Checkbox /> : <span>{heading}</span>}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {users.map((user, index) => (
              <Table.Row>
                <Table.Cell textAlign='center'>{index + 1}</Table.Cell>
                <Table.Cell textAlign='center'>{(user.companyName).toUpperCase()}</Table.Cell>
                <Table.Cell textAlign='center'>₹ {getTotalDebited(user.stmtData).toLocaleString("en-IN")}</Table.Cell>
                <Table.Cell textAlign='center'>₹ {getTotalCredited(user.stmtData).toLocaleString("en-IN")}</Table.Cell>
              </Table.Row>
            ))}
            <Table.Row textAlign="center">
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>₹ {getTotalMerchantsDebitedAmt().toLocaleString("en-IN")}</Table.Cell>
              <Table.Cell>₹ {getTotalMerchantsCreditedAmt().toLocaleString("en-IN")}</Table.Cell>
            </Table.Row>
            <Table.Row textAlign="center">
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>₹ {getClosingBalance().toLocaleString("en-IN")}</Table.Cell>
            </Table.Row>
            <Table.Row textAlign="center">
              <Table.Cell></Table.Cell>
              <Table.Cell>Closing Balance</Table.Cell>
              <Table.Cell>₹ {getTotalMerchantsDebitedAmt().toLocaleString("en-IN")}</Table.Cell>
              <Table.Cell>₹ {(getClosingBalance() + getTotalMerchantsCreditedAmt()).toLocaleString("en-IN")}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default AllStatementsTable;

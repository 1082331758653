import React, { useEffect, useState } from "react";
import {
  Table,
  Modal,
  Icon,
  Checkbox,
  Button,
  Input,
  Select,
} from "semantic-ui-react";
import _ from "lodash";
import toastr from "toastr";
import moment from "moment";
import { HEADING_DATA } from "./assets.constant";
import { BASE_URL } from "../../../config";
import TableExport from "tableexport";

const AssetsTable = (props) => {
  const [asset_name, setAssetName] = useState("");
  const [asset_type, setAssetType] = useState("");
  const [purchase_value, setPurchasedValue] = useState("");
  const [selectedAsset, setAssetID] = useState("");
  const [size, setSize] = useState("");
  const [selectedvehicle, setSelectedVehicle] = useState("");
  const [selectedMaintenances, setSelectedMaintences] = useState([]);
  const [selectedMileages, setSelectedMileages] = useState([]);
  const [selectedExpenses, setSelectedExpenses] = useState([]);
  const [edit, setEdit] = useState(false);
  const [assets, setAssets] = useState([]);
  const [assetTypes, setAssetTypes] = useState([]);
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);
  const [showMaintenanceCost, setShowMaintenanceCost] = useState(false);
  const [showMileageModal, setShowMileageModal] = useState(false);
  const [showExpenseModal, setShowExpenseModal] = useState(false);
  const [showAssignedPersons, setShowAssignedPersons] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  console.log(assets, 'assets');

  const close = () => {
    setShowMaintenanceCost(false);
    setShowAssignedPersons(false);
    setShowMileageModal(false);
    setShowDeleteModal(false);
    setShowEditModal(false);
    setShowExpenseModal(false);
  };

  useEffect(() => {
    fetchAssets();
    fetchAssetTypes();
  }, []);

  const fetchAssets = () => {
    fetch(`${BASE_URL}/get-assets`)
      .then((res) => res.json())
      .then((json) => {
        setAssets(json.assets);
      });
  };

  const fetchAssetTypes = () => {
    fetch(`${BASE_URL}/get-asset-types`)
      .then((res) => res.json())
      .then((json) => {
        setAssetTypes(json.asset_types);
      });
  };

  const handleSort = (clickedColumn) => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setAssets(_.sortBy(assets, [clickedColumn]));
      setDirection("ascending");

      return;
    }

    setAssets(assets.reverse());
    setDirection(direction === "ascending" ? "descending" : "ascending");
  };

  const editAsset = (size, asset) => () => {
    setSize(size);
    setShowEditModal(true);
    setEdit(true);
    setAssetID(asset._id);
    setAssetName(asset.asset_name);
    setAssetType(asset.asset_type);
    setPurchasedValue(asset.purchase_value);
  };

  const handleUpdate = () => {
    fetch(`${BASE_URL}/update-asset?asset_id=${selectedAsset}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        asset_name,
        asset_type,
        purchase_value,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        toastr.success("Asset updated successfully!");
        fetchAssets();
        close();
      });
  };

  const deleteAsset = (size, ID) => () => {
    setSize(size);
    setShowDeleteModal(true);
    setAssetID(ID);
  };

  const handleDelete = () => {
    fetch(`${BASE_URL}/delete-asset?asset_id=${selectedAsset}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((json) => {
        fetchAssets();
        close();
      });
  };

  const assets_table = assets.filter((asset) =>
    Object.keys(asset).some(
      (key) => asset[key].toString().search(props.searchString) !== -1
    )
  );

  const renderTotalMaintenance = (maintenanceData) =>
    maintenanceData.reduce(
      (sum, maintenance, index) => sum + maintenance.cost,
      0
    );

  return (
    <div>
      <Table
        sortable
        unstackable
        celled
        basic="very"
        className="CustomTableLayout"
        id="asset-table"
      >
        <Table.Header>
          <Table.Row>
            {HEADING_DATA.map((heading) => (
              <Table.HeaderCell
                sorted={column === heading ? direction : null}
                onClick={handleSort(heading)}
              >
                {heading === "Checkbox" ? <Checkbox /> : <span>{heading}</span>}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {assets_table.map((asset, index) => (
            <Table.Row>
              <Table.Cell>{index + 1}</Table.Cell>
              <Table.Cell>{asset.asset_name}</Table.Cell>
              <Table.Cell>{asset.asset_type}</Table.Cell>
              <Table.Cell>{asset.purchase_value}</Table.Cell>
              <Table.Cell>
                {asset.mileages !== undefined ? (
                  <p
                    className="maintenanceCost"
                    onClick={() => {
                      setSelectedMileages(asset.mileages);
                      setShowMileageModal(true);
                    }}
                  >
                    View
                  </p>
                ) : (
                  <p
                    className="maintenanceCost"
                    onClick={() => setShowMileageModal(true)}
                  >
                    -
                  </p>
                )}
              </Table.Cell>
              <Table.Cell>
                {asset.maintenances !== undefined ? (
                  <p
                    className="maintenanceCost"
                    onClick={() => {
                      setSelectedMaintences(asset.maintenances);
                      setSelectedVehicle(asset.asset_name);
                      setShowMaintenanceCost(true);
                    }}
                  >
                    {renderTotalMaintenance(asset.maintenances)}
                  </p>
                ) : (
                  <p className="maintenanceCost">-</p>
                )}
              </Table.Cell>
              <Table.Cell>
                {asset.expenses !== undefined ? (
                  <p
                    className="maintenanceCost"
                    onClick={() => {
                      setSelectedExpenses(asset.expenses);
                      setShowExpenseModal(true);
                    }}
                  >
                    View
                  </p>
                ) : (
                  <p
                    className="maintenanceCost"
                    onClick={() => setShowExpenseModal(true)}
                  >
                    -
                  </p>
                )}
              </Table.Cell>
              <Table.Cell>
                <p
                  className="maintenanceCost"
                  onClick={() => setShowAssignedPersons(true)}
                >
                  Valmiki
                </p>
              </Table.Cell>
              <Table.Cell>
                <Button
                  style={{ fontSize: 13, backgroundColor: "#f3f3f3" }}
                  onClick={editAsset("small", asset)}
                >
                  <Icon name="edit" />
                  Edit
                </Button>

                <Button
                  style={{ fontSize: 13, backgroundColor: "#f4cccc" }}
                  onClick={deleteAsset("mini", asset._id)}
                >
                  <Icon name="trash" /> Delete
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      {/* Maintenance Cost */}
      <div>
        <Modal size="tiny" open={showMaintenanceCost} onClose={close}>
          <div style={{ padding: 30 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h3 className="maintenanceModalHeading">
                Details for Vehicle Maintenance
              </h3>
              <Button
                icon
                labelPosition="right"
                onClick={() => {
                  toastr.success("Exported Successfully");
                  const table = new TableExport(
                    document.getElementById("maintainance-table"),
                    {
                      formats: ["csv"],
                      exportButtons: false,
                    }
                  );
                  const link = document.createElement("a");
                  link.setAttribute(
                    "href",
                    "data:text/csv;charset=utf-8," +
                    table.getExportData()["maintainance-table"].csv.data
                  );
                  link.setAttribute(
                    "download",
                    table.getExportData()["maintainance-table"].csv.filename
                  );
                  link.click();
                }}
                style={{
                  backgroundColor: "#d9ead3",
                  color: "#000",
                }}
              >
                Export
                <Icon name="share square" />
              </Button>
            </div>

            <Table
              sortable
              unstackable
              celled
              color="grey"
              className="CustomTableLayout"
              id="maintainance-table"
            >
              <Table.Header>
                <Table.Row textAlign="center">
                  <Table.HeaderCell>S.No</Table.HeaderCell>
                  <Table.HeaderCell>Vehicle</Table.HeaderCell>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Cost </Table.HeaderCell>
                  <Table.HeaderCell>Reason </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {selectedMaintenances.map((maintenance, idx) => (
                  <Table.Row textAlign="center">
                    <Table.Cell>{idx + 1}</Table.Cell>
                    <Table.Cell>{selectedvehicle}</Table.Cell>
                    <Table.Cell>
                      {moment(maintenance.date).format("L")}
                    </Table.Cell>
                    <Table.Cell>₹ {maintenance.cost}</Table.Cell>
                    <Table.Cell>{maintenance.reason}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </Modal>
      </div>

      {/* Mielage */}
      <div>
        <Modal size="tiny" open={showMileageModal} onClose={close}>
          <div style={{ padding: 30 }}>
            <h3 className="maintenanceModalHeading">Details for Mileage</h3>
            <Table
              sortable
              unstackable
              celled
              color="grey"
              className="CustomTableLayout"
            >
              <Table.Header>
                <Table.Row textAlign="center">
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Mileage </Table.HeaderCell>
                  <Table.HeaderCell>Snapshots </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {selectedMileages.map((mileage) => (
                  <Table.Row textAlign="center">
                    <Table.Cell>
                      {moment(mileage.date).format("DD MMM YYYY")}
                    </Table.Cell>
                    <Table.Cell>{Math.round(mileage.mileage)} Kms / Ltr</Table.Cell>
                    <Table.Cell>
                      <a
                        href={`${mileage.starting_km_snapshot}`}
                        target="blank"
                      >
                        {mileage.starting_km || 0}
                      </a>{" "}
                      kms -{" "}
                      <a href={`${mileage.ending_km_snapshot}`} target="blank">
                        {mileage.ending_km || 0}
                      </a>{" "}
                      kms
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </Modal>
      </div>


      {/* Expenses */}
      <div>
        <Modal size="small" open={showExpenseModal} onClose={close}>
          <div style={{ padding: 30 }}>
            <h3 className="maintenanceModalHeading">Details for Expenses</h3>
            <Table
              sortable
              unstackable
              celled
              color="grey"
              className="CustomTableLayout"
            >
              <Table.Header>
                <Table.Row textAlign="center">
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Current Meter Reading</Table.HeaderCell>
                  <Table.HeaderCell>Service Reason</Table.HeaderCell>
                  <Table.HeaderCell>Total Expense</Table.HeaderCell>
                  <Table.HeaderCell>Billing Snapshot</Table.HeaderCell>
                  <Table.HeaderCell>Detailed Explanation</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {selectedExpenses.map((expense) => (
                  <Table.Row textAlign="center">
                    <Table.Cell>
                      {moment(expense.date).format("DD MMM YYYY")}
                    </Table.Cell>
                    <Table.Cell>{Math.round(expense.current_meter_reading)} Kms</Table.Cell>
                    <Table.Cell>{expense.service_reason}</Table.Cell>
                    <Table.Cell>Rs. {expense.total_expense}</Table.Cell>
                    <Table.Cell><a
                      href={`${expense.billing_snapshot}`}
                      target="blank"
                    >
                      View
                      </a></Table.Cell>
                    <Table.Cell>{expense.detailed_explanation}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </Modal>
      </div>

      <div>
        <Modal size="tiny" open={showAssignedPersons} onClose={close}>
          <div style={{ padding: 30 }}>
            <h3 className="maintenanceModalHeading">
              Details of assigned person
            </h3>
            <Table
              sortable
              unstackable
              celled
              color="grey"
              className="CustomTableLayout"
            >
              <Table.Header>
                <Table.Row textAlign="center">
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Assigned Person </Table.HeaderCell>
                  <Table.HeaderCell>Days used</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row textAlign="center">
                  <Table.Cell>01-05-2020</Table.Cell>
                  <Table.Cell>Valmiki</Table.Cell>
                  <Table.Cell>10</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </Modal>
        <Modal size={size} open={showEditModal} onClose={close}>
          <Modal.Header>Edit Asset </Modal.Header>
          <Modal.Content>
            <div className="vendorFormRow">
              <p className="titleVendorform">Asset name:</p>
              <Input
                value={asset_name}
                style={{ width: "50%" }}
                maxLength="20"
                onChange={(evt) => setAssetName(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {asset_name.length}/20
              </p>
            </div>
            <div className="vendorFormRow">
              <p className="titleVendorform">Asset type:</p>
              <Select
                value={asset_type}
                options={assetTypes.map((type) => ({
                  key: type._id,
                  value: type.assetType,
                  text: type.assetType,
                }))}
                onChange={(event, data) => setAssetType(data.value)}
                style={{ width: "30%" }}
              />
            </div>
            <div className="vendorFormRow">
              <p className="titleVendorform">Purchase Value:</p>
              <Input
                value={purchase_value}
                style={{ width: "50%" }}
                maxLength="20"
                onChange={(evt) => setPurchasedValue(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {purchase_value.length}/20
              </p>
            </div>
          </Modal.Content>
          <Modal.Actions style={{ textAlign: "center" }}>
            <Button className="addCustomerCancelBtn" onClick={close}>
              Cancel
            </Button>
            <Button
              className="addCustomersubmitBtn"
              onClick={() => handleUpdate()}
              content="Update"
            />
          </Modal.Actions>
        </Modal>
        <Modal size={size} open={showDeleteModal} onClose={close}>
          <Modal.Header>Delete Asset</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete asset ?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button className="addCustomerCancelBtn" onClick={close}>
              No
            </Button>
            <Button
              className="addCustomersubmitBtn"
              icon="checkmark"
              labelPosition="right"
              content="Yes"
              onClick={() => handleDelete()}
            />
          </Modal.Actions>
        </Modal>
      </div>
    </div>
  );
};

export default AssetsTable;

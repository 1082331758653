import React from 'react';
import { Grid, Image, Responsive, Label, Icon } from 'semantic-ui-react';
import PROFILE_PIC from '../../../../images/pic.png';
import LEVEL_1 from '../../../../images/Level-1.png';
import LEVEL_2 from '../../../../images/Level-2.png';
import './merchantsComparison.css';
import { MERCHANT_DETAILS } from './merchantComparison.constant';
import { Link } from 'react-router-dom';

const labelColor = ['yellow', 'grey', 'yellow'];

const ComparsionDetailsPage = (props) => {
  return (
    <React.Fragment>
      <div
        style={{ marginTop: 10, marginBottom: 20, padding: 10 }}
        className='gridLayout'
      >
        <Grid centered container columns={3} divided>
          <Grid.Row>
            <Grid.Column floated='left'>
              <div style={{ margin: 20, textAlign: 'left' }}>
                <h2>Merchant details</h2>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {props.merchantData.map((merchant) => (
              <Grid.Column>
                <Responsive minWidth={767}>
                  <div className='merchantHeader'>
                    <Image
                      src={PROFILE_PIC}
                      inline
                      circular
                      size='tiny'
                      style={{ width: 100 }}
                    />
                    <div
                      style={{
                        textAlign: 'left',
                        marginLeft: 20,
                        alignSelf: 'center',
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <h3>{merchant.name}</h3>
                        <div style={{ marginLeft: '20px' }}>

                          <Label color='blue'>
                            <Icon name='external alternate' />
                            <a href={`/#/profile-merchant/${merchant.phoneNumber}`} target="_blank" >View</a>             
                          </Label>

                        </div>
                      </div>
                      <p style={{ fontWeight: '500' }}>
                        {merchant.companyName}
                      </p>
                      <Label color='yellow'>{merchant.currentLevel.currentLevelName !== undefined ? merchant.currentLevel.currentLevelName : "ZERO"}</Label>
                    </div>
                  </div>
                </Responsive>
              </Grid.Column>
            ))}
          </Grid.Row>
          <Grid.Row>
            <Grid.Column floated='left'>
              <div style={{ margin: 20, textAlign: 'left' }}>
                <h2>Contact details</h2>
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            {props.merchantData.map((merchant) => (
              <Grid.Column centered>
                <Responsive minWidth={767}>
                  <div className='merchantContact'>
                    <div style={{ display: 'flex' }}>
                      <h4 style={{ fontWeight: '500' }}>Mobile Number : </h4>
                      <div style={{ marginLeft: 20 }}>
                        <Label>
                          <Icon name='mobile alternate' color='green' />{' '}
                          {merchant.phoneNumber}
                        </Label>
                      </div>
                    </div>

                    <div style={{ display: 'flex', marginTop: 10 }}>
                      <h4 style={{ fontWeight: '500' }}>Email Address : </h4>
                      <div style={{ marginLeft: 20 }}>
                        <Label>
                          <Icon name='mail' color='red' /> {merchant.email}
                        </Label>
                      </div>
                    </div>
                  </div>
                </Responsive>
              </Grid.Column>
            ))}
          </Grid.Row>
          <Grid.Row>
            <Grid.Column floated='left'>
              <div style={{ margin: 20, textAlign: 'left' }}>
                <h2>Order History</h2>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            {props.merchantData.map((merchant) => (
              <Grid.Column centered>
                <Responsive minWidth={767}>
                  <div className='merchantOrderHistory'>
                    <div style={{ display: 'flex' }}>
                      <h4 style={{ fontWeight: '500' }}>Total Turnover : </h4>
                      <div style={{ marginLeft: 20 }}>
                        <Label color='orange'>{merchant.turnOver}</Label>
                      </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: 10 }}>
                      <h4 style={{ fontWeight: '500' }}>Total Orders : </h4>
                      <div style={{ marginLeft: 20 }}>
                        <h3>25</h3>
                      </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: 10 }}>
                      <h4 style={{ fontWeight: '500' }}>
                        High Value Purchase :{' '}
                      </h4>
                      <div style={{ marginLeft: 20 }}>
                        <Label color='green'>500000</Label>
                      </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: 10 }}>
                      <h4 style={{ fontWeight: '500' }}>
                        Outstanding Amount :{' '}
                      </h4>
                      <div style={{ marginLeft: 20 }}>
                        <Label color='red'>5000</Label>
                      </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: 10 }}>
                      <h4 style={{ fontWeight: '500' }}>Account Status : </h4>
                      <div style={{ marginLeft: 20 }}>
                        <Label color='green'>Active</Label>
                      </div>
                    </div>
                  </div>
                </Responsive>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default ComparsionDetailsPage;

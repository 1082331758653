import React from "react";
import { Tab } from "semantic-ui-react";
import DriversFeedbackTable from "./drivers-feedback-table.component";
import MerchantsFeedbackTable from "./merchants-feedback-table.component";

const FeedbacksComponent = () => {
    return (
        <div className="DefaultLayout">
            <Header />
            <Card />
        </div>
    );
};

const payoutPanes = [
    {
        menuItem: "Driver Feedbacks",
        render: () => (
            <Tab.Pane attached={false}>
                <DriversFeedbackTable />
            </Tab.Pane>
        ),
    },
    {
        menuItem: "Merchant Feedbacks",
        render: () => (
            <Tab.Pane attached={false}>
                <MerchantsFeedbackTable />
            </Tab.Pane>
        ),
    },

];

const Header = () => {
    return (
        <div className="HeaderLayout">
            <h3>Feedbacks</h3>
        </div>
    );
};

const Card = () => {
    return (
        <div className="CardContainer">
            <Tab menu={{ borderless: true, pointing: true }} panes={payoutPanes} />
        </div>
    );
};

export default FeedbacksComponent;

import React, { useState, useEffect } from "react";
import { Card, Statistic } from "semantic-ui-react";
import { getOrders, getPercentage } from "./orderrequests.api";

const OrderRequestsHeader = () => {
  const [orders, setOrders] = useState([]);
  const [activeOrders, setActiveOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [ordersPerc, setOrdersPerc] = useState(0);

  useEffect(() => {
    getOrders()
      .then((json) => {
        const status_map = [
          "ORDER_REQUESTED",
          "INVOICE_GENERATED",
          "DRIVER_ASSIGNED",
          "OUT_FOR_DELIVERY",
          "OTP_VERIFIED",
        ];
        const orders = json.orders;
        const active_orders = json.orders.filter(
          (order) => status_map.indexOf(order.status) !== -1
        );
        const completed_orders = json.orders.filter(
          (order) => order.status.toString().search("DELIVERED") !== -1
        );
        const percentage = getPercentage(json.orders);
        setOrdersPerc(percentage);
        setOrders(orders);
        setActiveOrders(active_orders);
        setCompletedOrders(completed_orders);
      });
  }, []);

  return (
    <div>
      <Card.Group>
        <Card className="AllOrdersCard">
          <Card.Content>
            <div className="headercardContainer">
              <div>
                <Card.Header className="AllOrdersTitle">
                  Total Orders
                </Card.Header>
                {Math.sign(ordersPerc) === -1 ? (
                  <span style={{ color: "#21ba45", fontWeight: 600 }}>
                    +{Math.round(Math.abs(ordersPerc)) || 0}%
                  </span>
                ) : (
                  <span style={{ color: "#db2828", fontWeight: 600 }}>
                    -{Math.round(ordersPerc) || 0}%
                  </span>
                )}
                <span style={{ color: "#00000066", marginLeft: 5 }}>
                  this month
                </span>
              </div>{" "}
              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="tiny">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      {orders.length}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
        <Card className="ActiveOrdersCard">
          <Card.Content>
            <div className="headercardContainer">
              <div>
                <Card.Header className="ActiveOrdersTitle">
                  Active Order Requests
                </Card.Header>
              </div>{" "}
              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="tiny">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      {activeOrders.length}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
        <Card className="CompletedOrdersCard">
          <Card.Content>
            <div className="headercardContainer">
              <div>
                <Card.Header className="CompletedOrdersTitle">
                  Completed Orders
                </Card.Header>
              </div>{" "}
              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="tiny">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      {completedOrders.length}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
      </Card.Group>
    </div>
  );
};

const CardTitle = {
  fontSize: 14,
  fontWeight: "bold",
  color: "#22252f",
  textAlign: "left",
  paddingTop: 5,
};

const CardUI = {
  width: 270,
  borderRadius: 20,
};

const CardUI2 = {
  width: 350,
  borderRadius: 20,
};

// const CardValue = {
//   fontSize: 20,
//   fontWeight: 'bold',
//   textAlign: 'left',
//   paddingTop: 5,
// };

export default OrderRequestsHeader;

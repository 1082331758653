import React from "react";
import { Tab } from "semantic-ui-react";
import BillingHeader from "./billing_header.component";
import CustomerBillingTable from "./customerbilling_table.component";
import MerchantBillingTable from "./merchantbilling_table.component";
import PendingPayments from "./pendingpayments.component";
import ReceivedPaymentsApproval from "./recieved_payments_approval";
import AllInvoicesTable from "./allinvoices_table.component";

const Billing = () => {
  return (
    <div className="DefaultLayout">
      <Header />
      <Card />
    </div>
  );
};

const panes = [
  {
    menuItem: "All Invoices",
    render: () => (
      <Tab.Pane attached={false}>
        <AllInvoicesTable />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Customers",
    render: () => (
      <Tab.Pane attached={false}>
        <CustomerBillingTable />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Merchants",
    render: () => (
      <Tab.Pane attached={false}>
        <MerchantBillingTable />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Pending Payments",
    render: () => (
      <Tab.Pane attached={false}>
        <PendingPayments />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Received Payments Approval",
    render: () => (
      <Tab.Pane attached={false}>
        <ReceivedPaymentsApproval />
      </Tab.Pane>
    ),
  },
];

const Header = () => {
  return (
    <div className="HeaderLayout">
      <BillingHeader />
    </div>
  );
};

const Card = () => {
  return (
    <div className="CardContainer">
      <div>
        <Tab menu={{ borderless: true, pointing: true }} panes={panes} />
      </div>
    </div>
  );
};

export default Billing;

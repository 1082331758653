import React, { useEffect, useState } from "react";
import { Input, Button } from "semantic-ui-react";
import toastr from "toastr";
import { BASE_URL } from "../../../../config";

const CustomerDiscount = () => {
  const [discounts_applicable_amount, setDiscountAmt] = useState(0);
  const [discount_percentage, setDiscountPerc] = useState(0);
  const [customerSettingsID, setSettingsID] = useState("");
  const [noData, setNoData] = useState("");

  useEffect(() => {
    fetchCustomerSettings();
  }, []);

  const fetchCustomerSettings = () => {
    fetch(`${BASE_URL}/get-customer-settings`)
      .then((res) => res.json())
      .then((json) => {
        if (json.customer_settings[0] === undefined) {
          setNoData("true");
          setDiscountAmt(0);
          setDiscountPerc(0);
        } else {
          setNoData("false");
          setSettingsID(json.customer_settings[0]._id);
          setDiscountAmt(json.customer_settings[0].discounts_applicable_amount);
          setDiscountPerc(json.customer_settings[0].discount_percentage);
        }
      });
  };

  const saveCustomerSettings = () => {
    fetch(`${BASE_URL}/create-customer-settings`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        discounts_applicable_amount,
        discount_percentage,
        setting_type: "customer_discounts",
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        fetchCustomerSettings();
        toastr.success("Customer Settings Saved");
      });
  };

  const updateCustomerSettings = () => {
    fetch(
      `${BASE_URL}/update-customer-settings?customer_setting_id=${customerSettingsID}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          discounts_applicable_amount,
          discount_percentage,
          setting_type: "customer_discounts",
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        fetchCustomerSettings();
        toastr.success("Customer Settings Updated Successfuly");
      });
  };

  return (
    <div>
      <div className="TurnoverRow">
        <div>
          <Input
            action={{
              labelPosition: "right",
              icon: "inr",
              content: "Discount applicable only for above",
            }}
            value={discounts_applicable_amount}
            actionPosition="left"
            placeholder="Enter a amount..."
            className="inputMobile"
            onChange={(e) => setDiscountAmt(e.target.value)}
          />
        </div>
        <div style={{ marginLeft: 10, alignSelf: "center" }}>
          <p>per order</p>
        </div>
      </div>
      <div className="TurnoverRow">
        <div>
          <Input
            action={{
              labelPosition: "right",
              icon: "percent",
              content: "Discount percentage",
            }}
            value={discount_percentage}
            actionPosition="left"
            placeholder="Enter a Percentage..."
            className="inputMobile"
            onChange={(e) => setDiscountPerc(e.target.value)}
          />
        </div>
        <div style={{ marginLeft: 10, alignSelf: "center" }}>
          <p>per order</p>
        </div>
      </div>
      {noData === "true" ? (
        <div className="SubmitBtnRow">
          <Button
            className="submitBtn"
            content="Submit"
            onClick={saveCustomerSettings}
          />
        </div>
      ) : (
        <div className="SubmitBtnRow">
          <Button
            className="submitBtn"
            content="Update"
            onClick={updateCustomerSettings}
          />
        </div>
      )}
    </div>
  );
};

export default CustomerDiscount;

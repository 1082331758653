import React, { useState, useEffect } from "react";
import {
  Icon,
  Image,
  Button,
  Input,
  Modal,
  Dropdown,
  TextArea,
} from "semantic-ui-react";
import moment from "moment";
import toastr from "toastr";
import SUCCESS from "../../../images/tickmark.gif";
import { BASE_URL } from "../../../config";

const paymentOptions = [
  { key: 0, text: "IMPS", value: "IMPS" },
  { key: 1, text: "NEFT", value: "NEFT" },
  { key: 2, text: "RTGS", value: "RTGS" },
  { key: 3, text: "DD", value: "DD" },
  { key: 4, text: "CHEQUE", value: "CHEQUE" },
  { key: 5, text: "CASH", value: "CASH" },
];

const chequeOptions = [
  { key: 0, text: "Pending", value: "PENDING" },
];

const AddNewPaymentModal = (props) => {
  const [companies, setCompanies] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [cname, setCname] = useState("");
  const [selectedMerchant, setSelectedMerchant] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [invoice, setInvoice] = useState({});
  const [addPaymentModal, setAddPaymentModal] = useState(props.openModal);
  const [addPaymentStatus, setAddPaymentStatus] = useState(false);
  const [payment_type, setPaymentType] = useState("");
  const [transaction_date, setTransactionDate] = useState("");
  const [transaction_amount, setTransactionAmount] = useState(0);
  const [cheque_no, setChequeNo] = useState(0);
  const [cheque_submit_date, setChequeSubmitDate] = useState("");
  const [cheque_clearing_date, setChequeClearingDate] = useState("");
  const [cheque_remarks, setChequeRemarks] = useState("");
  const [cheque_status, setChequeStatus] = useState("");
  const [remarks, setRemarks] = useState("");
  const [excess_amount, setExcessAmount] = useState("");
  const [excess_amount_status, setExcessAmountStatus] = useState(null);

  useEffect(() => {
    fetchCompanies();
    fetchMerchants();
  }, []);

  const fetchCompanies = () => {
    fetch(`${BASE_URL}/get-companies`)
      .then((res) => res.json())
      .then((json) => {
        setCompanies(json.companies);
      });
  };

  const fetchMerchants = () => {
    fetch(`${BASE_URL}/get-merchants`)
      .then((res) => res.json())
      .then((json) => {
        setMerchants(json.merchants);
      });
  };

  const handleChangeCompany = (value, merchant) => {
    setCname(`${value}_invoices`);
    fetch(`${BASE_URL}/get-invoices?cname=${value}_invoices`)
      .then((res) => res.json())
      .then((json) => {
        const merchantInvoices = json.invoices.filter(
          (invoice) => invoice.customer_phone === merchant.phoneNumber
        );
        const merchantDeliveredInvoices = merchantInvoices.filter(
          (invoice) => invoice.order_status === "DELIVERED"
        );
        const merchantUnpaidInvoices = merchantDeliveredInvoices.filter(
          (invoice) => invoice.payment_status === "unpaid"
        );
        setInvoices(merchantUnpaidInvoices);
      });
  };

  const getBalanceAmount = () => {
    const transaction_total = (
      invoice.transactions !== undefined ? invoice.transactions : []
    ).reduce(
      (sum, transaction, index) => sum + Number(transaction.transaction_amount),
      0
    );

    const balance = invoice.total_amount - transaction_total;
    return balance;
  };

  const addPaymentClose = () => {
    setAddPaymentModal(false);
    props.onModalClose(false);
  };

  const addTransaction = () => {
    if (payment_type === "CHEQUE") {
      if (payment_type === "") {
        toastr.error("Please Select Payment Type");
      } else if (cheque_no === "") {
        toastr.error("Please Enter Cheque Number");
      } else if (cheque_submit_date === "") {
        toastr.error("Please Provide Check Submitted Date");
      } else if (cheque_clearing_date === "") {
        toastr.error("Please Provide Check Clearance Date");
      } else if (cheque_status === "") {
        toastr.error("Please Provide Cheque Status");
      } else if (transaction_date === "") {
        toastr.error("Please Provide valid Date");
      } else if (transaction_amount === "") {
        toastr.error("Please Enter Transaction Amount");
      } else if (invoice.invoice_no === undefined) {
        toastr.error("Please select invoice no to Add Transaction");
      } else if (cname === "") {
        toastr.error("Please select company to proceed!");
      } else {
        fetch(`${BASE_URL}/create-cheque`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            cheque_no,
            cheque_submit_date,
            cheque_clearing_date,
            cheque_amount: transaction_amount,
            cheque_status,
            remarks: cheque_remarks,
            invoice_no: invoice.invoice_no,
            cname,
          }),
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status === 400) {
              toastr.error("Cheque Already Exists");
            }
            props.onModalClose(false);
            toastr.success("New Transaction Updated Succesfully!");
          });
      }
    } else {
      if (payment_type === "") {
        toastr.error("Please Select Payment Type");
      } else if (transaction_date === "") {
        toastr.error("Please Provide valid Date");
      } else if (transaction_amount === "") {
        toastr.error("Please Enter Transaction Amount");
      } else if (invoice.invoice_no === undefined) {
        toastr.error("Please select invoice no to Add Transaction");
      } else {
        const excess_amount_details = {
          invoice_no: invoice.invoice_no,
          transaction_date,
          transaction_type: "payment",
          payment_type,
          excess_amount,
          remarks,
          cname,
        };

        const transaction = {
          transaction_type: "payment",
          payment_type,
          transaction_date,
          transaction_amount,
          remarks,
        };

        if (excess_amount > 0) {
          transaction.excess_amount_details = excess_amount_details;
          transaction.type = "EXCESS_AMOUNT";
        }

        fetch(
          `${BASE_URL}/update-credit-transaction?invoice_no=${invoice.invoice_no}&cname=${cname}`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(transaction),
          }
        )
          .then((res) => res.json())
          .then((json) => {
            props.onModalClose(false);
            toastr.success("New Transaction Updated Succesfully!");
          });
      }
    }
  };

  return (
    <Modal size="small" open={addPaymentModal} onClose={addPaymentClose}>
      <Modal.Header>Add New Payment</Modal.Header>
      <Modal.Content>
        {addPaymentStatus === false && (
          <div>
            <div className="FieldsRow">
              <p className="LabelText">Select Merchant:</p>
              <Dropdown
                placeholder="Select Merchant"
                fluid
                selection
                options={merchants.map((merchant, idx) => ({
                  key: idx,
                  text: merchant.name,
                  value: merchant,
                }))}
                onChange={(event, data) => {
                  setSelectedMerchant(data.value);
                  if (cname.length > 0) {
                    handleChangeCompany(cname.split("_")[0], data.value);
                    setInvoice({});
                  }
                }}
                style={{ width: 200 }}
              />
            </div>
            <div className="FieldsRow">
              <p className="LabelText">Select Company:</p>
              <Dropdown
                placeholder="Select Company"
                fluid
                selection
                options={companies.map((company, idx) => ({
                  key: idx,
                  text: company.company_name,
                  value: company.collection_name,
                }))}
                onChange={(event, data) =>
                  handleChangeCompany(data.value, selectedMerchant)
                }
                style={{ width: 200 }}
              />
            </div>
            <div className="FieldsRow">
              <p className="LabelText">Select Invoice:</p>
              <Dropdown
                placeholder="Select Invoice"
                fluid
                selection
                options={invoices.map((invoice, idx) => ({
                  key: idx,
                  text: invoice.invoice_no,
                  value: invoice,
                }))}
                onChange={(event, data) => setInvoice(data.value)}
                style={{ width: 200 }}
              />
            </div>
            {invoice.customer_name !== undefined && (
              <div>
                <div className="FieldsRow">
                  <p className="LabelText">Customer Name:</p>
                  <p>{invoice.customer_name}</p>
                </div>
                <div className="FieldsRow">
                  <p className="LabelText">Customer Mobile:</p>
                  <p>{invoice.customer_phone}</p>
                </div>
                <div className="FieldsRow">
                  <p className="LabelText">Due Amount:</p>
                  <p>Rs. {getBalanceAmount()}</p>
                </div>
              </div>
            )}

            {getBalanceAmount() !== 0 ? (
              <div>
                <div className="FieldsRow">
                  <p className="LabelText">Payment Type:</p>
                  <Dropdown
                    placeholder="Select Payment Type"
                    value={payment_type}
                    fluid
                    selection
                    options={paymentOptions}
                    onChange={(event, data) => setPaymentType(data.value)}
                    style={{ width: 200 }}
                  />
                </div>

                {payment_type === "CHEQUE" ? (
                  <div>
                    <div className="FieldsRow">
                      <p className="LabelText">Cheque No:</p>
                      <Input
                        placeholder="Cheque No"
                        type="Number"
                        onChange={(e) => setChequeNo(e.target.value)}
                      />
                    </div>
                    <div className="FieldsRow">
                      <p className="LabelText">Cheque Submitted Date:</p>
                      <input
                        type="date"
                        placeholder="Select Date"
                        onChange={(e) => setChequeSubmitDate(e.target.value)}
                      />
                    </div>
                    <div className="FieldsRow">
                      <p className="LabelText">Cheque Clearing Date:</p>
                      <input
                        type="date"
                        placeholder="Select Date"
                        onChange={(e) => setChequeClearingDate(e.target.value)}
                      />
                    </div>
                    <div className="FieldsRow">
                      <p className="LabelText">Cheque Remarks:</p>
                      <TextArea
                        placeholder="Cheque Remarks"
                        onChange={(e) => setChequeRemarks(e.target.value)}
                      />
                    </div>
                    <div className="FieldsRow">
                      <p className="LabelText">Cheque Status:</p>
                      <Dropdown
                        placeholder="Select Status"
                        fluid
                        selection
                        options={chequeOptions}
                        onChange={(event, data) => {
                          setChequeStatus(data.value);
                          setTransactionAmount(
                            data.value === "Pending" ? 0 : transaction_amount
                          );
                        }}
                        style={{ width: 200 }}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="FieldsRow">
                  <p className="LabelText">Date of Payment:</p>
                  <input
                    type="date"
                    placeholder="Transaction ID"
                    value={moment(transaction_date).format("YYYY-MM-DD")}
                    onChange={(e) => setTransactionDate(e.target.value)}
                  />
                </div>
                <div className="FieldsRow">
                  <p className="LabelText">Amount:</p>
                  <Input
                    placeholder="Payment Amount"
                    type="Number"
                    value={transaction_amount}
                    onChange={(e) => setTransactionAmount(e.target.value)}
                  />
                </div>
                <div className="FieldsRow">
                  <p className="LabelText">Remarks:</p>
                  <TextArea
                    placeholder="Remarks"
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </div>
                {payment_type !== "CHEQUE" && (
                  <div>
                    {Number(transaction_amount) > getBalanceAmount() &&
                    excess_amount_status === null ? (
                      <div className="FieldsRow">
                        <p className="LabelText">
                          Entered Amount is higher than balance amount, would
                          you like to convert extra amount to Excess Amount?
                        </p>
                        <div>
                          <Button
                            style={{
                              backgroundColor: "rgb(182, 215, 168)",
                              color: "rgba(0, 0, 0, 0.6)",
                            }}
                            onClick={() => {
                              const pay_amount = getBalanceAmount();
                              const excess_amount =
                                Number(transaction_amount) - getBalanceAmount();
                              setExcessAmountStatus(true);
                              setExcessAmount(excess_amount);
                              setTransactionAmount(pay_amount);
                            }}
                          >
                            yes
                          </Button>
                          <Button
                            style={{ backgroundColor: "#ea9999" }}
                            onClick={() => setExcessAmountStatus(false)}
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {excess_amount_status === false ? (
                          <p style={{ color: "red", textAlign: "center" }}>
                            Entered Amount is higher than balance amount, please
                            enter a valid amount to proceed!
                          </p>
                        ) : excess_amount_status === true ? (
                          <div className="FieldsRow">
                            <p className="LabelText">Excess Amount:</p>
                            <p>{excess_amount}</p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <p style={{ color: "green", textAlign: "center" }}>
                You have already cleared all the bills
              </p>
            )}
          </div>
        )}

        {addPaymentStatus === true && (
          <div style={{ textAlign: "center" }}>
            <Image src={SUCCESS} style={{ width: 250, display: "initial" }} />
            <p>Payment is Successfully Completed</p>
          </div>
        )}
      </Modal.Content>
      {addPaymentStatus === false && (
        <Modal.Actions>
          {Number(transaction_amount) > getBalanceAmount() ? (
            <div>
              <Button
                onClick={addPaymentClose}
                style={{ backgroundColor: "#ea9999" }}
              >
                <Icon name="close" />
                Close
              </Button>
              {payment_type === "CHEQUE" && (
                <Button
                  icon="checkmark"
                  labelPosition="right"
                  content="Add Transaction"
                  style={{
                    backgroundColor: "rgb(182, 215, 168)",
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                  onClick={addTransaction}
                />
              )}
            </div>
          ) : (
            <div>
              <Button
                onClick={addPaymentClose}
                style={{ backgroundColor: "#ea9999" }}
              >
                <Icon name="close" />
                Close
              </Button>
              {Number(transaction_amount) > 0 && (
                <Button
                  icon="checkmark"
                  labelPosition="right"
                  content="Add Transaction"
                  style={{
                    backgroundColor: "rgb(182, 215, 168)",
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                  onClick={addTransaction}
                />
              )}
            </div>
          )}
        </Modal.Actions>
      )}
      {addPaymentStatus === true && (
        <Modal.Actions>
          <Button style={{ backgroundColor: "#ea9999" }}>
            <Icon name="close" />
            Close
          </Button>
        </Modal.Actions>
      )}
    </Modal>
  );
};

export default AddNewPaymentModal;

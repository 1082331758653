import OFFERIMG  from "../../../../../images/ricebag2.png";

export const OFFERS_DATA = [
  {
    sno: "1",
    offerimage: OFFERIMG,
    heading: "Cool Summer Offer",
    description: "Upto 20% offer for all orders",
    couponCode:"COOL20",
  },
  {
    sno: "2",
    offerimage: OFFERIMG,
    heading: "Rainy Offer",
    description: "Upto 20% offer for all orders",
    couponCode:"RAINY20",
  },
  {
    sno: "3",
    offerimage: OFFERIMG,
    heading: "First Orders Offer",
    description: "Upto 30% offer for all orders",
    couponCode:"FIRST30",
  }
];

export const HEADING_DATA = [
  "S.no",
  "Offer Image",
  "Offer Heading",
  "Offer Description",
  "Offer CouponCode",
  "Actions",
];
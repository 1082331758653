import React from "react";
import moment from "moment";
import {
  Icon,
  Image,
  Button,
  Popup,
  Input,
  Table,
  Modal,
  Dropdown,
  Checkbox,
  Form,
  TextArea,
  Select,
} from "semantic-ui-react";
import toastr from "toastr";
import PAID from "../../../images/paid.png";
import UNPAID from "../../../images/Un-paid.png";
import SUCCESS from "../../../images/tickmark.gif";
import { BASE_URL } from "../../../config";

const EditEstimate = (props) => {
  return (
    <div className="invoiceDefaultLayout">
      <Card history={props.history} userData={props.history.location.state} />
    </div>
  );
};

const paymentOptions = [
  { key: 0, text: "IMPS", value: "IMPS" },
  { key: 1, text: "NEFT", value: "NEFT" },
  { key: 2, text: "RTGS", value: "RTGS" },
  { key: 3, text: "DD", value: "DD" },
  { key: 4, text: "CHEQUE", value: "CHEQUE" },
  { key: 5, text: "CASH", value: "CASH" },
];

const chequeOptions = [
  { key: 0, text: "Pending", value: "Pending" },
  { key: 1, text: "Cleared", value: "Cleared" },
];

class Card extends React.Component {
  state = {
    estimate_settings: {},
    estimate_details: {},
    estimate_no: 0,
    estimate_id: "",
    estimate_date: "",
    customer_name: "",
    customer_phone: "",
    customer_address: "",
    transaction_type: "",
    payment_type: "",
    remarks: "",
    transaction_date: new Date(),
    transaction_amount: "",
    payment_status: "",
    cheque_no: "",
    checque_submit_date: "",
    checque_clearing_date: "",
    cheque_remarks: "",
    cheque_status: "",
    balance_amount: "",
    product_name: "",
    product_weight: "",
    product_quantity: "",
    product_price: "",
    customer_notes: "",
    pricing_terms: "",
    delivery_charges: "",
    coupon_code: "",
    coupon_discount: 0,
    gst_amount: "",
    order_total: "",
    cust_notes_status: false,
    pricing_terms_status: false,
    addPaymentModal: false,
    addPaymentStatus: false,
    addProductModal: false,
    productsList: [],
    transactions: [],
    products: [],
    product_pricing: [],
    customers: [],
    companies: [],
    cgst: 0,
    sgst: 0,
    cgstOptions: [],
    sgstOptions: [],
    drivers: [],
    vehicles: [],
    agentDetails: {
      Id: "",
      name: "Driver Name",
      phone: "",
      TruckNumber: "Truck Number",
    },
    excess_amount: "",
    excess_amount_status: null,
    excess_amount_details: {},
  };

  componentDidMount = () => {
    this.setState({ estimate_date: Date.now() });

    const { location } = this.props.history;
    const data = location.pathname.split("/");
    const estimate_no = data[3];

    fetch(`${BASE_URL}/get-estimate?estimateNo=${estimate_no}&cname=${data[2]}`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          estimate_id : json.estimate[0]._id,
          estimate_details: json.estimate[0],
          customer_name: json.estimate[0].customer_name,
          customer_address: json.estimate[0].customer_address,
          productsList: json.estimate[0].products,
          coupon_details:
            json.estimate[0].coupon_details !== null
              ? json.estimate[0].coupon_details
              : {},
          membership_details: json.estimate[0].membership_details,
          transactions:
            json.estimate[0].transactions !== undefined
              ? json.estimate[0].transactions
              : [],
          estimate_settings: json.estimate[0].company_details,
          agent_details: json.estimate[0].agentDetails,
        });
      });

    fetch(`${BASE_URL}/get-estimate-settings`)
      .then((res) => res.json())
      .then((json) => {
        this.getInvoiceNo(json.estimate_settings[0].collection_name);
        this.setState({
          estimate_settings: json.estimate_settings[0],
          customer_notes: json.estimate_settings[0].default_customer_notes,
          pricing_terms: json.estimate_settings[0].default_pricing_terms,
          delivery_charges: json.estimate_settings[0].delivery_charges,
          companies: json.estimate_settings,
          cgstOptions: json.estimate_settings[0].cgst,
          sgstOptions: json.estimate_settings[0].sgst,
          cgst: json.estimate_settings[0].cgst[0],
          sgst: json.estimate_settings[0].sgst[0],
        });
      });
    fetch(`${BASE_URL}/get-merchants`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ customers: json.merchants });
      });
    fetch(`${BASE_URL}/get-drivers`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ drivers: json.drivers });
      });
    fetch(`${BASE_URL}/get-assets`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ vehicles: json.assets });
      });
    fetch(`${BASE_URL}/get-products`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ products: json.products });
      });
  };

  updateEstimate = () => {
    const {
      estimate_id,
      estimate_settings,
      customer_name,
      customer_phone,
      customer_address,
      productsList,
      delivery_charges,
      coupon_code,
      coupon_discount,
      transactions,
      cust_notes_status,
      customer_notes,
      pricing_terms_status,
      pricing_terms,
      cgst,
      sgst,
      excess_amount_details,
      payment_status,
      estimate_details
    } = this.state;

    if (
      transactions.length > 0 &&
      transactions[0].cheque_details !== undefined
    ) {
      if (transactions[0].cheque_details.cheque_status === "pending") {
        payment_status = "unpaid";
      } else {
        payment_status = "fullypaid";
      }
    }

    if (customer_name.length === 0 && customer_address.length === 0) {
      toastr.error("Please enter merchant details to generate estimate!");
    } else if (productsList.length === 0) {
      toastr.error("Please add atleast one product to generate estimate!");
    } else {
      fetch(`${BASE_URL}/update-estimate?estimate_id=${estimate_id}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          estimate_no : estimate_details.estimate_no,
          estimate_date: estimate_details.estimate_date,
          company_details: estimate_settings,
          products: productsList,
          delivery_fee: delivery_charges,
          coupon_details: {
            code: coupon_code,
            discount: coupon_discount,
          },
          gst_amount: this.getGstAmount(productsList),
          cgst,
          sgst,
          total_amount: this.getOrderTotal(productsList),
          customer_name,
          customer_phone,
          customer_address,
          payment_status: this.getBalanceDue(productsList, transactions) === 0 ? 'fullypaid' : 'unpaid',
          transactions,
          customer_notes : cust_notes_status === true ? customer_notes : "",
          pricing_terms : pricing_terms_status === true ? pricing_terms : "",
          excess_amount_details,
          role: estimate_details.customer_role,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          toastr.success("Estimate Updated Successfully!");
          this.props.history.push("/estimates");
        });
    }
  };

  showAddPaymentModal = () => () => this.setState({ addPaymentModal: true });
  addPaymentClose = () => this.setState({ addPaymentModal: false });

  showAddProductModal = () => () => this.setState({ addProductModal: true });
  addProductClose = () => this.setState({ addProductModal: false });

  addTransaction = () => {
    const {
      remarks,
      payment_type,
      transaction_date,
      transaction_amount,
      cheque_no,
      checque_submit_date,
      checque_clearing_date,
      cheque_remarks,
      cheque_status,
      excess_amount,
      estimate_no,
      estimate_settings,
    } = this.state;

    const cheque_details = {
      cheque_no,
      checque_submit_date,
      checque_clearing_date,
      cheque_remarks,
      cheque_status,
    };

    if (payment_type === "CHEQUE") {
      if (excess_amount > 0) {
        const excess_amount_data = {
          estimate_no,
          cname: estimate_settings.collection_name,
          transaction_type: "payment",
          payment_type,
          cheque_details,
          transaction_date,
          excess_amount,
          remarks,
        };
        this.setState({
          excess_amount_details: excess_amount_data,
          transactions: this.state.transactions.concat([
            {
              transaction_type: "payment",
              payment_type,
              cheque_details,
              transaction_date,
              transaction_amount,
              excess_amount,
              remarks,
            },
          ]),
          addPaymentStatus: true,
          payment_status: cheque_status === "Pending" ? "unpaid" : "fullypaid",
        });
      } else {
        this.setState({
          transactions: this.state.transactions.concat([
            {
              transaction_type: "payment",
              payment_type,
              cheque_details,
              transaction_date,
              transaction_amount,
              remarks,
            },
          ]),
          addPaymentStatus: true,
          payment_status: cheque_status === "Pending" ? "unpaid" : "fullypaid",
        });
      }
    } else {
      if (excess_amount > 0) {
        const excess_amount_data = {
          estimate_no,
          cname: estimate_settings.collection_name,
          transaction_type: "payment",
          payment_type,
          transaction_date,
          excess_amount,
          remarks,
        };
        this.setState({
          excess_amount_details: excess_amount_data,
          transactions: this.state.transactions.concat([
            {
              transaction_type: "payment",
              payment_type,
              remarks,
              transaction_date,
              transaction_amount,
              excess_amount,
            },
          ]),
          addPaymentStatus: true,
          payment_status: cheque_status === "Pending" ? "unpaid" : "fullypaid",
        });
      } else {
        this.setState({
          transactions: this.state.transactions.concat([
            {
              transaction_type: "payment",
              payment_type,
              remarks,
              transaction_date,
              transaction_amount,
            },
          ]),
          addPaymentStatus: true,
          payment_status: cheque_status === "Pending" ? "unpaid" : "fullypaid",
        });
      }
    }
  };

  addProduct = () => {
    const {
      product_name,
      product_quantity,
      product_price,
      product_weight,
    } = this.state;
    if (product_name === "") {
      toastr.error("Please Enter Product Name");
    } else if (product_weight === "") {
      toastr.error("Please Enter Product Weight");
    } else if (product_price === undefined || product_price === "") {
      toastr.error("Please Enter Product Price");
    } else if (product_quantity === "") {
      toastr.error("Please Enter Product Quantity");
    } else {
      this.setState({
        productsList: this.state.productsList.concat([
          {
            name: product_name,
            quantity: product_quantity,
            weight: product_weight,
            price: product_price,
          },
        ]),
      });
    }
  };

  removeProduct = (idx) => () => {
    this.setState({
      productsList: this.state.productsList.filter((s, sidx) => idx !== sidx),
    });
  };

  removeTransaction = (idx, amt) => () => {
    this.setState({
      transactions: this.state.transactions.filter((s, sidx) => idx !== sidx),
    });
    if (amt === this.state.excess_amount) {
      this.setState({
        excess_amount: 0,
        excess_amount_status: null,
        excess_amount_details: {},
      });
    }
  };

  getTransactionsBalance = (transactions) => {
    const paymentData = transactions.filter((transaction) =>
      Object.keys(transaction).some(
        (key) => transaction[key].toString().search("payment") !== -1
      )
    );
    const payments = paymentData.reduce(
      (sum, transaction, index) => sum + Number(transaction.transaction_amount),
      0
    );

    const refundData = transactions.filter((transaction) =>
      Object.keys(transaction).some(
        (key) => transaction[key].toString().search("refund") !== -1
      )
    );
    const refunds = refundData.reduce(
      (sum, transaction, index) => sum + Number(transaction.transaction_amount),
      0
    );

    const total_balance = payments - refunds;
    return Number(total_balance);
  };

  getProductsTotal = (products) => {
    const total_value = products.reduce(
      (sum, product, index) => sum + Number(product.quantity * product.price),
      0
    );
    return Number(total_value);
  };

  getCouponDiscount = () => {
    const { coupon_discount, productsList } = this.state;

    const discount =
      (coupon_discount / 100) * this.getProductsTotal(productsList);

    return discount;
  };

  getGstAmount = (products) => {
    const { cgst, sgst } = this.state;
    const gst =
      (this.getProductsTotal(products) * (Number(cgst) + Number(sgst))) / 100;

    return Math.round(gst);
  };

  getBalanceDue = (products, transactions) => {
    const { delivery_charges } = this.state;

    const order_total =
      this.getProductsTotal(products) +
      this.getGstAmount(products) +
      Number(delivery_charges) -
      this.getCouponDiscount();

    const due = order_total - this.getTransactionsBalance(transactions);

    return due;
  };

  getOrderTotal = (products) => {
    const { delivery_charges } = this.state;

    const order_total =
      this.getProductsTotal(products) +
      this.getGstAmount(products) +
      Number(delivery_charges) -
      this.getCouponDiscount();

    return order_total;
  };

  getInvoiceNo = (value) => {
    fetch(`${BASE_URL}/get-estimate-no?cname=${value}`)
      .then((res) => res.json())
      .then((json) => {
        if (json.estimates.length > 0) {
          this.setState({ estimate_no: json.estimates[0].estimate_no + 1 });
        } else {
          this.setState({ estimate_no: 1001 });
        }
      });
  };

  handleChangeCompany = (id) => {
    const data = this.state.companies.filter((company) => company._id === id);
    const company = data[0];

    this.getInvoiceNo(company.collection_name);
    this.setState({
      estimate_settings: company,
    });
  };

  render() {
    const {
      estimate_details,
      estimate_settings,
      transaction_date,
      customer_name,
      customer_address,
      addPaymentModal,
      addPaymentStatus,
      transactions,
      customers,
      products,
      product_pricing,
      productsList,
      customer_notes,
      pricing_terms,
      delivery_charges,
      coupon_code,
      cust_notes_status,
      pricing_terms_status,
      companies,
      payment_type,
      cgst,
      sgst,
      cgstOptions,
      sgstOptions,
      transaction_amount,
      excess_amount,
      excess_amount_status,
      payment_status,
    } = this.state;

    return (
      <div className="invoiceCardContainer">
        <div className="invoicePageTitleRow">
          <div style={{ paddingTop: 10, paddingLeft: 20, display: "flex" }}>
            <Icon
              name="arrow left"
              style={{ paddingTop: 3, cursor: "pointer" }}
              onClick={() => this.props.history.push("/orderrequests")}
            />
            <h3 style={{ marginTop: 0, marginLeft: 10 }}>
              Edit Estimate
            </h3>
          </div>
          <div>
            <Select
              placeholder="Select Company"
              options={companies.map((company) => ({
                key: company._id,
                value: company._id,
                text: company.company_name,
              }))}
              style={{ margin: "auto" }}
              onChange={(event, data) => this.handleChangeCompany(data.value)}
            />
          </div>
          <div>
            <Button
              className="invoiceSetBtn"
              onClick={() => this.props.history.push("/estimate-settings")}
            >
              <Icon name="setting" /> Estimate Settings
            </Button>
          </div>

          <div>
            <Button
              className="generateSaveBtn"
              onClick={() => this.updateEstimate()}
            >
              <Icon name="save" /> Update Estimate
            </Button>
          </div>
        </div>

        <div className="Card">
          <div className="invoiceContainer">
            <div>
              <Image
                src={estimate_settings.estimate_logo}
                style={{ width: 100 }}
              />
            </div>
            <div className="section1">
              <div>
                <p className="section1Text" style={{ fontSize: 22 }}>
                  {estimate_settings.company_name},
                </p>
                <p className="section1Text">{estimate_settings.address_line1}</p>
                <p className="section1Text">
                  {estimate_settings.address_line2}{" "}
                  {estimate_settings.address_line3}
                </p>
                <p className="section1Text">Email: {estimate_settings.email}</p>

                <p className="section1Text" style={{ marginBottom: 30 }}>
                  Contact: +91-{estimate_settings.whatsapp_number}
                </p>
                <p className="section1Text">GSTIN: {estimate_settings.gst_no}</p>

              </div>
              <div>
                {this.getBalanceDue(productsList, transactions) === 0 ? (
                  <div>
                    <Image
                      src={PAID}
                      style={{ width: 150, marginRight: 100 }}
                    />
                  </div>
                ) : (
                  <div>
                    <Image
                      src={UNPAID}
                      style={{ width: 150, marginRight: 100 }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="section2">
              <div>
                <p
                  className="section2Text"
                  style={{ fontSize: 22, fontWeight: "bold" }}
                >
                  Estimate #{estimate_details.estimate_no}{" "}
                </p>
                <p className="section2Text">
                  Estimate Date: {moment(estimate_details.estimate_date).format("DD-MM-YYYY")}
                </p>
              </div>
              <div>
                <p
                  className="section3Text"
                  style={{ fontSize: 17, fontWeight: "bold" }}
                >
                  <Popup
                    on="click"
                    pinned
                    style={{ textAlign: "center" }}
                    trigger={
                      <Icon
                        name="pencil"
                        style={{
                          fontSize: 15,
                          color: "#93c47d",
                          marginLeft: 5,
                        }}
                      />
                    }
                  >
                    <div>
                      <p>Select Merchant:</p>
                      <Dropdown
                        placeholder="Select Merchant"
                        fluid
                        search
                        selection
                        options={customers.map((customer, index) => ({
                          key: index,
                          text: customer.name,
                          value: customer,
                        }))}
                        onChange={(event, data) =>
                          this.setState({
                            customer_name: data.value.name,
                            customer_phone: data.value.phoneNumber,
                            customer_address: data.value.address,
                          })
                        }
                      />
                    </div>
                  </Popup>
                  Estimated To
                </p>
                <p className="section3Text">
                  {customer_name || "Customer Name"}
                </p>
                <p className="section3Text">
                  {customer_address || "Customer Address"}
                </p>
              </div>
            </div>

            <div className="section3"></div>
            <div className="section4">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontSize: 20, fontWeight: "600" }}>Products</p>
                <div>
                  <Popup
                    on="click"
                    pinned
                    position="left center"
                    style={{ textAlign: "center" }}
                    trigger={
                      <Icon
                        name="pencil"
                        style={{
                          fontSize: 15,
                          color: "#93c47d",
                          marginLeft: 5,
                        }}
                      />
                    }
                  >
                    <div>
                      <p>Select Product:</p>
                      <Dropdown
                        placeholder="Select Product"
                        fluid
                        search
                        selection
                        options={products.map((product, index) => ({
                          key: index,
                          text: product.name,
                          value: product,
                        }))}
                        onChange={(event, data) =>
                          this.setState({
                            product_name: data.value.name,
                            product_pricing: data.value.pricing,
                          })
                        }
                      />
                    </div>
                    <div>
                      <p>Select Weight:</p>
                      <Dropdown
                        placeholder="Select Pricing"
                        fluid
                        search
                        selection
                        options={product_pricing.map((pricing, index) => ({
                          key: index,
                          text: `${pricing.weight} Kg`,
                          value: pricing,
                        }))}
                        onChange={(event, data) =>
                          this.setState({
                            product_weight: data.value.weight,
                            product_price: data.value.merchantPrice,
                          })
                        }
                      />
                    </div>
                    <div>
                      <p>Amount:</p>
                      <Input value={this.state.product_price} disabled />
                    </div>
                    <div>
                      <p>Quantity:</p>
                      <Input
                        placeholder="Enter Quantity"
                        onChange={(e) =>
                          this.setState({ product_quantity: e.target.value })
                        }
                      />
                    </div>
                    <div>
                      <Button onClick={this.addProduct}>Submit</Button>
                    </div>
                  </Popup>
                </div>
              </div>
              <div>
                <Table celled unstackable>
                  <Table.Header>
                    <Table.Row textAlign="center">
                      <Table.HeaderCell style={{ backgroundColor: "#b6d7a8" }}>
                        S.no
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: "#b6d7a8" }}>
                        Products
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: "#b6d7a8" }}>
                        Quantity
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: "#b6d7a8" }}>
                        Total
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {productsList.map((product, idx) => (
                      <Table.Row textAlign="center">
                        <Table.Cell textAlign="center">{idx + 1}</Table.Cell>
                        <Table.Cell>
                          {product.name} - {product.weight}Kg
                        </Table.Cell>
                        <Table.Cell>{product.quantity}</Table.Cell>
                        <Table.Cell>
                          Rs. {product.quantity * product.price}
                          <Icon
                            name="times circle"
                            className="cancelRowBtn"
                            color="red"
                            onClick={this.removeProduct(idx)}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ alignSelf: "flex-end" }}>
                    <div style={{ marginBottom: 20 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 17,
                            fontWeight: "600",
                            marginBottom: 10,
                          }}
                        >
                          Customer Notes
                        </p>
                        <Checkbox
                          label="Enable"
                          checked={cust_notes_status}
                          onChange={() =>
                            this.setState({
                              cust_notes_status: !cust_notes_status,
                            })
                          }
                        />
                      </div>

                      <Form>
                        <TextArea
                          placeholder="Thanks for your business."
                          cols="50"
                          maxLength="70"
                          value={customer_notes}
                          onChange={(e) =>
                            this.setState({ customer_notes: e.target.value })
                          }
                        />
                      </Form>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 17,
                            fontWeight: "600",
                            marginBottom: 10,
                          }}
                        >
                          Pricing Terms
                        </p>
                        <Checkbox
                          label="Enable"
                          checked={pricing_terms_status}
                          onChange={() =>
                            this.setState({
                              pricing_terms_status: !pricing_terms_status,
                            })
                          }
                        />
                      </div>

                      <Form>
                        <TextArea
                          placeholder="Thanks for your business."
                          cols="50"
                          maxLength="70"
                          value={pricing_terms}
                          onChange={(e) =>
                            this.setState({ pricing_terms: e.target.value })
                          }
                        />
                      </Form>
                    </div>
                  </div>

                  <div>
                    <Table celled unstackable collapsing>
                      <Table.Body>
                        <Table.Row textAlign="right">
                          <Table.Cell className="tableCellbg">
                            Item Total
                          </Table.Cell>
                          <Table.Cell className="tableCellbg">
                            ₹ {this.getProductsTotal(productsList)}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ color: "#cc0000" }}
                          >
                            <Popup
                              on="click"
                              pinned
                              style={{ textAlign: "center" }}
                              trigger={
                                <Icon
                                  name="pencil"
                                  style={{
                                    fontSize: 15,
                                    color: "#93c47d",
                                    marginLeft: 5,
                                  }}
                                />
                              }
                            >
                              <div>
                                <p style={{ marginBottom: 5 }}>
                                  Edit Coupon Code:
                                </p>
                                <Input
                                  placeholder="Coupon code"
                                  onChange={(e) =>
                                    this.setState({
                                      coupon_code: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div>
                                <p style={{ marginBottom: 5 }}>
                                  Edit Coupon discount:
                                </p>
                                <Input
                                  placeholder="Coupon discount Perc"
                                  onChange={(e) =>
                                    this.setState({
                                      coupon_discount: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </Popup>
                            Coupon Applied({coupon_code || "No Coupon"})
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ color: "#cc0000" }}
                          >
                            -₹ {this.getCouponDiscount() || 0}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            Other Charges
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            ₹ {delivery_charges}
                            <Popup
                              on="click"
                              pinned
                              style={{ textAlign: "center" }}
                              trigger={
                                <Icon
                                  name="pencil"
                                  style={{
                                    fontSize: 15,
                                    color: "#93c47d",
                                    marginLeft: 5,
                                   
                                    right: 85,
                                  }}
                                />
                              }
                            >
                              <div>
                                <p style={{ marginBottom: 5 }}>
                                  Edit Other Charges:
                                </p>
                                <Input
                                  placeholder="Other Charges"
                                  onChange={(e) =>
                                    this.setState({
                                      delivery_charges: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </Popup>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            {cgst}.00% CGST
                            <Popup
                              on="click"
                              pinned
                              style={{ textAlign: "center" }}
                              trigger={
                                <Icon
                                  name="pencil"
                                  style={{
                                    fontSize: 15,
                                    color: "#93c47d",
                                    marginLeft: 5,
                                  }}
                                />
                              }
                            >
                              <div>
                                <p>Select Percentage:</p>
                                <Dropdown
                                  placeholder="Select Percentage"
                                  fluid
                                  search
                                  selection
                                  options={cgstOptions.map((cgst, index) => ({
                                    key: index,
                                    text: cgst,
                                    value: cgst,
                                  }))}
                                  onChange={(event, data) =>
                                    this.setState({
                                      cgst: data.value,
                                    })
                                  }
                                />
                              </div>
                            </Popup>
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            ₹ {this.getGstAmount(productsList) / 2}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            {sgst}.00% SGST
                            <Popup
                              on="click"
                              pinned
                              style={{ textAlign: "center" }}
                              trigger={
                                <Icon
                                  name="pencil"
                                  style={{
                                    fontSize: 15,
                                    color: "#93c47d",
                                    marginLeft: 5,
                                  }}
                                />
                              }
                            >
                              <div>
                                <p>Select Percentage:</p>
                                <Dropdown
                                  placeholder="Select Percentage"
                                  fluid
                                  search
                                  selection
                                  options={sgstOptions.map((sgst, index) => ({
                                    key: index,
                                    text: sgst,
                                    value: sgst,
                                  }))}
                                  onChange={(event, data) =>
                                    this.setState({
                                      sgst: data.value,
                                    })
                                  }
                                />
                              </div>
                            </Popup>
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            ₹ {this.getGstAmount(productsList) / 2}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ color: "#cc0000" }}
                          >
                            Paid Amount
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ color: "#cc0000" }}
                          >
                            -₹ {this.getTransactionsBalance(transactions)}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ color: "#6aa84f" }}
                          >
                            Total Balance Due
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ color: "#6aa84f" }}
                          >
                            ₹ {this.getBalanceDue(productsList, transactions)}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </div>
              </div>
            </div>

            <div className="section5">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontSize: 20, fontWeight: "600" }}>Transactions</p>
                <div>
                  <Button
                    style={{ backgroundColor: "#fce5cd" }}
                    onClick={this.showAddPaymentModal()}
                  >
                    <Icon name="plus" /> Add Transaction{" "}
                  </Button>
                </div>
              </div>
              <div>
                <Table celled unstackable>
                  <Table.Header>
                    <Table.Row textAlign="center">
                      <Table.HeaderCell style={{ backgroundColor: "#b6d7a8" }}>
                        Transaction Date
                      </Table.HeaderCell>

                      <Table.HeaderCell style={{ backgroundColor: "#b6d7a8" }}>
                        Remarks
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: "#b6d7a8" }}>
                        Amount
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {transactions.map((transaction, idx) => (
                      <Table.Row textAlign="center">
                        <Table.Cell>
                          {moment(transaction.transaction_date).format("l")}
                        </Table.Cell>
                        <Table.Cell>{transaction.remarks}</Table.Cell>
                        <Table.Cell>
                          {transaction.transaction_type === "payment" ? (
                            <p>₹ {transaction.transaction_amount} (+)</p>
                          ) : (
                            <p>₹ {transaction.transaction_amount} (-)</p>
                          )}
                          <Icon
                            name="times circle"
                            className="cancelRowBtn"
                            color="red"
                            onClick={this.removeTransaction(
                              idx,
                              transaction.excess_amount
                            )}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                    <Table.Row>
                      <Table.Cell
                        textAlign="right"
                        className="tableCellbg"
                        colSpan="2"
                      >
                        Balance
                      </Table.Cell>
                      <Table.Cell textAlign="center" className="tableCellbg">
                        ₹ {this.getTransactionsBalance(transactions)}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>

            <Modal
              size="small"
              open={addPaymentModal}
              onClose={this.addPaymentClose}
            >
              <Modal.Header>Add Transaction</Modal.Header>
              <Modal.Content>
                {addPaymentStatus === false && (
                  <div>
                    <div className="FieldsRow">
                      <p className="LabelText">Payment Type:</p>
                      <Dropdown
                        placeholder="Select Payment Type"
                        fluid
                        selection
                        options={paymentOptions}
                        onChange={(event, data) =>
                          this.setState({ payment_type: data.value })
                        }
                        style={{ width: 200 }}
                      />
                    </div>

                    {payment_type === "CHEQUE" ? (
                      <div>
                        <div className="FieldsRow">
                          <p className="LabelText">Cheque No:</p>
                          <Input
                            placeholder="Cheque No"
                            type="Number"
                            onChange={(e) =>
                              this.setState({
                                cheque_no: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="FieldsRow">
                          <p className="LabelText">Cheque Submitted Date:</p>
                          <input
                            type="date"
                            placeholder="Select Date"
                            onChange={(e) =>
                              this.setState({
                                checque_submit_date: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="FieldsRow">
                          <p className="LabelText">Cheque Clearing Date:</p>
                          <input
                            type="date"
                            placeholder="Select Date"
                            onChange={(e) =>
                              this.setState({
                                checque_clearing_date: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="FieldsRow">
                          <p className="LabelText">Cheque Remarks:</p>
                          <TextArea
                            placeholder="Cheque Remarks"
                            onChange={(e) =>
                              this.setState({ cheque_remarks: e.target.value })
                            }
                          />
                        </div>
                        <div className="FieldsRow">
                          <p className="LabelText">Cheque Status:</p>
                          <Dropdown
                            placeholder="Select Status"
                            fluid
                            selection
                            options={chequeOptions}
                            onChange={(event, data) =>
                              this.setState({ cheque_status: data.value })
                            }
                            style={{ width: 200 }}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="FieldsRow">
                      <p className="LabelText">Date of Payment:</p>
                      <input
                        type="date"
                        placeholder="Transaction ID"
                        value={moment(transaction_date).format("YYYY-MM-DD")}
                        onChange={(e) =>
                          this.setState({ transaction_date: e.target.value })
                        }
                      />
                    </div>
                    <div className="FieldsRow">
                      <p className="LabelText">Amount:</p>
                      <Input
                        placeholder="Payment Amount"
                        type="Number"
                        value={transaction_amount}
                        onChange={(e) =>
                          this.setState({
                            transaction_amount: e.target.value,
                            excess_amount: 0,
                            excess_amount_status: null,
                          })
                        }
                      />
                    </div>
                    <div className="FieldsRow">
                      <p className="LabelText">Remarks:</p>
                      <TextArea
                        placeholder="Remarks"
                        onChange={(e) =>
                          this.setState({ remarks: e.target.value })
                        }
                      />
                    </div>
                    {Number(transaction_amount) >
                      this.getBalanceDue(productsList, transactions) &&
                      excess_amount_status === null ? (
                      <div className="FieldsRow">
                        <p className="LabelText">
                          Entered Amount is higher than balance amount, would
                          you like to convert extra amount to Excess Amount?
                        </p>
                        <div>
                          <Button
                            style={{
                              backgroundColor: "rgb(182, 215, 168)",
                              color: "rgba(0, 0, 0, 0.6)",
                            }}
                            onClick={() => {
                              const pay_amount = this.getBalanceDue(
                                productsList,
                                transactions
                              );
                              const excess_amount =
                                Number(transaction_amount) -
                                this.getBalanceDue(productsList, transactions);
                              this.setState({
                                excess_amount_status: true,
                                excess_amount: excess_amount,
                                transaction_amount: pay_amount,
                              });
                            }}
                          >
                            yes
                          </Button>
                          <Button
                            style={{ backgroundColor: "#ea9999" }}
                            onClick={() =>
                              this.setState({ excess_amount_status: false })
                            }
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {excess_amount_status === false ? (
                          <p style={{ color: "red", textAlign: "center" }}>
                            Entered Amount is higher than balance amount, please
                            enter a valid amount to proceed!
                          </p>
                        ) : excess_amount_status === true ? (
                          <div className="FieldsRow">
                            <p className="LabelText">Excess Amount:</p>
                            <p>{excess_amount}</p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                )}

                {addPaymentStatus === true && (
                  <div style={{ textAlign: "center" }}>
                    <Image
                      src={SUCCESS}
                      style={{ width: 250, display: "initial" }}
                    />
                    <p>Payment is Successfully Completed</p>
                  </div>
                )}
              </Modal.Content>
              {addPaymentStatus === false && (
                <Modal.Actions>
                  {Number(transaction_amount) >
                    this.getBalanceDue(productsList, transactions) ? (
                    <Button
                      onClick={this.addPaymentClose}
                      style={{ backgroundColor: "#ea9999" }}
                    >
                      <Icon name="close" />
                      Close
                    </Button>
                  ) : (
                    <div>
                      <Button
                        onClick={this.addPaymentClose}
                        style={{ backgroundColor: "#ea9999" }}
                      >
                        <Icon name="close" />
                        Close
                      </Button>
                      {Number(transaction_amount) > 0 && (
                        <Button
                          icon="checkmark"
                          labelPosition="right"
                          content="Add Transaction"
                          style={{
                            backgroundColor: "rgb(182, 215, 168)",
                            color: "rgba(0, 0, 0, 0.6)",
                          }}
                          onClick={this.addTransaction}
                        />
                      )}
                    </div>
                  )}
                </Modal.Actions>
              )}
              {addPaymentStatus === true && (
                <Modal.Actions>
                  <Button
                    onClick={() =>
                      this.setState({
                        transaction_id: "",
                        transaction_type: "",
                        transaction_date: "",
                        transaction_amount: "",
                        addPaymentStatus: false,
                        addPaymentModal: false,
                      })
                    }
                    style={{ backgroundColor: "#ea9999" }}
                  >
                    <Icon name="close" />
                    Close
                  </Button>
                </Modal.Actions>
              )}
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

export default EditEstimate;

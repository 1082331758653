import moment from "moment";

export const getPercentage = (members) => {
  var date = new Date();
  var prevFirstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  var prevLastDay = new Date(date.getFullYear(), date.getMonth(), 0);
  var currFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var currLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const prevMon = members.filter(function (x) {
    return (
      (x.createdAt > moment(prevFirstDay).format()) &
      (x.createdAt < moment(prevLastDay).format())
    );
  });
  const currMon = members.filter(function (x) {
    return (
      (x.createdAt > moment(currFirstDay).format()) &
      (x.createdAt < moment(currLastDay).format())
    );
  });

  var perc = ((prevMon.length - currMon.length) / prevMon.length) * 100;

  if (perc === -Infinity) {
    return -100;
  } else {
    return perc;
  }
};

export const getTurnoverPercentage = (members) => {
  var date = new Date();
  var prevFirstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  var prevLastDay = new Date(date.getFullYear(), date.getMonth(), 0);
  var currFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var currLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const prevMon = members.filter(function (x) {
    return (
      (x.invoice_date > moment(prevFirstDay).format()) &
      (x.invoice_date < moment(prevLastDay).format())
    );
  });
  const currMon = members.filter(function (x) {
    return (
      (x.invoice_date > moment(currFirstDay).format()) &
      (x.invoice_date < moment(currLastDay).format())
    );
  });

  const prevMonTurnover = prevMon.reduce(
    (sum, invoice, index) =>
      sum +
      Number(invoice.total_amount !== undefined ? invoice.total_amount : 0),
    0
  );

  const currMonTurnover = currMon.reduce(
    (sum, invoice, index) =>
      sum +
      Number(invoice.total_amount !== undefined ? invoice.total_amount : 0),
    0
  );

  var perc = getNum(
    ((prevMonTurnover - currMonTurnover) / prevMonTurnover) * 100
  );

  if (perc === -Infinity) {
    return -100;
  } else {
    return Math.round(perc);
  }
};

export const getNum = (val) => {
  if (isNaN(val)) {
    return 0;
  }
  return val;
};

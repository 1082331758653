export const ROLE1_MENU = [
  {
    name: "Dashboard",
    iconName: "dashboard",
    route: "/dashboard",
  },
  {
    name: "Merchants",
    iconName: "box",
    route: "/merchants",
  },
  {
    name: "Order Request",
    iconName: "clipboard list",
    route: "/orderrequests",
  },
  {
    name: "Billing",
    iconName: "file text",
    route: "/billing",
  },
  {
    name: "Statements",
    iconName: "file text",
    route: "/statements",
  },
  {
    name: "Estimates",
    iconName: "file text",
    route: "/estimates",
  },
  {
    name: "Products",
    iconName: "product hunt",
    route: "/products",
  },
  {
    name: "Customers",
    iconName: "user",
    route: "/customers",
  },
];

export const ROLE2_MENU = [
  {
    name: "Dashboard",
    iconName: "dashboard",
    route: "/dashboard",
  },
  {
    name: "Weigh Bridge",
    iconName: "warehouse",
    route: "/weigh-bridge",
  },
  {
    name: "Merchants",
    iconName: "box",
    route: "/merchants",
  },
  {
    name: "Order Request",
    iconName: "clipboard list",
    route: "/orderrequests",
  },
  {
    name: "Billing",
    iconName: "file text",
    route: "/billing",
  },
  {
    name: "Statements",
    iconName: "file text",
    route: "/statements",
  },
  {
    name: "Estimates",
    iconName: "file text",
    route: "/estimates",
  },
  {
    name: "Products",
    iconName: "product hunt",
    route: "/products",
  },
  {
    name: "Customers",
    iconName: "user",
    route: "/customers",
  },
];

export const ROLE3_MENU = [
  {
    name: "Dashboard",
    iconName: "dashboard",
    route: "/dashboard",
  },
  {
    name: "Weigh Bridge",
    iconName: "warehouse",
    route: "/weigh-bridge",
  },
  {
    name: "Billing",
    iconName: "file text",
    route: "/billing",
  },
  {
    name: "Estimates",
    iconName: "file text",
    route: "/estimates",
  },
  {
    name: "Order Request",
    iconName: "clipboard list",
    route: "/orderrequests",
  },
  {
    name: "Customers",
    iconName: "user",
    route: "/customers",
  },
];

export const ROLE4_MENU = [
  {
    name: "Dashboard",
    iconName: "dashboard",
    route: "/dashboard",
  },
  {
    name: "Walk-In Delivery",
    iconName: "warehouse",
    route: "/walk-in-delivery",
  },
  {
    name: "Customers",
    iconName: "user",
    route: "/customers",
  },
];

export const MENU = [
  {
    name: "Dashboard",
    iconName: "dashboard",
    route: "/dashboard",
  },
  {
    name: "Order Request",
    iconName: "clipboard list",
    route: "/orderrequests",
  },
  {
    name: "Walk-In Delivery",
    iconName: "warehouse",
    route: "/walk-in-delivery",
  },
  {
    name: "Weigh Bridge",
    iconName: "warehouse",
    route: "/weigh-bridge",
  },
  {
    name: "Companies",
    iconName: "warehouse",
    route: "/companies",
  },
  {
    name: "Products",
    iconName: "product hunt",
    route: "/products",
  },
  {
    name: "Customers",
    iconName: "user",
    route: "/customers",
  },
  {
    name: "Merchants",
    iconName: "box",
    route: "/merchants",
  },
  {
    name: "Vendors",
    iconName: "clipboard list",
    route: "/vendors",
  },
  {
    name: "Drivers",
    iconName: "drivers license",
    route: "/drivers",
  },
  {
    name: "Marketing Executives",
    iconName: "user secret",
    route: "/marketing-executives",
  },
  // {
  //   name: "Manage Warehouses",
  //   iconName: "warehouse",
  //   route: "/manage-warehouses",
  // },
  {
    name: "Payouts",
    iconName: "money bill alternate outline",
    route: "/payouts",
  },
  {
    name: "Billing",
    iconName: "file text",
    route: "/billing",
  },
  {
    name: "Statements",
    iconName: "file text",
    route: "/statements",
  },
  {
    name: "Cheque Clearance",
    iconName: "file text",
    route: "/cheques",
  },
  {
    name: "Excess Amount",
    iconName: "money",
    route: "/excess-amounts",
  },
  {
    name: "Estimates",
    iconName: "file text",
    route: "/estimates",
  },
  {
    name: "Assets",
    iconName: "gem",
    route: "/assets",
  },
  {
    name: "Reports",
    iconName: "area graph",
    route: "/reports",
  },
  {
    name: "Feedbacks",
    iconName: "discussions",
    route: "/feedbacks",
  },
  // {
  //   name: "Help Desk",
  //   iconName: "headphones",
  //   route: "/helpdesk",
  // },
  {
    name: "Settings",
    iconName: "settings",
    route: "/settings",
  },
  // {
  //   name: "Invoice Settings",
  //   iconName: "settings",
  //   route: "/invoice-settings",
  // },
  // {
  //   name: "Estimate Settings",
  //   iconName: "settings",
  //   route: "/estimate-settings",
  // },
  // {
  //   name: "Diwali Offer Settings",
  //   iconName: "qrcode",
  //   route: "/spinuniquecodes",
  // },
];

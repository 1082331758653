import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
  SET_CURRENT_USER,
  CLEAR_CURRENT_USER,
  GET_ERRORS,
  CLEAR_ERRORS,
} from "./types";
import { BASE_URL } from "../config";
import toastr from "toastr";

export const loginUser = (userData, history) => (dispatch) => {
  fetch(`${BASE_URL}/login`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  })
    .then((res) => res.json())
    .then((res) => {
      

      toastr.success("Welcome to KK Rice Mills SuperAdmin");
      // save to localStorage
      const { token } = res;
      // set token to localStorage
      localStorage.setItem("jwtToken", token);
      // set token to auth header
      setAuthToken(token);
      // decode token to get user data
      const decoded = jwt_decode(token);
      //set current user
      dispatch(setCurrentUser(decoded));
      // redirect user
      history.push("/dashboard");
      // clear errors
      dispatch({
        type: CLEAR_ERRORS,
        payload: {},
      });
    })
    .catch((err) =>{
      toastr.error("Something Went wrong please try again");

      
      dispatch({
        type: GET_ERRORS,
        payload: err,
      })
    }
     
    );
};

// set token of logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// log user out
export const logoutUser = () => (dispatch) => {
  // remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Clear the current user
  dispatch({
    type: CLEAR_CURRENT_USER,
    payload: {},
  });
};

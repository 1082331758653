import React, { useState, useEffect } from "react";
import { Icon, Image, Label, Grid, Responsive } from "semantic-ui-react";
import PROFILE_PIC from "../../../../images/pic.png";
import CustomerProfileDetails from "./customer-profile-details.component";
import { BASE_URL } from "../../../../config";
import "./customer-profile.css";

const CustomerProfilePage = (props) => {
  return (
    <div className="DefaultLayout">
      <Header />
      <Card props={props.history} />
    </div>
  );
};

const Header = (props) => {
  return (
    <div className="HeaderLayout">
      <h3 style={{ paddingLeft: 30 }}>Customer Profile</h3>
    </div>
  );
};

const Card = (props) => {
  const { location } = props.props;
  const data = location.pathname.split("/");
  const customer = data[2];

  const [customerData, setCustomerData] = useState({});

  useEffect(() => {
    fetch(`${BASE_URL}/get-customer-by-id?phoneNumber=${customer}`)
      .then((res) => res.json())
      .then((json) => {
        setCustomerData(json.user);
      });
  }, []);

  return (
    <div>
      <div className="CardContainer" style={{ backgroundColor: "#f8ffff" }}>
        <div style={{ margin: 30 }}>
          <Grid>
            <Grid.Column width={4}>
              <Responsive minWidth={767}>
                <Image src={PROFILE_PIC} inline circular size="small" />
              </Responsive>
            </Grid.Column>

            <Responsive maxWidth={767}>
              <Grid.Column>
                <Image src={PROFILE_PIC} inline circular size="small" />
              </Grid.Column>
            </Responsive>

            <Grid.Column width={8}>
              <Responsive minWidth={767}>
                <h2>{customerData.name}</h2>
                <div style={{ display: "flex" }}>
                  <Label as="a" basic color="teal">
                    {customerData.role}
                  </Label>
                </div>

                <div>
                  <Label style={{ marginTop: 20 }}>
                    <Icon name="phone" color="green" /> {customerData.phoneNumber}
                  </Label>
                  <Label style={{ marginTop: 20 }}>
                    <Icon name="map pin" color="red" /> {customerData.address}
                  </Label>
                </div>
              </Responsive>
            </Grid.Column>

            <Responsive maxWidth={767}>
              <Grid.Column>
                <h2>{customerData.name}</h2>
                <div style={{ display: "flex" }}>
                  <Label as="a" basic color="teal">
                  {customerData.role}
                  </Label>
                </div>

                <div style={{ marginTop: 20 }}>
                  <Label>
                    <Icon name="phone" color="green" /> {customerData.phoneNumber}
                  </Label>
                  <Label>
                    <Icon name="map pin" color="red" /> {customerData.address}
                  </Label>
                </div>
              </Grid.Column>
            </Responsive>
          </Grid>
        </div>
      </div>
      <div
        className="CardContainer"
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        <CustomerProfileDetails customerDetails={customerData}/>
      </div>
    </div>
  );
};

export default CustomerProfilePage;

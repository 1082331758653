import React, { useState, useEffect } from "react";
import { Card, Statistic } from "semantic-ui-react";
import { getPercentage } from "./payouts.api";
import { BASE_URL } from "../../../config";
import "./payouts.css";

const PayoutsHeader = () => {
  const [drivers, setDrivers] = useState([]);
  const [driverPerc, setDriverPerc] = useState(0);

  useEffect(() => {
    fetch(`${BASE_URL}/get-drivers`)
      .then((res) => res.json())
      .then((json) => {
        const percentage = getPercentage(json.drivers);
        setDriverPerc(percentage);
        setDrivers(json.drivers);
      });
  }, []);

  const getPayoutAmount = () => {
    const datas = drivers.map((driver) =>
      driver.payouts.reduce(
        (sum, payout, index) => sum + payout.totalEarnings,
        0
      )
    );

    const value = datas.reduce((sum, data, index) => sum + data, 0);

    const total_payout = value.toLocaleString("en-IN");

    return total_payout;
  };
  return (
    <div style={{ marginTop: 20 }}>
      <Card.Group>
        <Card className="AllPayoutsCard">
          <Card.Content>
            <div className="PayoutCardContainer">
              <div>
                <Card.Header className="DriversPayoutTitle">
                  Drivers Payout
                </Card.Header>
                {/* <span style={{ color: "#21ba45", fontWeight: 600 }}>+25%</span>
                <span style={{ color: "#00000066", marginLeft: 5 }}>
                  this month
                </span> */}
              </div>

              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="tiny">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      ₹{getPayoutAmount()}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
        <Card className="VendorsPayoutCard">
          <Card.Content>
            <div className="PayoutCardContainer">
              <div>
                <Card.Header className="VendorsPayoutTitle">
                  Vendors Payouts
                </Card.Header>
                <span style={{ color: "#21ba45", fontWeight: 600 }}>+50%</span>
                <span style={{ color: "#00000066", marginLeft: 5 }}>
                  this month
                </span>
              </div>
              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="tiny">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      ₹10,000
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
        <Card className="ExecutivePayoutCard">
          <Card.Content>
            <div className="PayoutCardContainer">
              <div>
                <Card.Header className="ExecutivePayoutTitle">
                  Executives Payouts
                </Card.Header>
                <span style={{ color: "#db2828", fontWeight: 500 }}>-5% </span>
                <span style={{ color: "#00000066", marginLeft: 5 }}>
                  this month
                </span>
              </div>
              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="tiny">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      ₹8,000
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
      </Card.Group>
    </div>
  );
};

export default PayoutsHeader;

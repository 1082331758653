import React, { useEffect, useState } from "react";
import { Icon, Modal, Button, Table, Input, Image } from "semantic-ui-react";
import toastr from "toastr";
import { HEADING_DATA } from "./vendortypes-table.constants";
import _ from "lodash";
import JOE_PIC from "../../../../images/joe.jpg";
import { BASE_URL } from "../../../../config";

const VendorTypesTable = (props) => {
  const [vendor_type, setVendorType] = useState("");
  const [vendor_firm, setVendorFirm] = useState("");
  const [selectedVendorType, setVendorTypeID] = useState("");
  const [size, setSize] = useState("");
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [vendorTypes, setVendorTypes] = useState([]);
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);

  useEffect(() => {
    fetchVendorTypes();
  }, []);

  const fetchVendorTypes = () => {
    fetch(`${BASE_URL}/get-vendor-types`)
      .then((res) => res.json())
      .then((json) => {
        setVendorTypes(json.vendor_types);
      });
  };

  const handleSort = (clickedColumn) => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setVendorTypes(_.sortBy(vendorTypes, [clickedColumn]));
      setDirection("ascending");

      return;
    }

    setVendorTypes(vendorTypes.reverse());
    setDirection(direction === "ascending" ? "descending" : "ascending");
  };

  const saveVendorType = () => {
    if ((vendor_type, vendor_firm)) {
      fetch(`${BASE_URL}/create-vendor-type`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          vendor_type,
          vendor_firm,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          toastr.success('Vendor Type Saved');
          window.location.reload(true);
          close();
        });
    } else {
      toastr.error("Please fill all the fields!");
    }
  };


  const editVendorType = (size, type) => () => {
    setSize(size);
    setOpen(true);
    setEdit(true);
    setVendorTypeID(type._id);
    setVendorType(type.vendor_type);
    setVendorFirm(type.vendor_firm);
  };

  const handleUpdate = () => {
    fetch(
      `${BASE_URL}/update-vendor-type?vendor_type_id=${selectedVendorType}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          vendor_type,
          vendor_firm,
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        toastr.success('Vendor Type Updated Succesfully');
        fetchVendorTypes();
        close();
      });
  };

  const deleteVendorType = (size, ID) => () => {
    setSize(size);
    setOpenDeleteModal(true);
    setVendorTypeID(ID);
  };

  const handleDelete = () => {
    fetch(
      `${BASE_URL}/delete-vendor-type?vendor_type_id=${selectedVendorType}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => res.json())
      .then((json) => {
        fetchVendorTypes();
        close();
      });
  };

  const close = () => {
    setOpen(false);
    setOpenModal(false);
    setOpenDeleteModal(false);
  };

  return (
    <div className="TabContainer">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: 20,
        }}
      >
        <h3>Vendor Types</h3>
        <Button
          style={{ fontSize: 13, backgroundColor: "#fce5cd" }}
          onClick={() => setOpenModal(true)}
        >
          <Icon name="plus" />
          Create
        </Button>
      </div>
      <Table
        sortable
        unstackable
        celled
        basic="very"
        className="CustomTableLayout"
      >
        <Table.Header>
          <Table.Row>
            {HEADING_DATA.map((heading) => (
              <Table.HeaderCell
                sorted={column === heading ? direction : null}
                onClick={handleSort(heading)}
              >
                {<span>{heading}</span>}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {vendorTypes.map((types, index) => (
            <Table.Row>
              <Table.Cell>{index + 1}</Table.Cell>
              <Table.Cell>{types.vendor_type}</Table.Cell>
              <Table.Cell>{types.vendor_firm}</Table.Cell>
              <Table.Cell>
                <Button
                  style={{ fontSize: 13, backgroundColor: "#f3f3f3" }}
                  onClick={editVendorType("tiny", types)}
                >
                  <Icon name="edit" />
                  Edit
                </Button>

                <Button
                  style={{ fontSize: 13, backgroundColor: "#f4cccc" }}
                  onClick={deleteVendorType("tiny", types._id)}
                >
                  <Icon name="trash" /> Delete
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <div>
        <Modal size="tiny" open={openModal} onClose={close}>
          <Modal.Header>
            <Image
              src={JOE_PIC}
              inline
              circular
              size="mini"
              style={{ marginRight: 20 }}
            />
            <span>Add Vendor Type</span>
          </Modal.Header>
          <Modal.Content>
            <div className="customerFormRow">
              <p className="customerFormTitle">Vendor Type:</p>
              <Input
                placeholder="Enter vendor type"
                style={{ width: "50%" }}
                maxLength="20"
                onChange={(evt) => setVendorType(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {vendor_type.length}/20
              </p>
            </div>

            <div className="customerFormRow">
              <p className="customerFormTitle">Vendor Firm:</p>
              <Input
                placeholder="Enter vendor firm"
                style={{ width: "50%" }}
                maxLength="20"
                onChange={(evt) => setVendorFirm(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {vendor_firm.length}/20
              </p>
            </div>
          </Modal.Content>
          <Modal.Actions style={{ textAlign: "center" }}>
            <Button className="addCustomerCancelBtn" onClick={close}>
              Cancel
            </Button>
            <Button
              className="addCustomersubmitBtn"
              onClick={() => saveVendorType(props.history)}
              content="Submit"
            />
          </Modal.Actions>
        </Modal>
      </div>
      {/* {edit === true ? ( */}
        <Modal size={size} open={open} onClose={close}>
          <Modal.Header>Edit Vendor Type </Modal.Header>
          <Modal.Content>
            <div className="customerFormRow">
              <p className="customerFormTitle">Vendor Type:</p>
              <Input
                value={vendor_type}
                style={{ width: "50%" }}
                maxLength="20"
                onChange={(evt) => setVendorType(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {vendor_type.length}/20
              </p>
            </div>

            <div className="customerFormRow">
              <p className="customerFormTitle">Vendor Firm:</p>
              <Input
                value={vendor_firm}
                style={{ width: "50%" }}
                maxLength="20"
                onChange={(evt) => setVendorFirm(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {vendor_firm.length}/20
              </p>
            </div>
          </Modal.Content>
          <Modal.Actions style={{ textAlign: "center" }}>
            <Button className="addCustomerCancelBtn" onClick={close}>
              Cancel
            </Button>
            <Button
              className="addCustomersubmitBtn"
              onClick={() => handleUpdate()}
              content="Update"
            />
          </Modal.Actions>
        </Modal>
      {/* ) : ( */}
        <Modal size={size} open={openDeleteModal} onClose={close}>
          <Modal.Header>Delete Vendor Type</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete vendor type ?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button className="addCustomerCancelBtn" onClick={close}>
              No
            </Button>
            <Button
              className="addCustomersubmitBtn"
              icon="checkmark"
              labelPosition="right"
              content="Yes"
              onClick={() => handleDelete()}
            />
          </Modal.Actions>
        </Modal>
      {/* )} */}
    </div>
  );
};

export default VendorTypesTable;

import React, { useEffect, useState } from "react";
import {
  Table,
  Label,
  Icon,
  Checkbox,
  Button,
  Input,
  Select,
} from "semantic-ui-react";
import toastr from "toastr";
import { HEADING_DATA } from "./customerbilling.constant";
import { BASE_URL } from "../../../config";
import TableExport from "tableexport";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { TailSpin } from "react-loader-spinner";
const moment = extendMoment(originalMoment);

const AllInvoicesTable = () => {
  const today = moment();
  const [invoices, setInvoices] = useState([]);
  const [allInVoices, setAllInvoices] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState(
    moment.range(today.clone().subtract(7, "days"), today.clone())
  );
  const [companies, setCompanies] = useState([]);
  const [collectionName, setCollectionName] = useState({});
  const [searchString, setSearchString] = useState("paid");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(`${BASE_URL}/get-companies`)
      .then((res) => res.json())
      .then((json) => {
        if (json.companies.length > 0) {
          setCompanies(json.companies);
          setCollectionName(`${json.companies[0].collection_name}_invoices`);
          fetch(
            `${BASE_URL}/get-invoices?cname=${`${json.companies[0].collection_name}_invoices`}`
          )
            .then((res) => res.json())
            .then((json) => {
              setInvoices(json.invoices);
              setAllInvoices(json.invoices);
              setLoading(false);
            });
        }
      });
  }, []);

  const onSelect = (value, states) => {
    const invoices_data = allInVoices.filter(
      (invoice) => invoice.payment_status.toString().indexOf("paid") !== -1
    );

    const start_date = new Date(value.start);
    const end_date = new Date(value.end);

    let resultProductData = invoices_data.filter((each) => {
      let date = new Date(each.invoice_date);
      return date >= start_date && date <= end_date;
    });

    setOpen(!isOpen);
    setInvoices(resultProductData);
    setValue(value);
  };

  const onToggle = () => {
    setOpen(!isOpen);
  };

  const invoices_data = invoices.filter(
    (invoice) => invoice.payment_status.toString().indexOf("paid") !== -1
  );

  const invoices_table = invoices_data.filter((order) =>
    Object.keys(order).some(
      (key) => order[key].toString().search(searchString) !== -1
    )
  );

  const handleChangeCompany = (id) => {
    const data = companies.filter((company) => company._id === id);
    const company = data[0];
    setCollectionName(`${company.collection_name}_invoices`);
    fetch(`${BASE_URL}/get-invoices?cname=${company.collection_name}_invoices`)
      .then((res) => res.json())
      .then((json) => {
        setInvoices(json.invoices);
      });
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "30rem",
          }}
        >
          <TailSpin ariaLabel="loading-indicator" />
        </div>
      ) : (
        <div>
          <div className="BillingTitleRow">
            <div style={{ marginTop: 10 }}>
              <Button
                icon
                labelPosition="right"
                style={{
                  backgroundColor: "#d9ead3",
                  color: "#000",
                  marginBottom: 15,
                }}
                onClick={() => {
                  toastr.success("Exported Buttons Enabled Successfully");
                  const table = new TableExport(
                    document.getElementById("all-invoices"),
                    {
                      formats: ["csv"],
                      exportButtons: true,
                      position: "top",
                    }
                  );
                  const link = document.createElement("a");
                  link.setAttribute(
                    "download",
                    table.getExportData()["all-invoices"].csv.filename
                  );
                  link.click();
                }}
              >
                Export
                <Icon name="share square icon" />
              </Button>
            </div>
            <div style={{ marginTop: 10 }}>
              <Select
                placeholder={
                  companies.length > 0
                    ? companies[0].company_name
                    : "Select Company"
                }
                options={companies.map((company) => ({
                  key: company._id,
                  value: company._id,
                  text: company.company_name,
                }))}
                style={{ margin: "auto" }}
                onChange={(event, data) => handleChangeCompany(data.value)}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              Select Range:{" "}
              <input
                type="text"
                onClick={onToggle}
                style={{ width: 170 }}
                value={`${value.start.format(
                  "DD-MM-YYYY"
                )} - ${value.end.format("DD-MM-YYYY")}`}
              />
              {isOpen && (
                <DateRangePicker
                  value={value}
                  onSelect={onSelect}
                  singleDateRange={true}
                />
              )}
            </div>
            {/* <div style={{ marginTop: 10 }}>
          <Button.Group size="tiny">
            <Button>Open</Button>
            <Button.Or />
            <Button>Cancel</Button>
            <Button.Or />
            <Button>Download</Button>
          </Button.Group>
        </div> */}
            <div style={{ marginTop: 10 }}>
              <Input
                placeholder="Quick Search..."
                icon="search"
                style={{ marginRight: 10 }}
                onChange={(e) => setSearchString(e.target.value)}
              />
            </div>
          </div>
          <Table
            sortable
            unstackable
            celled
            basic="very"
            className="CustomTableLayout"
            id="all-invoices"
          >
            <Table.Header>
              <Table.Row>
                {HEADING_DATA.map((heading) => (
                  <Table.HeaderCell>
                    {heading === "Checkbox" ? (
                      <Checkbox />
                    ) : (
                      <span>{heading}</span>
                    )}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {invoices_table.map((invoice, index) => (
                <Table.Row>
                  {/* <Table.cell>r</Table.cell> */}
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>#{invoice.order_id}</Table.Cell>
                  <Table.Cell>{invoice.customer_name}</Table.Cell>
                  <Table.Cell>INR {invoice.total_amount}</Table.Cell>
                  <Table.Cell>{invoice.invoice_no}</Table.Cell>
                  <Table.Cell>
                    {invoice.order_status !== undefined
                      ? invoice.order_status
                      : "-"}
                  </Table.Cell>
                  <Table.Cell>
                    {invoice.payment_details !== undefined
                      ? invoice.payment_details.payment_type
                      : "cash"}
                  </Table.Cell>
                  <Table.Cell>
                    <Label color="grey">
                      <a
                        onClick={() =>
                          window.open(
                            `/#/edit-invoice/${invoice.company_details.collection_name}_invoices/${invoice.invoice_no}`,
                            "_blank"
                          )
                        }
                      >
                        edit
                      </a>
                    </Label>
                    <Label color="grey">
                      <a
                        onClick={() =>
                          window.open(
                            `/#/view-invoice/${invoice.company_details.collection_name}_invoices/${invoice.invoice_no}`,
                            "_blank"
                          )
                        }
                      >
                        view
                      </a>
                    </Label>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </>
  );
};

export default AllInvoicesTable;

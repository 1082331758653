import React from 'react'

function AccessDenied() {
    return (
        <div>
            <h1 style={{ color: 'red', textAlign: 'center'}}>Access Denied</h1>
        </div>
    )
}

export default AccessDenied;

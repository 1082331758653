import React, { useState } from "react";
import {
  Select,
  Icon,
  Input,
  Button,
  Modal,
  TextArea,
  Form,
  Image,
  Grid,
  Responsive,Tab
} from "semantic-ui-react";
import toastr from "toastr";
import UniquecodeTab from '../../../commons/unicodetab/uniquecodetab.component';
import SmsLeadsNotifications from './smsleadsnotification.component';
import EmailLeadsNotifications from './emailleadsnotifications.component';

const CashbackNotificationsComponent = (props) => {
  return (
    <div className="DefaultLayout">
      <Header history={props.history} />
      <Card history={props.history} />
    </div>
  );
};

const Header = (props) => {
  return (
    <div className="HeaderLayout">
      <div className="addProductRow">
      <h3>Diwali Offer Settings </h3>
        {/* <AddProductModal /> */}
      </div>
      <UniquecodeTab history={props.history} />
    </div>
  );
};

const ShippingNotificationSettingsPanes = [
    {
      menuItem: 'SMS Notifications',
      render: () => (
        <Tab.Pane attached={false}>
         <div>
            <SmsLeadsNotifications />
          </div>
         
        </Tab.Pane>
      ),
    },
    // {
    //   menuItem: 'Email Notifications',
    //   render: () => (
    //     <Tab.Pane attached={false}>
    //        <EmailLeadsNotifications />
    //     </Tab.Pane>
    //   ),
    // },
    
  ];
const Card = (props) => {
 

  return (
    <div className="CardContainer">
      <div className='CardContainer'>
      {/* <h3>Notification Settings</h3> */}
      <Tab
        menu={{ borderless: true, pointing: true }}
        panes={ShippingNotificationSettingsPanes}
      />
    </div>
    </div>
  );
};

export default CashbackNotificationsComponent;

import moment from "moment";

export const getPercentage = (members) => {
  var date = new Date();
  var prevFirstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  var prevLastDay = new Date(date.getFullYear(), date.getMonth(), 0);
  var currFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var currLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const prevMon = members.filter(function (x) {
    return (
      (x.createdAt > moment(prevFirstDay).format()) &
      (x.createdAt < moment(prevLastDay).format())
    );
  });
  const currMon = members.filter(function (x) {
    return (
      (x.createdAt > moment(currFirstDay).format()) &
      (x.createdAt < moment(currLastDay).format())
    );
  });

  var perc = ((prevMon.length - currMon.length) / prevMon.length) * 100;

  if (perc === -Infinity) {
    return -100;
  } else {
    return perc;
  }
};
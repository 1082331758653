import React from 'react';
import SettingsTabBarMenu from '../../../commons/settingstabbar/settingstabbar.component';
import { Tab } from 'semantic-ui-react';
import MerchantLevels from './merchantlevels.component';
import MerchantAnnouncement from './merchantannouncement.component';
import MerchantOffers from './merchantoffers.component';
import MerchantPaymentRemainder from './merchant-payment-remainder.component';
import MerchantCouponCode from './merchantcouponcode/merchant-couponcode';
import MerchantOffersComponent from './merchantoffers/merchant-offers';
import '../settings.css';

const MerchantSettings = (props) => {
  return (
    <div className='DefaultLayout'>
      <Header history={props.history} />
      <Card />
    </div>
  );
};

const merchantSettingsPanes = [
  {
    menuItem: 'Merchants Levels',
    render: () => (
      <Tab.Pane attached={false}>
        <MerchantLevels />
      </Tab.Pane>
    ),
  },
  // {
  //   menuItem: 'Announcements',
  //   render: () => (
  //     <Tab.Pane attached={false}>
  //       <MerchantAnnouncement />
  //     </Tab.Pane>
  //   ),
  // },
  // {
  //   menuItem: 'Offers',
  //   render: () => (
  //     <Tab.Pane attached={false}>
  //       <MerchantOffers />
  //     </Tab.Pane>
  //   ),
  // },
  // {
  //   menuItem: 'Pending Payment Remainder',
  //   render: () => (
  //     <Tab.Pane attached={false}>
  //       <MerchantPaymentRemainder />
  //     </Tab.Pane>
  //   ),
  // },
  {
    menuItem: 'Coupon Code',
    render: () => (
      <Tab.Pane attached={false}>
        <MerchantCouponCode />
      </Tab.Pane>
    ),
  },
  // {
  //   menuItem: 'Offers',
  //   render: () => (
  //     <Tab.Pane attached={false}>
  //       <MerchantOffersComponent />
  //     </Tab.Pane>
  //   ),
  // },
];

const Header = (props) => {
  return (
    <div className='HeaderLayout'>
      <h3>Settings</h3>
      <SettingsTabBarMenu history={props.history} />
    </div>
  );
};

const Card = () => {
  return (
    <div className='CardContainer'>
      <h3>Merchant Settings</h3>
      <Tab
        menu={{ borderless: true, pointing: true }}
        panes={merchantSettingsPanes}
      />
    </div>
  );
};
export default MerchantSettings;

import React, { useEffect, useState } from "react";
import {
  Table,
  Label,
  Checkbox,
  Button,
  Image,
  Select,
  Icon,
  Modal,
} from "semantic-ui-react";
import _ from "lodash";
import toastr from "toastr";
import moment from "moment";
import TableExport from "tableexport";
import CHEQUES_CLEARED_IMG from "../../../images/All_Cheques_cleared.png";
import { HEADING_DATA } from "./excessAmount.constant";
import { BASE_URL } from "../../../config";

const ExcessAmountsTable = (props) => {
  const [companies, setCompanies] = useState([]);
  const [excessAmounts, setExcessAmounts] = useState([]);
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);
  const [selectedExcessAmount, setSelectedExcessAmount] = useState({});
  const [searchString, setSearchString] = useState("");
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = () => {
    fetch(`${BASE_URL}/get-companies`)
      .then((res) => res.json())
      .then((json) => {
        if(json.companies.length > 0){
          setCompanies(json.companies);
          fetch(
            `${BASE_URL}/get-excess-amounts?cname=${json.companies[0].collection_name}_invoices`
          )
            .then((res) => res.json())
            .then((json) => {
              setExcessAmounts(json.excess_amounts);
            });
        }
      });
  };

  const handleChangeCompany = (id) => {
    const data = companies.filter((company) => company._id === id);
    const company = data[0];
    fetch(`${BASE_URL}/get-excess-amounts?cname=${company.collection_name}_invoices`)
      .then((res) => res.json())
      .then((json) => {
        setExcessAmounts(json.excess_amounts);
      });
  };

  const handleSort = (clickedColumn) => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setExcessAmounts(_.sortBy(excessAmounts, [clickedColumn]));
      setDirection("ascending");

      return;
    }

    setExcessAmounts(excessAmounts.reverse());
    setDirection(direction === "ascending" ? "descending" : "ascending");
  };

  const Close = () => {
    setOpenModal(false);
  };

  const handleOpen = (excessAmount) => {
    setSelectedExcessAmount(excessAmount);
    setOpenModal(true);
  };

  const handleDelete = () => {
    fetch(`${BASE_URL}/delete-excess-amount?excessAmountID=${selectedExcessAmount._id}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((json) => {
        fetchCompanies();
        Close();
      });
  };

  const excess_amounts_table = excessAmounts.filter((excessAmount) =>
    Object.keys(excessAmount).some(
      (key) => excessAmount[key].toString().search(searchString) !== -1
    )
  );

  return (
    <div>
      <div className="PageTitleRow">
        <div style={{ marginTop: 10 }}>
          <Select
            placeholder={
              companies.length > 0
                ? companies[0].company_name
                : "Select Company"
            }
            options={companies.map((company) => ({
              key: company._id,
              value: company._id,
              text: company.company_name,
            }))}
            style={{ margin: "auto" }}
            onChange={(event, data) => handleChangeCompany(data.value)}
          />
        </div>

        <div style={{ marginTop: 10 }}>
          <Button
            icon
            labelPosition="right"
            style={{
              backgroundColor: "#d9ead3",
              color: "#000",
              marginBottom: 15,
            }}
            onClick={() => {
              toastr.success("Exported Buttons Enabled Successfully");
              const table = new TableExport(
                document.getElementById("excess-amounts-table"),
                {
                  formats: ["csv"],
                  exportButtons: true,
                  position: "top",
                }
              );
              const link = document.createElement("a");
              link.setAttribute(
                "download",
                table.getExportData()["excess-amounts-table"].csv.filename
              );
              link.click();
            }}
          >
            Export
            <Icon name="share square icon" />
          </Button>
        </div>
      </div>

      {excess_amounts_table.length > 0 ? (
        <Table
          sortable
          unstackable
          celled
          basic="very"
          className="CustomTableLayout"
          id="excess-amounts-table"
        >
          <Table.Header>
            <Table.Row>
              {HEADING_DATA.map((heading) => (
                <Table.HeaderCell
                  sorted={column === heading ? direction : null}
                  onClick={handleSort(heading)}
                >
                  {heading === "Checkbox" ? (
                    <Checkbox />
                  ) : (
                    <span>{heading}</span>
                  )}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {excess_amounts_table.map((excessAmount, index) => (
              <Table.Row>
                <Table.Cell>{index + 1} </Table.Cell>
                <Table.Cell> # {excessAmount.invoice_no} </Table.Cell>
                <Table.Cell>
                  {moment(excessAmount.transaction_date).format("DD-MM-YYYY")}
                </Table.Cell>
                <Table.Cell>{excessAmount.payment_type}</Table.Cell>
                <Table.Cell>₹ {excessAmount.excess_amount}</Table.Cell>
                <Table.Cell>{excessAmount.remarks}</Table.Cell>
                <Table.Cell>
                  <Button
                    style={{ fontSize: 13 }}
                    color="red"
                    onClick={() => handleOpen(excessAmount)}
                  >
                    Delete
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <div>
          {/* <Image
            src={CHEQUES_CLEARED_IMG}
            style={{ width: 300, marginLeft: 700 }}
          /> */}
        </div>
      )}

      <Modal size="tiny" open={openModal} onClose={Close}>
        <Modal.Header>Delete Excess Amount</Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to delete?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button className="addCustomerCancelBtn" onClick={Close}>
            No
          </Button>
          <Button
            className="addCustomersubmitBtn"
            icon="checkmark"
            labelPosition="right"
            content="Yes"
            onClick={() => handleDelete()}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default ExcessAmountsTable;

import React from 'react';
import moment from 'moment';
import {
  Icon,
  Image,
  Button,
  Popup,
  Input,
  Table,
  Modal,
  Dropdown,
  Checkbox,
  Form,
  TextArea,
  Select,
} from 'semantic-ui-react';
import toastr from 'toastr';
import PAID from '../../../images/paid.png';
import UNPAID from '../../../images/Un-paid.png';
import SUCCESS from '../../../images/tickmark.gif';
import { BASE_URL } from '../../../config';
import DatePicker from 'react-date-picker';

const CustomerInvoiceGeneration = (props) => {
  return (
    <div className="invoiceDefaultLayout">
      <Card history={props.history} userData={props.history.location.state} />
    </div>
  );
};

const paymentOptions = [
  { key: 0, text: 'IMPS', value: 'IMPS' },
  { key: 1, text: 'NEFT', value: 'NEFT' },
  { key: 2, text: 'RTGS', value: 'RTGS' },
  { key: 3, text: 'DD', value: 'DD' },
  { key: 4, text: 'CASH', value: 'CASH' },
];

const chequeOptions = [
  { key: 0, text: 'Pending', value: 'Pending' },
  { key: 1, text: 'Cleared', value: 'Cleared' },
];

class Card extends React.Component {
  state = {
    invoice_settings: {},
    invoice_no: 0,
    invoice_date: '',
    customer_name: '',
    customer_phone: '',
    customer_address: '',
    customer_gst: '',
    transaction_type: '',
    payment_type: '',
    payment_status: '',
    remarks: '',
    transaction_date: '',
    transaction_amount: '',
    cheque_no: '',
    cheque_submit_date: '',
    cheque_clearing_date: '',
    cheque_remarks: '',
    balance_amount: '',
    product_name: '',
    product_weight: '',
    product_quantity: '',
    product_price: '',
    customer_notes: '',
    pricing_terms: '',
    delivery_charges: '',
    coupon_code: '',
    coupon_discount: 0,
    gst_amount: '',
    order_total: '',
    cust_notes_status: false,
    pricing_terms_status: false,
    addPaymentModal: false,
    addPaymentStatus: false,
    addProductModal: false,
    addCustomerModal: false,
    printStatus: false,
    addTransactionOption: true,
    productsList: [],
    transactions: [],
    products: [],
    product_pricing: [],
    customers: [],
    companies: [],
    cgst: 0,
    sgst: 0,
    cgstOptions: [],
    sgstOptions: [],
    drivers: [],
    vehicles: [],
    agentDetails: {
      Id: '',
      name: '',
      phone: '',
      TruckNumber: '',
    },
  };

  componentDidMount = () => {
    // this.setState({ invoice_date: Date.now() });
    fetch(`${BASE_URL}/get-companies`)
      .then((res) => res.json())
      .then((json) => {
        if (json.companies.length > 0) {
          console.log(
            'Getting the company name and calling the invoices',
            json.companies
          );
          this.getInvoiceNo(`${json.companies[0].collection_name}_invoices`);
          this.setState({
            invoice_settings: json.companies[0],
            customer_notes: json.companies[0].default_customer_notes,
            pricing_terms: json.companies[0].default_pricing_terms,
            delivery_charges: json.companies[0].delivery_charges,
            companies: json.companies,
            cgstOptions: json.companies[0].cgst,
            sgstOptions: json.companies[0].sgst,
            cgst: json.companies[0].cgst[0],
            sgst: json.companies[0].sgst[0],
          });
        }
      });
    this.getCustomers();

    fetch(`${BASE_URL}/get-products`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ products: json.products });
      });
  };

  getCustomers = () => {
    fetch(`${BASE_URL}/get-customers`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ customers: json.customers });
      });
  };

  generateInvoice = () => {
    const {
      invoice_no,
      invoice_date,
      invoice_settings,
      customer_name,
      customer_phone,
      customer_address,
      customer_gst,
      productsList,
      delivery_charges,
      coupon_code,
      coupon_discount,
      transactions,
      cust_notes_status,
      customer_notes,
      pricing_terms_status,
      pricing_terms,
      agentDetails,
      cgst,
      sgst,
      payment_status,
    } = this.state;

    if (
      transactions.length > 0 &&
      transactions[0].cheque_details !== undefined
    ) {
      if (transactions[0].cheque_details.cheque_status === 'pending') {
        payment_status = 'unpaid';
      } else {
        payment_status = 'fullypaid';
      }
    }

    if (customer_name.length === 0 && customer_phone.length === 0) {
      toastr.error('Please enter customer details to generate invoice!');
    } else if (productsList.length === 0) {
      toastr.error('Please add atleast one product to generate invoice!');
    }else if (invoice_date == '') {
      toastr.error('Please select the invoice date');
    }
     else {
      fetch(`${BASE_URL}/create-invoice`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          invoice_no,
          invoice_date,
          company_details: invoice_settings,
          products: productsList,
          delivery_charges,
          coupon_details: {
            code: coupon_code,
            discount: coupon_discount,
          },
          gst_amount: this.getGstAmount(productsList),
          cgst,
          sgst,
          order_total: this.getOrderTotal(productsList),
          customer_name,
          customer_phone,
          customer_address: customer_address,
          customer_gst,
          payment_status:
            this.getBalanceDue(productsList, transactions) === 0
              ? 'fullypaid'
              : 'unpaid',
          transactions,
          cust_notes_status,
          customer_notes,
          pricing_terms_status,
          pricing_terms,
          agentDetails,
          role: 'CUSTOMER',
          invoice_type: 'ADMIN_CUSTOMER_INVOICE',
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          // this.printInvoice();
          toastr.success('Customer Invoice Generated Successfully!');
          this.props.history.push('/walk-in-delivery');
        });
    }
  };

  showAddPaymentModal = () => () => {
    const { productsList } = this.state;
    this.setState({
      payment_type: 'CASH',
      transaction_date: Date.now(),
      transaction_amount: this.getOrderTotal(productsList),
      addPaymentModal: true,
    });
  };
  addPaymentClose = () => this.setState({ addPaymentModal: false });

  showAddProductModal = () => () => this.setState({ addProductModal: true });
  addProductClose = () => this.setState({ addProductModal: false });

  addCustomerClose = () => this.setState({ addCustomerModal: false });

  addTransaction = () => {
    const {
      remarks,
      payment_type,
      transaction_date,
      transaction_amount,
      cheque_no,
      cheque_submit_date,
      cheque_clearing_date,
      cheque_remarks,
      cheque_status,
    } = this.state;

    const cheque_details = {
      cheque_no,
      cheque_submit_date,
      cheque_clearing_date,
      cheque_remarks,
      cheque_status,
    };

    if (payment_type === 'CHEQUE') {
      if (payment_type === '') {
        toastr.error('Please Select Payment Type');
      } else if (cheque_no === '') {
        toastr.error('Please Enter Cheque Number');
      } else if (cheque_submit_date === '') {
        toastr.error('Please Provide Check Submitted Date');
      } else if (cheque_clearing_date === '') {
        toastr.error('Please Provide Check Clearance Date');
      } else if (cheque_status === '') {
        toastr.error('Please Provide Cheque Status');
      } else if (transaction_date === '') {
        toastr.error('Please Provide valid Date');
      } else if (transaction_amount === '') {
        toastr.error('Please Enter Transaction Amount');
      } else {
        this.setState({
          transactions: this.state.transactions.concat([
            {
              transaction_type: 'payment',
              payment_type,
              cheque_details,
              transaction_date,
              transaction_amount,
              remarks,
            },
          ]),
          addPaymentStatus: true,
          payment_status: cheque_status === 'Pending' ? 'unpaid' : 'fullypaid',
        });
      }
    } else {
      if (payment_type === '') {
        toastr.error('Please Select Payment Type');
      } else if (transaction_date === '') {
        toastr.error('Please Provide valid Date');
      } else if (transaction_amount === '') {
        toastr.error('Please Enter Transaction Amount');
      } else {
        this.setState({
          transactions: this.state.transactions.concat([
            {
              transaction_type: 'payment',
              payment_type,
              transaction_date,
              transaction_amount,
              remarks,
            },
          ]),
          addPaymentStatus: true,
        });
      }
    }
  };

  addProduct = () => {
    const { product_name, product_quantity, product_price, product_weight } =
      this.state;

    if (product_name === '') {
      toastr.error('Please Enter Product Name');
    } else if (product_weight === '') {
      toastr.error('Please Enter Product Weight');
    } else if (product_price === undefined || product_price === '') {
      toastr.error('Please Enter Product Price');
    } else if (product_quantity === '') {
      toastr.error('Please Enter Product Quantity');
    } else {
      this.setState({
        productsList: this.state.productsList.concat([
          {
            name: product_name,
            quantity: product_quantity,
            weight: product_weight,
            price: product_price,
          },
        ]),
      });
    }
  };

  removeProduct = (idx) => () => {
    this.setState({
      productsList: this.state.productsList.filter((s, sidx) => idx !== sidx),
    });
  };

  removeTransaction = (idx) => () => {
    this.setState({
      transactions: this.state.transactions.filter((s, sidx) => idx !== sidx),
    });
  };

  getTransactionsBalance = (transactions) => {
    const paymentData = transactions.filter((transaction) =>
      Object.keys(transaction).some(
        (key) => transaction[key].toString().search('payment') !== -1
      )
    );
    const payments = paymentData.reduce(
      (sum, transaction, index) => sum + Number(transaction.transaction_amount),
      0
    );

    const refundData = transactions.filter((transaction) =>
      Object.keys(transaction).some(
        (key) => transaction[key].toString().search('refund') !== -1
      )
    );
    const refunds = refundData.reduce(
      (sum, transaction, index) => sum + Number(transaction.transaction_amount),
      0
    );

    const total_balance = payments - refunds;
    return Number(total_balance);
  };

  getProductsTotal = (products) => {
    const total_value = products.reduce(
      (sum, product, index) => sum + Number(product.quantity * product.price),
      0
    );
    return Number(total_value);
  };

  getCouponDiscount = () => {
    const { coupon_discount, productsList } = this.state;

    const discount =
      (coupon_discount / 100) * this.getProductsTotal(productsList);

    return discount;
  };

  getGstAmount = (products) => {
    const { cgst, sgst } = this.state;
    const gst =
      (this.getProductsTotal(products) * (Number(this.state.invoice_settings?.cgst?.[0]) + Number(this.state.invoice_settings?.cgst?.[0]))) / 100;

    return Math.round(gst);
  };

  getBalanceDue = (products, transactions) => {
    const { delivery_charges } = this.state;

    const order_total =
      this.getProductsTotal(products) +
      this.getGstAmount(products) +
      Number(delivery_charges) -
      this.getCouponDiscount();

    const due = order_total - this.getTransactionsBalance(transactions);

    return due;
  };

  getOrderTotal = (products) => {
    const { delivery_charges } = this.state;

    const order_total =
      this.getProductsTotal(products) +
      this.getGstAmount(products) +
      Number(delivery_charges) -
      this.getCouponDiscount();

    return order_total;
  };

  getInvoiceNo = (value) => {
    fetch(`${BASE_URL}/get-invoice-no?cname=${value}`)
      .then((res) => res.json())
      .then((json) => {
        if (json.invoices.length > 0) {
          this.setState({ invoice_no: json.invoices[0].invoice_no + 1 });
        } else {
          const { invoice_settings } = this.state;
          // console.log(
          //   'This is the current invoice setting=> ',
          //   invoice_settings
          // );
          if (
            invoice_settings?.collection_name.replaceAll(' ', '') == 'kkriceinc'
          ) {
            this.setState({ invoice_no: 86 });
          } else {
            this.setState({ invoice_no: 80 });
          }
        }
      });
  };

  handleChangeCompany = (id) => {
    const data = this.state.companies.filter((company) => company._id === id);
    const company = data[0];

    this.getInvoiceNo(company.collection_name);
    this.setState({
      invoice_settings: company,
    });
  };

  saveCustomer = () => {
    const { customer_name, customer_phone, customer_address } = this.state;
    if ((customer_name, customer_phone, customer_address)) {
      fetch(`${BASE_URL}/create-customer`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: customer_name,
          gstNo: '',
          mobileNumber: customer_phone,
          email: '',
          address: customer_address,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.status == 400) {
            toastr.error('Customer already exists.');
          } else {
            this.getCustomers();
            this.addCustomerClose();
            toastr.success('New Customer Created Successfully!');
          }
        });
    } else {
      toastr.error('Please fill all the fields!');
    }
  };

  printInvoice = () => {
    this.setState({ printStatus: true });
    setTimeout(function () {
      var printContents = document.getElementById('capture').innerHTML;
      var originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;

      window.print();

      document.body.innerHTML = originalContents;
      window.location.reload();
    }, 1000);
  };

  render() {
    const {
      invoice_settings,
      invoice_no,
      invoice_date,
      customer_name,
      customer_phone,
      customer_address,
      customer_gst,
      addPaymentModal,
      addPaymentStatus,
      transactions,
      customers,
      products,
      product_pricing,
      productsList,
      customer_notes,
      pricing_terms,
      delivery_charges,
      coupon_code,
      cust_notes_status,
      pricing_terms_status,
      companies,
      payment_type,
      cgst,
      sgst,
      cgstOptions,
      sgstOptions,
      transaction_date,
      transaction_amount,
      payment_status,
      addCustomerModal,
      printStatus,
    } = this.state;

    const handleDateChange = (e) => {
      this.setState({ invoice_date: e });
    };

  const cgstValue=invoice_settings.cgst;
  console.log("🚀 ~ file: cutsomerInvoice.component.jsx ~ line 561 ~ Card ~ render ~ cgstValue", cgstValue?.[0])
  
  

    return (
      <div className="invoiceCardContainer">
        <div className="invoicePageTitleRow">
          <div style={{ paddingTop: 10, paddingLeft: 20, display: 'flex' }}>
            <Icon
              name="arrow left"
              style={{ paddingTop: 3, cursor: 'pointer' }}
              onClick={() => this.props.history.push('/orderrequests')}
            />
            <h3 style={{ marginTop: 0, marginLeft: 10 }}>
              Customer Invoice Generation
            </h3>
          </div>
          <div>
            <Select
              placeholder="Select Company"
              options={companies.map((company) => ({
                key: company._id,
                value: company._id,
                text: company.company_name,
              }))}
              style={{ margin: 'auto' }}
              onChange={(event, data) => this.handleChangeCompany(data.value)}
            />
          </div>

          <div>
            <Button
              className="invoiceSetBtn"
              onClick={() => this.props.history.push('/companies')}
            >
              <Icon name="setting" /> Invoice Settings
            </Button>
          </div>

          <div>
            <Button onClick={() => this.printInvoice()}>
              <Icon name="print" /> Print
            </Button>
          </div>

          <div>
            <Button
              className="generateSaveBtn"
              onClick={() => this.generateInvoice()}
            >
              <Icon name="save" /> Generate Invoice
            </Button>
          </div>
        </div>

        <div className="Card">
          <div className="invoiceContainer" id="capture">
            <div>
              <Image src={invoice_settings.logo} style={{ width: 100 }} />
            </div>
            <div className="section1">
              <div>
                <p className="section1Text" style={{ fontSize: 22 }}>
                  {invoice_settings.company_name || 'Enter Company Name'},
                </p>
                <p className="section1Text">
                  {invoice_settings.address_line1 || 'Address Line 1,'}
                </p>
                <p className="section1Text">
                  {invoice_settings.address_line2 || 'Adresss Line 2,'}{' '}
                  {invoice_settings.address_line3 || 'Address Line 3'}
                </p>
                <p className="section1Text">
                  Email: {invoice_settings.email || 'Enter Company Email'}
                </p>

                <p className="section1Text">
                  Contact: {invoice_settings.whatsapp_number || 'Enter Contact'}
                </p>
                <p className="section1Text">
                  GSTIN: {invoice_settings.gst_no || 'No GST Number'}
                </p>
              </div>
              {/* <div>
                {this.getBalanceDue(productsList, transactions) === 0 ? (
                  <div>
                    <Image
                      src={PAID}
                      style={{ width: 150, marginRight: 100 }}
                    />
                  </div>
                ) : (
                  <div>
                    <Image
                      src={UNPAID}
                      style={{ width: 150, marginRight: 100 }}
                    />
                  </div>
                )}
              </div> */}
            </div>
            <div className="section2">
              <div>
                <p
                  className="section2Text"
                  style={{ fontSize: 22, fontWeight: 'bold' }}
                >
                  Invoice #{invoice_no}{' '}
                </p>
                <p className="section2Text">
                  Invoice Date: 
                  {/* {moment(invoice_date).format('DD/MM/YYYY')} */}
                  <DatePicker
                    value={invoice_date}
                    onChange={(e) => handleDateChange(e)}
                  />
                </p>
              </div>
              <div>
                {printStatus === false ? (
                  <div
                    style={{
                      fontSize: 17,
                      fontWeight: 'bold',
                      display: 'flex',
                      marginBottom: 5,
                      flexDirection: 'row',
                    }}
                  >
                    <div style={{ marginTop: 10, marginRight: 5 }}>
                      <p>Invoiced To</p>
                    </div>

                    <div>
                      <Dropdown
                        placeholder="Select Customer"
                        fluid
                        search
                        selection
                        options={customers.map((customer, index) => ({
                          key: index,
                          text: customer.name,
                          value: customer,
                        }))}
                        onChange={(event, data) =>
                          this.setState({
                            customer_name: data.value.name,
                            customer_phone: data.value.phoneNumber,
                            customer_address: data.value.address,
                            customer_gst: data.value.gstNo,
                          })
                        }
                      />
                    </div>

                    <div style={{ marginTop: 8 }}>
                      <Popup
                        on="click"
                        pinned
                        style={{ textAlign: 'center' }}
                        open={addCustomerModal}
                        onClose={this.addCustomerClose}
                        position="left center"
                        trigger={
                          <Icon
                            name="plus"
                            style={{
                              marginTop: 8,
                              fontSize: 26,
                              color: '#93c47d',
                              marginLeft: 5,
                            }}
                            onClick={() =>
                              this.setState({ addCustomerModal: true })
                            }
                          />
                        }
                      >
                        <div>
                          <p>Customer Name:</p>
                          <Input
                            placeholder="Enter Name"
                            onChange={(evt) =>
                              this.setState({ customer_name: evt.target.value })
                            }
                          />
                        </div>
                        <div>
                          <p>Customer Phone:</p>
                          <Input
                            placeholder="Enter Phone Number"
                            maxLength="10"
                            onChange={(evt) =>
                              this.setState({
                                customer_phone: evt.target.value,
                              })
                            }
                          />
                        </div>
                        <div>
                          <p>Customer Address:</p>
                          <Input
                            placeholder="Enter Address"
                            onChange={(evt) =>
                              this.setState({
                                customer_address: evt.target.value,
                              })
                            }
                          />
                        </div>

                        <Button
                          style={{
                            fontSize: 12,
                            marginTop: 10,
                            backgroundColor: '#c9daf8',
                          }}
                          onClick={() => this.saveCustomer()}
                        >
                          {' '}
                          Submit{' '}
                        </Button>
                      </Popup>
                    </div>
                  </div>
                ) : (
                  <p
                    className="section3Text"
                    style={{ fontSize: 17, fontWeight: 'bold' }}
                  >
                    Invoiced To
                  </p>
                )}
                <p
                  className="section3Text"
                  style={{ fontSize: 22, fontWeight: 'bold' }}
                >
                  {customer_name || 'Customer Name'}
                </p>
                <p
                  className="section3Text"
                  style={{ fontSize: 16, fontWeight: '500' }}
                >
                  {customer_phone || 'Customer Phone'}
                </p>
                <p
                  className="section3Text"
                  style={{ fontSize: 16, fontWeight: '500' }}
                >
                  {customer_address || 'Customer Address'}
                </p>
                {customer_gst.length > 0 ? (
                  <p
                    className="section3Text"
                    style={{ fontSize: 16, fontWeight: '500' }}
                  >
                    GSTIN: {customer_gst}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div className="section3"></div>
            <div className="section4">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p style={{ fontSize: 20, fontWeight: '600' }}>Products</p>
                {printStatus === false && (
                  <div>
                    <Popup
                      on="click"
                      pinned
                      position="left center"
                      style={{ textAlign: 'center' }}
                      trigger={
                        <Icon
                          name="plus"
                          style={{
                            fontSize: 20,
                            color: '#93c47d',
                            marginLeft: 5,
                          }}
                        />
                      }
                    >
                      <div className="mb-15">
                        <p className="selectionHeading">Select Product:</p>
                        <Dropdown
                          placeholder="Select Product"
                          fluid
                          search
                          selection
                          className="selectionProductDropdownWidth"
                          options={products.map((product, index) => ({
                            key: index,
                            text: product.name,
                            value: product,
                          }))}
                          onChange={(event, data) =>
                            this.setState({
                              product_name: data.value.name,
                              product_pricing: data.value.pricing,
                            })
                          }
                        />
                      </div>
                      <div className="mb-15">
                        <p className="selectionHeading">Select Weight:</p>
                        <Dropdown
                          placeholder="Select Pricing"
                          fluid
                          search
                          selection
                          className="selectionProductDropdownWidth"
                          options={product_pricing.map((pricing, index) => ({
                            key: index,
                            text: `${pricing.weight} Kg`,
                            value: pricing,
                          }))}
                          onChange={(event, data) =>
                            this.setState({
                              product_weight: data.value.weight,
                              product_price: data.value.customerPrice,
                            })
                          }
                        />
                      </div>
                      <div className="mb-15">
                        <p className="selectionHeading">Amount:</p>
                        <Input
                          className="selectionProductDropdownWidth"
                          value={this.state.product_price}
                          onChange={(e) =>
                            this.setState({ product_price: e.target.value })
                          }
                        />
                      </div>
                      <div className="mb-15">
                        <p className="selectionHeading">Quantity:</p>
                        <Input
                          placeholder="Enter Quantity"
                          className="selectionProductDropdownWidth"
                          onChange={(e) =>
                            this.setState({ product_quantity: e.target.value })
                          }
                        />
                      </div>
                      <div>
                        <Button onClick={this.addProduct}>Submit</Button>
                      </div>
                    </Popup>
                  </div>
                )}
              </div>
              <div>
                <Table celled unstackable>
                  <Table.Header>
                    <Table.Row textAlign="center">
                      <Table.HeaderCell style={{ backgroundColor: '#b6d7a8' }}>
                        S.no
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: '#b6d7a8' }}>
                        Products
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: '#b6d7a8' }}>
                        Quantity
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: '#b6d7a8' }}>
                        Price
                      </Table.HeaderCell>
                    
                      <Table.HeaderCell style={{ backgroundColor: '#b6d7a8' }}>
                        Total
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {productsList.map((product, idx) => (
                      <Table.Row textAlign="center">
                        <Table.Cell textAlign="center">{idx + 1}</Table.Cell>
                        <Table.Cell>
                          {product.name} - {product.weight}Kg
                        </Table.Cell>
                        <Table.Cell>{product.quantity}</Table.Cell>
                        <Table.Cell>Rs. {product.price}</Table.Cell>
                        <Table.Cell>
                          Rs. {product.quantity * product.price}
                          {printStatus === false && (
                            <Icon
                              name="times circle"
                              className="cancelRowBtn"
                              color="red"
                              onClick={this.removeProduct(idx)}
                            />
                          )}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>

                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div style={{ alignSelf: 'flex-end' }}>
                    <div style={{ marginBottom: 20 }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p
                          style={{
                            fontSize: 17,
                            fontWeight: '600',
                            marginBottom: 10,
                          }}
                        >
                          Customer Notes
                        </p>
                        {printStatus === false && (
                          <Checkbox
                            label="Enable"
                            checked={cust_notes_status}
                            onChange={() =>
                              this.setState({
                                cust_notes_status: !cust_notes_status,
                              })
                            }
                          />
                        )}
                      </div>

                      <Form>
                        <TextArea
                          placeholder="Thanks for your business."
                          cols="50"
                          maxLength="70"
                          value={customer_notes}
                          onChange={(e) =>
                            this.setState({ customer_notes: e.target.value })
                          }
                        />
                      </Form>
                    </div>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p
                          style={{
                            fontSize: 17,
                            fontWeight: '600',
                            marginBottom: 10,
                          }}
                        >
                          Pricing Terms
                        </p>
                        {printStatus === false && (
                          <Checkbox
                            label="Enable"
                            checked={pricing_terms_status}
                            onChange={() =>
                              this.setState({
                                pricing_terms_status: !pricing_terms_status,
                              })
                            }
                          />
                        )}
                      </div>

                      <Form>
                        <TextArea
                          placeholder="Thanks for your business."
                          cols="50"
                          maxLength="70"
                          value={pricing_terms}
                          onChange={(e) =>
                            this.setState({ pricing_terms: e.target.value })
                          }
                        />
                      </Form>
                    </div>
                  </div>

                  <div>
                    <Table celled unstackable collapsing>
                      <Table.Body>
                        <Table.Row textAlign="right">
                          <Table.Cell className="tableCellbg">
                            Item Total
                          </Table.Cell>
                          <Table.Cell className="tableCellbg">
                            ₹ {this.getProductsTotal(productsList)}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ color: '#cc0000' }}
                          >
                            {printStatus === false && (
                              <Popup
                                on="click"
                                pinned
                                style={{ textAlign: 'center' }}
                                trigger={
                                  <Icon
                                    name="pencil"
                                    style={{
                                      fontSize: 15,
                                      color: '#93c47d',
                                      marginLeft: 5,
                                    }}
                                  />
                                }
                              >
                                <div>
                                  <p style={{ marginBottom: 5 }}>
                                    Edit Coupon Code:
                                  </p>
                                  <Input
                                    placeholder="Coupon code"
                                    onChange={(e) =>
                                      this.setState({
                                        coupon_code: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                                <div>
                                  <p style={{ marginBottom: 5 }}>
                                    Edit Coupon discount:
                                  </p>
                                  <Input
                                    placeholder="Coupon discount Perc"
                                    onChange={(e) =>
                                      this.setState({
                                        coupon_discount: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </Popup>
                            )}
                            Coupon Applied({coupon_code || 'No Coupon'})
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ color: '#cc0000' }}
                          >
                            -₹ {this.getCouponDiscount() || 0}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            Other Charges
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            ₹ {delivery_charges}
                            {printStatus === false && (
                              <Popup
                                on="click"
                                pinned
                                style={{ textAlign: 'center' }}
                                trigger={
                                  <Icon
                                    name="pencil"
                                    style={{
                                      fontSize: 15,
                                      color: '#93c47d',
                                      marginLeft: 5,
                                      position: 'absolute',
                                      right: 85,
                                    }}
                                  />
                                }
                              >
                                <div>
                                  <p style={{ marginBottom: 5 }}>
                                    Edit Other Charges:
                                  </p>
                                  <Input
                                    placeholder="Other Charges"
                                    onChange={(e) =>
                                      this.setState({
                                        delivery_charges: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </Popup>
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            {cgstValue?.[0]}%CGST
                            {/* {printStatus === false && (
                              <Popup
                                on="click"
                                pinned
                                style={{ textAlign: "center" }}
                                trigger={
                                  <Icon
                                    name="pencil"
                                    style={{
                                      fontSize: 15,
                                      color: "#93c47d",
                                      marginLeft: 5,
                                    }}
                                  />
                                }
                              >
                                <div>
                                  <p>Select Percentage:</p>
                                  <Dropdown
                                    placeholder="Select Percentage"
                                    fluid
                                    search
                                    selection
                                    options={cgstOptions.map((cgst, index) => ({
                                      key: index,
                                      text: cgst,
                                      value: cgst,
                                    }))}
                                    onChange={(event, data) =>
                                      this.setState({
                                        cgst: data.value,
                                      })
                                    }
                                  />
                                </div>
                              </Popup>
                            )} */}
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            ₹ {this.getGstAmount(productsList) / 2}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            {cgstValue?.[0]}%
                             SGST
                            {/* {printStatus === false && (
                              <Popup
                                on="click"
                                pinned
                                style={{ textAlign: "center" }}
                                trigger={
                                  <Icon
                                    name="pencil"
                                    style={{
                                      fontSize: 15,
                                      color: "#93c47d",
                                      marginLeft: 5,
                                    }}
                                  />
                                }
                              >
                                <div>
                                  <p>Select Percentage:</p>
                                  <Dropdown
                                    placeholder="Select Percentage"
                                    fluid
                                    search
                                    selection
                                    options={sgstOptions.map((sgst, index) => ({
                                      key: index,
                                      text: sgst,
                                      value: sgst,
                                    }))}
                                    onChange={(event, data) =>
                                      this.setState({
                                        sgst: data.value,
                                      })
                                    }
                                  />
                                </div>
                              </Popup>
                            )} */}
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            ₹ {this.getGstAmount(productsList) / 2}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ color: '#cc0000' }}
                          >
                            Paid Amount
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ color: '#cc0000' }}
                          >
                            -₹ {this.getTransactionsBalance(transactions)}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ color: '#6aa84f' }}
                          >
                            Total Balance Due
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ color: '#6aa84f' }}
                          >
                            ₹ {this.getBalanceDue(productsList, transactions)}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </div>
              </div>
            </div>

            <div className="section5">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p style={{ fontSize: 20, fontWeight: '600' }}>Transactions</p>
                {printStatus === false && (
                  <div>
                    <Button
                      style={{ backgroundColor: '#fce5cd' }}
                      onClick={this.showAddPaymentModal()}
                    >
                      <Icon name="plus" /> Add Transaction{' '}
                    </Button>
                  </div>
                )}
              </div>
              <div>
                <Table celled unstackable>
                  <Table.Header>
                    <Table.Row textAlign="center">
                      <Table.HeaderCell style={{ backgroundColor: '#b6d7a8' }}>
                        Transaction Date
                      </Table.HeaderCell>

                      <Table.HeaderCell style={{ backgroundColor: '#b6d7a8' }}>
                        Transaction Type
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: '#b6d7a8' }}>
                        Amount
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {transactions.map((transaction, idx) => (
                      <Table.Row textAlign="center">
                        <Table.Cell>
                          {moment(transaction.transaction_date).format('l')}
                        </Table.Cell>
                        <Table.Cell>
                          {transaction.payment_type}
                          {transaction.cheque_details !== undefined
                            ? `(Cheque No:${transaction.cheque_details.cheque_no})`
                            : ''}
                        </Table.Cell>
                        <Table.Cell>
                          {transaction.transaction_type === 'payment' ? (
                            <p>₹ {transaction.transaction_amount} (+)</p>
                          ) : (
                            <p>₹ {transaction.transaction_amount} (-)</p>
                          )}
                          {printStatus === false && (
                            <Icon
                              name="times circle"
                              className="cancelRowBtn"
                              color="red"
                              onClick={this.removeTransaction(idx)}
                            />
                          )}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                    {printStatus === false && (
                      <Table.Row>
                        <Table.Cell
                          textAlign="right"
                          className="tableCellbg"
                          colSpan="2"
                        >
                          Balance
                        </Table.Cell>
                        <Table.Cell textAlign="center" className="tableCellbg">
                          ₹ {this.getTransactionsBalance(transactions)}
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </div>
            </div>

            <Modal
              size="small"
              open={addPaymentModal}
              onClose={this.addPaymentClose}
            >
              <Modal.Header>Add Transaction</Modal.Header>
              <Modal.Content>
                {addPaymentStatus === false && (
                  <div>
                    <div className="FieldsRow">
                      <p className="LabelText">Payment Type:</p>
                      <Dropdown
                        placeholder="Select Payment Type"
                        value={payment_type}
                        fluid
                        selection
                        options={paymentOptions}
                        onChange={(event, data) =>
                          this.setState({ payment_type: data.value })
                        }
                        style={{ width: 200 }}
                      />
                    </div>

                    {payment_type === 'CHEQUE' ? (
                      <div>
                        <div className="FieldsRow">
                          <p className="LabelText">Cheque No:</p>
                          <Input
                            placeholder="Cheque No"
                            type="Number"
                            onChange={(e) =>
                              this.setState({
                                cheque_no: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="FieldsRow">
                          <p className="LabelText">Cheque Submitted Date:</p>
                          <input
                            type="date"
                            placeholder="Select Date"
                            onChange={(e) =>
                              this.setState({
                                cheque_submit_date: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="FieldsRow">
                          <p className="LabelText">Cheque Clearing Date:</p>
                          <input
                            type="date"
                            placeholder="Select Date"
                            onChange={(e) =>
                              this.setState({
                                cheque_clearing_date: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="FieldsRow">
                          <p className="LabelText">Cheque Remarks:</p>
                          <TextArea
                            placeholder="Cheque Remarks"
                            onChange={(e) =>
                              this.setState({ cheque_remarks: e.target.value })
                            }
                          />
                        </div>
                        <div className="FieldsRow">
                          <p className="LabelText">Cheque Status:</p>
                          <Dropdown
                            placeholder="Select Status"
                            fluid
                            selection
                            options={chequeOptions}
                            onChange={(event, data) => {
                              this.setState({
                                cheque_status: data.value,
                                transaction_amount:
                                  data.value === 'Pending'
                                    ? 0
                                    : transaction_amount,
                              });
                            }}
                            style={{ width: 200 }}
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <div className="FieldsRow">
                      <p className="LabelText">Date of Payment:</p>
                      <input
                        type="date"
                        placeholder="Transaction ID"
                        value={moment(transaction_date).format('YYYY-MM-DD')}
                        onChange={(e) =>
                          this.setState({ transaction_date: e.target.value })
                        }
                      />
                    </div>
                    <div className="FieldsRow">
                      <p className="LabelText">Amount:</p>
                      <Input
                        placeholder="Payment Amount"
                        type="Number"
                        value={transaction_amount}
                        onChange={(e) =>
                          this.setState({ transaction_amount: e.target.value })
                        }
                        disabled
                      />
                    </div>
                    <div className="FieldsRow">
                      <p className="LabelText">Remarks:</p>
                      <TextArea
                        placeholder="Remarks"
                        onChange={(e) =>
                          this.setState({ remarks: e.target.value })
                        }
                      />
                    </div>
                  </div>
                )}

                {addPaymentStatus === true && (
                  <div style={{ textAlign: 'center' }}>
                    <Image
                      src={SUCCESS}
                      style={{ width: 250, display: 'initial' }}
                    />
                    <p>Payment is Successfully Completed</p>
                  </div>
                )}
              </Modal.Content>
              {addPaymentStatus === false && (
                <Modal.Actions>
                  <Button
                    onClick={this.addPaymentClose}
                    style={{ backgroundColor: '#ea9999' }}
                  >
                    <Icon name="close" />
                    Close
                  </Button>
                  <Button
                    icon="checkmark"
                    labelPosition="right"
                    content="Add Transaction"
                    style={{
                      backgroundColor: 'rgb(182, 215, 168)',
                      color: 'rgba(0, 0, 0, 0.6)',
                    }}
                    onClick={this.addTransaction}
                  />
                </Modal.Actions>
              )}
              {addPaymentStatus === true && (
                <Modal.Actions>
                  <Button
                    onClick={() =>
                      this.setState({
                        transaction_id: '',
                        transaction_type: '',
                        transaction_date: '',
                        transaction_amount: '',
                        addPaymentStatus: false,
                        addPaymentModal: false,
                      })
                    }
                    style={{ backgroundColor: '#ea9999' }}
                  >
                    <Icon name="close" />
                    Close
                  </Button>
                </Modal.Actions>
              )}
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerInvoiceGeneration;

import React from 'react';
import SettingsTabBarMenu from '../../../commons/settingstabbar/settingstabbar.component';
import { Tab } from 'semantic-ui-react';
import SmsAdminNotifications from './smsnotification/sms-admin-notification';
import SmsConsumerNotifications from './smsnotification/sms-customer-notification';
import SmsMerchantNotifications from './smsnotification/sms-merchant-notification';
import SmsDriverNotifications from './smsnotification/sms-driver-notification';
import SmsMarketingExecutiveNotifications from './smsnotification/sms-marketing-executive-notification';
import EmailAdminNotifications from './emailnotification/email-admin-notification';
import EmailCustomerNotifications from './emailnotification/email-customer-notification';
import EmailDriverNotifications from './emailnotification/email-driver-notification';
import EmailMerchantNotifications from './emailnotification/email-merchant-notification';
import EmailMarketingExecutiveNotifications from './emailnotification/email-marketing-executive-notification';
import AppAdminNotifications from './appnotification/app-admin-notification';
import AppCustomerNotifications from './appnotification/app-customer-notification';
import AppMerchantNotifications from './appnotification/app-merchant-notification';
import AppDriverNotifications from './appnotification/app-driver-notification';
import AppMarketingExecutiveNotifications from './appnotification/app-marketing-executive-notification';

const NotificationSettings = (props) => {
  return (
    <div className='ReportLayout'>
      <Header history={props.history} />
      <Card />
    </div>
  );
};

const notificationSettingsPanes = [
  {
    menuItem: 'SMS Notifications',
    render: () => (
      <Tab.Pane attached={false}>
        <div>
          <SmsAdminNotifications />
        </div>
        <div>
          <SmsConsumerNotifications />
        </div>
        <div>
          <SmsMerchantNotifications />
        </div>
        <div>
          <SmsDriverNotifications />
        </div>
        <div>
          <SmsMarketingExecutiveNotifications />
        </div>
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Email Notifications',
    render: () => (
      <Tab.Pane attached={false}>
        <div>
          <EmailAdminNotifications />
        </div>
        <div>
          <EmailCustomerNotifications />
        </div>
        <div>
          <EmailMerchantNotifications />
        </div>
        <div>
          <EmailDriverNotifications />
        </div>
        <div>
          <EmailMarketingExecutiveNotifications />
        </div>
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'In-App Notifications',
    render: () => (
      <Tab.Pane attached={false}>
        <div>
          <AppAdminNotifications />
        </div>
        <div>
          <AppCustomerNotifications />
        </div>
        <div>
          <AppMerchantNotifications />
        </div>
        <div>
          <AppDriverNotifications />
        </div>
        <div>
          <AppMarketingExecutiveNotifications />
        </div>
      </Tab.Pane>
    ),
  },
];

const Header = (props) => {
  return (
    <div className='HeaderLayout'>
      <h3>Settings</h3>
      <SettingsTabBarMenu history={props.history} />
    </div>
  );
};

const Card = () => {
  return (
    <div className='CardContainer'>
      <h3>Notification Settings</h3>
      <Tab
        menu={{ borderless: true, pointing: true }}
        panes={notificationSettingsPanes}
      />
    </div>
  );
};
export default NotificationSettings;

import React, { useEffect, useState } from "react";
import {
  Table,
  Label,
  Icon,
  Checkbox,
  Button,
  Input,
  Select,
} from "semantic-ui-react";
import toastr from "toastr";
import { CUS_HEADING_DATA } from "./customerbilling.constant";
import { BASE_URL } from "../../../config";
import TableExport from "tableexport";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

const CustomerBillingTable = () => {
  const today = moment();
  const [invoices, setInvoices] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState(
    moment.range(today.clone().subtract(7, "days"), today.clone())
  );
  const [companies, setCompanies] = useState([]);
  const [collectionName, setCollectionName] = useState({});
  const [searchString, setSearchString] = useState("paid");

  useEffect(() => {
    fetch(`${BASE_URL}/get-companies`)
      .then((res) => res.json())
      .then((json) => {
        if (json.companies.length > 0) {
          setCompanies(json.companies);
          setCollectionName(`${json.companies[0].collection_name}_invoices`);
          fetch(
            `${BASE_URL}/get-invoices?cname=${`${json.companies[0].collection_name}_invoices`}`
          )
            .then((res) => res.json())
            .then((json) => {
              setInvoices(json.invoices);
            });
        }
      });
  }, []);

  const onSelect = (value, states) => {
    fetch(
      `${BASE_URL}/get-allinvoices-by-range?startDate=${value.start.format(
        "DD-MM-YYYY"
      )}&endDate=${value.end.format("DD-MM-YYYY")}&cname=${collectionName}`
    )
      .then((res) => res.json())
      .then((json) => {
        setValue(value);
        setOpen(!isOpen);
        setInvoices(json.result);
      });
  };

  const onToggle = () => {
    setOpen(!isOpen);
  };

  const invoices_data = invoices.filter((invoice) =>
    Object.keys(invoice).some(
      (key) => invoice[key].toString().search(searchString.length > 0 ? searchString : "CUSTOMER") !== -1
    )
  );

  const invoices_table = invoices_data.filter((invoice) =>
    Object.keys(invoice).some(
      (key) => invoice[key].toString().search("fullypaid") !== -1
    )
  );

  const handleChangeCompany = (id) => {
    const data = companies.filter((company) => company._id === id);
    const company = data[0];
    setCollectionName(`${company.collection_name}_invoices`);
    fetch(`${BASE_URL}/get-invoices?cname=${`${company.collection_name}_invoices`}`)
      .then((res) => res.json())
      .then((json) => {
        setInvoices(json.invoices);
      });
  };

  return (
    <div>
      <div className="BillingTitleRow">
        <div style={{ marginTop: 10 }}>
          <Button
            icon
            labelPosition="right"
            style={{
              backgroundColor: "#d9ead3",
              color: "#000",
              marginBottom: 15,
            }}
            onClick={() => {
              toastr.success("Exported Buttons Enabled Successfully");
              const table = new TableExport(
                document.getElementById("customer-invoices"),
                {
                  formats: ["csv"],
                  exportButtons: true,
                  position: "top",
                }
              );
              const link = document.createElement("a");
              link.setAttribute(
                "download",
                table.getExportData()["customer-invoices"].csv.filename
              );
              link.click();
            }}
          >
            Export
            <Icon name="share square icon" />
          </Button>
        </div>
        <div style={{ marginTop: 10 }}>
          <Select
            placeholder={companies.length > 0 ? companies[0].company_name : 'Select Company'}
            options={companies.map((company) => ({
              key: company._id,
              value: company._id,
              text: company.company_name,
            }))}
            style={{ margin: "auto" }}
            onChange={(event, data) => handleChangeCompany(data.value)}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          Select Range:{" "}
          <input
            type="text"
            onClick={onToggle}
            style={{ width: 170 }}
            value={`${value.start.format("DD-MM-YYYY")} - ${value.end.format(
              "DD-MM-YYYY"
            )}`}
          />
          {isOpen && (
            <DateRangePicker
              value={value}
              onSelect={onSelect}
              singleDateRange={true}
            />
          )}
        </div>
        {/* <div style={{ marginTop: 10 }}>
          <Button.Group size="tiny">
            <Button>Open</Button>
            <Button.Or />
            <Button>Cancel</Button>
            <Button.Or />
            <Button>Download</Button>
          </Button.Group>
        </div> */}
        <div style={{ marginTop: 10 }}>
          <Input
            placeholder="Quick Search..."
            icon="search"
            style={{ marginRight: 10 }}
            onChange={(e) => setSearchString(e.target.value)}
          />
        </div>
      </div>

      <div>
        <Table
          sortable
          unstackable
          celled
          basic="very"
          className="CustomTableLayout"
          id="customer-invoices"
        >
          <Table.Header>
            <Table.Row>
              {CUS_HEADING_DATA.map((heading) => (
                <Table.HeaderCell>
                  {heading === "Checkbox" ? (
                    <Checkbox />
                  ) : (
                    <span>{heading}</span>
                  )}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {invoices_table.map((invoice, index) => (
              <Table.Row>
                <Table.Cell>{index + 1}</Table.Cell>
                <Table.Cell>{invoice.order_id}</Table.Cell>
                <Table.Cell>{invoice.customer_name}</Table.Cell>
                <Table.Cell>{invoice.total_amount}</Table.Cell>
                <Table.Cell>{invoice.invoice_no}</Table.Cell>
                <Table.Cell>
                  {invoice.payment_status === "fullypaid" ? "PAID" : "UNPAID"}
                </Table.Cell>
                <Table.Cell>cash</Table.Cell>
                <Table.Cell>
                  <Label color="grey">
                    <a
                      onClick={() =>
                        window.open(
                          `/#/view-invoice/${invoice.company_details.collection_name}_invoices/${invoice.invoice_no}`,
                          "_blank"
                        )
                      }
                    >
                      view
                    </a>
                  </Label>
                </Table.Cell>

                {/* <Table.Cell>
                  <Button style={{ fontSize: 13, backgroundColor: "#f3f3f3" }}>
                    <Icon name="edit" />
                    Edit
                  </Button>

                  <Button style={{ fontSize: 13, backgroundColor: "#f4cccc" }}>
                    <Icon name="trash" /> Delete
                  </Button>
                </Table.Cell> */}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default CustomerBillingTable;

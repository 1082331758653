export const TABLE_DATA = [
  {
    sno: '1',
    name: 'Saravan Vel',
    Companyname: 'A.K Corporation',
    mobilenumber: '9874563210',
    email: 'saravan@gmail.com',
    gst:'18AABCT3518Q1ZV',
    address: 'chennai',
    turnover: '₹ 2,00,000',
  },
  {
    sno: '2',
    name: 'Muruga Vel',
    Companyname: 'A.K Corporation',
    mobilenumber: '9874563210',
    email: 'saravan@gmail.com',
    gst:'18AABCT3518Q1ZV',
    address: 'chennai',
    turnover: '₹ 1,00,000',
  },
  { 
    sno: '3',
    name: 'Sanmuga Vel',
    Companyname: 'A.K Corporation',
    mobilenumber: '9874563210',
    email: 'saravan@gmail.com',
    gst:'18AABCT3518Q1ZV',
    address: 'chennai',
    turnover: '₹ 50,000',
  },

];

export const HEADING_DATA = [
  'S.No',
  'Name',
  'Companyname',
  'Mobile',
  'Email',
  'GST',
  'Address',
  'Total Kgs Purchased',
  'Levels',
  'Actions',
];

export const TABLE_DATA = [
    {
      order_id: '08142678',
      name: "Akhil",
      phoneNumber: "8985747557",
      userRole: "merchant",
      products : [
        {
          "amount" : "400",
          "quantity" : 1,
          "product_id" : "5e11bc006a63de0385496f2d",
          "price" : "100",
          "weight" : "5",
          "main_image" : "https://res.cloudinary.com/dgvup74b7/image/upload/v1578220413/jgiddrfhgwwwrmkz3oxc.jpg",
          "name" : "Basmati rice"
        },
        {
          "amount" : "4000",
          "quantity" : 2,
          "product_id" : "5e11bc006a63de0385496f2d",
          "price" : "400",
          "weight" : "10",
          "main_image" : "https://res.cloudinary.com/dgvup74b7/image/upload/v1578220413/jgiddrfhgwwwrmkz3oxc.jpg",
          "name" : "Basmati rice"
        },
        {
          "amount" : "800",
          "quantity" : 5,
          "product_id" : "5e11bc006a63de0385496f2d",
          "price" : "1000",
          "weight" : "20",
          "main_image" : "https://res.cloudinary.com/dgvup74b7/image/upload/v1578220413/jgiddrfhgwwwrmkz3oxc.jpg",
          "name" : "Basmati rice"
        }
      ],
      status: "ORDER_REQUESTED",   /* "ORDER_REQUESTED", "INVOICE_GENERATED", "DRIVER_ASSIGNED", "VEHICLE_ASSIGNED", "OUT_OF_DELIVERY", "DELIVERED" */
      order_total: 3000,
      delivery_address : {
        addressType : "WORK",
        detailedAddress : "620, Spaces, EA tower 2, 50l, Whites Road, Express Estate, Royapettah, Chennai, 600014",
        pincode : "600014"
      },
      ordered_date: "02-07-20"
    },
    {
      order_id: '08142678',
      name: "siva sai akhil",
      phoneNumber: "8985747557",
      userRole: "merchant",
      products : [
        {
          "amount" : "400",
          "quantity" : 1,
          "product_id" : "5e11bc006a63de0385496f2d",
          "price" : "400",
          "weight" : "5",
          "main_image" : "https://res.cloudinary.com/dgvup74b7/image/upload/v1578220413/jgiddrfhgwwwrmkz3oxc.jpg",
          "name" : "Basmati rice"
        },
        {
          "amount" : "400",
          "quantity" : 1,
          "product_id" : "5e11bc006a63de0385496f2d",
          "price" : "400",
          "weight" : "5",
          "main_image" : "https://res.cloudinary.com/dgvup74b7/image/upload/v1578220413/jgiddrfhgwwwrmkz3oxc.jpg",
          "name" : "Basmati rice"
        },
        {
          "amount" : "400",
          "quantity" : 1,
          "product_id" : "5e11bc006a63de0385496f2d",
          "price" : "400",
          "weight" : "5",
          "main_image" : "https://res.cloudinary.com/dgvup74b7/image/upload/v1578220413/jgiddrfhgwwwrmkz3oxc.jpg",
          "name" : "Basmati rice"
        },
        {
          "amount" : "400",
          "quantity" : 1,
          "product_id" : "5e11bc006a63de0385496f2d",
          "price" : "400",
          "weight" : "5",
          "main_image" : "https://res.cloudinary.com/dgvup74b7/image/upload/v1578220413/jgiddrfhgwwwrmkz3oxc.jpg",
          "name" : "Basmati rice"
        }
      ],
      status: "INVOICE_GENERATED",   /* "ORDER_REQUESTED", "INVOICE_GENERATED", "DRIVER_ASSIGNED","VEHICLE_ASSIGNED", "OUT_OF_DELIVERY", "DELIVERED" */
      order_total: 3000,
      delivery_address : {
        addressType : "WORK",
        detailedAddress : "620, Spaces, EA tower 2, 50l, Whites Road, Express Estate, Royapettah, Chennai, 600014",
        pincode : "600014"
      },
      ordered_date: "02-07-20"
    },
    {
      order_id: '08142678',
      name: "Akhil",
      phoneNumber: "8985747557",
      userRole: "merchant",
      products : [
        {
          "amount" : "400",
          "quantity" : 1,
          "product_id" : "5e11bc006a63de0385496f2d",
          "price" : "400",
          "weight" : "5",
          "main_image" : "https://res.cloudinary.com/dgvup74b7/image/upload/v1578220413/jgiddrfhgwwwrmkz3oxc.jpg",
          "name" : "Basmati rice"
        }
      ],
      status: "CANCELLED", /* "ORDER_REQUESTED", "INVOICE_GENERATED", "DRIVER_ASSIGNED", "VEHICLE_ASSIGNED", "OUT_OF_DELIVERY", "DELIVERED" */
      order_total: 3000,
      delivery_address : {
        addressType : "WORK",
        detailedAddress : "620, Spaces, EA tower 2, 50l, Whites Road, Express Estate, Royapettah, Chennai, 600014",
        pincode : "600014"
      },
      ordered_date: "02-07-20"
    },
    {
      order_id: '08142678',
      name: "Akhil",
      phoneNumber: "8985747557",
      userRole: "merchant",
      products : [
        {
          "amount" : "400",
          "quantity" : 1,
          "product_id" : "5e11bc006a63de0385496f2d",
          "price" : "400",
          "weight" : "5",
          "main_image" : "https://res.cloudinary.com/dgvup74b7/image/upload/v1578220413/jgiddrfhgwwwrmkz3oxc.jpg",
          "name" : "Basmati rice"
        }
      ],
      status: "DRIVER_ASSIGNED", /* "ORDER_REQUESTED", "INVOICE_GENERATED", "DRIVER_ASSIGNED", "VEHICLE_ASSIGNED", "OUT_OF_DELIVERY", "DELIVERED" */
      order_total: 3000,
      delivery_address : {
        addressType : "WORK",
        detailedAddress : "620, Spaces, EA tower 2, 50l, Whites Road, Express Estate, Royapettah, Chennai, 600014",
        pincode : "600014"
      },
      ordered_date: "02-07-20"
    },
    
    {
      order_id: '08142678',
      name: "Akhil",
      phoneNumber: "8985747557",
      userRole: "merchant",
      products : [
        {
          "amount" : "400",
          "quantity" : 1,
          "product_id" : "5e11bc006a63de0385496f2d",
          "price" : "400",
          "weight" : "5",
          "main_image" : "https://res.cloudinary.com/dgvup74b7/image/upload/v1578220413/jgiddrfhgwwwrmkz3oxc.jpg",
          "name" : "Basmati rice"
        }
      ],
      status: "VEHICLE_ASSIGNED",  /* "ORDER_REQUESTED", "INVOICE_GENERATED", "DRIVER_ASSIGNED", "VEHICLE_ASSIGNED", "OUT_OF_DELIVERY", "DELIVERED" */
      order_total: 3000,
      delivery_address : {
        addressType : "WORK",
        detailedAddress : "620, Spaces, EA tower 2, 50l, Whites Road, Express Estate, Royapettah, Chennai, 600014",
        pincode : "600014"
      },
      ordered_date: "02-07-20"
    },
    {
      order_id: '08142678',
      name: "Akhil",
      phoneNumber: "8985747557",
      userRole: "merchant",
      products : [
        {
          "amount" : "400",
          "quantity" : 1,
          "product_id" : "5e11bc006a63de0385496f2d",
          "price" : "400",
          "weight" : "5",
          "main_image" : "https://res.cloudinary.com/dgvup74b7/image/upload/v1578220413/jgiddrfhgwwwrmkz3oxc.jpg",
          "name" : "Basmati rice"
        }
      ],
      status: "OUT_OF_DELIVERY",   /* "ORDER_REQUESTED", "INVOICE_GENERATED", "DRIVER_ASSIGNED", "VEHICLE_ASSIGNED", "OUT_OF_DELIVERY", "DELIVERED" */
      order_total: 3000,
      delivery_address : {
        addressType : "WORK",
        detailedAddress : "620, Spaces, EA tower 2, 50l, Whites Road, Express Estate, Royapettah, Chennai, 600014",
        pincode : "600014"
      },
      ordered_date: "02-07-20"
    },
    {
      order_id: '08142678',
      name: "Akhil",
      phoneNumber: "8985747557",
      userRole: "merchant",
      products : [
        {
          "amount" : "400",
          "quantity" : 1,
          "product_id" : "5e11bc006a63de0385496f2d",
          "price" : "400",
          "weight" : "5",
          "main_image" : "https://res.cloudinary.com/dgvup74b7/image/upload/v1578220413/jgiddrfhgwwwrmkz3oxc.jpg",
          "name" : "Basmati rice"
        }
      ],
      status: "DELIVERED",   /* "ORDER_REQUESTED", "INVOICE_GENERATED", "DRIVER_ASSIGNED", "VEHICLE_ASSIGNED", "OUT_OF_DELIVERY", "DELIVERED" */
      order_total: 3000,
      delivery_address : {
        addressType : "WORK",
        detailedAddress : "620, Spaces, EA tower 2, 50l, Whites Road, Express Estate, Royapettah, Chennai, 600014",
        pincode : "600014"
      },
      ordered_date: "02-07-20"
    },

   ];
   
   export const HEADING_DATA = [
    'Order ID',
    'Products',
    'Billing',
    'Assign Driver & Vehicle',
    'Status',
    'Delivery Address',
    'User Details',

   ];
   

  /* "ORDER_REQUESTED", "ACCEPTED", "CANCELLED", "DA_ASSIGNED", "DA_ACCEPTED", "DA_REJECTED", "DA_REACHED_WAREHOUSE", "DA_ORDER_PICKED_UP", "COMPLETED" */

  /* "GREEN": "#93c47d", "RED": "#e06666", "YELLOW": "#ffd966", "BLUE": "#6d9eeb", "ORANGE": "#f6b26b" */

  /* "ORDER_REQUESTED", "INVOICE_GENERATED", "DRIVER_ASSIGNED", "VEHICLE_ASSIGNED", "OUT_OF_DELIVERY", "DELIVERED" */
import React from "react";
import WeighBridgeTable from "./weighBridgeTable.component";

const WeighBridge = (props) => {
  return (
    <div className="DefaultLayout">
      <Header />
      <Card history={props.history} />
    </div>
  );
};

const Header = () => {
  return (
    <div className="HeaderLayout">
      <div style={{ paddingLeft: 20, paddingBottom: 20 }}>
        <h3>Weigh Bridge</h3>
      </div>
    </div>
  );
};

const Card = (props) => {

  return (
    <div className="CardContainer">
      <WeighBridgeTable history={props.history} />
    </div>
  );
};

export default WeighBridge;

import React, { useEffect, useState } from "react";
import {
  Table,
  Label,
  Modal,
  Icon,
  Checkbox,
  Button,
  Input,
  Form,
  TextArea,
} from "semantic-ui-react";
import toastr from "toastr";
import _ from "lodash";
import { HEADING_DATA } from "./merchants.constant";
import { BASE_URL } from "../../../config";

const MerchantsTable = (props) => {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [credit_limit, setCreditLimit] = useState("");
  const [selectedMerchant, setMerchantID] = useState("");
  const [size, setSize] = useState("");
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);

  useEffect(() => {
    fetchMerchants();
  }, []);

  const fetchMerchants = () => {
    fetch(`${BASE_URL}/get-merchants`)
      .then((res) => res.json())
      .then((json) => {
        setMerchants(json.merchants);
      });
  };

  const handleSort = (clickedColumn) => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setMerchants(_.sortBy(merchants, [clickedColumn]));
      setDirection("ascending");

      return;
    }

    setMerchants(merchants.reverse());
    setDirection(direction === "ascending" ? "descending" : "ascending");
  };

  const editMerchant = (size, customer) => () => {
    setSize(size);
    setOpen(true);
    setEdit(true);
    setMerchantID(customer._id);
    setName(customer.name);
    setCompanyName(customer.companyName);
    setEmail(customer.email);
    setPhoneNumber(customer.phoneNumber);
    setAddress(customer.address);
    setGstNo(customer.gstNo);
    setCreditLimit(customer.credit_limit !== undefined ? customer.credit_limit : "");
  };

  const handleUpdate = () => {
    fetch(`${BASE_URL}/update-merchant?merchant_id=${selectedMerchant}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        companyName,
        gstNo,
        phoneNumber,
        email,
        address,
        credit_limit
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        toastr.success('Merchannt Updated Successfully!');
        fetchMerchants();
        close();
      });
  };

  const deleteMerchant = (size, ID) => () => {
    setSize(size);
    setOpen(true);
    setMerchantID(ID);
  };

  const handleDelete = () => {
    fetch(`${BASE_URL}/delete-merchant?merchantID=${selectedMerchant}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((json) => {
        fetchMerchants();
        close();
      });
  };

  const close = () => {
    setOpen(false);
    setEdit(false);
  };

  const merchants_table = merchants.filter((merchant) =>
    Object.keys(merchant).some(
      (key) => merchant[key].toString().search(props.searchString) !== -1
    )
  );

  return (
    <div>
      <Table
        sortable
        unstackable
        celled
        basic="very"
        className="CustomTableLayout"
        id="merchant-table"
      >
        <Table.Header>
          <Table.Row>
            {HEADING_DATA.map((heading) => (
              <Table.HeaderCell
                sorted={column === heading ? direction : null}
                onClick={handleSort(heading)}
              >
                {heading === "Checkbox" ? <Checkbox /> : <span>{heading}</span>}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {merchants_table.map((merchant, index) => (
            <Table.Row>
              <Table.Cell>{index + 1}</Table.Cell>
              <Table.Cell>
                <a
                  onClick={() =>
                    props.history.push({
                      pathname: `/profile-merchant/${merchant.phoneNumber}`,
                    })
                  }
                >
                  {merchant.name}{" "}<Icon name="eye" style={{ marginLeft: 5 }} />
                </a>
              </Table.Cell>
              <Table.Cell>{merchant.companyName}</Table.Cell>
              <Table.Cell>{merchant.phoneNumber}</Table.Cell>
              <Table.Cell>{merchant.email || 'UNAVAILABLE'}</Table.Cell>
              <Table.Cell>{merchant.gstNo || 'NOT_AVAILABLE'}</Table.Cell>
              <Table.Cell>
                {merchant.address} &nbsp;
                <a href={`https://maps.google.com/?q=${merchant.address}`} target="blank"><Label color="green">View</Label></a>
              </Table.Cell>
              <Table.Cell>
                {merchant.totalPurchasedKgs} Kgs &nbsp;
                <Label
                  color="green"
                  as="a"
                  onClick={() => window.open(`/#/product-sales/${merchant.phoneNumber}`, "_blank")}>Sales Report</Label>
              </Table.Cell>
              <Table.Cell>
                <Label color="yellow">{merchant.currentLevel.currentLevelName !== undefined ? merchant.currentLevel.currentLevelName : "ZERO"}</Label>
              </Table.Cell>

              <Table.Cell style={{ display: 'flex' }}>
                <Button
                  style={{ fontSize: 13, backgroundColor: "#f3f3f3", marginRight: 10 }}
                  onClick={editMerchant("large", merchant)}
                >
                  <Icon name="edit" />
                  Edit
                </Button>

                <Button
                  style={{ fontSize: 13, backgroundColor: "#f4cccc" }}
                  onClick={deleteMerchant("mini", merchant._id)}
                >
                  <Icon name="trash" /> Delete
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {edit === true ? (
        <Modal size={size} open={open} onClose={close}>
          <Modal.Header>Edit Merchant </Modal.Header>
          <Modal.Content>
            <div className="merchantFormRow">
              <p className="merchantFormTitle">Name:</p>
              <Input
                value={name}
                style={{ width: "50%" }}
                maxLength="20"
                onChange={(evt) => setName(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {name.length}/20
              </p>
            </div>

            <div className="merchantFormRow">
              <p className="merchantFormTitle">Company Name:</p>
              <Input
                value={companyName}
                style={{ width: "50%" }}
                maxLength="25"
                onChange={(evt) => setCompanyName(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {companyName.length}/20
              </p>
            </div>

            <div className="merchantFormRow">
              <p className="merchantFormTitle">Mobile number:</p>
              <Input
                value={phoneNumber}
                style={{ width: "50%" }}
                maxLength="10"
                onChange={(evt) => setPhoneNumber(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {phoneNumber.length}/10
              </p>
            </div>
            <div className="merchantFormRow">
              <p className="merchantFormTitle">Email:</p>
              <Input
                value={email}
                style={{ width: "50%" }}
                maxLength="25"
                onChange={(evt) => setEmail(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {email.length}/25
              </p>
            </div>
            <div className="merchantFormRow">
              <p className="merchantFormTitle">Address:</p>
              <Form style={{ width: "50%" }}>
                <TextArea
                  value={address}
                  cols="50"
                  rows={4}
                  maxLength="100"
                  style={{ paddingRight: 50 }}
                  onChange={(evt) => setAddress(evt.target.value)}
                />
              </Form>
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -50,
                  marginTop: 50,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {address.length}/100
              </p>
            </div>
            <div className="merchantFormRow">
              <p className="merchantFormTitle">GST IN: </p>
              <Input
                value={gstNo}
                style={{ width: "50%" }}
                maxLength="15"
                onChange={(evt) => setGstNo(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {gstNo.length}/15
              </p>
            </div>
            <div className="merchantFormRow">
              <p className="merchantFormTitle">Credit Limit(Days): </p>
              <Input
                value={credit_limit}
                style={{ width: "50%" }}
                maxLength="3"
                onChange={(evt) => setCreditLimit(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {credit_limit.length}/3
              </p>
            </div>
          </Modal.Content>
          <Modal.Actions style={{ textAlign: "center" }}>
            <Button className="addCustomerCancelBtn" onClick={close}>
              Cancel
            </Button>
            <Button
              className="addCustomersubmitBtn"
              onClick={() => handleUpdate()}
              content="Update"
            />
          </Modal.Actions>
        </Modal>
      ) : (
        <Modal size={size} open={open} onClose={close}>
          <Modal.Header>Delete Merchant </Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete merchant ?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button className="addCustomerCancelBtn" onClick={close}>
              No
            </Button>
            <Button
              className="addCustomersubmitBtn"
              icon="checkmark"
              labelPosition="right"
              content="Yes"
              onClick={() => handleDelete()}
            />
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
};

export default MerchantsTable;

import React, { useEffect, useState } from "react";
import { Form, TextArea, Checkbox, Button, Icon } from "semantic-ui-react";
import toastr from "toastr";
import { BASE_URL } from "../../../../config";

const DriverAnnouncement = () => {
  const [showAnnouncementToggle, setShowAnnouncementToggle] = useState([]);
  const [message, setMessage] = useState("");
  const [background_color, setBackgroundColor] = useState("red");
  const [driverSettingsID, setSettingsID] = useState("");
  const [noData, setNoData] = useState("");

  useEffect(() => {
    fetchDriverSettings();
  }, []);

  const fetchDriverSettings = () => {
    fetch(`${BASE_URL}/get-driver-announcements`)
      .then((res) => res.json())
      .then((json) => {
        if (json.announcements[0] === undefined) {
          setNoData("true");
          setMessage("");
          setBackgroundColor("");
          setShowAnnouncementToggle(false);
        } else {
          setNoData("false");
          setSettingsID(json.announcements[0]._id);
          setMessage(json.announcements[0].message);
          setBackgroundColor(json.announcements[0].background_color);
          setShowAnnouncementToggle(json.announcements[0].status);
        }
      });
  };

  const saveDriverSettings = () => {
    fetch(`${BASE_URL}/create-driver-announcements`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message,
        background_color,
        status: showAnnouncementToggle,
        setting_type: "announcements",
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        fetchDriverSettings();
        toastr.success("Driver Settings Saved");
      });
  };

  const updateDriverSettings = () => {
    fetch(
      `${BASE_URL}/update-driver-announcements?driver_settings_id=${driverSettingsID}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message,
          background_color,
          status: showAnnouncementToggle,
          setting_type: "announcements",
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        fetchDriverSettings();
        toastr.success("Driver Settings Updated Successfuly");
      });
  };

  const handleToggle = () => {
    if (showAnnouncementToggle === true) {
      setShowAnnouncementToggle(false);
    } else {
      setShowAnnouncementToggle(true);
    }
  };

  const announcementColors = ["red", "orange", "yellow", "olive", "green"];

  return (
    <div className="TabContainer">
      <div className="driverAppAnnouncementTitleRow">
        <h4>Driver-App Announcement :</h4>
        <div style={{ display: "flex" }}>
          <p>
            Switch to turn {showAnnouncementToggle === true ? "OFF" : "ON"}{" "}
            announcements:
          </p>
          <div style={{ marginLeft: 10 }}>
            <Checkbox
              toggle
              checked={showAnnouncementToggle}
              onChange={handleToggle}
            />
          </div>
        </div>
      </div>

      <div className="announcementMsgRow">
        <p className="announcementMsgTitle">Announcement message:</p>
        <Form className="driverAppAnnouncementForm">
          <TextArea
            placeholder="Enter a Announcement Message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Form>
      </div>

      <div className="announcementMsgColorRow">
        <p className="announcementMsgTitle">Select background color:</p>
        <div className="driverAppAnnouncementFormColor">
          {announcementColors.map((color) => (
            <Icon
              circular
              inverted
              color={color}
              name={color === background_color ? "circle" : "circle outline"}
              onClick={() => setBackgroundColor(color)}
              size="small"
            />
          ))}
        </div>
      </div>
      {noData === "true" ? (
        <div className="SubmitBtnRow">
          <Button
            className="submitBtn"
            content="Submit"
            onClick={saveDriverSettings}
          />
        </div>
      ) : (
        <div className="SubmitBtnRow">
          <Button
            className="submitBtn"
            content="Update"
            onClick={updateDriverSettings}
          />
        </div>
      )}
    </div>
  );
};

export default DriverAnnouncement;

import React, { Component } from 'react';
import { Grid, Checkbox, Responsive } from 'semantic-ui-react';

const MarketingExecutiveSmsNotificationOptions = () => {
  return (
    <React.Fragment>
      <Grid.Row>
        <Grid.Column>
          <Checkbox label='Marketing Executive Signup' />
        </Grid.Column>
        <Grid.Column>
          <Checkbox label='Received Payout' />
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );
};

export class SmsMarketingExecutiveNotifications extends Component {
  render() {
    return (
      <div>
        <div style={{ marginBottom: 20, marginTop: 40, marginLeft: 20 }}>
          <h4>Marketing Executive Notifications</h4>
        </div>

        <div style={{ margin: 20 }}>
          <div>
            <Responsive maxWidth={768}>
              <Grid columns={1} divided>
                <MarketingExecutiveSmsNotificationOptions />
              </Grid>
            </Responsive>
            <Responsive minWidth={768}>
              <Grid columns={3} divided>
                <MarketingExecutiveSmsNotificationOptions />
              </Grid>
            </Responsive>
          </div>
        </div>
      </div>
    );
  }
}

export default SmsMarketingExecutiveNotifications;

import React from "react";
import { Card, Select, Statistic, Icon } from "semantic-ui-react";
import CustomerChart from "./charts/customer.chart";
import MerchantChart from "./charts/merchant.chart";
import DriverChart from "./charts/driver.chart";
import TurnoverChart from "./charts/turnover.chart";
import OrderRequestsChart from "./charts/orderrequests.chart";
import CompletedOrdersChart from "./charts/completedorders.chart";
import CancelledOrdersChart from "./charts/cancelledorders.chart";
import RefundedOrdersChart from "./charts/refundedorders.chart";
import FeedbackChart from "./charts/feedback.chart";
import MerchantActivityChart from "./charts/merchantactivity.chart";
import "./reports.css";

const memberOptions = [
  { key: "today", value: "today", text: "Today" },
  { key: "month", value: "month", text: "This Month" },
  { key: "financialyear", value: "financialyear", text: "This Financial Year" },
  { key: "calenderyear", value: "calenderyear", text: "This Calender Year" },
];

const Reports = () => {
  return (
    <div className="ReportLayout">
      <Header />
      <Report />
    </div>
  );
};

const Header = () => {
  return (
    <div style={{ paddingLeft: 40, paddingTop: 20 }}>
      <h3>Reports</h3>
    </div>
  );
};

const Report = () => {
  return (
    <div style={{ padding: 20 }}>
      <Card.Group className="chatsRowContainer">
        <Card className="CustomerReportCard">
          <CustomerChart />
        </Card>
        <Card className="CustomerReportCard">
          <MerchantChart />
        </Card>
        <Card className="CustomerReportCard">
          <DriverChart />
        </Card>
      </Card.Group>

      <Card.Group className="chatsRowContainer">
        <Card className="SalesReportCard">
          <TurnoverChart />
        </Card>
        <Card className="SalesReportCard">
          <OrderRequestsChart />
        </Card>
      </Card.Group>

      <Card.Group className="chatsRowContainer">
        <Card className="OrdersReportCard">
            <CompletedOrdersChart />

            {/* <div>
              <Statistic color="red" size="mini" className="margin0">
                <Statistic.Value>
                  <Icon name="caret down" color="red" /> 20%
                </Statistic.Value>
              </Statistic>
            </div> */}
        </Card>

        <Card className="OrdersReportCard">
            <CancelledOrdersChart />

            {/* <div>
              <Statistic color="green" size="mini" className="margin0">
                <Statistic.Value>
                  <Icon name="caret up" color="green" /> 10%
                </Statistic.Value>
              </Statistic>
            </div> */}
        </Card>
        <Card className="OrdersReportCard">
            <RefundedOrdersChart />
            {/* <div>
              <Statistic color="green" size="mini" className="margin0">
                <Statistic.Value>
                  <Icon name="caret up" color="green" /> 15%
                </Statistic.Value>
              </Statistic>
            </div> */}
        </Card>
      </Card.Group>

      <Card.Group className="chatsRowContainer">
        <Card className="FeedbackReportCard">
          <FeedbackChart />
        </Card>
        <Card className="FeedbackReportCard">
          <MerchantActivityChart />
        </Card>
      </Card.Group>
    </div>
  );
};

export default Reports;

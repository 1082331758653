import React, { useState, useEffect } from "react";
import {
  Table,
  Label,
  Icon,
  Checkbox,
  Button,
  Dropdown,
  List,
  Pagination,
  Input,
  Select,
  Modal,
  TextArea
} from "semantic-ui-react";
import toastr from "toastr";
import { HEADING_DATA } from "./orderrequests.constant";
import {
  getDeliveryAgents,
  getDeliveryVehicles,
  updateDeliveryAgent,
  updateDeliveryVehicle,
  updateOrderStatus,
  cancelOrderRequest
} from "./orderrequests.api";
import "./orderrequests.css";
import OrderDetailsModal from "./orderdetails_modal";
import { getOrders } from "./orderrequests.api";
import ViewInvoice from "./view_invoice";
import TableExport from "tableexport";
import DateRangePicker from "react-daterange-picker";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import swal from "sweetalert";
import { BASE_URL } from "../../../config";

const OrderType = [
  { key: "af", value: "ORDER_REQUESTED", text: "Pending Orders" },
  { key: "ax", value: "DELIVERED", text: "Completed Orders" },
  { key: "al", value: "", text: "All Orders" },
];

const statusMap = [
  "DRIVER_ASSIGNED",
  "OUT_FOR_DELIVERY",
  "OTP_VERIFIED",
  "DELIVERED",
];

const moment = extendMoment(originalMoment);

const OrderRequestsTable = (props) => {
  const today = moment();

  const [showMore, setShowMore] = useState(false);
  const [orders, setOrders] = useState([]);
  const [latestDate, setDate] = useState("");

  //Pagination
  const [paginatedData, setPaginatedData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [per_page, setPerPage] = useState(0);
  const [total_pages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);

  // Print
  const [printStatus, setPrintStatus] = useState(false);
  const [invoiceNo, setInvoiceNo] = useState(0);
  const [colName, setColName] = useState("");
  const [challan, setChallan] = useState(false);

  // Store Delivery
  const [openModal, setOpenModal] = useState(false);
  const [size] = useState("tiny");
  const [selectedOrder, setSelectedOrder] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [driverName, setDriverName] = useState("");
  const [receivedCash, setReceivedCash] = useState(0);
  const [orderOTP, SetOrderOTP] = useState(0);

  // Order Cancellation
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [reasonForCancel, setReasonForCancel] = useState("");

  // Header Filters
  const [searchString, setSearchString] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState(
    moment.range(today.clone().subtract(7, "days"), today.clone())
  );

  useEffect(() => {
    fetchAllOrders();
  }, []);

  const fetchAllOrders = () => {
    getOrders().then((res) => {
      setOrders(res.orders);
      paginator(res.orders);
      setDate(Date.now());
    });
  };

  const onToggle = () => {
    setOpen(!isOpen);
  };

  const handleClose = () => {
    setOpenModal(false);
    setOpenCancelModal(false);
  };

  const handleShowMore = () => {
    return setShowMore({ showMore: !showMore });
  };

  const paginator = (items, page, per_page) => {
    var page = page || 1,
      per_page = per_page || 10,
      offset = (page - 1) * per_page,
      paginatedItems = items.slice(offset).slice(0, per_page),
      total_pages = Math.ceil(items.length / per_page);
    setActivePage(page);
    setPerPage(per_page);
    setTotal(items.length);
    setTotalPages(total_pages);
    setPaginatedData(paginatedItems);
  };

  const AssignDA = ({ status, orderid }) => {
    const [agents, setAgents] = useState([]);
    const [selectedDA, setSelectedDA] = useState("");

    useEffect(() => {
      getDeliveryAgents()
        .then((json) => {
          setAgents(json.drivers);
        })
        .catch((err) => {
          console.log(err);
        });

      const timer = setInterval(() => {
        getDeliveryAgents()
          .then((json) => {
            setAgents(json.drivers);
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }, []);

    function updateDeliveryAgentAction() {
      if (selectedDA.length > 0) {
        const agent = agents.filter((agent) => selectedDA === agent._id);

        updateDeliveryAgent({ agent: agent[0], orderid }).then((json) => {
          toastr.success("Delivery Agent Assigned");
          fetchAllOrders();
        });
      } else {
        toastr.error('Please select driver to Assign!')
      }

    }

    return (
      <div>
        {agents.length > 0 ? (
          <div>
            <Dropdown
              placeholder="Assign Driver"
              fluid
              search
              selection
              options={agents.map((agent) => ({
                key: agent.name,
                value: agent._id,
                text: agent.name,
              }))}
              onChange={(event, data) => setSelectedDA(data.value)}
              style={{ width: 200 }}
            />
            {status === "INVOICE_GENERATED" || status === "DRIVER_REJECTED" ? (
              <Button
                onClick={updateDeliveryAgentAction}
                style={{ marginTop: 20 }}
                primary
              >
                Update
              </Button>
            ) : null}{" "}
          </div>
        ) : (
          <p>No Drivers Available to Assign</p>
        )}
      </div>
    );
  };

  const AssignVehicle = ({ status, orderid }) => {
    const [vehicles, setVehicles] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState("");

    useEffect(() => {
      getDeliveryVehicles()
        .then((json) => {
          setVehicles(json.assets);
        })
        .catch((err) => {
          console.log(err);
        });

      const timer = setInterval(() => {
        getDeliveryVehicles()
          .then((json) => {
            setVehicles(json.assets);
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }, []);

    function updateDeliveryVehicleAction() {
      if (selectedVehicle.length > 0) {
        const vehicle = vehicles.filter(
          (vehicle) => selectedVehicle === vehicle._id
        );

        updateDeliveryVehicle({ vehicle: vehicle[0], orderid }).then((json) => {
          toastr.success("Delivery Vehicle Assigned");
          fetchAllOrders();
        });
      } else {
        toastr.error("Please select vehicle to Assign!")
      }
    }

    return (
      <div>
        {vehicles.length > 0 ? (
          <div>
            <Dropdown
              placeholder="Assign Vehicle"
              fluid
              search
              selection
              options={vehicles.map((vehicle) => ({
                key: vehicle._id,
                value: vehicle._id,
                text: vehicle.asset_name,
              }))}
              onChange={(event, data) => setSelectedVehicle(data.value)}
              style={{ width: 200 }}
            />
            {status === "DRIVER_ASSIGNED" ? (
              <Button
                onClick={updateDeliveryVehicleAction}
                style={{ marginTop: 20 }}
                primary
              >
                Assign
              </Button>
            ) : null}{" "}
          </div>
        ) : (
          <p>No Delivery Vehicles available</p>
        )}
      </div>
    );
  };

  const renderProducts = (order) => (
    <React.Fragment>
      {order.products.length > 2 ? (
        <React.Fragment>
          {showMore === false ? (
            <React.Fragment>
              {order.products.slice(1, 3).map((product, index) => (
                <React.Fragment>
                  <p>
                    {index + 1}) {product.name}: {product.quantity} x{" "}
                    {product.weight}Kg
                  </p>
                </React.Fragment>
              ))}
              <p
                style={{ color: "#6d9eeb", cursor: "pointer" }}
                onClick={handleShowMore}
              >
                Show more <Icon name="long arrow alternate down" size="small" />
              </p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {order.products.map((product, index) => (
                <React.Fragment>
                  <p>
                    {index + 1}) {product.name}: {product.quantity} x{" "}
                    {product.weight}Kg
                  </p>
                </React.Fragment>
              ))}
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {order.products.map((product, index) => (
            <React.Fragment>
              <p>
                {index + 1}) {product.name}: {product.quantity} x{" "}
                {product.weight}Kg
              </p>
            </React.Fragment>
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  );

  const printInvoice = (inv_no, cname, driver_challan) => {

    setInvoiceNo(inv_no);
    setColName(cname);
    setPrintStatus(true);
    if (driver_challan === true) {
      setChallan(true);
    } else {
      setChallan(false);
    }
    setTimeout(function () {
      var printContents = document.getElementById("capture").innerHTML;
      var originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;

      window.print();

      document.body.innerHTML = originalContents;
      window.location.reload();
    }, 1000);
  };

  const onSelect = (value) => {
    const start = value.start.format("YYYY-MM-DD");
    const end = value.end.format("YYYY-MM-DD");
    const ordersData = orders.filter((order) => {
      return new Date(order.ordered_date) >= new Date(start) && new Date(order.ordered_date) <= new Date(end);
    })
    setPaginatedData(ordersData);
    setValue(value);
    setOpen(!isOpen);
  };

  const handleUpdateDelivery = () => {
    if (vehicleNumber.length === 0) {
      toastr.error('Please Enter Delivery Vehicle Number!')
    } else if (driverName.length === 0) {
      toastr.error('Please Enter Driver Name!')
    } else if (receivedCash.length === 0) {
      toastr.error('Please Enter Valid Amount!')
    } else if (parseInt(orderOTP) !== parseInt(selectedOrder.OTP)) {
      toastr.error('Please Enter Valid Delivery OTP To Proceed!')
    } else {
      fetch(`${BASE_URL}/update-store-delivery-order`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            order_id: selectedOrder.order_id,
            vehicle_number: vehicleNumber,
            driver_name: driverName,
            received_cash: receivedCash,
            customer_phone: selectedOrder.phone_number,
            cname: selectedOrder.cname,
            status: "DELIVERED"
          }),
        }
      )
        .then((res) => res.json())
        .then((json) => {
          setOpenModal(false);
          toastr.success("Order Delivered Successfully!");
          fetchAllOrders();
        });
    }
  }

  const handleCancelDelivery = () => {
    if (reasonForCancel.length === 0) {
      toastr.error('Please enter valid reason to cancel the order!')
    } else {
      cancelOrderRequest({
        id: selectedOrder._id,
        reason: reasonForCancel
      }).then((res) => {
        if (res.status === 200) {
          setOpenCancelModal(false);
          swal("Good job!", "Order Cancelled Successfully!", "success");
          fetchAllOrders();
        }
      });
    }
  }

  const orderData = paginatedData.filter((order) =>
    Object.keys(order).some(
      (key) => String(order[key]).search(searchString) !== -1
    )
  );

  return (
    <div className="CardContainer">
      <div className="PageTitleRow">
        <div>
          <Input
            placeholder="Quick Search..."
            icon="search"
            style={{ marginTop: 10 }}
            onChange={(e) => setSearchString(e.target.value)}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <Select
            placeholder="Sort by"
            options={OrderType}
            style={{ margin: "auto" }}
            onChange={(event, data) => setSearchString(data.value)}
          />
        </div>
        <div style={{ marginTop: 10, textAlign: 'center' }}>
          Select Range:{" "}
          <input
            type="text"
            onClick={onToggle}
            style={{ width: 170 }}
            value={`${value.start.format("DD-MM-YYYY")} - ${value.end.format(
              "DD-MM-YYYY"
            )}`}
          />
          {isOpen && (
            <DateRangePicker
              value={value}
              onSelect={onSelect}
              singleDateRange={true}
            />
          )}
        </div>
        <div style={{ marginTop: 10 }}>
          <Button
            icon
            labelPosition="right"
            style={{
              backgroundColor: "#d9ead3",
              color: "#000",
              marginBottom: 15,
            }}
            onClick={() => {
              toastr.success("Exported Buttons Enabled Successfully");
              const table = new TableExport(
                document.getElementById("order-requests"),
                {
                  formats: ["csv"],
                  exportButtons: true,
                  position: "top",
                }
              );
              const link = document.createElement("a");
              link.setAttribute(
                "download",
                table.getExportData()["order-requests"].csv.filename
              );
              link.click();
            }}
          >
            Export
            <Icon name="share square icon" />
          </Button>
        </div>
      </div>
      <div
        style={{
          height: "-webkit-fill-available",
          overflow: "overlay",
          marginBottom: 75,
          paddingRight: 20,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <p>
            {" "}
            <Icon name="refresh" onClick={() => fetchAllOrders()} /> Last Updated
            At: {moment(latestDate).format("MMMM Do YYYY, h:mm:ss a")}{" "}
          </p>
        </div>
        <Table
          sortable
          unstackable
          celled
          basic="very"
          className="CustomTableLayout"
          collapsing
          id="order-requests"
        >
          <Table.Header>
            <Table.Row>
              {HEADING_DATA.map((heading) => (
                <Table.HeaderCell textAlign="center">
                  {heading === "Checkbox" ? <Checkbox /> : <span>{heading}</span>}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {orderData !== undefined && (
              <React.Fragment>
                {orderData.map((order) => (
                  <Table.Row>
                    <Table.Cell textAlign="center">
                      <OrderDetailsModal orderdetails={order} />
                      <p style={{ marginBottom: 5, marginTop: 5 }}>
                        {moment(order.ordered_date).format("DD MMM YYYY hh:mm A")}
                      </p>
                      <Icon name="rupee sign" size="small" />
                      {order.order_total}
                    </Table.Cell>
                    <Table.Cell>{renderProducts(order)}</Table.Cell>
                    <Table.Cell textAlign="center">
                      {order.status === "ORDER_REQUESTED" ? (
                        <div>
                          <Button
                            size="tiny"
                            style={{
                              marginBottom: 5,
                              backgroundColor: "#93c47d",
                              color: "#fff",
                            }}
                            onClick={() =>
                              props.history.push({
                                pathname: `/merchant-invoice-generation/${order.order_id}`,
                              })
                            }
                          >
                            Create Invoice
                          </Button>

                          <br />
                          <Button
                            size="tiny"
                            style={{ backgroundColor: "#e06666", color: "#fff" }}
                            onClick={() => {
                              setSelectedOrder(order);
                              setOpenCancelModal(true);
                            }}
                          >
                            Reject Order
                          </Button>
                        </div>
                      ) : (
                        <p> </p>
                      )}
                      {order.status !== "ORDER_REQUESTED" &&
                        order.status !== "CANCELLED" ? (
                        <React.Fragment>
                          {order.status === "INVOICE_GENERATED" && (
                            <Button color="green" onClick={() => {
                              setSelectedOrder(order);
                              setReceivedCash(0);
                              setOpenModal(true);
                            }}>
                              Deliver Now
                            </Button>
                          )}
                          <Button
                            onClick={() =>
                              props.history.push({
                                pathname: `/view-invoice/${order.cname}/${order.invoice_no}`,
                              })
                            }
                            style={{ backgroundColor: "#cfe2f3" }}
                          >
                            Invoice
                          </Button>

                          {printStatus === true && (
                            <div
                              style={{ display: "none" }}>
                              <ViewInvoice
                                invoice_no={invoiceNo}
                                collection_name={colName}
                                driver_challan={challan}
                                printDetails={printStatus}
                              />
                            </div>
                          )}

                          <Button
                            style={{ marginTop: 10 }}
                            onClick={() => {

                              printInvoice(order.invoice_no, order.cname, false)

                            }
                            }
                          >
                            Print
                          </Button>

                          {statusMap.indexOf(order.status) !== -1 &&
                            <Button
                              style={{ marginTop: 10 }}
                              onClick={() =>
                                printInvoice(order.invoice_no, order.cname, true)
                              }
                            >
                              Driver Challan
                            </Button>
                          }
                        </React.Fragment>
                      ) : (
                        <p></p>
                      )}
                      {order.reason !== undefined && (
                          <p style={{ marginTop: 5 }}>Reason For Cancellation: {order.reason}</p>
                      )}
                    </Table.Cell>

                    <Table.Cell textAlign="center">
                      {order.status === "INVOICE_GENERATED" || order.status === 'DRIVER_REJECTED' ? (
                        <React.Fragment>
                          <div style={{ flexDirection: "row" }}>
                            <AssignDA orderid={order._id} status={order.status} />
                          </div>
                        </React.Fragment>
                      ) : (
                        <p>{"-"} </p>
                      )}
                      {order.status === "DRIVER_ASSIGNED" ? (
                        <React.Fragment>
                          <div style={{ flexDirection: "row" }}>
                            <AssignVehicle
                              orderid={order._id}
                              status={order.status}
                            />
                          </div>
                        </React.Fragment>
                      ) : (
                        <p>{"-"}</p>
                      )}

                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {order.status === "ORDER_REQUESTED" ? (
                          <Label className="LabelColorYellow">{order.status}</Label>
                        ) : order.status === "OUT_FOR_DELIVERY" ? (
                          <Label className="LabelColorBlue">{order.status}</Label>
                        ) : order.status === "DELIVERED" ? (
                          <Label className="LabelColorGreen">{order.status}</Label>
                        ) : order.status === "CANCELLED" ? (
                          <Label className="LabelColorRed">{order.status}</Label>
                        ) : (
                          <Label className="LabelColorOrange">{order.status}</Label>
                        )}
                        <Label className="LabelColorGreen" style={{ marginTop: 5 }}>Delivery OTP: {order.OTP}</Label>
                        
                      </div>
                    </Table.Cell>
                    {/* <Table.Cell textAlign="center">
                      {[
                        "DA_ACCEPTED",
                        "DA_REACHED_WAREHOUSE",
                        "DA_ORDER_PICKED_UP"
                      ].indexOf(order.status) !== -1 ? (
                        <Button content="Maps" style={{ backgroundColor: "#f6b26b",color:"#fff" }} />
                      ) : (
                        <Label className="LabelColorRed">UNAVAILABLE</Label>
                      )}
                    </Table.Cell> */}
                    <Table.Cell textAlign="center">
                      <p>{order.company_name}, </p>
                      {order.shipping_address.detailedAddress}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <List>
                        <List.Item>
                          <List.Icon name="user" />
                          <List.Content
                            style={{ fontSize: 17, fontWeight: "600" }}
                          >
                            {order.name || "-"}
                          </List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Icon name="phone" />
                          <List.Content>{order.phone_number || "-"}</List.Content>
                        </List.Item>
                        {/* <List.Item>
                        <List.Icon name="marker" />
                        <List.Content>
                          {order.shipping_address.pincode || "-"}
                        </List.Content>
                      </List.Item> */}
                        <List.Item>
                          <List.Icon name="user secret" />
                          <List.Content>{order.user_role || "-"}</List.Content>
                        </List.Item>
                      </List>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </React.Fragment>
            )}
          </Table.Body>
        </Table>

        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Pagination
            activePage={activePage}
            onPageChange={(e, { activePage }) => paginator(orders, activePage)}
            totalPages={total_pages}
          />
        </div>

        <Modal size={size} open={openModal} onClose={handleClose}>
          <Modal.Header>Order Delivery</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to deliver this order #{selectedOrder.order_id}?</p>
            <div className="merchantFormRow">
              <p className="merchantFormTitle">Vehicle No:</p>
              <Input
                value={vehicleNumber}
                style={{ width: "50%" }}
                maxLength="10"
                onChange={(evt) => setVehicleNumber(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {vehicleNumber.length}/10
              </p>
            </div>
            <div className="merchantFormRow">
              <p className="merchantFormTitle">Driver Name:</p>
              <Input
                value={driverName}
                style={{ width: "50%" }}
                maxLength="20"
                onChange={(evt) => setDriverName(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {driverName.length}/20
              </p>
            </div>
            <div className="merchantFormRow">
              <p className="merchantFormTitle">Received Cash:</p>
              <Input
                value={receivedCash}
                style={{ width: "50%" }}
                maxLength="10"
                onChange={(evt) => setReceivedCash(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {receivedCash.length}/10
              </p>
            </div>
            <div className="merchantFormRow">
              <p className="merchantFormTitle">Balance Due Amount:</p>
              <Input
                value={selectedOrder.order_total - receivedCash}
                style={{ width: "50%" }}
                disabled
              />
            </div>
            <div className="merchantFormRow">
              <p className="merchantFormTitle">Order OTP:</p>
              <Input
                value={orderOTP}
                style={{ width: "50%" }}
                maxLength="4"
                onChange={(evt) => SetOrderOTP(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {orderOTP.length}/4
              </p>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={handleClose}>
              No
            </Button>
            <Button
              positive
              icon="checkmark"
              labelPosition="right"
              content="Deliver"
              onClick={() => handleUpdateDelivery()}
            />
          </Modal.Actions>
        </Modal>

        <Modal size={size} open={openCancelModal} onClose={handleClose}>
          <Modal.Header>Order Cancellation</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to cancel this order #{selectedOrder.order_id}?</p>
            <div className="merchantFormRow">
              <p className="merchantFormTitle">Reason:</p>
              <TextArea
                value={reasonForCancel}
                style={{ width: "50%" }}
                maxLength="100"
                rows="5"
                onChange={(evt) => setReasonForCancel(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {reasonForCancel.length}/100
              </p>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={handleClose}>
              No
            </Button>
            <Button
              positive
              icon="checkmark"
              labelPosition="right"
              content="Reject"
              onClick={() => handleCancelDelivery()}
            />
          </Modal.Actions>
        </Modal>
      </div>
    </div>
  );
};

export default OrderRequestsTable;

import React, { useState } from 'react';
import { Menu } from 'semantic-ui-react';

const SettingsTabBarMenu = (props) => {
  const [activeItem, setActiveItem] = useState(props.history.location.pathname);

  return (
    <div className='NavTabs'>
      <Menu pointing borderless>
        <Menu.Item
          name='general'
          active={activeItem === '/settings'}
          content='General'
          onClick={() => props.history.push('/settings')}
        />
        {/* <Menu.Item
          name='notifications'
          active={activeItem === '/settings/notifications'}
          content='Notifications'
          onClick={() => props.history.push('/settings/notifications')}
        /> */}
        {/* <Menu.Item
          name='customers'
          active={activeItem === '/settings/customers'}
          content='Customers'
          onClick={() => props.history.push('/settings/customers')}
        /> */}
        <Menu.Item
          name='merchants'
          active={activeItem === '/settings/merchants'}
          content='Merchants'
          onClick={() => props.history.push('/settings/merchants')}
        />
        <Menu.Item
          name='vendors'
          active={activeItem === '/settings/vendors'}
          content='Vendors'
          onClick={() => props.history.push('/settings/vendors')}
        />
        {/* <Menu.Item
          name='drivers'
          active={activeItem === '/settings/drivers'}
          content='Drivers'
          onClick={() => props.history.push('/settings/drivers')}
        /> */}
        {/* <Menu.Item
          name='executives'
          active={activeItem === '/settings/marketing-executives'}
          content='Marketing Executives'
          onClick={() => props.history.push('/settings/marketing-executives')}
        /> */}
        {/* <Menu.Item
          name='payouts'
          active={activeItem === '/settings/payouts'}
          content='Payouts'
          onClick={() => props.history.push('/settings/payouts')}
        /> */}
        {/* <Menu.Item
          name='billing'
          active={activeItem === '/settings/billing'}
          content='Billing'
          onClick={() => props.history.push('/settings/billing')}
        /> */}
        {/* <Menu.Item
          name='translation'
          active={activeItem === '/settings/translation'}
          content='Translation'
          onClick={() => props.history.push('/settings/translation')}
        /> */}
        {/* <Menu.Item
          name='assets'
          active={activeItem === '/settings/assets'}
          content='Assets'
          onClick={() => props.history.push('/settings/assets')}
        /> */}
      </Menu>
    </div>
  );
};

export default SettingsTabBarMenu;

import React, { useState, useEffect } from "react";
import { Icon, Input, Button, Modal } from "semantic-ui-react";
import SettingsTabBarMenu from "../../../commons/settingstabbar/settingstabbar.component";
import { BASE_URL } from "../../../../config";

const TranslationSettings = (props) => {
  return (
    <div className="DefaultLayout">
      <Header history={props.history} />
      <Card />
    </div>
  );
};

const Header = (props) => {
  return (
    <div className="HeaderLayout">
      <h3>Settings</h3>
      <SettingsTabBarMenu history={props.history} />
    </div>
  );
};

const Card = () => {
  const [english_word, setEnglishWord] = useState("");
  const [translation_word, setTranslationWord] = useState("");
  const [translationSettings, setTranslationSettings] = useState("");
  const [showAddWord, setShowAddWord] = useState(false);
  const [showEditWord, setShowEditWord] = useState(false);

  useEffect(() => {
    fetchTranslationSettings();
  }, []);

  const fetchTranslationSettings = () => {
    fetch(`${BASE_URL}/get-translation-settings`)
      .then((res) => res.json())
      .then((json) => {
        setTranslationSettings(json.translation_settings);
      });
  };

  const close = () => {
    setShowAddWord(false);
    setShowEditWord(false);
  };

  const saveTranslationSettings = () => {
    if ((english_word, translation_word)) {
      fetch(`${BASE_URL}/create-translation-settings`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          english_word,
          translation_word,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          window.location.reload(true);
          close();
        });
    } else {
      alert("Please fill all the fields!");
    }
  };

  return (
    <div className="CardContainer">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3>Translation Settings</h3>
        <Button className="addMerchantBtn" onClick={() => setShowAddWord(true)}>
          <Icon name="plus" /> Add Word
        </Button>
      </div>

      {/* ADD WORD MODAL */}
      <div>
        <Modal size="small" open={showAddWord} onClose={close}>
          <Modal.Header>
            <span>Add Word to Translate</span>
          </Modal.Header>
          <Modal.Content>
            <div className="vendorFormRow">
              <p className="titleVendorform">Add English Word:</p>
              <Input
                placeholder="Enter Translate Word"
                style={{ width: "50%" }}
                onChange={(evt) => setEnglishWord(evt.target.value)}
              />
            </div>
            <div className="vendorFormRow">
              <p className="titleVendorform">Add Translation Word:</p>
              <Input
                placeholder="Enter Translated Word"
                style={{ width: "50%" }}
                onChange={(evt) => setTranslationWord(evt.target.value)}
              />
            </div>
          </Modal.Content>
          <Modal.Actions style={{ textAlign: "center" }}>
            <Button
              className="cancelBtn"
              onClick={() => {
                setShowAddWord(false);
              }}
            >
              Cancel
            </Button>
            <Button className="submitBtn" content="Submit" onClick={() => saveTranslationSettings()}/>
          </Modal.Actions>
        </Modal>
      </div>
      <div>
        
        <div style={{ display: "flex", marginTop: 30 }}>
          <p style={{ fontSize: 15 }}>Fill the Store Details{"   "}:</p>
          <p style={{ fontSize: 15, marginLeft: 20 }}>
            கடை விவரங்களை நிரப்பவும்
          </p>
          <p onClick={() => setShowEditWord(true)}>
            <Icon
              name="pencil"
              style={{
                fontSize: 15,
                color: "#93c47d",
                marginLeft: 10,
              }}
            />
          </p>
        </div>
      </div>

      {/* EDIT WORD MODAL */}
      <div>
        <Modal size="small" open={showEditWord} onClose={close}>
          <Modal.Header>
            <span>Edit Word to Translate</span>
          </Modal.Header>
          <Modal.Content>
            <div className="vendorFormRow">
              <p className="titleVendorform">English Word:</p>
              <Input
                placeholder="Enter Translate Word"
                style={{ width: "50%" }}
              />
            </div>
            <div className="vendorFormRow">
              <p className="titleVendorform">Translation Word:</p>
              <Input
                placeholder="Enter Translated Word"
                style={{ width: "50%" }}
              />
            </div>
          </Modal.Content>
          <Modal.Actions style={{ textAlign: "center" }}>
            <Button
              className="cancelBtn"
              onClick={() => {
                setShowAddWord(false);
              }}
            >
              Cancel
            </Button>
            <Button className="submitBtn" content="Submit" />
          </Modal.Actions>
        </Modal>
      </div>
    </div>
  );
};
export default TranslationSettings;

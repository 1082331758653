import React, { useEffect, useState } from "react";
import { Table, Modal, Icon, Checkbox, Button, Image } from "semantic-ui-react";
import { HEADING_DATA } from "./companies.constant";
import { BASE_URL } from "../../../config";
import _ from "lodash";

const CompaniesTable = (props) => {
  const [selectedCompanyID, setCompanyID] = useState("");
  const [size, setSize] = useState("");
  const [open, setOpen] = useState(false);
  const [companies, setComapnies] = useState([]);
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);

  useEffect(() => {
    fetchComapnies();
  }, []);

  const fetchComapnies = () => {
    fetch(`${BASE_URL}/get-companies`)
      .then((res) => res.json())
      .then((json) => {
        setComapnies(json.companies);
      });
  };

  const handleSort = (clickedColumn) => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setComapnies(_.sortBy(companies, [clickedColumn]));
      setDirection("ascending");

      return;
    }

    setComapnies(companies.reverse());
    setDirection(direction === "ascending" ? "descending" : "ascending");
  };

  const deleteSetting = (size, ID) => () => {
    setSize(size);
    setOpen(true);
    setCompanyID(ID);
  };

  const handleDelete = () => {
    fetch(`${BASE_URL}/delete-company?companyID=${selectedCompanyID}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((json) => {
        fetchComapnies();
        close();
      });
  };

  const close = () => {
    setOpen(false);
  };

  const companies_table = companies.filter((setting) =>
    Object.keys(setting).some(
      (key) => setting[key].toString().search(props.searchString) !== -1
    )
  );

  return (
    <div>
      <Table
        sortable
        unstackable
        celled
        basic="very"
        className="CustomTableLayout"
        id="company-table"
      >
        <Table.Header>
          <Table.Row>
            {HEADING_DATA.map((heading) => (
              <Table.HeaderCell
                sorted={column === heading ? direction : null}
                onClick={handleSort(heading)}
              >
                {heading === "Checkbox" ? <Checkbox /> : <span>{heading}</span>}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {companies_table.map((company, index) => (
            <Table.Row>
              <Table.Cell>{index + 1}</Table.Cell>
              <Table.Cell>
                <Image
                  src={company.logo}
                  style={{ height: 60, width: 60 }}
                />
              </Table.Cell>
              <Table.Cell>{company.company_name}</Table.Cell>
              <Table.Cell>{company.email}</Table.Cell>
              <Table.Cell>{company.gst_no}</Table.Cell>
              <Table.Cell>CGST: {company.cgst[0]} %, SGST: {company.sgst[0]} %</Table.Cell>
              <Table.Cell>
                {company.address_line1} {company.address_line2}{" "}
                {company.address_line3}
              </Table.Cell>

              <Table.Cell>
                <Button
                  style={{ fontSize: 13, backgroundColor: "#f3f3f3" }}
                  onClick={() =>
                    props.history.push(`/edit-company/${company.company_id}`)
                  }
                >
                  <Icon name="edit" />
                </Button>

                <Button
                  style={{ fontSize: 13, backgroundColor: "#f4cccc" }}
                  onClick={deleteSetting("mini", company._id)}
                >
                  <Icon name="trash" />
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Modal size={size} open={open} onClose={close}>
        <Modal.Header>Delete Company</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete company?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button className="addCustomerCancelBtn" onClick={close}>
            No
          </Button>
          <Button
            className="addCustomersubmitBtn"
            icon="checkmark"
            labelPosition="right"
            content="Yes"
            onClick={() => handleDelete()}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default CompaniesTable;

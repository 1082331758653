import React, { Component } from "react";

import ProfilePic from "../../../images/pic.png";

class UserDetails extends Component {
  render() {
    const { userDetails } = this.props;

    return (
      <div>
        <div className="profileContainer">
          <img src={ProfilePic} className="profilePicContainer" />

          <div className="profileDetailsContainer">
            <p className="profileName">{userDetails.name}</p>
            <p className="profileEmail">{userDetails.email}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default UserDetails;

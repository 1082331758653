import React from "react";
import OrderRequestsHeader from "./orderrequests_header";
import OrderRequestsTable from "./orderrequests_table.component";

const OrderRequests = (props) => {
  return (
    <div className="DefaultLayout">
      <Header />
      <Card history={props.history} />
    </div>
  );
};

const Header = () => {
  return (
    <div className="HeaderLayout">
      <div style={{ paddingLeft: 20, paddingBottom: 20 }}>
        <h3>Order Requests</h3>
      </div>
      <OrderRequestsHeader />
    </div>
  );
};

const Card = (props) => {
  return (
    <OrderRequestsTable history={props.history} />
  );
};

export default OrderRequests;

import React, { useEffect, useState } from "react";
import {
  Icon,
  Button,
  Table,
  Select,
  Checkbox,
} from "semantic-ui-react";
import toastr from "toastr";
import moment from "moment";
import TableExport from "tableexport";
import { HEADING_DATA_CUSTOMER_FEEDBACK } from "./feedbacks.constant";
import { BASE_URL } from "../../../config";

const MerchantsFeedbackTable = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [merchantDetails, setMerchantDetails] = useState({});

  useEffect(() => {
    fetchMerchants();
  }, []);

  const fetchMerchants = () => {
    fetch(`${BASE_URL}/get-merchants`)
      .then((res) => res.json())
      .then((json) => {
        setMerchants(json.merchants);
      });
  };

  const handleAgent = (phoneNumber) => {
    const data = merchants.filter(
      (driver) => driver.phoneNumber.indexOf(phoneNumber) !== -1
    );
    const merchantData = data[0];
    const feedbacksData = data[0].feedbacks !== undefined ? data[0].feedbacks : [];
    setFeedbacks(feedbacksData);
    setMerchantDetails(merchantData);
  };

  const renderAvgRating = feedbacks => {
    let data = [];
    var sum = 0;
    feedbacks.map(element => {
      data.push(element.rating);
    });
    for (var i = 0; i < data.length; i++) {
      sum += parseInt(data[i], 10);
    }
    var avg = sum / data.length;
    return Math.round(avg);
  };


  return (
    <div>
      <div className="exprtBtnRow">
        <div style={{ marginTop: 10 }}>
          {merchants.length > 0 ? (
            <Select
              placeholder="Select Merchant"
              options={merchants.map((merchant) => ({
                key: merchant._id,
                value: merchant.phoneNumber,
                text: merchant.name,
              }))}
              onChange={(event, data) => handleAgent(data.value)}
            />
          ) : (
            "Please Add Merchant"
          )}
        </div>

        <div style={{ marginTop: 10 }}>
          <Button
            icon
            labelPosition="right"
            style={{
              backgroundColor: "#d9ead3",
              color: "#000",
              marginBottom: 15,
            }}
            onClick={() => {
              toastr.success("Exported Buttons Enabled Successfully");
              const table = new TableExport(
                document.getElementById("merchant-feedbacks"),
                {
                  formats: ["csv"],
                  exportButtons: true,
                  position: "top",
                }
              );
              const link = document.createElement("a");
              link.setAttribute(
                "download",
                table.getExportData()["merchant-feedbacks"].csv.filename
              );
              link.click();
            }}
          >
            Export
            <Icon name="share square icon" />
          </Button>
        </div>
      </div>
      <div>
        <Table
          sortable
          unstackable
          celled
          basic="very"
          id="merchant-feedbacks"
        >
          <Table.Header>
            <Table.Row>
              {HEADING_DATA_CUSTOMER_FEEDBACK.map((heading) => (
                <Table.HeaderCell>
                  {heading === "Checkbox" ? (
                    <Checkbox />
                  ) : (
                    <span>{heading}</span>
                  )}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {feedbacks.map((feedback, index) => (
              <Table.Row>
                <Table.Cell>{index + 1}</Table.Cell>
                <Table.Cell>
                  {merchantDetails.name}{""}
                  {renderAvgRating(feedbacks) >= 0 ? (
                    <span
                      style={{
                        color: "#fbbc05",
                        paddingLeft: 5
                      }}
                    >
                      ({renderAvgRating(feedbacks)}
                      /5 <Icon name="star" color="yellow" />)
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "#fbbc05",
                        paddingLeft: 5
                      }}
                    >
                      No Rating
                    </span>
                  )}
                  <br />
                  {feedback.date !== undefined ? moment(feedback.date).format('lll') : ''}
                </Table.Cell>
                <Table.Cell>{feedback.rating} <Icon name="star" color="yellow" /></Table.Cell>
                <Table.Cell>{feedback.message || '-'}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default MerchantsFeedbackTable;

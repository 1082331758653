export const PRODUCT_DETAILS = [
  {
    name: "White Rice",
    price: "₹ 500",
  },
  {
    name: "Basmati Rice",
    price: "₹ 800",
  },
  {
    name: "Broken Rice",
    price: "₹ 1000",
  },
  {
    name: "Samba Rice",
    price: "₹ 500",
  },
  {
    name: "Ponni Rice",
    price: "₹ 900",
  },
];

export const PRODUCT_TAGS = [
  { key: "raw-rice", text: "Raw rice", value: "raw-rice" },
  { key: "boiled-rice", text: "Boiled rice", value: "boiled-rice" },
  { key: "high-in-protein", text: "High in Protein", value: "high-in-protein" },
  {
    key: "no-preservatives",
    text: "No Preservatives",
    value: "no-preservatives",
  },
  { key: "brown-rice", text: "Brown rice", value: "brown-rice" },
  { key: "organic", text: "Organic", value: "organic" },
  {
    key: "no-artificial-flavour",
    text: "No Artificial Flavour",
    value: "no-artificial-flavour",
  },
  { key: "gluten-free", text: "Gluten Free", value: "gluten-free" },
  { key: "low-fat", text: "Low Fat", value: "low-fat" },
  { key: "weight-watcher", text: "Weight Watcher", value: "weight-watcher" },
  { key: "fat-free", text: "Fat Free", value: "fat-free" },
  {
    key: "suitable-for-diabetics",
    text: "Suitable for Diabetics",
    value: "suitable-for-diabetics",
  },
  {
    key: "suitable-for-vegetarians",
    text: "Suitable for Vegetarians",
    value: "suitable-for-vegetarians",
  },
];

import React, { useEffect, useState } from 'react';
import {
  Table,
  Label,
  Checkbox,
  Button,
  Image,
  Icon,
  Input,
  Modal,
  Header,
} from 'semantic-ui-react';
import _ from 'lodash';
import toastr from 'toastr';
import ORDERS_DELIVERED_IMG from '../../../images/All_Orders_Delivered.png';

import { BASE_URL } from '../../../config';
import TableExport from 'tableexport';
import moment from 'moment';
import { HEADING_DATA } from './delivery.constant';

const WalkInDeliveryTable = (props) => {
  const [customerOrders, setCustomerOrders] = useState([]);
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [size] = useState('tiny');
  const [deliveredBy, setDeliveredBy] = useState('');
  const [selectedOrder, setSelectedOrder] = useState('');
  const [cloneCustomersOrdersData, setCloneCustomersOrdersData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [approvedButtonString, setApprovedButtonString] =
    useState('Click to Approve');
  const [currentDateCashStatus, setCurrentDateCashStatus] = useState(null);
  const { dateValue, setDateValue, grandTotalValue, setGrandTotalValue } =
    props;

  useEffect(() => {
    setApprovedButtonString('Click to Approve');
    fetch(`${BASE_URL}/get-cash-given-status`, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((json) => {
        const cashGivenStatusResults = json.result;
        let currentStatus = cashGivenStatusResults.find((object) => {
          return object.date == moment(dateValue).format('DD-MM-YYYY');
        });

        setCurrentDateCashStatus(currentStatus);
        if (currentStatus && currentStatus.cashGiven == 'true') {
          setApprovedButtonString('Approved');
        }
      });
  }, [dateValue]);

  let customer_orders_table = customerOrders.filter((customer) =>
    Object.keys(customer).some(
      (key) => customer[key].toString().search(props.searchString) !== -1
    )
  );
  customer_orders_table = customer_orders_table.filter((order) => {
    return (
      moment(order.date).format('DD-MM-YYYY') ===
      moment(dateValue).format('DD-MM-YYYY')
    );
  });

  let initialGrandTotal = customer_orders_table.reduce((acc, curr) => {
    return +acc + +curr.order_total;
  }, 0);

  setGrandTotalValue(initialGrandTotal);

  useEffect(() => {
    const filteredDateData = cloneCustomersOrdersData.filter((order) => {
      return (
        moment(order.date).format('DD-MM-YYYY') ===
        moment(dateValue).format('DD-MM-YYYY')
      );
    });
    setCustomerOrders(filteredDateData);
    customer_orders_table = customerOrders.filter((customer) =>
      Object.keys(customer).some(
        (key) => customer[key].toString().search(props.searchString) !== -1
      )
    );
  }, [dateValue]);

  useEffect(() => {
    fetchCustomerOrders();
  }, []);

  const fetchCustomerOrders = () => {
    fetch(`${BASE_URL}/get-customer-orders`)
      .then((res) => res.json())
      .then((json) => {
        setCustomerOrders(json.customer_orders);
        setCloneCustomersOrdersData(json.customer_orders);
      });
  };

  const handleSort = (clickedColumn) => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setCustomerOrders(_.sortBy(customerOrders, [clickedColumn]));
      setDirection('ascending');

      return;
    }

    setCustomerOrders(customerOrders.reverse());
    setDirection(direction === 'ascending' ? 'descending' : 'ascending');
  };

  const handleUpdate = () => {
    if (deliveredBy.length === 0) {
      toastr.error('Please Enter Delivered By Person Name!');
    } else {
      fetch(
        `${BASE_URL}/update-customer-orders?customer_orders_id=${selectedOrder._id}`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            status: 'DELIVERED',
            delivered_by: deliveredBy,
          }),
        }
      )
        .then((res) => res.json())
        .then((json) => {
          handleClose();
          toastr.success('Order Delivered Successfully!');
          fetchCustomerOrders();
        });
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleGrandTotalApproval = () => {
    setOpen(false);

    fetch(`${BASE_URL}/cash-given`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        date: moment(dateValue).format('DD-MM-YYYY'),
        cashGiven: 'true',
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        setOpen(false);
        toastr.success('Approved Successfully!');

        setApprovedButtonString('Approved');
        window.location.reload();
      });
  };

  const handleAlreadyApprovedStatus = () => {
    toastr.error('You have already approved the cash given status!');
  };

  return (
    <div>
      {customer_orders_table.length > 0 ? (
        <div>
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            {/* Modal */}
            <Modal
              closeIcon
              onClose={() => setOpen(false)}
              onOpen={() => {
                if (
                  currentDateCashStatus !== null &&
                  currentDateCashStatus?.cashGiven == 'true'
                ) {
                  handleAlreadyApprovedStatus();
                } else {
                  setOpen(true);
                }
              }}
              open={open}
              size="small"
              trigger={
                <Button
                  icon
                  style={{
                    backgroundColor:
                      approvedButtonString == 'Approved'
                        ? '#d9ead3'
                        : '#2778C2',
                    color: approvedButtonString == 'Approved' ? '#000' : '#fff',
                    marginBottom: 15,
                  }}
                >
                  {approvedButtonString}
                </Button>
              }
            >
              <Header icon>
                <Icon name="archive" />
                Approve Today's Total
              </Header>
              <Modal.Content>
                <p>
                  Do you want to approve the total amount of today's orders?
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button color="red" onClick={() => setOpen(false)}>
                  <Icon name="remove" /> No
                </Button>
                <Button color="green" onClick={handleGrandTotalApproval}>
                  <Icon name="checkmark" /> Yes
                </Button>
              </Modal.Actions>
            </Modal>
            {/* Modal End */}
            <Button
              icon
              labelPosition="right"
              style={{
                backgroundColor: '#d9ead3',
                color: '#000',
                marginBottom: 15,
              }}
              onClick={() => {
                toastr.success('Exported Buttons Enabled Successfully');
                const table = new TableExport(
                  document.getElementById('walk-in-deliveries'),
                  {
                    formats: ['csv'],
                    exportButtons: true,
                    position: 'top',
                  }
                );
                const link = document.createElement('a');
                link.setAttribute(
                  'download',
                  table.getExportData()['walk-in-deliveries'].csv.filename
                );
                link.click();
              }}
            >
              Export
              <Icon name="share square icon" />
            </Button>
          </div>
          <Table
            sortable
            unstackable
            celled
            basic="very"
            // className="CustomTableLayout"
            id="walk-in-deliveries"
          >
            <Table.Header>
              <Table.Row>
                {HEADING_DATA.map((heading) => (
                  <Table.HeaderCell
                    sorted={column === heading ? direction : null}
                    onClick={handleSort(heading)}
                  >
                    {heading === 'Checkbox' ? (
                      <Checkbox />
                    ) : (
                      <span>{heading}</span>
                    )}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {customer_orders_table.map((order, index) => (
                <Table.Row>
                  {/* <Table.Cell>
                  <Label color="green" style={{ fontSize: 15 }}>
                    { order.company_details.length > 0 ? order.company_details[0].company_name : "Null" } 
                  </Label>
                </Table.Cell> */}
                  <Table.Cell># {order.invoice_no} </Table.Cell>
                  <Table.Cell>{order.customer_name} </Table.Cell>
                  <Table.Cell>{order.customer_phone} </Table.Cell>
                  <Table.Cell>
                    {order.products.map((product, index) => (
                      <React.Fragment>
                        <p>
                          {index + 1}) {product.name}: {product.quantity} x{' '}
                          {product.weight}Kg
                        </p>
                      </React.Fragment>
                    ))}
                  </Table.Cell>
                  <Table.Cell>₹ {order.order_total}</Table.Cell>
                  <Table.Cell>
                    {order.status === 'PENDING' ? (
                      <Button
                        style={{ fontSize: 13, backgroundColor: '#d9ead3' }}
                        onClick={() => {
                          setSelectedOrder(order);
                          setDeliveredBy('');
                          setOpenModal(true);
                        }}
                      >
                        Deliver
                      </Button>
                    ) : (
                      <div>
                        <Label color="green">{order.status}</Label> By{' '}
                        {order.delivered_by}
                      </div>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <Modal size={size} open={openModal} onClose={handleClose}>
            <Modal.Header>Order Delivery</Modal.Header>
            <Modal.Content>
              <p>
                Are you sure you want to deliver this order #
                {selectedOrder.invoice_no}?
              </p>
              <div className="merchantFormRow">
                <p className="merchantFormTitle">Delivered By:</p>
                <Input
                  value={deliveredBy}
                  style={{ width: '50%' }}
                  maxLength="25"
                  onChange={(evt) => setDeliveredBy(evt.target.value)}
                />
                <p
                  style={{
                    alignSelf: 'center',
                    marginLeft: -40,
                    marginTop: 10,
                    zIndex: 0,
                    color: 'grey',
                  }}
                >
                  {deliveredBy.length}/20
                </p>
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button negative onClick={handleClose}>
                No
              </Button>
              <Button
                positive
                icon="checkmark"
                labelPosition="right"
                content="Update"
                onClick={() => handleUpdate()}
              />
            </Modal.Actions>
          </Modal>
        </div>
      ) : (
        <div>
          <Image
            src={ORDERS_DELIVERED_IMG}
            style={{ width: 300, marginLeft: 380 }}
          />
        </div>
      )}
    </div>
  );
};

export default WalkInDeliveryTable;

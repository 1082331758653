import React, { useEffect, useState } from "react";
import {
  Table,
  Label,
  Icon,
  Checkbox,
  Button,
  Input,
  Modal,
} from "semantic-ui-react";
import toastr from "toastr";
import { RPA_HEADING_DATA } from "./customerbilling.constant";
import { BASE_URL } from "../../../config";
import TableExport from "tableexport";
import swal from 'sweetalert';
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

const ReceivedPaymentsApproval = () => {
  const today = moment();
  const [invoices, setInvoices] = useState([]);
  const [invoice, setInvoice] = useState({});
  const [size, setSize] = useState("");
  const [paidAmt, setPaidAmt] = useState("");
  const [open, setModalOpen] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState(
    moment.range(today.clone().subtract(7, "days"), today.clone())
  );
  const [searchString, setSearchString] = useState("");


  useEffect(() => {
    fetchInvoices();
  }, []);

  const fetchInvoices = () => {
    fetch(`${BASE_URL}/get-all-orders`)
      .then((res) => res.json())
      .then((json) => {
        setInvoices(json.orders);
      });
  };

  const onSelect = (value, states) => {
    fetch(
      `${BASE_URL}/get-allinvoices-by-range?startDate=${value.start.format(
        "DD-MM-YYYY"
      )}&endDate=${value.end.format("DD-MM-YYYY")}`
    )
      .then((res) => res.json())
      .then((json) => {
        setValue(value);
        setOpen(!isOpen);
        setInvoices(json.result);
      });
  };

  const onToggle = () => {
    setOpen(!isOpen);
  };

  const approveTransaction = (size, invoice) => () => {
    setSize(size);
    setModalOpen(true);
    setInvoice(invoice);
    setPaidAmt(invoice.credit_details.paidAmount);
  };

  const close = () => {
    setModalOpen(false);
  };

  const handleTransaction = () => {
    const transaction = {
      transaction_id: "cash",
      transaction_date: Date.now(),
      transaction_amount: Number(paidAmt),
      transaction_type: "payment",
    };

    fetch(
      `${BASE_URL}/approve-recieved-payment?invoice_no=${invoice.invoice_no}&cname=${invoice.cname}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(transaction),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        swal("Good job!", "Transaction Approved Successfully!", "success");
        fetchInvoices();
        close();
      });
  };

  const invoices_data = invoices.filter((invoice) =>
    Object.keys(invoice).some(
      (key) => invoice[key].toString().search(searchString) !== -1
    )
  );

  const invoices_table = invoices_data.filter((invoice) =>
  invoice.payment_status === "partial-paid"
    // Object.keys(invoice).some(
    //   (key) => invoice[key].toString().search("partial-paid") !== -1
    // )
  );

  return (
    <div>
      <div className="BillingTitleRow">
        <div style={{ marginTop: 10 }}>
          <Button
            icon
            labelPosition="right"
            style={{ backgroundColor: "#d9ead3", color: "#000", marginBottom: 15 }}
            onClick={() => {
              toastr.success('Exported Buttons Enabled Successfully');
              const table = new TableExport(document.getElementById("recieved-payments"), {
                formats: ['csv'],
                exportButtons: true,
                position: 'top'
              });
              const link = document.createElement('a');
              link.setAttribute('download', table.getExportData()['recieved-payments'].csv.filename);
              link.click();
            }}
          >
            Export
            <Icon name="share square icon" />
          </Button>
        </div>
        <div style={{ marginTop: 10 }}>
          Select Range:{" "}
          <input
            type="text"
            onClick={onToggle}
            style={{ width: 170 }}
            value={`${value.start.format("DD-MM-YYYY")} - ${value.end.format(
              "DD-MM-YYYY"
            )}`}
          />
          {isOpen && (
            <DateRangePicker
              value={value}
              onSelect={onSelect}
              singleDateRange={true}
            />
          )}
        </div>

        <div style={{ marginTop: 10 }}>
          <Input
            placeholder="Quick Search..."
            icon="search"
            style={{ marginRight: 10 }}
            onChange={(e) => setSearchString(e.target.value)}
          />
        </div>
      </div>
      <Table
        sortable
        unstackable
        celled
        basic="very"
        className="CustomTableLayout"
        id="recieved-payments"
      >
        <Table.Header>
          <Table.Row>
            {RPA_HEADING_DATA.map((heading) => (
              <Table.HeaderCell>
                {heading === "Checkbox" ? <Checkbox /> : <span>{heading}</span>}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {invoices_table.length<=0 ?[] :invoices_table.map((invoice, index) => (
            <Table.Row>
              {/* <Table.cell>r</Table.cell> */}
              <Table.Cell>{index + 1}</Table.Cell>
              <Table.Cell>#{invoice.order_id}</Table.Cell>
              <Table.Cell>{invoice.name}</Table.Cell>
              <Table.Cell>INR {invoice.order_total}</Table.Cell>
              <Table.Cell>{invoice.invoice_no}</Table.Cell>
              <Table.Cell>{invoice.status}</Table.Cell>
              <Table.Cell>CASH</Table.Cell>
              <Table.Cell>INR {invoice.credit_details.paidAmount}</Table.Cell>
              <Table.Cell>
                <Button
                  color="green"
                  onClick={approveTransaction("mini", invoice)}
                >
                  Approve
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Modal size={size} open={open} onClose={close}>
        <Modal.Header>Approve Transaction</Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to approve transaction #{invoice.order_id}?
          </p>
          <div className="merchantFormRow">
            <p className="merchantFormTitle">Paid Amount:</p>
            <Input
              value={paidAmt}
              style={{ width: "50%" }}
              maxLength="10"
              onChange={(evt) => setPaidAmt(evt.target.value)}
            />
            <p
              style={{
                alignSelf: "center",
                marginLeft: -40,
                marginTop: 10,
                zIndex: 0,
                color: "grey",
              }}
            >
              {paidAmt.length}/10
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button className="addCustomerCancelBtn" onClick={close}>
            No
          </Button>
          <Button
            className="addCustomersubmitBtn"
            icon="checkmark"
            labelPosition="right"
            content="Yes"
            onClick={() => handleTransaction()}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default ReceivedPaymentsApproval;

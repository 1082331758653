import React, { useState, useEffect } from "react";
import { Icon, Input, TextArea, Form, Button, Grid } from "semantic-ui-react";
import { BASE_URL } from "../../../config";

const EditEstimateSettings = (props) => {
  return (
    <div className="invoiceSetDefaultLayout">
      <div style={{ paddingTop: 10, paddingLeft: 20, display: "flex" }}>
        <Icon
          name="arrow left"
          style={{ paddingTop: 3, cursor: "pointer" }}
          onClick={() => props.history.push("/estimate-settings")}
        />
        <h3 style={{ marginTop: 0, marginLeft: 10 }}>Edit Company Details</h3>
      </div>
      <Card history={props.history} />
    </div>
  );
};

const Card = (props) => {
  const [estimate_logo, setEstimateLogo] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  const [address_line1, setAddressLine1] = useState("");
  const [address_line2, setAddressLine2] = useState("");
  const [address_line3, setAddressLine3] = useState("");
  const [email, setEmail] = useState("");
  const [whatsapp_number, setWhatsAppNumber] = useState("");
  const [gst_no, setGSTNo] = useState("");
  const [cin_no, setCINNo] = useState("");
  const [pan_no, setPANNo] = useState("");
  const [others, setOthers] = useState("");
  const [cgst, setCGST] = useState("");
  const [sgst, setSGST] = useState("");
  const [delivery_charges, setDeliveryCharges] = useState("");
  const [default_customer_notes, setDefaultCustomerNotes] = useState("");
  const [default_pricing_terms, setDefaultPricingTerms] = useState("");
  const [estimateSettingsID, setSettingsID] = useState("");

  useEffect(() => {
    fetchEstimateSettings();
  }, []);

  const fetchEstimateSettings = () => {
    const { location } = props.history;
    const data = location.pathname.split("/");
    const settingID = data[2];

    fetch(`${BASE_URL}/get-estimate-setting-by-id?settingsId=${settingID}`)
      .then((res) => res.json())
      .then((json) => {
        setSettingsID(json.estimate_setting[0]._id);
        setEstimateLogo(json.estimate_setting[0].estimate_logo);
        setCompanyName(json.estimate_setting[0].company_name);
        setWebsite(json.estimate_setting[0].website);
        setAddressLine1(json.estimate_setting[0].address_line1);
        setAddressLine2(json.estimate_setting[0].address_line2);
        setAddressLine3(json.estimate_setting[0].address_line3);
        setEmail(json.estimate_setting[0].email);
        setWhatsAppNumber(json.estimate_setting[0].whatsapp_number);
        setGSTNo(json.estimate_setting[0].gst_no);
        setCINNo(json.estimate_setting[0].cin_no);
        setPANNo(json.estimate_setting[0].pan_no);
        setOthers(json.estimate_setting[0].others);
        setCGST(json.estimate_setting[0].cgst.toString());
        setSGST(json.estimate_setting[0].sgst.toString());
        setDeliveryCharges(json.estimate_setting[0].delivery_charges);
        setDefaultCustomerNotes(json.estimate_setting[0].default_customer_notes);
        setDefaultPricingTerms(json.estimate_setting[0].default_pricing_terms);
      });
  };

  const updateEstimateSettings = () => {
    fetch(
      `${BASE_URL}/update-estimate-settings?estimate_settings_id=${estimateSettingsID}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          estimate_logo,
          company_name,
          website,
          address_line1,
          address_line2,
          address_line3,
          email,
          whatsapp_number,
          gst_no,
          cin_no,
          pan_no,
          others,
          cgst: cgst.split(","),
          sgst: sgst.split(","),
          delivery_charges,
          default_customer_notes,
          default_pricing_terms,
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        fetchEstimateSettings();
        alert("Estimate Settings Updated Successfuly");
      });
  };

  const handleChange = () => {
    document.getElementById("main_image_uploader").click();
  };

  const mainImageUploader = (event) => {
    const file = event.target.files[0];
    var regex = new RegExp("([a-zA-Z0-9s_\\.-:])+(.jpg|.png)$");
    if (regex.test(file.name)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        var image = new Image();

        image.src = e.target.result;

        // const scope = this;
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (height > 150 || width > 150) {
            alert("Height and Width must not exceed 150px.");
            return false;
          } else {
            alert("Selected Image Uploaded Succesfully.");
            uploadFile(file, "MAIN_IMAGE");
            return true;
          }
        };
      };
    } else {
      alert("JPG and PNG Images only allowed!");
      return false;
    }
  };

  const uploadFile = (file, imageType) => {
    var url = `https://api.cloudinary.com/v1_1/dgvup74b7/upload`;
    var xhr = new XMLHttpRequest();
    var fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.onreadystatechange = (e) => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var response = JSON.parse(xhr.responseText);
        if (imageType === "MAIN_IMAGE") {
          setEstimateLogo(response.secure_url);
        }
      }
    };

    fd.append("upload_preset", "lomurso1");
    fd.append("tags", "browser_upload");
    fd.append("file", file);
    xhr.send(fd);
  };

  return (
    <div className="invoiceSetCardContainer">
      <div style={{ marginTop: 50 }}>
        <Grid columns={3} divided>
          <Grid.Row>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">Logo: </p>
                <input
                  type="file"
                  id="main_image_uploader"
                  placeholder="Enter customer name"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={(e) => {
                    mainImageUploader(e);
                  }}
                />
                <div>
                  <div
                    onClick={() => {
                      handleChange();
                    }}
                    style={{
                      height: 100,
                      width: 100,
                      border: "1px dashed #000",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {estimate_logo.length > 0 ? (
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={estimate_logo}
                      />
                    ) : (
                      <Icon
                        style={{
                          fontSize: 45,
                          marginTop: 25,
                          color: "#a7c777",
                        }}
                        name="add"
                      />
                    )}
                  </div>
                </div>
              </div>
            </Grid.Column>

            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">Company Name: </p>
                <Input
                  placeholder="Enter Company Name"
                  style={{ width: 250 }}
                  maxLength="35"
                  value={company_name}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>
            </Grid.Column>

            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText"> Website:</p>
                <Input
                  placeholder="website"
                  style={{ width: 250 }}
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">Address Line 1:</p>
                <Input
                  placeholder="Address line 1"
                  style={{ width: 250 }}
                  maxLength="35"
                  value={address_line1}
                  onChange={(e) => setAddressLine1(e.target.value)}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">Address Line 2:</p>
                <Input
                  placeholder="Address line 2"
                  style={{ width: 250 }}
                  maxLength="35"
                  value={address_line2}
                  onChange={(e) => setAddressLine2(e.target.value)}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">Address Line 3:</p>
                <Input
                  placeholder="Address line 3"
                  style={{ width: 250 }}
                  maxLength="35"
                  value={address_line3}
                  onChange={(e) => setAddressLine3(e.target.value)}
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">Email Address:</p>
                <Input
                  placeholder="Email"
                  style={{ width: 250 }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText"> WhatsApp For Business:</p>
                <Input
                  placeholder="Whatsapp number"
                  style={{ width: 250 }}
                  value={whatsapp_number}
                  onChange={(e) => setWhatsAppNumber(e.target.value)}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText"> GST:</p>
                <Input
                  placeholder="Enter GST Number"
                  style={{ width: 250 }}
                  value={gst_no}
                  onChange={(e) => setGSTNo(e.target.value)}
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText"> CIN:</p>
                <Input
                  placeholder="Enter CIN number"
                  style={{ width: 250 }}
                  value={cin_no}
                  onChange={(e) => setCINNo(e.target.value)}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText"> PAN:</p>
                <Input
                  placeholder="Enter PAN number"
                  style={{ width: 250 }}
                  value={pan_no}
                  onChange={(e) => setPANNo(e.target.value)}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">Others:</p>
                <Form>
                  <TextArea
                    placeholder="others"
                    cols="40"
                    maxLength="70"
                    value={others}
                    onChange={(e) => setOthers(e.target.value)}
                  />
                </Form>
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">CGST%:</p>
                <Input
                  placeholder="CGST%"
                  style={{ width: 250 }}
                  value={cgst}
                  onChange={(e) => setCGST(e.target.value)}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText"> SGST%:</p>
                <Input
                  placeholder="SGST%"
                  style={{ width: 250 }}
                  value={sgst}
                  onChange={(e) => setSGST(e.target.value)}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">Default Delivery Charges:</p>
                <Input
                  placeholder="Delivery Charges"
                  style={{ width: 250 }}
                  value={delivery_charges}
                  onChange={(e) => setDeliveryCharges(e.target.value)}
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">Default Customer Notes:</p>
                <Form>
                  <TextArea
                    placeholder="Default customer notes"
                    cols="40"
                    maxLength="70"
                    value={default_customer_notes}
                    onChange={(e) => setDefaultCustomerNotes(e.target.value)}
                  />
                </Form>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">Default Pricing Terms:</p>
                <Form>
                  <TextArea
                    placeholder="Default pricing terms"
                    cols="40"
                    maxLength="70"
                    value={default_pricing_terms}
                    onChange={(e) => setDefaultPricingTerms(e.target.value)}
                  />
                </Form>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <div style={{ textAlign: "center", marginTop: 30 }}>
          <Button
            style={{ backgroundColor: "#d9ead3", marginRight: 30 }}
            onClick={updateEstimateSettings}
          >
            Update Changes
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditEstimateSettings;

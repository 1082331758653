export const HEADING_DATA = [
  "S.No",
  "Logo",
  "Company Name",
  "Email",
  "GST",
  "Tax",
  "Address",
  "Actions",
];

import React from 'react';
import { Icon, Button, Table, Select, Checkbox } from 'semantic-ui-react';
import { HEADING_DATA, TABLE_DATA } from './vendors-table.constant';

const VendorOptions = [
  { key: 'af', value: 'af', text: 'Arun kumar' },
  { key: 'ax', value: 'ax', text: 'Bala Subramanium' },
  { key: 'al', value: 'al', text: 'Chandra Sekar' },
];

const VendorsPayoutTable = () => {
  return (
    <div>
      <div className='exprtBtnRow'>
        <div style={{ marginTop: 10 }}>
          <Select placeholder='Select Vendor' options={VendorOptions} />
        </div>

        <div style={{ marginTop: 10 }}>
          Select Range :
          <input type='date' />
        </div>
        <div style={{ marginTop: 10 }}>
          <Button
            content='Total Earnings'
            icon='inr'
            className='totalEarningsBtn'
            label={{ as: 'a', basic: true, content: 'Rs. 0' }}
            labelPosition='right'
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <Button className='exprtBtn'>
            <Icon name='share square' /> Export
          </Button>
        </div>
      </div>
      <div>
        <Table
          sortable
          unstackable
          celled
          basic='very'
          className='CustomTableLayout'
        >
          <Table.Header>
            <Table.Row>
              {HEADING_DATA.map((heading) => (
                <Table.HeaderCell>
                  {heading === 'Checkbox' ? (
                    <Checkbox />
                  ) : (
                    <span>{heading}</span>
                  )}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {TABLE_DATA.map((vendor) => (
              <Table.Row>
                <Table.Cell>{vendor.sno}</Table.Cell>
                <Table.Cell>{vendor.orderId}</Table.Cell>
                <Table.Cell> {vendor.name}</Table.Cell>
                <Table.Cell>{vendor.mobilenumber}</Table.Cell>
                <Table.Cell>{vendor.earnings}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default VendorsPayoutTable;

import React from "react";
import ProductsPageHeader from "./products_header.component";
import { Icon, Input, Button, Grid, Responsive } from "semantic-ui-react";
import ProductList from "./product_list.component";
import AddProductModal from "./addproduct_modal.component";
import "./products.css";

const ProductsComponent = () => {
  return (
    <div className="DefaultLayout">
      <Header />
      <Card />
    </div>
  );
};

const Header = () => {
  return (
    <div className="HeaderLayout">
      <div className="addProductRow">
        <h3>Products & Pricing List</h3>
        <AddProductModal />
      </div>
      <ProductsPageHeader />
    </div>
  );
};

const Card = () => {
  const [searchString, setSearchString] = React.useState("");

  return (
    <div className="CardContainer">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: 20,
          marginLeft: 20,
        }}
      >
        <div>
          <Button className="exprtBtn">
            <Icon name="share square" /> Export
          </Button>
        </div>
        <Input
          icon="search"
          placeholder="Search by name"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
      </div>
      <div className="productsView">
        <Responsive maxWidth={768}>
          <Grid centered columns={1}>
            <ProductList searchString={searchString} />
          </Grid>
        </Responsive>
        <Responsive minWidth={768}>
          <Grid centered columns={5}>
            <ProductList searchString={searchString} />
          </Grid>
        </Responsive>
      </div>
    </div>
  );
};

export default ProductsComponent;

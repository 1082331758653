import React, { useState, useEffect } from "react";
import { Label, List, Tab, Table, Icon, TableCell } from "semantic-ui-react";
import MerchantProfileOrderHistory from "./merchant-profile-order-history.component";
import DateRangePicker from "react-daterange-picker";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { BASE_URL } from "../../../../config";
import toastr from "toastr";


const moment = extendMoment(originalMoment);

const MerchantProfileDetails = (props) => {
  const panes = [
    {
      menuItem: "Merchant Details",
      render: () => (
        <Tab.Pane style={{ marginLeft: -10 }}>
          <MerchantDetails userDetails={props.merchantDetails} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Billing Details",
      render: () => (
        <Tab.Pane style={{ marginLeft: -10 }}>
          <BillingDetails userDetails={props.merchantDetails} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Order History",
      render: () => (
        <Tab.Pane style={{ marginLeft: -10 }}>
          <OrderHistory userDetails={props.merchantDetails} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Coupons",
      render: () => (
        <Tab.Pane style={{ marginLeft: -10 }}>
          <Coupons userDetails={props.merchantDetails} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div>
      <Tab
        menu={{
          fluid: true,
          vertical: true,
          tabular: true,
          pointing: true,
        }}
        // style={{ width: '60%' }}
        panes={panes}
      />
    </div>
  );
};

const MerchantDetails = (props) => {
  const { userDetails } = props;
  const [merchantOpeningBalance, setMerchantOpeningBalance] = useState(0);


  return (
    <List divided animated>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{userDetails.name}</p>
        </List.Content>
        <List.Content>Merchant Name: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{userDetails.companyName}</p>
        </List.Content>
        <List.Content>Company Name: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{userDetails.email}</p>
        </List.Content>
        <List.Content>Merchant email: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <h4>{userDetails.gstNo}</h4>
        </List.Content>
        <List.Content>GST: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{userDetails.address}</p>
        </List.Content>
        <List.Content>Address: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{userDetails.totalPurchasedKgs}</p>
        </List.Content>
        <List.Content>Prurchased Kgs: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>₹ {userDetails.totalDiscountGiven}</p>
        </List.Content>
        <List.Content>Total Discount Given: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{moment(userDetails.createdAt).format("ll")}</p>
        </List.Content>
        <List.Content>Registration Date: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{moment(userDetails.createdAt).format("ll")}</p>
        </List.Content>
        <List.Content>Account Activated on :</List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          {/* <p>{moment(userDetails.createdAt).format("ll")}</p> */}
          <input type="text" id="fname" name="fname" onChange={(e) => setMerchantOpeningBalance(e.target.value)} defaultValue={userDetails?.openingBalance}/>
        </List.Content>
        <List.Content>Opening Balance: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <Label color="green">active</Label>
        </List.Content>
        <List.Content>Account Status :</List.Content>
      </List.Item>
      {/* <List.Item className="DetailListItems">
        <List.Content floated="right">
          <Label>https://akcorporation.com</Label>
        </List.Content>
        <List.Content>Business website :</List.Content>
      </List.Item> */}
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>Muthukumar</p>
        </List.Content>
        <List.Content>Converted by Marketing :</List.Content>
      </List.Item>
      <div style={{display:'grid', placeItems: 'center'}}>
        <button style={{backgroundColor: '#21BA45', padding: '5px 10px', color: 'white', border:'none', borderRadius:'5px', cursor: 'pointer'}} onClick={
          () => handleMerchantOpeningBalanceUpdation(userDetails,merchantOpeningBalance)}>Save</button>
      </div>
    </List>
  );
};

const handleMerchantOpeningBalanceUpdation = (userDetails,merchantOpeningBalance) => {
  console.log('clicked the merchant open!',userDetails);
  console.log('Written val', merchantOpeningBalance)
  fetch(`${BASE_URL}/update-merchant-balance`, {
    method: "POST",
    headers:{
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userDetails: userDetails,
      merchantOpeningBalance,
    })
  })
  .then((res) => res.json())
  .then((json) => {
    console.log("This is the response", json)
    toastr.success(json.message);
  })

}

const Coupons = (props) => {
  const { userDetails } = props;

  const couponData = userDetails.coupons !== undefined ? userDetails.coupons : [];

  return (
    <List divided animated>
      <List.Item className="DetailListItems">
        <Table
          sortable
          unstackable
          celled
          color="grey"
          className="CustomTableLayout"
        >
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>S.No</Table.HeaderCell>
              <Table.HeaderCell>Code</Table.HeaderCell>
              <Table.HeaderCell>Coupon Description</Table.HeaderCell>
              <Table.HeaderCell>Discount</Table.HeaderCell>
              <Table.HeaderCell>Start Date & Expiry Date</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {couponData.map((coupon, index) => (
              <Table.Row textAlign="center">
                <Table.Cell>#{index + 1}</Table.Cell>
                <Table.Cell>{coupon.code}</Table.Cell>
                <Table.Cell>{coupon.description}</Table.Cell>
                <Table.Cell>{coupon.discount} %</Table.Cell>
                <Table.Cell>
                  {moment(coupon.startDate).format("DD-MM-YYYY")} -{" "}
                  {moment(coupon.endDate).format("DD-MM-YYYY")}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </List.Item>
    </List>
  );
};

const BillingDetails = (props) => {
  const {openingBalance} = props.userDetails;
  console.log("This is the opening balance", openingBalance, props);
  const today = moment();
  const { userDetails } = props;
  const [statements, setStatements] = useState(userDetails.stmtData || []);
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState(
    moment.range(today.clone().subtract(7, "days"), today.clone())
  );

  const onToggle = () => {
    setOpen(!isOpen);
  };

  const onSelect = (value, states) => {
    const start = value.start.format("YYYY-MM-DD");
    const end = value.end.format("YYYY-MM-DD");
    const newStart = moment(start).startOf('day');
    const newEnd = moment(end).endOf('day');
    const stmtDatas = userDetails.stmtData.filter((stmt) => {
      return new Date(stmt.date) >= new Date(newStart) && new Date(stmt.date) <= new Date(newEnd);
    });
    setStatements(stmtDatas);
    setValue(value);
    setOpen(!isOpen);
  };

  const getTotalDebited = () => {
    const debitData = statements.filter((stmt) => (stmt.amount_type === 'Debit'));
    const debitAmt = debitData.reduce((sum, debit, index) => sum + Number(debit.amount), 0);
    return debitAmt;
  }

  const getTotalCredited = () => {
    const creditData = statements.filter((stmt) => (stmt.amount_type === 'Credit'));
    const creditAmt = creditData.reduce((sum, credit, index) => sum + Number(credit.amount), 0);
    return creditAmt;
  }

  const getClosingBalance = () => {
    const balance_amount = getTotalDebited() - getTotalCredited();
    // const final_amount = getTotalCredited() + balance_amount;
    return balance_amount;
  }

  const printInvoice = () => {
    setTimeout(function () {
      var printContents = document.getElementById("capture").innerHTML;
      var originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;

      window.print();

      document.body.innerHTML = originalContents;
      window.location.reload();
    }, 1000);
  };

  return (
    <List divided animated>
      <List.Item className="DetailListItems">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <List.Content>List of Transactions : </List.Content>
          <List.Content><div>
            Select Range:{" "}
            <input
              type="text"
              onClick={onToggle}
              style={{ width: 170 }}
              value={`${value.start.format("DD-MM-YYYY")} - ${value.end.format(
                "DD-MM-YYYY"
              )}`}
            />
            {isOpen && (
              <DateRangePicker
                value={value}
                onSelect={onSelect}
                singleDateRange={true}
              />
            )}
          </div></List.Content>
          <List.Content style={{ marginBottom: 2 }}><Label as="a" color="green" onClick={() => printInvoice()}>Print</Label></List.Content>
        </div>
        <div id="capture">
          <Table
            sortable
            unstackable
            celled
            color="grey"
          // className="CustomTableLayout"
          >
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Particulars</Table.HeaderCell>
                <Table.HeaderCell>Voucher Type</Table.HeaderCell>
                <Table.HeaderCell>invoice No</Table.HeaderCell>
                <Table.HeaderCell>Debit Amount</Table.HeaderCell>
                <Table.HeaderCell>Credit Amount</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
            {console.log('Statements', statements)}
              {statements.map((stmt, index) => (
                <Table.Row textAlign="center">
                  <Table.Cell>{moment(stmt.date).format('DD-MM-YYYY')}</Table.Cell>
                  <Table.Cell>{stmt.voucher_type === 'Sales' ? 'To' : 'By'}  {stmt.particular_type}</Table.Cell>
                  <Table.Cell>{stmt.voucher_type} </Table.Cell>
                  <Table.Cell>
                  <a
                    onClick={() =>
                      window.open(
                        `/#/view-invoice/${stmt.cname}/${stmt.voucher_no}`,
                        "_blank"
                      )
                    }
                  >
                    {stmt.voucher_no}{" "}<Icon name="eye" style={{ marginLeft: 5 }} />
                  </a>
                  </Table.Cell>
                  <Table.Cell>{stmt.amount_type === 'Debit' ? `₹ ${stmt.amount}` : ''}</Table.Cell>
                  <Table.Cell>{stmt.amount_type === 'Credit' ? `₹ ${stmt.amount}` : ''}</Table.Cell>
                </Table.Row>
              ))}
              <Table.Row textAlign="center">
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell> ₹ {getTotalDebited().toLocaleString("en-IN")}</Table.Cell>
                <Table.Cell> ₹ {getTotalCredited().toLocaleString("en-IN")}</Table.Cell>
              </Table.Row>
              <Table.Row textAlign="center">
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell> ₹ {getClosingBalance().toLocaleString("en-IN")}</Table.Cell>
              </Table.Row>
              <Table.Row textAlign="center">
                <Table.Cell></Table.Cell>
                <Table.Cell>Opening Balance</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                {openingBalance !== null && openingBalance > 0 ? <Table.Cell>₹ {openingBalance?.toLocaleString("en-IN")}</Table.Cell> : <TableCell>--</TableCell> }
                
                {openingBalance !== null && openingBalance > 0 ? <Table.Cell>₹ {openingBalance?.toLocaleString("en-IN")}</Table.Cell> : <TableCell>--</TableCell> }
              </Table.Row>
              <Table.Row textAlign="center">
                <Table.Cell></Table.Cell>
                <Table.Cell>Closing Balance</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                {openingBalance > 0 ? 
                <Table.Cell> ₹ {Math.abs(openingBalance - getTotalDebited()).toLocaleString("en-IN")}</Table.Cell>: 
                   <Table.Cell> ₹ {getTotalDebited().toLocaleString("en-IN")}</Table.Cell>
                }

                {openingBalance > 0 ? 
                <Table.Cell> ₹ {Math.abs(openingBalance - getTotalDebited()).toLocaleString("en-IN")}</Table.Cell>: 
                   <Table.Cell> ₹ {getTotalDebited().toLocaleString("en-IN")}</Table.Cell>
                }  
                {/* <Table.Cell> ₹ {(getTotalCredited() + getClosingBalance()).toLocaleString("en-IN")}</Table.Cell> */}
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      </List.Item>
    </List>
  );
};

const OrderHistory = (props) => {
  const { userDetails } = props;

  return <MerchantProfileOrderHistory orders={userDetails.ordersData} />;
};

export default MerchantProfileDetails;

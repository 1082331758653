import React, { useState, useEffect } from "react";
import { Card, Statistic } from "semantic-ui-react";
import { getPercentage } from "./drivers.api";
import { BASE_URL } from "../../../config";
import "./drivers.css";

const DriversHeader = () => {
  const [drivers, setDrivers] = useState([]);
  const [driverPerc, setDriverPerc] = useState(0);

  useEffect(() => {
    fetch(`${BASE_URL}/get-drivers`)
      .then((res) => res.json())
      .then((json) => {
        const percentage = getPercentage(json.drivers);
        setDriverPerc(percentage);
        setDrivers(json.drivers);
      });
  }, []);

  return (
    <div style={{ marginTop: 20 }}>
      <Card.Group>
        <Card className="AllDriversCard">
          <Card.Content>
            <div className="headercardContainer">
              <div>
                <Card.Header className="AllDriversTitle">
                  All Drivers
                </Card.Header>
                {Math.sign(driverPerc) === -1 ? (
                  <span style={{ color: "#21ba45", fontWeight: 600 }}>
                    +{Math.abs(driverPerc) || 0}%
                  </span>
                ) : (
                  <span style={{ color: "#db2828", fontWeight: 600 }}>
                    -{driverPerc || 0}%
                  </span>
                )}
                <span style={{ color: "#00000066", marginLeft: 5 }}>
                  in this month
                </span>
              </div>

              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="tiny">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      {drivers.length}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
        <Card className="PermanentDriversCard">
          <Card.Content>
            <div className="headercardContainer">
              <div>
                <Card.Header className="PermanentDriversTitle">
                  Permanent Drivers
                </Card.Header>
                <span style={{ color: "#21ba45", fontWeight: 600 }}>+5%</span>
                <span style={{ color: "#00000066", marginLeft: 5 }}>
                  in this month
                </span>
              </div>
              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="tiny">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      30
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
        <Card className="TempDriversCard">
          <Card.Content>
            <div className="headercardContainer">
              <div>
                <Card.Header className="TempDriversTitle">
                  Total Kms Driven
                </Card.Header>
                {/* <span style={{ color: '#00000066',marginLeft: 5 }}>in this month</span> */}
              </div>
              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="tiny">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      400
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
      </Card.Group>
    </div>
  );
};

const CardTitle = {
  fontSize: 14,
  fontWeight: "bold",
  color: "#22252f",
  textAlign: "left",
  paddingTop: 5,
};

const CardUI = {
  width: 270,
  borderRadius: 20,
};

const CardUI2 = {
  width: 350,
  borderRadius: 20,
};

// const CardValue = {
//   fontSize: 20,
//   fontWeight: 'bold',
//   textAlign: 'left',
//   paddingTop: 5,
// };

export default DriversHeader;

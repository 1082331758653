import React, { useState } from "react";
import { Input, Button, Modal, TextArea, Form, Image } from "semantic-ui-react";
import toastr from "toastr";
import JOE_PIC from "../../../images/joe.jpg";
import { BASE_URL } from "../../../config";

const AddMerchantModal = (props) => {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [creditLimit, setCreditLimit] = useState("15");
  const [open, setOpen] = useState(props.openModal);

  const close = () => {
    setOpen(false);
    props.onModalClose(false, "MERCHANT");
  };

  const validateGst = (g) => {
    let regTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
      g
    );
    if (regTest) {
      let a = 65,
        b = 55,
        c = 36,
        p;
      return Array["from"](g).reduce((i, j, k, g) => {
        p =
          (p =
            (j.charCodeAt(0) < a ? parseInt(j) : j.charCodeAt(0) - b) *
            ((k % 2) + 1)) > c
            ? 1 + (p - c)
            : p;
        return k < 14
          ? i + p
          : j == ((c = c - (i % c)) < 10 ? c : String.fromCharCode(c + b));
      }, 0);
    }
    return alert("Please Provide Valid GST No");
  };

  const saveMerchant = () => {
    if ((name, companyName, mobileNumber, address, creditLimit)) {
      fetch(`${BASE_URL}/create-merchant`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          companyName,
          gstNo,
          mobileNumber,
          email,
          address,
          creditLimit
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.status == 400) {
            toastr.error("Mobile number already exists.");
          } else {
            props.onModalClose(false, "SAVE");
            close();
          }
        });
    } else {
      toastr.error("Please fill all the fields!");
    }
  };

  return (
    <Modal size="small" open={open} onClose={close}>
      <Modal.Header>
        <Image
          src={JOE_PIC}
          inline
          circular
          size="mini"
          style={{ marginRight: 20 }}
        />
        <span>Add Merchant</span>
      </Modal.Header>
      <Modal.Content>
        <div className="merchantFormRow">
          <p className="merchantFormTitle">Name:</p>
          <Input
            placeholder="Enter your name"
            style={{ width: "50%" }}
            maxLength="20"
            onChange={(evt) => setName(evt.target.value)}
          />
          <p
            style={{
              alignSelf: "center",
              marginLeft: -40,
              marginTop: 10,
              zIndex: 0,
              color: "grey",
            }}
          >
            {name.length}/20
          </p>
        </div>

        <div className="merchantFormRow">
          <p className="merchantFormTitle">Company Name:</p>
          <Input
            placeholder="Enter company name"
            style={{ width: "50%" }}
            maxLength="25"
            onChange={(evt) => setCompanyName(evt.target.value)}
          />
          <p
            style={{
              alignSelf: "center",
              marginLeft: -40,
              marginTop: 10,
              zIndex: 0,
              color: "grey",
            }}
          >
            {companyName.length}/20
          </p>
        </div>

        <div className="merchantFormRow">
          <p className="merchantFormTitle">Mobile number:</p>
          <Input
            placeholder="Enter mobile number"
            style={{ width: "50%" }}
            maxLength="10"
            onChange={(evt) => setMobileNumber(evt.target.value)}
          />
          <p
            style={{
              alignSelf: "center",
              marginLeft: -40,
              marginTop: 10,
              zIndex: 0,
              color: "grey",
            }}
          >
            {mobileNumber.length}/10
          </p>
        </div>
        <div className="merchantFormRow">
          <p className="merchantFormTitle">Email:</p>
          <Input
            placeholder="Enter Email"
            style={{ width: "50%" }}
            maxLength="25"
            onChange={(evt) => setEmail(evt.target.value)}
          />
          <p
            style={{
              alignSelf: "center",
              marginLeft: -40,
              marginTop: 10,
              zIndex: 0,
              color: "grey",
            }}
          >
            {email.length}/25
          </p>
        </div>
        <div className="merchantFormRow">
          <p className="merchantFormTitle">Address:</p>
          <Form style={{ width: "50%" }}>
            <TextArea
              placeholder="Enter Address"
              cols="50"
              rows={4}
              maxLength="100"
              style={{ paddingRight: 50 }}
              onChange={(evt) => setAddress(evt.target.value)}
            />
          </Form>
          <p
            style={{
              alignSelf: "center",
              marginLeft: -50,
              marginTop: 50,
              zIndex: 0,
              color: "grey",
            }}
          >
            {address.length}/100
          </p>
        </div>
        <div className="merchantFormRow">
          <p className="merchantFormTitle">GST IN: </p>
          <Input
            placeholder="Enter GST IN"
            style={{ width: "50%" }}
            maxLength="15"
            onChange={(evt) => setGstNo(evt.target.value)}
          />
          <p
            style={{
              alignSelf: "center",
              marginLeft: -40,
              marginTop: 10,
              zIndex: 0,
              color: "grey",
            }}
          >
            {gstNo.length}/15
          </p>
        </div>
        <div className="merchantFormRow">
          <p className="merchantFormTitle">Credit Limit(Days): </p>
          <Input
            placeholder="Enter Credit Limit Days"
            style={{ width: "50%" }}
            maxLength="3"
            value={creditLimit}
            onChange={(evt) => setCreditLimit(evt.target.value)}
          />
          <p
            style={{
              alignSelf: "center",
              marginLeft: -40,
              marginTop: 10,
              zIndex: 0,
              color: "grey",
            }}
          >
            {creditLimit.length}/3
          </p>
        </div>
      </Modal.Content>
      <Modal.Actions style={{ textAlign: "center" }}>
        <Button className="addMerchantCancelBtn" onClick={close}>
          Cancel
        </Button>
        <Button
          className="addMerchantsubmitBtn"
          onClick={() => saveMerchant(props.history)}
          content="Submit"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default AddMerchantModal;

export const TABLE_DATA = [
  {
    sno: ' 1',
    name: 'Saravan Vel',
    mobilenumber: '9874563210',
    email: 'saravan@gmail.com',
    stores_visited: '20',
    km_driven: '10 km',
  },
  {
    sno: ' 2',
    name: 'Sathya Murthy',
    mobilenumber: '9874563210',
    email: 'sathya@gmail.com',
    stores_visited: '20',
    km_driven: '10 km',
  },
  {
    sno: ' 3',
    name: 'Chandra Bose',
    mobilenumber: '9874563210',
    email: 'bose@gmail.com',
    stores_visited: '20',
    km_driven: '10 km',
  },
];

export const HEADING_DATA = [
  'S.No',
  'Executive Name',
  'Mobile',
  'E-Mail',
  // 'Order Value',
  // 'Stores Visited',
  // 'KM Driven',
  // 'Levels',
  // 'Expenses Reimbursed',
  // 'Commission Given',
  'Actions',
];

import _ from "lodash";
import React, { Component } from "react";
import { Search, Grid, Label } from "semantic-ui-react";
import { BASE_URL } from "../../../config";

const initialState = { isLoading: false, members: [], results: [], value: "" };

const resultRenderer = ({ name, role }) => (
  <div className="result">
    <h3>{name}</h3>
    <div style={{ marginLeft: 5 }}>
      <Label color="green" size="mini">
        {role}
      </Label>
    </div>
  </div>
);

export default class SearchComponent extends Component {
  state = initialState;

  componentDidMount = () => {
    fetch(`${BASE_URL}/get-all-users`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ members: json.users });
      });
  };

  handleResultSelect = (e, { result }) => {
    this.setState({ value: result.name });
    const { history } = this.props;

    switch (result.role) {
      case "CUSTOMER":
        history.push({
          pathname: `/profile-customer/${result.phoneNumber}`,
        });
        break;
      case "MERCHANT":
        history.push({
          pathname: `/profile-merchant/${result.phoneNumber}`,
        });
        break;
      case "VENDOR":
        history.push({
          pathname: `/profile-vendor/${result.phoneNumber}`,
        });
        break;
      case "DRIVER":
        history.push({
          pathname: `/profile-driver/${result.phoneNumber}`,
        });
        break;
      case "MARKETING_EXECUTIVE":
        history.push({
          pathname: `/profile-marketing-executive/${result.phoneNumber}`,
        });
        break;
    }
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value });

    setTimeout(() => {
      if (this.state.value.length < 1)
        return this.setState({ isLoading: false, results: [], value: "" });

      const re = new RegExp(_.escapeRegExp(this.state.value), "i");
      const isMatch = (result) => re.test([result.name, result.phoneNumber]);

      this.setState({
        isLoading: false,
        results: _.filter(this.state.members, isMatch),
      });
    }, 300);
  };

  render() {
    const { isLoading, value, results } = this.state;

    return (
      <Grid>
        <Grid.Column width={6}>
          <Search
            fluid
            loading={isLoading}
            onResultSelect={this.handleResultSelect}
            onSearchChange={_.debounce(this.handleSearchChange, 500, {
              leading: true,
            })}
            resultRenderer={resultRenderer}
            results={results}
            value={value}
            placeholder="Search Members..."
            className="searchmembers"
            size="tiny"
          />
        </Grid.Column>
      </Grid>
    );
  }
}

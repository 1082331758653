export const TABLE_DATA = [
    {
      sno: ' 1',
      name: 'Saravan Vel',
      vendor_type: 'Regular',
      mobilenumber: '9874563210',
      email: 'saravan@gmail.com',
    },
    {
      sno: ' 2',
      name: 'Sathya Murthy',
      vendor_type: 'Regular',
      mobilenumber: '9874563210',
      email: 'sathya@gmail.com',
     
    },
    {
      sno: ' 3',
      name: 'Chandra Bose',
      vendor_type: 'Regular',
      mobilenumber: '9874563210',
      email: 'bose@gmail.com',
    },
   ];
   
   export const HEADING_DATA = [
    'S.No',
    'Vendor Name',
    'Vendor Type',
    'Mobile',
    'Email',
    'Recent Materials Purchased',
    'Amount Given',
    'Actions',
   ];
   
export const TABLE_DATA = [
  {
    sno: ' 1',
    name: 'Saravan Vel',
    mobilenumber: '9874563210',
    email: 'saravan@gmail.com',
    address: 'chennai',
    turnover: '₹ 20,000',
  },
  {
    sno: ' 2',
    name: 'A.K Store',
    mobilenumber: '9874563210',
    email: 'ak@gmail.com',
    address: 'chennai',
    turnover: '₹50,000',
  },
  {
    sno: ' 3',
    name: 'Chandra Bose',
    mobilenumber: '9874563210',
    email: 'bose@gmail.com',
    address: 'chennai',
    turnover: '₹ 40,000',
  },
];

export const HEADING_DATA = [
  'S.No',
  'Name / Companyname',
  'Mobile',
  'Email',
  'Address',
  'Total Turnover',
  'Actions',
];

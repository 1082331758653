import React from "react";
import moment from "moment";
import {
  Icon,
  Image,
  Button,
  Popup,
  Input,
  Table,
  Dropdown,
  Checkbox,
  Form,
  TextArea,
  Select,
} from "semantic-ui-react";
import toastr from "toastr";
import { BASE_URL } from "../../../config";

const MerchantEstimateGeneration = (props) => {
  return (
    <div className="invoiceDefaultLayout">
      <Card history={props.history} userData={props.history.location.state} />
    </div>
  );
};

class Card extends React.Component {
  state = {
    invoice_settings: {},
    estimate_no: 0,
    invoice_date: "",
    transaction_type: "",
    transaction_id: "",
    transaction_date: "",
    transaction_amount: "",
    balance_amount: "",
    product_name: "",
    product_weight: "",
    product_quantity: "",
    product_price: "",
    order_id: "",
    delivery_charges: "",
    coupon_details: {},
    membership_details: {},
    gst_amount: "",
    order_total: "",
    customer_name: "Customer Name",
    customer_phone: "",
    customer_address: {},
    customer_notes: "",
    pricing_terms: "",
    cust_notes_status: false,
    pricing_terms_status: false,
    addPaymentModal: false,
    addPaymentStatus: false,
    addProductModal: false,
    productsList: [],
    transactions: [],
    products: [],
    product_pricing: [],
    companies: [],
  };

  componentDidMount = () => {
    const { location } = this.props.history;
    const data = location.pathname.split("/");
    const order_id = data[2];

    fetch(`${BASE_URL}/get-order-by-id?orderID=${order_id}`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          order_id: json.orderData.order_id,
          productsList: json.orderData.products,
          delivery_charges: json.orderData.delivery_fee,
          coupon_details: json.orderData.coupon_applied,
          membership_details: json.orderData.membership_details,
          customer_name: json.orderData.name,
          customer_phone: json.orderData.phone_number,
          customer_address: json.orderData.shipping_address,
        });
      });

    this.setState({ invoice_date: Date.now() });
    fetch(`${BASE_URL}/get-invoice-settings`)
      .then((res) => res.json())
      .then((json) => {
        this.getEstimateNo(json.invoice_settings[0].collection_name);
        this.setState({
          invoice_settings: json.invoice_settings[0],
          customer_notes: json.invoice_settings[0].default_customer_notes,
          pricing_terms: json.invoice_settings[0].default_pricing_terms,
          companies: json.invoice_settings,
        });
      });

    fetch(`${BASE_URL}/get-products`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ products: json.products });
      });
  };

  generateInvoice = () => {
    const {
      order_id,
      invoice_no,
      invoice_date,
      invoice_settings,
      customer_name,
      customer_phone,
      customer_address,
      productsList,
      delivery_charges,
      transactions,
      cust_notes_status,
      customer_notes,
      pricing_terms_status,
      pricing_terms,
      coupon_details,
      membership_details,
    } = this.state;
    fetch(`${BASE_URL}/create-estimate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        invoice_no,
        invoice_date,
        company_details: invoice_settings,
        order_id,
        products: productsList,
        delivery_charges,
        coupon_details,
        membership_details,
        gst_amount: this.getGstAmount(productsList),
        order_total: this.getOrderTotal(productsList),
        customer_name,
        customer_phone,
        customer_address: customer_address,
        payment_status:
          this.getBalanceDue(productsList, transactions) === 0
            ? "paid"
            : "unpaid",
        transactions,
        cust_notes_status,
        customer_notes,
        pricing_terms_status,
        pricing_terms,
        role: "MERCHANT",
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        toastr.success("Estimate Generated Successfully!");
        this.props.history.push("/orderrequests");
      });
  };

  showAddProductModal = () => () => this.setState({ addProductModal: true });
  addProductClose = () => this.setState({ addProductModal: false });

  addProduct = () => {
    const {
      product_name,
      product_quantity,
      product_price,
      product_weight,
    } = this.state;
    this.setState({
      productsList: this.state.productsList.concat([
        {
          name: product_name,
          quantity: product_quantity,
          weight: product_weight,
          price: product_price,
        },
      ]),
    });
  };

  removeProduct = (idx) => () => {
    this.setState({
      productsList: this.state.productsList.filter((s, sidx) => idx !== sidx),
    });
  };

  getTransactionsBalance = (transactions) => {
    const paymentData = transactions.filter((transaction) =>
      Object.keys(transaction).some(
        (key) => transaction[key].toString().search("payment") !== -1
      )
    );
    const payments = paymentData.reduce(
      (sum, transaction, index) => sum + Number(transaction.transaction_amount),
      0
    );

    const refundData = transactions.filter((transaction) =>
      Object.keys(transaction).some(
        (key) => transaction[key].toString().search("refund") !== -1
      )
    );
    const refunds = refundData.reduce(
      (sum, transaction, index) => sum + Number(transaction.transaction_amount),
      0
    );

    const total_balance = payments - refunds;
    return Number(total_balance);
  };

  getProductsTotal = (products) => {
    const total_value = products.reduce(
      (sum, product, index) => sum + Number(product.quantity * product.price),
      0
    );
    return Number(total_value);
  };

  getCouponDiscount = () => {
    const { coupon_details, productsList } = this.state;

    const discount =
      (this.getProductsTotal(productsList) *
        Number(
          coupon_details.discount !== undefined ? coupon_details.discount : 0
        )) /
      100;

    return discount;
  };

  getGstAmount = (products) => {
    const { invoice_settings } = this.state;
    const gst =
      (this.getProductsTotal(products) *
        (Number(invoice_settings.cgst) + Number(invoice_settings.sgst))) /
      100;

    return Math.round(gst);
  };

  getBalanceDue = (products, transactions) => {
    const { delivery_charges, membership_details } = this.state;

    const order_total =
      this.getProductsTotal(products) +
      this.getGstAmount(products) +
      Number(delivery_charges) -
      this.getCouponDiscount() -
      membership_details.discountAmt;

    const due = order_total - this.getTransactionsBalance(transactions);

    return due;
  };

  getOrderTotal = (products) => {
    const { delivery_charges, membership_details } = this.state;

    const order_total =
      this.getProductsTotal(products) +
      this.getGstAmount(products) +
      Number(delivery_charges) -
      this.getCouponDiscount() -
      membership_details.discountAmt;

    return order_total;
  };

  getEstimateNo = (value) => {
    fetch(`${BASE_URL}/get-estimate-no?cname=${value}`)
      .then((res) => res.json())
      .then((json) => {
        if (json.estimates.length > 0) {
          this.setState({ estimate_no: json.estimates[0].estimate_no + 1 });
        } else {
          this.setState({ estimate_no: 1001 });
        }
      });
  };

  handleChangeCompany = (id) => {
    const data = this.state.companies.filter((company) => company._id === id);
    const company = data[0];

    this.getEstimateNo(company.collection_name);
    this.setState({
      invoice_settings: company,
    });
  };

  render() {
    const {
      invoice_settings,
      estimate_no,
      invoice_date,
      customer_name,
      customer_address,
      transactions,
      products,
      product_pricing,
      productsList,
      customer_notes,
      pricing_terms,
      delivery_charges,
      cust_notes_status,
      pricing_terms_status,
      coupon_details,
      membership_details,
      companies,
    } = this.state;

    return (
      <div className="invoiceCardContainer">
        <div className="invoicePageTitleRow">
          <div style={{ paddingTop: 10, paddingLeft: 20, display: "flex" }}>
            <Icon
              name="arrow left"
              style={{ paddingTop: 3, cursor: "pointer" }}
              onClick={() => this.props.history.push("/orderrequests")}
            />
            <h3 style={{ marginTop: 0, marginLeft: 10 }}>
              Estimate Generation
            </h3>
          </div>
          <div>
            <Select
              placeholder="Select Company"
              options={companies.map((company) => ({
                key: company._id,
                value: company._id,
                text: company.company_name,
              }))}
              style={{ margin: "auto" }}
              onChange={(event, data) => this.handleChangeCompany(data.value)}
            />
          </div>
          <div>
            <Button
              className="invoiceSetBtn"
              onClick={() => this.props.history.push("/invoice-settings")}
            >
              <Icon name="setting" /> Invoice Settings
            </Button>
          </div>
          <div>
            <Button className="PDFBtn">
              <Icon name="file pdf" /> PDF
            </Button>
          </div>
          <div>
            <Button
              className="generateSaveBtn"
              onClick={() => this.generateInvoice()}
            >
              <Icon name="save" /> Generate Estimate
            </Button>
          </div>
        </div>

        <div className="Card">
          <div className="invoiceContainer">
            <div>
              <Image
                src={invoice_settings.invoice_logo}
                style={{ width: 100 }}
              />
            </div>
            <div className="section1">
              <div>
                <p className="section1Text" style={{ fontSize: 22 }}>
                  {invoice_settings.company_name},
                </p>
                <p className="section1Text">{invoice_settings.address_line1}</p>
                <p className="section1Text">
                  {invoice_settings.address_line2}{" "}
                  {invoice_settings.address_line3}
                </p>
                <p className="section1Text">Email: {invoice_settings.email}</p>
                <p className="section1Text">
                  Website: {invoice_settings.website}
                </p>
                <p className="section1Text" style={{ marginBottom: 30 }}>
                  WhatsApp for Business: +91-{invoice_settings.whatsapp_number}
                </p>
                <p className="section1Text">GSTIN: {invoice_settings.gst_no}</p>
                <p className="section1Text">CIN: {invoice_settings.cin_no}</p>
                <p className="section1Text">PAN: {invoice_settings.pan_no}</p>
              </div>
            </div>
            <div className="section2">
              <div>
                <p
                  className="section2Text"
                  style={{ fontSize: 22, fontWeight: "bold" }}
                >
                  Invoice #{estimate_no}{" "}
                </p>
                <p className="section2Text">
                  Invoice Date: {moment(invoice_date).format("L")}
                  <Popup
                    on="click"
                    pinned
                    style={{ textAlign: "center" }}
                    trigger={
                      <Icon
                        name="pencil"
                        style={{
                          fontSize: 15,
                          color: "#93c47d",
                          marginLeft: 10,
                        }}
                      />
                    }
                  >
                    <div>
                      <p>Edit date:</p>
                      <input
                        type="date"
                        placeholder="Invoice number"
                        className="datepickerinput"
                        onChange={(e) =>
                          this.setState({ invoice_date: e.target.value })
                        }
                      />
                    </div>
                  </Popup>
                </p>
              </div>
              <div>
                <p
                  className="section3Text"
                  style={{ fontSize: 17, fontWeight: "bold" }}
                >
                  Invoiced To
                </p>
                <p className="section3Text">{customer_name}</p>
                <p className="section3Text">
                  {customer_address.detailedAddress}
                </p>
                <p className="section3Text">{customer_address.pincode}.</p>
              </div>
            </div>

            <div className="section3"></div>
            <div className="section4">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontSize: 20, fontWeight: "600" }}>Products</p>
                <div>
                  <Popup
                    on="click"
                    pinned
                    position="left center"
                    style={{ textAlign: "center" }}
                    trigger={
                      <Icon
                        name="pencil"
                        style={{
                          fontSize: 15,
                          color: "#93c47d",
                          marginLeft: 5,
                        }}
                      />
                    }
                  >
                    <div>
                      <p>Select Product:</p>
                      <Dropdown
                        placeholder="Select Product"
                        fluid
                        search
                        selection
                        options={products.map((product, index) => ({
                          key: index,
                          text: product.name,
                          value: product,
                        }))}
                        onChange={(event, data) =>
                          this.setState({
                            product_name: data.value.name,
                            product_pricing: data.value.pricing,
                          })
                        }
                      />
                    </div>
                    <div>
                      <p>Select Weight:</p>
                      <Dropdown
                        placeholder="Select Pricing"
                        fluid
                        search
                        selection
                        options={product_pricing.map((pricing, index) => ({
                          key: index,
                          text: `${pricing.weight} Kg`,
                          value: pricing,
                        }))}
                        onChange={(event, data) =>
                          this.setState({
                            product_weight: data.value.weight,
                            product_price: data.value.merchantPrice,
                          })
                        }
                      />
                    </div>
                    <div>
                      <p>Amount:</p>
                      <Input value={this.state.product_price} disabled />
                    </div>
                    <div>
                      <p>Quantity:</p>
                      <Input
                        placeholder="Enter Quantity"
                        onChange={(e) =>
                          this.setState({ product_quantity: e.target.value })
                        }
                      />
                    </div>
                    <div>
                      <Button onClick={this.addProduct}>Submit</Button>
                    </div>
                  </Popup>
                </div>
              </div>
              <div>
                <Table celled unstackable>
                  <Table.Header>
                    <Table.Row textAlign="center">
                      <Table.HeaderCell style={{ backgroundColor: "#b6d7a8" }}>
                        S.no
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: "#b6d7a8" }}>
                        Products
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: "#b6d7a8" }}>
                        Quantity
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: "#b6d7a8" }}>
                        Total
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {productsList.map((product, idx) => (
                      <Table.Row textAlign="center">
                        <Table.Cell textAlign="center">{idx + 1}</Table.Cell>
                        <Table.Cell>
                          {product.name} - {product.weight}Kg
                        </Table.Cell>
                        <Table.Cell>{product.quantity}</Table.Cell>
                        <Table.Cell>
                          Rs. {product.quantity * product.price}
                          <Icon
                            name="times circle"
                            className="cancelRowBtn"
                            color="red"
                            onClick={this.removeProduct(idx)}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ alignSelf: "flex-end" }}>
                    <div style={{ marginBottom: 20 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 17,
                            fontWeight: "600",
                            marginBottom: 10,
                          }}
                        >
                          Customer Notes
                        </p>
                        <Checkbox
                          label="Enable"
                          checked={cust_notes_status}
                          onChange={() =>
                            this.setState({
                              cust_notes_status: !cust_notes_status,
                            })
                          }
                        />
                      </div>

                      <Form>
                        <TextArea
                          placeholder="Thanks for your business."
                          cols="50"
                          maxLength="70"
                          value={customer_notes}
                          onChange={(e) =>
                            this.setState({ customer_notes: e.target.value })
                          }
                        />
                      </Form>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 17,
                            fontWeight: "600",
                            marginBottom: 10,
                          }}
                        >
                          Pricing Terms
                        </p>
                        <Checkbox
                          label="Enable"
                          checked={pricing_terms_status}
                          onChange={() =>
                            this.setState({
                              pricing_terms_status: !pricing_terms_status,
                            })
                          }
                        />
                      </div>

                      <Form>
                        <TextArea
                          placeholder="Thanks for your business."
                          cols="50"
                          maxLength="70"
                          value={pricing_terms}
                          onChange={(e) =>
                            this.setState({ pricing_terms: e.target.value })
                          }
                        />
                      </Form>
                    </div>
                  </div>

                  <div>
                    <Table celled unstackable collapsing>
                      <Table.Body>
                        <Table.Row textAlign="right">
                          <Table.Cell className="tableCellbg">
                            Item Total
                          </Table.Cell>
                          <Table.Cell className="tableCellbg">
                            ₹ {this.getProductsTotal(productsList)}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ color: "#cc0000" }}
                          >
                            Coupon Applied({coupon_details.code})
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ color: "#cc0000" }}
                          >
                            -₹ {this.getCouponDiscount()}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ color: "#cc0000" }}
                          >
                            Membership Level({membership_details.level})
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ color: "#cc0000" }}
                          >
                            -₹ {membership_details.discountAmt}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            Other Charges
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            ₹ {delivery_charges}
                            <Popup
                              on="click"
                              pinned
                              style={{ textAlign: "center" }}
                              trigger={
                                <Icon
                                  name="pencil"
                                  style={{
                                    fontSize: 15,
                                    color: "#93c47d",
                                    marginLeft: 5,
                                    position: "absolute",
                                    right: 85,
                                  }}
                                />
                              }
                            >
                              <div>
                                <p style={{ marginBottom: 5 }}>
                                  Edit Other Charges:
                                </p>
                                <Input
                                  placeholder="Other Charges"
                                  onChange={(e) =>
                                    this.setState({
                                      delivery_charges: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </Popup>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            {invoice_settings.cgst}.00% CGST
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            ₹ {this.getGstAmount(productsList) / 2}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            {invoice_settings.sgst}.00% SGST
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ fontWeight: 500 }}
                          >
                            ₹ {this.getGstAmount(productsList) / 2}
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row textAlign="right">
                          <Table.Cell
                            className="tableCellbg"
                            style={{ color: "#6aa84f" }}
                          >
                            Total Estimation Amount
                          </Table.Cell>
                          <Table.Cell
                            className="tableCellbg"
                            style={{ color: "#6aa84f" }}
                          >
                            ₹ {this.getBalanceDue(productsList, transactions)}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MerchantEstimateGeneration;

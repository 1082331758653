export const LEADS_DATA = [
    {
      name: 'Akhil',
      mobile_number: '9494023849',
      email_id: 'sai.iki1997@gmail.com',
      kgs: '10kg',
      cashback_amount: '10',
      cashback_status: 'Sent Gpay',  
    },
    {
      name: 'Sai Akhil',
      mobile_number: '9494023849',
      email_id: 'sai.iki1997@gmail.com',
      kgs: '25kg',
      cashback_amount: '25',
      cashback_status: 'Not sent',  
    },
    {
      name: 'Anil Kumar',
      mobile_number: '9494023849',
      email_id: 'sai.iki1997@gmail.com',
      kgs: '5kg',
      cashback_amount: '5',
      cashback_status: 'Sent Gpay',  
    }
]

export const HEADING_DATA = [
    'S.no',
    'Name',
    'Mobile',
    'Email ID',
    'Kgs',
    'Cashback',
    'Status of Cashback',
]

export const CASHBACK_STATUS_DATA = [
  {
    key: 1,
    name: "SENT GPAY",
    value: "SENT_GPAY",
  },
  {
    key: 2,
    name: "NOT SENT",
    value: "NOT_SENT",
  },
];

export const BAG_WEIGHT = [
  {
    key: 0,
    name: "All",
    value: "",
  },
  {
    key: 1,
    name: "5 Kg",
    value: "5",
  },
  {
    key: 2,
    name: "10 Kg",
    value: "10",
  },
  {
    key: 3,
    name: "25 Kg",
    value: "25",
  },
];
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Icon, Divider, Table, Button } from "semantic-ui-react";
import { BASE_URL } from "../../../config";
import './weighBridge.css';

const ViewWeighBridgeTicket = (props) => {
  const { location } = props;
  const data = location.pathname.split("/");
  const id = data[2];

  const [ticketInfo, setTicketInfo] = useState({});


  useEffect(() => {
    fetch(`${BASE_URL}/get-weight-ticket?challan_no=${id}`)
      .then((res) => res.json())
      .then((json) => {
        setTicketInfo(json.weigh_ticket);
      });
  }, []);

  const printInvoice = () => {
    setTimeout(function () {
      var printContents = document.getElementById("capture").innerHTML;
      var originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;

      window.print();

      document.body.innerHTML = originalContents;
      window.location.reload();
    }, 1000);
  };

  return (
    <div className="DefaultLayout">
      <div className="HeaderLayout">
        <div style={{ paddingTop: 10, paddingLeft: 20, display: "flex" }}>
          <Icon
            name="arrow left"
            style={{ paddingTop: 3, cursor: "pointer" }}
            onClick={() => props.history.push("/weigh-bridge")}
          />
          <h3 style={{ marginTop: 0, marginLeft: 10 }}>
            View Weigh Bridge Ticket
          </h3>
        </div>
      </div>
      <div className="CardContainer">
        <div id="capture" style={{ marginLeft: 100, marginRight: 100 }}>
          <div className="WBTitleRow">
            <div style={{ margin: "auto" }}>
              <h1>{ticketInfo.company_name}</h1>
            </div>
          </div>
          <div className="WBTitleRow">
            <div></div>
            <div>
              <h4>Ticket No: #{ticketInfo.weigh_challan_no}</h4>
            </div>
          </div>
          <div className="WBTitleRow">
            <div>
              <h4>Billing Date: {moment(ticketInfo.date).format("DD-MM-YYYY")}</h4>
            </div>
            <div>
              <h4>
                Weigh Date: {moment(ticketInfo.weigh_date).format("DD-MM-YYYY")}
              </h4>
            </div>
          </div>
          <div className="WBTitleRow">
            <div></div>
            <div>
              <h4>
                Weigh Time: {moment(ticketInfo.weigh_date).format("HH:MM:SS A")}
              </h4>
            </div>
          </div>
          <Divider />
          <div className="WBTextRow">
            <div className="WBColumn">
              <div className="WBColumnText">
                <h4>Name: {ticketInfo.name}</h4>
              </div>
              <div className="WBColumnText">
                <h4>Material: {ticketInfo.product_name}</h4>
              </div>
              <div className="WBColumnText">
                <p>Vehicle No: {ticketInfo.vehicle_no}</p>
              </div>
              <div className="WBColumnText">
                <p>Vehicle Weight: {ticketInfo.vehicle_weight} (Kg)</p>
              </div>
              <div className="WBColumnText">
                <p>Total Weight: {ticketInfo.total_weight} (Kg)</p>
              </div>
              <div className="WBColumnText">
                <h4>Stock Weight: {ticketInfo.stock_weight} (Kg)</h4>
              </div>
              <div className="WBColumnText">
                <p>Total Value: ₹ {Number(ticketInfo.total_value).toLocaleString('en-IN')}</p>
              </div>
              <div className="WBColumnText">
                <p>Labour Charges: ₹ {Number(ticketInfo.labour_charges).toLocaleString('en-IN')}</p>
              </div>
            </div>
            <div className="WBColumn">
              <div className="WBColumnText">
                <h4>Total Bags: {ticketInfo.total_bags} (Units)</h4>
              </div>
              <div style={{ marginBottom: 20 }}>
                <Table
                  sortable
                  unstackable
                  celled
                  basic="very"
                  className="CustomTableLayout"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Grade</Table.HeaderCell>
                      <Table.HeaderCell>Bags</Table.HeaderCell>
                      <Table.HeaderCell>Price</Table.HeaderCell>
                      <Table.HeaderCell>Reason</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell textAlign="center">1</Table.Cell>
                      <Table.Cell textAlign="center">{ticketInfo.quality1_bags} (Units)</Table.Cell>
                      <Table.Cell textAlign="center">₹ {Number(ticketInfo.quality1_price).toLocaleString('en-IN')}</Table.Cell>
                      <Table.Cell textAlign='center'>{ticketInfo.quality1_reason}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="center">2</Table.Cell>
                      <Table.Cell textAlign="center">{ticketInfo.quality2_bags} (Units)</Table.Cell>
                      <Table.Cell textAlign="center">₹ {Number(ticketInfo.quality2_price).toLocaleString('en-IN')}</Table.Cell>
                      <Table.Cell textAlign="center">{ticketInfo.quality2_reason || '-'}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
              <div className="WBColumnText">
                <h2>Final Value: ₹ {Number(ticketInfo.final_value).toLocaleString('en-IN')}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="WBTitleRow">
          <div style={{ margin: "auto" }}>
            <Button color="green" onClick={() => printInvoice()} style={{ cursor: 'pointer' }}>
              Print
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewWeighBridgeTicket;

import React, { useState, useEffect } from "react";
import { Card, Statistic, Button, Icon } from "semantic-ui-react";
import { BASE_URL } from "../../../config";
import moment from "moment";

const StatementsHeader = () => {
  
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 20,
        }}
      >
        <div style={{ paddingTop: 10, paddingLeft: 20 }}>
          <h3>Statements</h3>
        </div>
      </div>

      <Card.Group>
        <Card className="TotalTransactionsCard">
          <Card.Content>
            <div className="billingCardContainer">
              <div>
                <Card.Header className="TotalTransactionsTitle">
                  Total Transactions
                </Card.Header>

              </div>

              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="mini">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      0
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>

        <Card className="TotalRevenueCard">
          <Card.Content>
            <div className="billingCardContainer">
              <div>
                <Card.Header className="TotalRevenueTitle">
                  Total Debited
                </Card.Header>
              </div>
              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="mini">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      ₹0
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>

        <Card className="TotalRevenueCard">
          <Card.Content>
            <div className="billingCardContainer">
              <div>
                <Card.Header className="TotalRevenueTitle">
                  Total Credited
                </Card.Header>
              </div>
              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="mini">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      ₹0
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>

        <Card className="TotalRevenueCard">
          <Card.Content>
            <div className="billingCardContainer">
              <div>
                <Card.Header className="PendingPaymentsTitle">
                  Closing Balance
                </Card.Header>
              </div>
              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="mini">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      ₹0
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
      </Card.Group>
    </div>
  );
};

export default StatementsHeader;

import React, { useEffect, useState } from "react";
import { Table, Modal, Icon, Checkbox, Button, Input } from "semantic-ui-react";
import toastr from "toastr";
import _ from "lodash";
import { HEADING_DATA } from "./marketing-executive.constant";
import { BASE_URL } from "../../../config";

const MarkingExecutiveTable = (props) => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [selectedExecutive, setExecutiveID] = useState("");
  const [size, setSize] = useState("");
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [marketingExecutives, setMarketingExecutives] = useState([]);
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);

  useEffect(() => {
    fetchMarketingExecutives();
  }, []);

  const fetchMarketingExecutives = () => {
    fetch(`${BASE_URL}/get-marketing-executives`)
      .then((res) => res.json())
      .then((json) => {
        setMarketingExecutives(json.executives);
      });
  };

  const handleSort = (clickedColumn) => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setMarketingExecutives(_.sortBy(marketingExecutives, [clickedColumn]));
      setDirection("ascending");

      return;
    }

    setMarketingExecutives(marketingExecutives.reverse());
    setDirection(direction === "ascending" ? "descending" : "ascending");
  };

  const editExecutive = (size, executive) => () => {
    setSize(size);
    setOpen(true);
    setEdit(true);
    setExecutiveID(executive._id);
    setName(executive.name);
    setEmail(executive.email);
    setPhoneNumber(executive.phoneNumber);
  };

  const handleUpdate = () => {
    fetch(
      `${BASE_URL}/update-marketing-executive?executive_id=${selectedExecutive}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          phoneNumber,
          email,
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        toastr.success("Marketing Executive Updated Successfully!");
        fetchMarketingExecutives();
        close();
      });
  };

  const deleteExecutive = (size, ID) => () => {
    setSize(size);
    setOpen(true);
    setExecutiveID(ID);
  };

  const handleDelete = () => {
    fetch(
      `${BASE_URL}/delete-marketing-executive?executive_id=${selectedExecutive}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => res.json())
      .then((json) => {
        fetchMarketingExecutives();
        close();
      });
  };

  const close = () => {
    setOpen(false);
    setEdit(false);
  };

  const executives_table = marketingExecutives.filter((executive) =>
    Object.keys(executive).some(
      (key) => executive[key].toString().search(props.searchString) !== -1
    )
  );

  return (
    <div className="MEtableiss">
      <Table
        sortable
        unstackable
        celled
        basic="very"
        // className="CustomTableLayout"
        id="marketing-executive-table"
      >
        <Table.Header>
          <Table.Row>
            {HEADING_DATA.map((heading) => (
              <Table.HeaderCell
                sorted={column === heading ? direction : null}
                onClick={handleSort(heading)}
              >
                {heading === "Checkbox" ? <Checkbox /> : <span>{heading}</span>}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {executives_table.map((executive, index) => (
            <Table.Row>
              <Table.Cell>{index + 1}</Table.Cell>
              <Table.Cell>
                <a
                  onClick={() =>
                    props.history.push({
                      pathname: `/profile-marketing-executive/${executive.phoneNumber}`,
                    })
                  }
                >
                  {executive.name}{" "}
                </a>
              </Table.Cell>
              <Table.Cell>{executive.phoneNumber}</Table.Cell>
              <Table.Cell>{executive.email}</Table.Cell>
              {/* <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>10 km</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell> */}
              <Table.Cell>
                <Button
                  style={{ fontSize: 13, backgroundColor: "#d9ead3" }}
                  onClick={editExecutive("small", executive)}
                >
                  <Icon name="edit" />
                  Edit
                </Button>

                <Button
                  style={{ fontSize: 13, backgroundColor: "#f4cccc" }}
                  onClick={deleteExecutive("mini", executive._id)}
                >
                  <Icon name="trash" /> Delete
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {edit === true ? (
        <Modal size={size} open={open} onClose={close}>
          <Modal.Header>Edit Marketing Executive </Modal.Header>
          <Modal.Content style={{ marginBottom: 20 }}>
            <div className="executiveFormRow">
              <p className="titleExecutiveform">Executive name:</p>
              <Input
                value={name}
                style={{ width: "50%" }}
                maxLength="20"
                onChange={(evt) => setName(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {name.length}/20
              </p>
            </div>

            <div className="executiveFormRow">
              <p className="titleExecutiveform">Mobile number:</p>
              <Input
                value={phoneNumber}
                style={{ width: "50%" }}
                maxLength="10"
                onChange={(evt) => setPhoneNumber(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {phoneNumber.length}/10
              </p>
            </div>
            <div className="executiveFormRow">
              <p className="titleExecutiveform">Email:</p>
              <Input
                value={email}
                style={{ width: "50%" }}
                maxLength="25"
                onChange={(evt) => setEmail(evt.target.value)}
              />
              <p
                style={{
                  alignSelf: "center",
                  marginLeft: -40,
                  marginTop: 10,
                  zIndex: 0,
                  color: "grey",
                }}
              >
                {email.length}/25
              </p>
            </div>
          </Modal.Content>
          <Modal.Actions style={{ textAlign: "center" }}>
            <Button className="addCustomerCancelBtn" onClick={close}>
              Cancel
            </Button>
            <Button
              className="addCustomersubmitBtn"
              onClick={() => handleUpdate()}
              content="Update"
            />
          </Modal.Actions>
        </Modal>
      ) : (
        <Modal size={size} open={open} onClose={close}>
          <Modal.Header>Delete Marketing Executive </Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete marketing executive ?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button className="addCustomerCancelBtn" onClick={close}>
              No
            </Button>
            <Button
              className="addCustomersubmitBtn"
              icon="checkmark"
              labelPosition="right"
              content="Yes"
              onClick={() => handleDelete()}
            />
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
};

export default MarkingExecutiveTable;

import React, { useState } from 'react';
import { Menu, Icon, Responsive, Select, Button } from 'semantic-ui-react';
import Sidebar from '../sidebar/sidebar.component';
import AddCustomerModal from '../../pages/customers/addCustomerModal.component';
import AddMerchantModal from '../../pages/merchants/addMerchantModal.component';
import AddVendorModal from '../../pages/vendors/addVendorModal.component';
import AddDriverModal from '../../pages/drivers/addDriverModal.component';
import AddExecutiveModal from '../../pages/marketing-executives/addMarketingExecutiveModal.component';
import AddNewPaymentModal from './newPaymentModal.component';
import PropTypes from 'prop-types';
import SearchComponent from './search.component';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { logoutUser } from '../../../actions/authActions';
import { withRouter } from 'react-router';
import './navbar.styles.css';

const memberOptions = [
  { key: 'customer', value: 'CUSTOMER', text: 'Customer' },
  { key: 'merchant', value: 'MERCHANT', text: 'Merchant' },
  { key: 'vendor', value: 'VENDOR', text: 'Vendor' },
  { key: 'driver', value: 'DRIVER', text: 'Driver' },
  {
    key: 'marketingexecutive',
    value: 'MARKETING_EXECUTIVE',
    text: 'Marketing Executive',
  },
];

const Navbar = (props) => {
  const role = props.auth.user.role;
  const [isVisible, setVisibility] = useState(null);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [merchantModalOpen, setMerchantModalOpen] = useState(false);
  const [vendorModalOpen, setVendorModalOpen] = useState(false);
  const [driverModalOpen, setDriverModalOpen] = useState(false);
  const [executiveModalOpen, setExecutiveModalOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleCloseModal = (value, role) => {
    switch (role) {
      case 'CUSTOMER':
        setCustomerModalOpen(value);
        break;
      case 'MERCHANT':
        setMerchantModalOpen(value);
        break;
      case 'VENDOR':
        setVendorModalOpen(value);
        break;
      case 'DRIVER':
        setDriverModalOpen(value);
        break;
      case 'MARKETING_EXECUTIVE':
        setExecutiveModalOpen(value);
        break;
      default:
    }
  };

  const Close = (value) => {
    setOpen(value);
  };

  const onLogout = () => {
    props.logoutUser();
  };

  return (
    <div>
      <Menu fixed="top" borderless>
        <Icon
          name="bars"
          className="barssidebar"
          onClick={() => setVisibility(true)}
        />
        {isVisible && (
          <div>
            <Sidebar />
            <Icon
              name="close"
              className="closeBtn"
              onClick={() => setVisibility(false)}
            />
          </div>
        )}

        <Responsive maxWidth={768}>
          <h3 className="headingmobile">KK Rice Portal</h3>
        </Responsive>

        {role === 5 && (
          <div style={{ marginTop: 15, marginLeft: 15 }}>
            <Responsive minWidth={768}>
              <Select
                size="mini"
                placeholder="+ Add member"
                options={memberOptions}
                className="uiSelectionDropdownNavbar"
                onChange={(event, data) => {
                  switch (data.value) {
                    case 'CUSTOMER':
                      setCustomerModalOpen(true);
                      break;
                    case 'MERCHANT':
                      setMerchantModalOpen(true);
                      break;
                    case 'VENDOR':
                      setVendorModalOpen(true);
                      break;
                    case 'DRIVER':
                      setDriverModalOpen(true);
                      break;
                    case 'MARKETING_EXECUTIVE':
                      setExecutiveModalOpen(true);
                      break;
                    default:
                  }
                }}
              />
              <div>
                {customerModalOpen === true && (
                  <AddCustomerModal
                    openModal={customerModalOpen}
                    onModalClose={handleCloseModal}
                  />
                )}
              </div>
              <div>
                {merchantModalOpen === true && (
                  <AddMerchantModal
                    openModal={merchantModalOpen}
                    onModalClose={handleCloseModal}
                  />
                )}
              </div>
              <div>
                {vendorModalOpen === true && (
                  <AddVendorModal
                    openModal={vendorModalOpen}
                    onModalClose={handleCloseModal}
                  />
                )}
              </div>
              <div>
                {driverModalOpen === true && (
                  <AddDriverModal
                    openModal={driverModalOpen}
                    onModalClose={handleCloseModal}
                  />
                )}
              </div>
              <div>
                {executiveModalOpen === true && (
                  <AddExecutiveModal
                    openModal={executiveModalOpen}
                    onModalClose={handleCloseModal}
                  />
                )}
              </div>
            </Responsive>
          </div>
        )}

        {role === 5 && (
          <div className="newInvoice">
            <Responsive minWidth={768}>
              <Button
                size="mini"
                color="blue"
                onClick={() =>
                  window.open('/#/customer-invoice-generation', '_blank')
                }
              >
                {/* <Icon name="plus" /> */}
                New Walk-in Customer invoice
              </Button>
            </Responsive>
          </div>
        )}

        {role === 4 && (
          <div className="newInvoice">
            <Responsive minWidth={768}>
              <Button
                size="mini"
                color="blue"
                onClick={() =>
                  window.open('/#/customer-invoice-generation', '_blank')
                }
              >
                {/* <Icon name="plus" /> */}
                New Walk-in Customer invoice
              </Button>
            </Responsive>
          </div>
        )}

        {role === 5 && (
          <div className="newInvoice">
            <Responsive minWidth={768}>
              <Button
                size="mini"
                color="orange"
                onClick={() => window.open('/#/invoice-generation', '_blank')}
              >
                {/* <Icon name="plus" /> */}
                New Merchant Invoice
              </Button>
            </Responsive>
          </div>
        )}

        {role === 5 && (
          <div className="newInvoice">
            <Responsive minWidth={768}>
              <Button
                size="mini"
                color="green"
                onClick={() => window.open('/#/estimate-generation', '_blank')}
              >
                {/* <Icon name="plus" /> */}
                New Estimate
              </Button>
            </Responsive>
          </div>
        )}

        {role === 5 && (
          <div className="newInvoice">
            <Responsive minWidth={768}>
              <Button size="mini" color="green" onClick={() => setOpen(true)}>
                {/* <Icon name="plus" /> */}
                New Payment
              </Button>
              <div>
                {open === true && (
                  <AddNewPaymentModal openModal={open} onModalClose={Close} />
                )}
              </div>
            </Responsive>
          </div>
        )}

        {role === 5 && (
          <div>
            <Responsive minWidth={768}>
              <SearchComponent history={props.history} />
            </Responsive>
          </div>
        )}

        <Menu.Menu position="right" className="navBarissue">
          <Responsive minWidth={768}>
            <Icon
              name="power off"
              className="powerOff"
              onClick={() => onLogout()}
            />
          </Responsive>
          <Responsive maxWidth={768}>
            <Icon
              name="power off"
              className="powerOffMobile"
              onClick={() => onLogout()}
            />
          </Responsive>
        </Menu.Menu>
      </Menu>
    </div>
  );
};

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { logoutUser })
)(Navbar);

import React, { useEffect, useState } from "react";
import { Input, Button, Icon } from "semantic-ui-react";
import toastr from "toastr";
import { BASE_URL } from "../../../../config";

const MerchantLevels = () => {
  const [level_1, setLevel1] = useState(0);
  const [level_2, setLevel2] = useState(0);
  const [level_3, setLevel3] = useState(0);
  const [level_0, setLevel0] = useState(0);
  const [level0_name, setLevel0_name] = useState("");
  const [level1_name, setLevel1_name] = useState("");
  const [level2_name, setLevel2_name] = useState("");
  const [level3_name, setLevel3_name] = useState("");
  const [level1_image, setLevel1Image] = useState("");
  const [level2_image, setLevel2Image] = useState("");
  const [level3_image, setLevel3Image] = useState("");
  const [level0_image, setLevel0Image] = useState("");
  const [levelImageType, setLevelImageType] = useState("");
  const [merchantSettingsID, setSettingsID] = useState("");
  const [level1Discount, setLevel1Discount] = useState(0);
  const [level2Discount, setLevel2Discount] = useState(0);
  const [level3Discount, setLevel3Discount] = useState(0);
  const [level0Discount, setLevel0Discount] = useState(0);
  const [minCartValue, setMinCartValue] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [noData, setNoData] = useState("");
  const [CGST, setCGST] = useState(0);
  const [SGST, setSGST] = useState(0);

  useEffect(() => {
    fetchMerchantLevels();
  }, []);

  const fetchMerchantLevels = () => {
    fetch(`${BASE_URL}/get-merchant-levels`)
      .then(res => res.json())
      .then(json => {
        if (json.levels[0] === undefined) {
          setNoData("true");
          setLevel1(0);
          setLevel2(0);
          setLevel3(0);
          setLevel0(0);
          setLevel0_name("");
          setLevel1_name("");
          setLevel2_name("");
          setLevel3_name("");
          setLevel1Image({});
          setLevel2Image({});
          setLevel3Image({});
          setLevel0Image({});
          setLevel1Discount(0);
          setLevel2Discount(0);
          setLevel3Discount(0);
          setLevel0Discount(0);
          setCGST(0);
          setSGST(0);
        } else {
          setNoData("false");
          setSettingsID(json.levels[0]._id);
          setLevel1(json.levels[0].level_1);
          setLevel2(json.levels[0].level_2);
          setLevel3(json.levels[0].level_3);
          setLevel0(json.levels[0].level_0);
          setLevel0_name(json.levels[0].level0_name);
          setLevel1_name(json.levels[0].level1_name);
          setLevel2_name(json.levels[0].level2_name);
          setLevel3_name(json.levels[0].level3_name);
          setLevel1Image(json.levels[0].level1_image);
          setLevel2Image(json.levels[0].level2_image);
          setLevel3Image(json.levels[0].level3_image);
          setLevel0Image(json.levels[0].level0_image);
          setLevel1Discount(json.levels[0].level1Discount);
          setLevel2Discount(json.levels[0].level2Discount);
          setLevel3Discount(json.levels[0].level3Discount);
          setLevel0Discount(json.levels[0].level0Discount);
          setMinCartValue(json.levels[0].minCartValue);
          setDeliveryFee(json.levels[0].deliveryFee);
          setCGST(json.levels[0].CGST);
          setSGST(json.levels[0].SGST);
        }
      });
  };

  const saveMerchantSettings = () => {
    fetch(`${BASE_URL}/create-merchant-levels`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        level_1,
        level_2,
        level_3,
        level_0,
        level0_name,
        level1_name,
        level2_name,
        level3_name,
        level1_image,
        level2_image,
        level3_image,
        level0_image,
        level1Discount,
        level2Discount,
        level3Discount,
        level0Discount,
        minCartValue,
        deliveryFee,
        CGST,
        SGST,
        setting_type: "levels"
      })
    })
      .then(res => res.json())
      .then(json => {
        fetchMerchantLevels();
        toastr.success("Merchant Settings Saved");
      });
  };

  const updateMerchantSettings = () => {
    fetch(
      `${BASE_URL}/update-merchant-levels?merchant_settings_id=${merchantSettingsID}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          level_1,
          level_2,
          level_3,
          level_0,
          level0_name,
          level1_name,
          level2_name,
          level3_name,
          level1_image,
          level2_image,
          level3_image,
          level0_image,
          level1Discount,
          level2Discount,
          level3Discount,
          level0Discount,
          minCartValue,
          deliveryFee,
          CGST,
          SGST,
          setting_type: "levels"
        })
      }
    )
      .then(res => res.json())
      .then(json => {
        fetchMerchantLevels();
        toastr.success("Merchant Settings Updated Successfuly");
      });
  };

  const handleChange = () => {
    document.getElementById("main_image_uploader").click();
  };

  const mainImageUploader = event => {
    const file = event.target.files[0];
    var regex = new RegExp("([a-zA-Z0-9s_\\.-:])+(.jpg|.png)$");
    if (regex.test(file.name)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        var image = new Image();

        image.src = e.target.result;

        // const scope = this;
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          // if (height > 150 || width > 150) {
          //   alert("Height and Width must not exceed 150px.");
          //   return false;
          // } else {
          //   alert("Selected Image Uploaded Succesfully.");

          //   return true;
          // }
          if (levelImageType === "LEVEL1_IMAGE") {
            uploadFile(file, "LEVEL1_IMAGE");
          }
          if (levelImageType === "LEVEL2_IMAGE") {
            uploadFile(file, "LEVEL2_IMAGE");
          }
          if (levelImageType === "LEVEL3_IMAGE") {
            uploadFile(file, "LEVEL3_IMAGE");
          }
          if (levelImageType === "LEVEL0_IMAGE") {
            uploadFile(file, "LEVEL0_IMAGE");
          }
        };
      };
    } else {
      alert("JPG and PNG Images only allowed!");
      return false;
    }
  };

  const uploadFile = (file, imageType) => {
    var url = `https://api.cloudinary.com/v1_1/dgvup74b7/upload`;
    var xhr = new XMLHttpRequest();
    var fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.onreadystatechange = e => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var response = JSON.parse(xhr.responseText);
        if (imageType === "LEVEL1_IMAGE") {
          setLevel1Image(response.secure_url);
        }
        if (imageType === "LEVEL2_IMAGE") {
          setLevel2Image(response.secure_url);
        }
        if (imageType === "LEVEL3_IMAGE") {
          setLevel3Image(response.secure_url);
        }
        if (imageType === "LEVEL0_IMAGE") {
          setLevel0Image(response.secure_url);
        }
      }
    };

    fd.append("upload_preset", "lomurso1");
    fd.append("tags", "browser_upload");
    fd.append("file", file);
    xhr.send(fd);
  };

  return (
    <div className="TabContainer">
      <h4>Merchant Levels :</h4>

      <div className="TurnoverRow">
        <div style={{ marginRight: 20 }}>
          <div className="customerFormRow">
            <input
              type="file"
              id="main_image_uploader"
              placeholder="Enter customer name"
              style={{ display: "none" }}
              accept="image/*"
              onChange={e => {
                setLevelImageType("LEVEL1_IMAGE");
                mainImageUploader(e);
              }}
            />
            <div>
              <div
                onClick={() => {
                  setLevelImageType("LEVEL1_IMAGE");
                  handleChange();
                }}
                style={{
                  height: 100,
                  width: 100,
                  border: "1px dashed #000",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {level1_image.length > 0 ? (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={level1_image}
                  />
                ) : (
                  <Icon
                    style={{
                      fontSize: 45,
                      marginTop: 25,
                      color: "#a7c777"
                    }}
                    name="add"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <Input
            action={{
              labelPosition: "right",
              icon: "shopping basket",
              content: "Level 1 Requirement",
              className: "Level1"
            }}
            actionPosition="left"
            placeholder="Enter a Level..."
            value={level_1}
            className="inputMobile"
            onChange={e => setLevel1(e.target.value)}
          />
        </div>
        <div style={{ marginLeft: 10, alignSelf: "center" }}>
          <p>Kgs</p>
        </div>
      </div>

      <div className="merchantFormRow">
        <p className="merchantFormTitle">Discount Amount for 1kg:</p>
        <Input
          placeholder="Enter level 1 discont amount"
          style={{ width: "20%" }}
          maxLength="5"
          value={level1Discount}
          onChange={evt => setLevel1Discount(evt.target.value)}
        />
        <p
          style={{
            alignSelf: "center",
            marginLeft: -40,
            marginTop: 10,
            zIndex: 0,
            color: "grey"
          }}
        >
          {level1Discount.length}/5
        </p>
        <span style={{ marginLeft: 30, alignSelf: "center" }}>Rs</span>
      </div>

      <div className="merchantFormRow">
        <p className="merchantFormTitle">Display name for level 1:</p>
        <Input
          placeholder="Enter name for level 1"
          style={{ width: "20%" }}
          maxLength="10"
          value={level1_name}
          onChange={evt => setLevel1_name(evt.target.value)}
        />
        <p
          style={{
            alignSelf: "center",
            marginLeft: -40,
            marginTop: 10,
            zIndex: 0,
            color: "grey"
          }}
        >
          {level1_name.length}/10
        </p>
      </div>

      <div className="TurnoverRow">
        <div style={{ marginRight: 20 }}>
          <div className="customerFormRow">
            <input
              type="file"
              id="main_image_uploader"
              placeholder="Enter customer name"
              style={{ display: "none" }}
              accept="image/*"
              onChange={e => {
                setLevelImageType("LEVEL2_IMAGE");
                mainImageUploader(e);
              }}
            />
            <div>
              <div
                onClick={() => {
                  setLevelImageType("LEVEL2_IMAGE");
                  handleChange();
                }}
                style={{
                  height: 100,
                  width: 100,
                  border: "1px dashed #000",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {level2_image.length > 0 ? (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={level2_image}
                  />
                ) : (
                  <Icon
                    style={{
                      fontSize: 45,
                      marginTop: 25,
                      color: "#a7c777"
                    }}
                    name="add"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <Input
            action={{
              labelPosition: "right",
              icon: "shopping basket",
              content: "Level 2 Requirement",
              className: "Level2"
            }}
            actionPosition="left"
            placeholder="Enter a Level..."
            value={level_2}
            className="inputMobile"
            onChange={e => setLevel2(e.target.value)}
          />
        </div>
        <div style={{ marginLeft: 10, alignSelf: "center" }}>
          <p>Kgs</p>
        </div>
      </div>

      <div className="merchantFormRow">
        <p className="merchantFormTitle">Discount Amount for 1kg:</p>
        <Input
          placeholder="Enter level 2 discont amount"
          style={{ width: "20%" }}
          maxLength="5"
          value={level2Discount}
          onChange={evt => setLevel2Discount(evt.target.value)}
        />
        <p
          style={{
            alignSelf: "center",
            marginLeft: -40,
            marginTop: 10,
            zIndex: 0,
            color: "grey"
          }}
        >
          {level2Discount.length}/5
        </p>
        <span style={{ marginLeft: 30, alignSelf: "center" }}>Rs</span>
      </div>

      <div className="merchantFormRow">
        <p className="merchantFormTitle">Display name for level 2:</p>
        <Input
          placeholder="Enter name for level 2"
          style={{ width: "20%" }}
          maxLength="10"
          value={level2_name}
          onChange={evt => setLevel2_name(evt.target.value)}
        />
        <p
          style={{
            alignSelf: "center",
            marginLeft: -40,
            marginTop: 10,
            zIndex: 0,
            color: "grey"
          }}
        >
          {level2_name.length}/10
        </p>
      </div>

      <div className="TurnoverRow">
        <div style={{ marginRight: 20 }}>
          <div className="customerFormRow">
            <input
              type="file"
              id="main_image_uploader"
              placeholder="Enter customer name"
              style={{ display: "none" }}
              accept="image/*"
              onChange={e => {
                setLevelImageType("LEVEL3_IMAGE");
                mainImageUploader(e);
              }}
            />
            <div>
              <div
                onClick={() => {
                  setLevelImageType("LEVEL3_IMAGE");
                  handleChange();
                }}
                style={{
                  height: 100,
                  width: 100,
                  border: "1px dashed #000",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {level3_image.length > 0 ? (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={level3_image}
                  />
                ) : (
                  <Icon
                    style={{
                      fontSize: 45,
                      marginTop: 25,
                      color: "#a7c777"
                    }}
                    name="add"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <Input
            action={{
              labelPosition: "right",
              icon: "shopping basket",
              content: "Level 3 Requirement",
              className: "Level3"
            }}
            actionPosition="left"
            value={level_3}
            className="inputMobile"
            onChange={e => setLevel3(e.target.value)}
          />
        </div>
        <div style={{ marginLeft: 10, alignSelf: "center" }}>
          <p>Kgs</p>
        </div>
      </div>

      <div className="merchantFormRow">
        <p className="merchantFormTitle">Discount Amount for 1kg:</p>
        <Input
          placeholder="Enter level 3 discont amount"
          style={{ width: "20%" }}
          maxLength="5"
          value={level3Discount}
          onChange={evt => setLevel3Discount(evt.target.value)}
        />
        <p
          style={{
            alignSelf: "center",
            marginLeft: -40,
            marginTop: 10,
            zIndex: 0,
            color: "grey"
          }}
        >
          {level3Discount.length}/5
        </p>
        <span style={{ marginLeft: 30, alignSelf: "center" }}>Rs</span>
      </div>

      <div className="merchantFormRow">
        <p className="merchantFormTitle">Display name for level 3:</p>
        <Input
          placeholder="Enter name of level 3"
          style={{ width: "20%" }}
          maxLength="10"
          value={level3_name}
          onChange={evt => setLevel3_name(evt.target.value)}
        />
        <p
          style={{
            alignSelf: "center",
            marginLeft: -40,
            marginTop: 10,
            zIndex: 0,
            color: "grey"
          }}
        >
          {level3_name.length}/10
        </p>
      </div>

      <div className="TurnoverRow">
        <div style={{ marginRight: 20 }}>
          <div className="customerFormRow">
            <input
              type="file"
              id="main_image_uploader"
              placeholder="Enter customer name"
              style={{ display: "none" }}
              accept="image/*"
              onChange={e => {
                setLevelImageType("LEVEL0_IMAGE");
                mainImageUploader(e);
              }}
            />
            <div>
              <div
                onClick={() => {
                  setLevelImageType("LEVEL0_IMAGE");
                  handleChange();
                }}
                style={{
                  height: 100,
                  width: 100,
                  border: "1px dashed #000",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {level0_image.length > 0 ? (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={level0_image}
                  />
                ) : (
                  <Icon
                    style={{
                      fontSize: 45,
                      marginTop: 25,
                      color: "#a7c777"
                    }}
                    name="add"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <Input
            action={{
              labelPosition: "right",
              icon: "shopping basket",
              content: "Level 0 Requirement",
              className: "Level0"
            }}
            actionPosition="left"
            value={level_0}
            disabled
            className="inputMobile"
            onChange={e => setLevel0(e.target.value)}
          />
        </div>
        <div style={{ marginLeft: 10, alignSelf: "center" }}>
          <p>Kgs</p>
        </div>
      </div>

      <div className="merchantFormRow">
        <p className="merchantFormTitle">Discount Amount for 1kg:</p>
        <Input
          placeholder="Enter level 0 discont amount"
          style={{ width: "20%" }}
          maxLength="5"
          value={level0Discount}
          onChange={evt => setLevel0Discount(evt.target.value)}
        />
        <p
          style={{
            alignSelf: "center",
            marginLeft: -40,
            marginTop: 10,
            zIndex: 0,
            color: "grey"
          }}
        >
          {level0Discount.length}/5
        </p>
        <span style={{ marginLeft: 30, alignSelf: "center" }}>Rs</span>
      </div>

      <div className="merchantFormRow">
        <p className="merchantFormTitle">Display name for level 0:</p>
        <Input
          placeholder="Enter name of level 0"
          style={{ width: "20%" }}
          maxLength="10"
          value={level0_name}
          onChange={evt => setLevel0_name(evt.target.value)}
        />
        <p
          style={{
            alignSelf: "center",
            marginLeft: -40,
            marginTop: 10,
            zIndex: 0,
            color: "grey"
          }}
        >
          {level0_name.length}/10
        </p>
      </div>

      <h4>Delivery Fee Details :</h4>

      <div className="merchantFormRow">
        <p className="merchantFormTitle">Min cart value for delivery fee:</p>
        <Input
          placeholder="Enter min cart value"
          style={{ width: "20%" }}
          maxLength="3"
          value={minCartValue}
          onChange={evt => setMinCartValue(evt.target.value)}
        />
        <p
          style={{
            alignSelf: "center",
            marginLeft: -40,
            marginTop: 10,
            zIndex: 0,
            color: "grey"
          }}
        >
          {minCartValue.length}/3
        </p>
      </div>

      <div className="merchantFormRow">
        <p className="merchantFormTitle">Amount for delivery fee (Other Charges):</p>
        <Input
          placeholder="Enter delivery fee"
          style={{ width: "20%" }}
          maxLength="3"
          value={deliveryFee}
          onChange={evt => setDeliveryFee(evt.target.value)}
        />
        <p
          style={{
            alignSelf: "center",
            marginLeft: -40,
            marginTop: 10,
            zIndex: 0,
            color: "grey"
          }}
        >
          {deliveryFee.length}/3
        </p>
      </div>

      <h4>Tax % [CGST + SGST] Details :</h4>

      <div className="merchantFormRow">
        <p className="merchantFormTitle">Tax for CGST %:</p>
        <Input
          placeholder="Enter CGST %"
          style={{ width: "20%" }}
          maxLength="3"
          value={CGST}
          onChange={evt => setCGST(evt.target.value)}
        />
        <p
          style={{
            alignSelf: "center",
            marginLeft: -40,
            marginTop: 10,
            zIndex: 0,
            color: "grey"
          }}
        >
          {CGST.length}/3
        </p>
      </div>

      <div className="merchantFormRow">
        <p className="merchantFormTitle">Tax for SGST %:</p>
        <Input
          placeholder="Enter SGST %"
          style={{ width: "20%" }}
          maxLength="3"
          value={SGST}
          onChange={evt => setSGST(evt.target.value)}
        />
        <p
          style={{
            alignSelf: "center",
            marginLeft: -40,
            marginTop: 10,
            zIndex: 0,
            color: "grey"
          }}
        >
          {SGST.length}/3
        </p>
      </div>

      {noData === "true" ? (
        <div className="SubmitBtnRow">
          <Button
            className="submitBtn"
            content="Submit"
            onClick={saveMerchantSettings}
          />
        </div>
      ) : (
        <div className="SubmitBtnRow">
          <Button
            className="submitBtn"
            content="Update"
            onClick={updateMerchantSettings}
          />
        </div>
      )}
    </div>
  );
};

export default MerchantLevels;
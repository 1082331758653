import React, { Component } from "react";
import { HashRouter as Router, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import jwt_decode from "jwt-decode";
import moment from "moment";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import store from "./store";
import history from "./history";
import Dashboard from "./components/pages/dashboard/dashboard.component";
import WalkInDelivery from "./components/pages/walk-in-delivery/walkInDelivery.component";
import Cheques from "./components/pages/cheques/cheques.component";
import WeighBridge from "./components/pages/weigh-bridge/weighBridge.component";
import CreateWeighBridge from "./components/pages/weigh-bridge/createWeighBridge.component";
import ViewWeighBridgeTicket from "./components/pages/weigh-bridge/viewWeighBridgeTicket.component";

//OrderManagement components
import OrderRequests from "./components/pages/orderrequests/orderrequests.component";
import InvoiceGeneration from "./components/pages/orderrequests/invoicegeneration.component";
import EstimateGeneration from "./components/pages/estimates/estimategeneration.component";
import Estimates from "./components/pages/estimates/estimates.component";
import MerchantInvoiceGeneration from "./components/pages/orderrequests/merchant_invoice_generation";
import MerchantEstimateGeneration from "./components/pages/estimates/merchant_estimate_generation.component";
import ViewInvoice from "./components/pages/orderrequests/view_invoice";
import EditInvoice from "./components/pages/orderrequests/edit_invoice";
import ViewEstimate from "./components/pages/estimates/view-estimate.component";
import EditEstimate from "./components/pages/estimates/edit-estimate";
import ProductsComponent from "./components/pages/products/products.component";

// Companies
import Companies from "./components/pages/companies/companies.component";
import AddCompany from "./components/pages/companies/addCompany.component";
import EditCompany from "./components/pages/companies/editCompany.component";

// Invoice Settings
import InvoiceSettings from "./components/pages/invoicesettings/invoice_settings.component";
import AddInvoiceSettings from "./components/pages/invoicesettings/addSettings.component";
import EditInvoiceSettings from "./components/pages/invoicesettings/editSettings.component";

// Estimate Settings
import EstimateSettings from "./components/pages/estimatesettings/estimate_settings.component";
import AddEstimateSettings from "./components/pages/estimatesettings/addSettings.component";
import EditEstimateSettings from "./components/pages/estimatesettings/editSettings.component";

// Invoice
import CustomerInvoiceGeneration from "./components/pages/invoices/cutsomerInvoice.component";

// Excess Amounts
import ExcessAmounts from "./components/pages/excess-amount/excessAmounts.component";

//Members components
import Customers from "./components/pages/customers/customers.component";
import Merchants from "./components/pages/merchants/merchants.component";
import MerchantsComparisonPage from "./components/pages/merchants/merchantsComparison/merchantsComparison.component";
import Vendors from "./components/pages/vendors/vendors.component";
import Drivers from "./components/pages/drivers/drivers.component";
import MarketingExecutive from "./components/pages/marketing-executives/marketing-executive.component";

//Billing
import PayoutsComponent from "./components/pages/payouts/payouts.component";
import Billing from "./components/pages/billing/billing.component";
import Statements from "./components/pages/statements/statements.component";

//Reports
import Assets from "./components/pages/assets/assets.component";
import Reports from "./components/pages/reports/reports.component";

// Product Sales Reports
import ProductSales from "./components/pages/merchants/productSales/productSales.component";

// Feedbacks
import FeedbacksComponent from "./components/pages/feedbacks/feedbacks.component";

//Settings components
import GeneralSettingsComponent from "./components/pages/settings/generalsettings/general-settings.component";
import MerchantSettings from "./components/pages/settings/merchantsettings/merchantsettings.component";
import NotificationSettings from "./components/pages/settings/notificationsettings/notification-settings.component";
import CustomerSettings from "./components/pages/settings/customersettings/customersettings.component";
import VendorSettings from "./components/pages/settings/vendorsettings/vendorsettings.component";
import MarketingExecutiveSettings from "./components/pages/settings/marketingexecutivesettings/marketingexecutivesettings.component";
import DriverSettings from "./components/pages/settings/driversettings/driversettings.component";
import PayoutSettings from "./components/pages/settings/payoutsettings/payoutsettings.component";
import AssetSettings from "./components/pages/settings/assetsettings/assetsettings.component";
import BillingSettings from "./components/pages/settings/billingsettings/billingsettings.component";
import TranslationSettings from "./components/pages/settings/translationsettings/translationsettings.component";

//Profile Pages
import MerchantProfilePage from "./components/pages/profile/merchant-profile/merchant-profile.component";
import CustomerProfilePage from "./components/pages/profile/customer-profile/customer-profile.component";
import DriverProfilePage from "./components/pages/profile/driver-profile/driver-profile.component";
import MarketingExecutiveProfilePage from "./components/pages/profile/marketing-executive-profile/executive-profile.component";
import VendorProfilePage from "./components/pages/profile/vendor-profile/vendor-profile.component";

//Unique codes for spin
import UniquecodesComponent from "./components/pages/uniquecodesforspin/uniquecodes.component";
import LeadsComponent from "./components/pages/uniquecodesforspin/cashbackwinners/cashbackwinners.component";
import CashbackNotificationsComponent from "./components/pages/uniquecodesforspin/cashbacknotifications/cashbacknotifications.component";

//guards
import NotAuth from "./guards/NotAuth";
import IsAuth from "./guards/IsAuth";
import IsCommonRoute1 from "./guards/IsCommonRoute1";
import IsCommonRoute2 from "./guards/IsCommonRoute2";
import IsCommonRoute3 from "./guards/IsCommonRoute3";
import IsGate from "./guards/IsGate";
import IsAdmin from "./guards/IsAdmin";

//auth
import LoginComponent from "./components/pages/auth/login.component";
import AccessDenied from "./components/pages/auth/accessDenied.component";

// TOKEN CHECKER
// Checks the local storage if there's a token, and if it still valid
if (localStorage.jwtToken) {
  // set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // decode token and get suer info and expiration
  const decoded = jwt_decode(localStorage.jwtToken);

  // set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // check for epired token
  const currentTime = Date.now() / 1000;

  if (decoded.exp < currentTime) {
    // logout user
    store.dispatch(logoutUser());
  }
}

export class AppRouter extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <NotAuth path="/login" component={LoginComponent} exact />
            <NotAuth path="/access-denied" component={AccessDenied} exact />

            <IsAuth path="/" component={Dashboard} exact />
            <IsAuth path="/dashboard" component={Dashboard} exact />
            <IsAuth path="/customers" component={Customers} exact />
            <IsAuth
              path="/profile-customer/:id"
              component={CustomerProfilePage}
              exact
            />

            <IsCommonRoute1
              path="/products"
              component={ProductsComponent}
              exact
            />
            <IsCommonRoute1 path="/merchants" component={Merchants} exact />
            <IsCommonRoute1
              path="/profile-merchant/:id"
              component={MerchantProfilePage}
              exact
            />
            <IsCommonRoute1
              path="/product-sales/:id"
              component={ProductSales}
              exact
            />
            <IsCommonRoute1
              path="/merchant-comparison"
              component={MerchantsComparisonPage}
              exact
            />
            <IsCommonRoute1 path="/statements" component={Statements} exact />
            <IsCommonRoute1 path="/drivers" component={Drivers} exact />
            <IsCommonRoute1
              path="/profile-driver/:id"
              component={DriverProfilePage}
              exact
            />

            <IsCommonRoute2
              path="/orderrequests"
              component={OrderRequests}
              exact
            />
            <IsCommonRoute2
              path="/view-invoice/:id/:id"
              component={ViewInvoice}
              exact
            />
            <IsCommonRoute2 path="/billing" component={Billing} exact />
            <IsCommonRoute2
              path="/edit-invoice/:id/:id"
              component={EditInvoice}
              exact
            />
            <IsCommonRoute2 path="/estimates" component={Estimates} exact />
            <IsCommonRoute2
              path="/view-estimate/:id/:id"
              component={ViewEstimate}
              exact
            />
            <IsCommonRoute2
              path="/edit-estimate/:id/:id"
              component={EditEstimate}
              exact
            />

            <IsCommonRoute3
              path="/weigh-bridge"
              component={WeighBridge}
              exact
            />
            <IsCommonRoute3
              path="/create-weigh-bridge"
              component={CreateWeighBridge}
              exact
            />
            <IsCommonRoute3
              path="/view-weigh-bridge-ticket/:id"
              component={ViewWeighBridgeTicket}
              exact
            />

            <IsGate path="/walk-in-delivery" component={WalkInDelivery} exact />
            {/* customer invoice for Gate also */}
            <IsGate path="/customer-invoice-generation" component={CustomerInvoiceGeneration} exact />

            <IsAdmin
              path="/customer-invoice-generation"
              component={CustomerInvoiceGeneration}
              exact
            />
            <IsAdmin
              path="/invoice-generation"
              component={InvoiceGeneration}
              exact
            />
            <IsAdmin
              path="/merchant-invoice-generation/:id"
              component={MerchantInvoiceGeneration}
              exact
            />
            <IsAdmin
              path="/estimate-generation"
              component={EstimateGeneration}
              exact
            />
            <IsAdmin
              path="/merchant-estimate-generation/:id"
              component={MerchantEstimateGeneration}
              exact
            />
            <IsAdmin path="/companies" component={Companies} exact />
            <IsAdmin path="/add-company" component={AddCompany} exact />
            <IsAdmin path="/edit-company/:id" component={EditCompany} exact />
            <IsAdmin path="/vendors" component={Vendors} exact />
            <IsAdmin
              path="/profile-vendor/:id"
              component={VendorProfilePage}
              exact
            />
            <IsAdmin
              path="/marketing-executives"
              component={MarketingExecutive}
              exact
            />
            <IsAdmin
              path="/profile-marketing-executive/:id"
              component={MarketingExecutiveProfilePage}
              exact
            />
            <IsAdmin path="/payouts" component={PayoutsComponent} exact />
            <IsAdmin path="/cheques" component={Cheques} exact />
            <IsAdmin path="/excess-amounts" component={ExcessAmounts} exact />
            <IsAdmin path="/assets" component={Assets} exact />
            <IsAdmin path="/reports" component={Reports} exact />
            <IsAdmin path="/feedbacks" component={FeedbacksComponent} exact />
            <IsAdmin
              path="/settings"
              component={GeneralSettingsComponent}
              exact
            />
            <IsAdmin
              path="/settings/notifications"
              component={NotificationSettings}
              exact
            />
            <IsAdmin
              path="/settings/customers"
              component={CustomerSettings}
              exact
            />
            <IsAdmin
              path="/settings/merchants"
              component={MerchantSettings}
              exact
            />
            <IsAdmin
              path="/settings/vendors"
              component={VendorSettings}
              exact
            />
            <IsAdmin
              path="/settings/drivers"
              component={DriverSettings}
              exact
            />
            <IsAdmin
              path="/settings/marketing-executives"
              component={MarketingExecutiveSettings}
              exact
            />
            <IsAdmin
              path="/settings/payouts"
              component={PayoutSettings}
              exact
            />
            <IsAdmin
              path="/settings/billing"
              component={BillingSettings}
              exact
            />
            <IsAdmin path="/settings/assets" component={AssetSettings} exact />
            <IsAdmin
              path="/settings/translation"
              component={TranslationSettings}
              exact
            />
            <IsAdmin
              path="/invoice-settings"
              component={InvoiceSettings}
              exact
            />
            <IsAdmin
              path="/add-invoice-settings"
              component={AddInvoiceSettings}
              exact
            />
            <IsAdmin
              path="/edit-invoice-settings/:id"
              component={EditInvoiceSettings}
              exact
            />
            <IsAdmin
              path="/estimate-settings"
              component={EstimateSettings}
              exact
            />
            <IsAdmin
              path="/add-estimate-settings"
              component={AddEstimateSettings}
              exact
            />
            <IsAdmin
              path="/edit-estimate-settings/:id"
              component={EditEstimateSettings}
              exact
            />
            <IsAdmin
              path="/spinuniquecodes"
              component={UniquecodesComponent}
              exact
            />
            <IsAdmin
              path="/spinuniquecodes/cashbackwinners"
              component={LeadsComponent}
              exact
            />
            <IsAdmin
              path="/spinuniquecodes/notifications"
              component={CashbackNotificationsComponent}
              exact
            />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default AppRouter;

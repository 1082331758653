import React, { useState } from "react";
import { Icon, Input, TextArea, Form, Button, Grid } from "semantic-ui-react";
import toastr from "toastr";
import { BASE_URL } from "../../../config";

const AddCompany = (props) => {
  return (
    <div className="invoiceSetDefaultLayout">
      <div style={{ paddingTop: 10, paddingLeft: 20, display: "flex" }}>
        <Icon
          name="arrow left"
          style={{ paddingTop: 3, cursor: "pointer" }}
          onClick={() => props.history.push("/companies")}
        />
        <h3 style={{ marginTop: 0, marginLeft: 10 }}>Add Company Details</h3>
      </div>
      <Card history={props.history} />
    </div>
  );
};

const Card = (props) => {
  const [logo, setLogo] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  const [address_line1, setAddressLine1] = useState("");
  const [address_line2, setAddressLine2] = useState("");
  const [address_line3, setAddressLine3] = useState("");
  const [email, setEmail] = useState("");
  const [whatsapp_number, setWhatsAppNumber] = useState("");
  const [gst_no, setGSTNo] = useState("");
  const [cin_no, setCINNo] = useState("");
  const [pan_no, setPANNo] = useState("");
  const [others, setOthers] = useState("");
  const [cgst, setCGST] = useState("");
  const [sgst, setSGST] = useState("");
  const [delivery_charges, setDeliveryCharges] = useState("");
  const [default_customer_notes, setDefaultCustomerNotes] = useState("");
  const [default_pricing_terms, setDefaultPricingTerms] = useState("");
  const [collection_name, setCollectionName] = useState("");

  const saveCompany = () => {
    if (logo === "") {
      toastr.error("Please upload logo to proceed!");
    } else if (company_name === "") {
      toastr.error("Please enter company name to proceed!");
    } else if (address_line1 === "") {
      toastr.error("Please enter address line 1 to proceed!");
    } else if (address_line2 === "") {
      toastr.error("Please enter address line 2 to proceed!");
    } else if (address_line3 === "") {
      toastr.error("Please enter address line 3 to proceed!");
    } else if (email === "") {
      toastr.error("Please enter email to proceed!");
    } else if (whatsapp_number === "") {
      toastr.error("Please enter contact number to proceed!");
    } else if (gst_no === "") {
      toastr.error("Please enter GST No to proceed!");
    } else if (cgst === "") {
      toastr.error("Please enter cgst to proceed!");
    } else if (sgst === "") {
      toastr.error("Please enter sgst to proceed!");
    } else if (collection_name === "") {
      toastr.error("Please enter collection name to proceed!");
    } else {
      fetch(`${BASE_URL}/create-company`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          logo,
          company_name,
          website,
          address_line1,
          address_line2,
          address_line3,
          email,
          whatsapp_number,
          gst_no,
          cin_no,
          pan_no,
          others,
          cgst: cgst.split(","),
          sgst: sgst.split(","),
          delivery_charges,
          default_customer_notes,
          default_pricing_terms,
          collection_name,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          toastr.success("Company Saved Successfully!");
          props.history.push("/companies");
        });
    }
  };

  const handleChange = () => {
    document.getElementById("main_image_uploader").click();
  };

  const mainImageUploader = (event) => {
    const file = event.target.files[0];
    var regex = new RegExp("([a-zA-Z0-9s_\\.-:])+(.jpg|.png)$");
    if (regex.test(file.name)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        var image = new Image();

        image.src = e.target.result;

        // const scope = this;
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (height > 150 || width > 150) {
            toastr.error("Height and Width must not exceed 150px.");
            return false;
          } else {
            toastr.success("Selected Image Uploaded Succesfully.");
            uploadFile(file, "MAIN_IMAGE");
            return true;
          }
        };
      };
    } else {
      toastr.error("JPG and PNG Images only allowed!");
      return false;
    }
  };

  const uploadFile = (file, imageType) => {
    var url = `https://api.cloudinary.com/v1_1/dgvup74b7/upload`;
    var xhr = new XMLHttpRequest();
    var fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.onreadystatechange = (e) => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var response = JSON.parse(xhr.responseText);
        if (imageType === "MAIN_IMAGE") {
          setLogo(response.secure_url);
        }
      }
    };

    fd.append("upload_preset", "lomurso1");
    fd.append("tags", "browser_upload");
    fd.append("file", file);
    xhr.send(fd);
  };

  return (
    <div className="invoiceSetCardContainer">
      <div style={{ marginTop: 50 }}>
        <Grid columns={3} divided>
          <Grid.Row>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">Logo: </p>
                <input
                  type="file"
                  id="main_image_uploader"
                  placeholder="Enter customer name"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={(e) => {
                    mainImageUploader(e);
                  }}
                />
                <div>
                  <div
                    onClick={() => {
                      handleChange();
                    }}
                    style={{
                      height: 100,
                      width: 100,
                      border: "1px dashed #000",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {logo.length > 0 ? (
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={logo}
                      />
                    ) : (
                      <Icon
                        style={{
                          fontSize: 45,
                          marginTop: 25,
                          color: "#a7c777",
                        }}
                        name="add"
                      />
                    )}
                  </div>
                </div>
              </div>
            </Grid.Column>

            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">Company Name: </p>
                <Input
                  placeholder="Enter Company Name"
                  style={{ width: 250 }}
                  maxLength="35"
                  value={company_name}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>
            </Grid.Column>

            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText"> Website:</p>
                <Input
                  placeholder="website"
                  style={{ width: 250 }}
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">Address Line 1:</p>
                <Input
                  placeholder="Address line 1"
                  style={{ width: 250 }}
                  maxLength="35"
                  value={address_line1}
                  onChange={(e) => setAddressLine1(e.target.value)}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">Address Line 2:</p>
                <Input
                  placeholder="Address line 2"
                  style={{ width: 250 }}
                  maxLength="35"
                  value={address_line2}
                  onChange={(e) => setAddressLine2(e.target.value)}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">Address Line 3:</p>
                <Input
                  placeholder="Address line 3"
                  style={{ width: 250 }}
                  maxLength="35"
                  value={address_line3}
                  onChange={(e) => setAddressLine3(e.target.value)}
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">Email Address:</p>
                <Input
                  placeholder="Email"
                  style={{ width: 250 }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">Contact:</p>
                <Input
                  placeholder="Contact number"
                  style={{ width: 250 }}
                  value={whatsapp_number}
                  onChange={(e) => setWhatsAppNumber(e.target.value)}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText"> GST:</p>
                <Input
                  placeholder="Enter GST Number"
                  style={{ width: 250 }}
                  value={gst_no}
                  onChange={(e) => setGSTNo(e.target.value)}
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText"> CIN:</p>
                <Input
                  placeholder="Enter CIN number"
                  style={{ width: 250 }}
                  value={cin_no}
                  onChange={(e) => setCINNo(e.target.value)}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText"> PAN:</p>
                <Input
                  placeholder="Enter PAN number"
                  style={{ width: 250 }}
                  value={pan_no}
                  onChange={(e) => setPANNo(e.target.value)}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">Others:</p>
                <Form>
                  <TextArea
                    placeholder="others"
                    cols="40"
                    maxLength="70"
                    value={others}
                    onChange={(e) => setOthers(e.target.value)}
                  />
                </Form>
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">CGST%:</p>
                <Input
                  placeholder="CGST%"
                  style={{ width: 250 }}
                  value={cgst}
                  onChange={(e) => setCGST(e.target.value)}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText"> SGST%:</p>
                <Input
                  placeholder="SGST%"
                  style={{ width: 250 }}
                  value={sgst}
                  onChange={(e) => setSGST(e.target.value)}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">Default Delivery Charges:</p>
                <Input
                  placeholder="Delivery Charges"
                  style={{ width: 250 }}
                  value={delivery_charges}
                  onChange={(e) => setDeliveryCharges(e.target.value)}
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">Default Customer Notes:</p>
                <Form>
                  <TextArea
                    placeholder="Default customer notes"
                    cols="40"
                    maxLength="70"
                    value={default_customer_notes}
                    onChange={(e) => setDefaultCustomerNotes(e.target.value)}
                  />
                </Form>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">Default Pricing Terms:</p>
                <Form>
                  <TextArea
                    placeholder="Default pricing terms"
                    cols="40"
                    maxLength="70"
                    value={default_pricing_terms}
                    onChange={(e) => setDefaultPricingTerms(e.target.value)}
                  />
                </Form>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="FieldsRow">
                <p className="LabelText">Collection Name:</p>

                <Input
                  placeholder="Ex: companyname"
                  style={{ width: 250 }}
                  value={collection_name}
                  onChange={(e) => {
                    const value = e.target.value;
                    const finalValue = value.toLowerCase();
                    setCollectionName(finalValue);
                  }}
                />
              </div>
              <p style={{ color: "red", marginLeft: 260 }}>
                (Enter company name without spaces)
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <div style={{ textAlign: "center", marginTop: 30 }}>
          <Button
            style={{ backgroundColor: "#d9ead3", marginRight: 30 }}
            onClick={saveCompany}
          >
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddCompany;

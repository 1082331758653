export const TABLE_DATA = [
  {
    sno: ' 1',
    orderId: '#83769828',
    name: 'Saravan Vel',
    mobilenumber: '9874563012',
  
    earnings: '₹ 200',
  },
  {
    sno: ' 2',
    orderId: '#85669824',
    name: 'Muruga Vel',
    mobilenumber: '9874563210',
   
    earnings: '₹ 200',
  },
  {
    sno: ' 3',
    orderId: '#89769887',
    name: 'Sathya Moorthy',
    mobilenumber: '9874563789',

    earnings: '₹ 200',
  },
];

export const HEADING_DATA = [
  'S.No',
  'Order ID',
  'Vendor Name',
  'Mobile Number',

  'Vendor Earnings',
];

import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Icon,
  Input,
  Form,
  TextArea,
  Dropdown,
} from "semantic-ui-react";
import toastr from "toastr";
import { PRODUCT_TAGS } from "./products.constant";
import { BASE_URL } from "../../../config";
import Resizer from "react-image-file-resizer";

const AddProductModal = () => {
  const [main_image, setMainImage] = useState({
    name: "",
    file: null,
  });
  const [fileDetails, setFileDetails] = useState({});
  const [Name, setName] = useState("");
  const [tamilName, setTamilName] = useState("");
  const [Description, setDescription] = useState("");
  const [tamilDescription, setTamilDescription] = useState("");
  const [Pricing, setPricing] = useState([]);
  const [Tags, setTags] = useState([]);
  const [warehouses, setWarehousesList] = useState([]);
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const [size, setSize] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchWarehouses();
  }, []);

  const fetchWarehouses = () => {
    fetch(`${BASE_URL}/get-warehouses`)
      .then((res) => res.json())
      .then((json) => {
        setWarehousesList(json.warehouses);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = () => {
    document.getElementById("main_image_uploader").click();
  };

  const mainImageUploader = (event) => {
    const file = event.target.files[0];
    var regex = new RegExp("([a-zA-Z0-9s_\\.-:])+(.jpg|.jpeg|.png)$");
    if (regex.test(file.name)) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          150,
          150,
          "JPEG",
          100,
          0,
          (uri) => {
            uploadFile(uri, "MAIN_IMAGE");
          },
          "base64",
          150,
          150
        );
      } catch (err) {
        console.log(err);
      }
    } else {
      toastr.error("JPG and PNG Images only allowed!");
      return false;
    }
  };

  const uploadFile = (file, imageType) => {
    var url = `https://api.cloudinary.com/v1_1/dgvup74b7/upload`;
    var xhr = new XMLHttpRequest();
    var fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.onreadystatechange = (e) => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var response = JSON.parse(xhr.responseText);
        if (imageType === "MAIN_IMAGE") {
          setMainImage({ name: file.name, url: response.secure_url });
        } else {
          console.log("Additional Images");
        }
      }
    };

    fd.append("upload_preset", "lomurso1");
    fd.append("tags", "browser_upload");
    fd.append("file", file);
    xhr.send(fd);
  };

  const addpricefields = () => {
    return Pricing.map((el, i) => (
      <div key={i} style={{ marginBottom: 10 }}>
        <Input
          onChange={(e) => handlePricingChanges(e, i, "weight")}
          placeholder="Kg"
          className="priceinput"
        />
        <Input
          onChange={(e) => handlePricingChanges(e, i, "customerPrice")}
          placeholder="Customer price"
          className="priceinput"
        />
        <Input
          onChange={(e) => handlePricingChanges(e, i, "merchantPrice")}
          placeholder="Merchant price"
          className="priceinput"
        />
        <Button
          content="Remove"
          color="red"
          onClick={removeClick.bind(this, i)}
        />
      </div>
    ));
  };

  const handlePricingChanges = (e, index, type) => {
    let pricing = Pricing;
    let price = pricing[index];
    price[type] = e.target.value;
    pricing[index] = price;
  };

  const addprice = () => {
    setPricing([...Pricing, {}]);
  };

  const removeClick = (i) => {
    let pricing = [...Pricing];
    pricing.splice(i, 1);
    setPricing(pricing);
  };


  const getPricingValidation = () => {
    const data = Pricing.map((price) => {
      if (price.weight === undefined || price.customerPrice === undefined || price.merchantPrice === undefined) {
        toastr.error("Please fill Weight, Customer Price and Merchant Price to Proceed!");
        return false;
      } else if (price.weight === "" || price.customerPrice === "" || price.merchantPrice === "") {
        toastr.error("Please fill Weight, Customer Price and Merchant Price to Proceed!");
        return false;
      } else {
        return true;
      }
    });

    const output = data.every((x) => x === true);

    return output;
  }

  const handleCreateProduct = () => {
    const data = {
      main_image: main_image,
      product_name: Name,
      description: Description,
      tamil_product_name: tamilName,
      tamil_description: tamilDescription,
      values: Pricing,
      tags: Tags,
      warehouse_pincodes: selectedWarehouses,
    };

    if (Name === "") {
      toastr.error("Please fill the name of the product.");
    } else if (Description === "") {
      toastr.error("Please enter the description.");
    } else if (main_image.url === undefined) {
      toastr.error("Please upload the image to proceed!");
    } else if (Pricing.length === 0) {
      toastr.error("Please Add the Pricing Details");
    } else if (getPricingValidation() === true) {
      fetch(`${BASE_URL}/add-new-product`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((json) => {
          setOpen(false);
          setName("");
          setTamilName("");
          setDescription("");
          setTamilDescription("");
          setPricing([]);
          setTags([]);
          setMainImage({
            name: "",
            file: null,
          });
          window.location.reload(true);
        });
    }
  };

  const show = (size) => () => {
    setSize(size);
    setOpen(true);
  };

  const close = () => {
    setOpen(false)
    setFileDetails({});
  };

  return (
    <div>
      <Button onClick={show("large")} className="addCustomerBtn">
        <Icon name="plus" /> Add New Product
      </Button>
      <Modal size={size} open={open} onClose={() => close()}>
        <Modal.Header>
          <span>Add Product</span>
        </Modal.Header>
        <Modal.Content>
          <div className="customerFormRow">
            <p className="customerFormTitle">Main Image:</p>
            <input
              type="file"
              id="main_image_uploader"
              placeholder="Enter customer name"
              style={{ display: "none" }}
              accept="image/*"
              onChange={(e) => mainImageUploader(e)}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                onClick={() => handleChange()}
                style={{
                  height: 100,
                  width: 100,
                  border: "1px dashed #000",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {main_image.url !== undefined ? (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={main_image.url}
                  />
                ) : (
                  <Icon
                    style={{
                      fontSize: 45,
                      color: "#a7c777",
                    }}
                    name="add"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="customerFormRow">
            <p className="customerFormTitle">Name of the product:</p>
            <Input
              placeholder="Enter product name"
              style={{ width: "60%" }}
              onChange={(e) => {
                setName(e.target.value);
              }}
              maxLength="25"
            />
            <p
              style={{
                alignSelf: "center",
                marginLeft: -40,
                zIndex: 0,
                marginTop: 10,
                color: "grey",
              }}
            >
              {Name.length}/25
            </p>
          </div>
          <div className="customerFormRow">
            <p className="customerFormTitle">Product name in Tamil:</p>
            <Input
              placeholder="Enter product name in tamil"
              style={{ width: "60%" }}
              onChange={(e) => {
                setTamilName(e.target.value);
              }}
              maxLength="25"
            />
            <p
              style={{
                alignSelf: "center",
                marginLeft: -40,
                zIndex: 0,
                marginTop: 10,
                color: "grey",
              }}
            >
              {tamilName.length}/25
            </p>
          </div>
          <div className="customerFormRow">
            <p className="customerFormTitle">Description:</p>
            <Form style={{ width: "60%" }}>
              <TextArea
                placeholder="Enter description for product"
                cols="50"
                rows={4}
                maxLength="100"
                style={{ paddingRight: 50 }}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </Form>
            <p
              style={{
                alignSelf: "center",
                marginLeft: -50,
                zIndex: 0,
                marginTop: 50,
                color: "grey",
              }}
            >
              {Description.length}/100
            </p>
          </div>
          <div className="customerFormRow">
            <p className="customerFormTitle">Product description in Tamil:</p>
            <Form style={{ width: "60%" }}>
              <TextArea
                placeholder="Enter description for product in tamil"
                cols="50"
                rows={4}
                maxLength="100"
                style={{ paddingRight: 50 }}
                onChange={(e) => {
                  setTamilDescription(e.target.value);
                }}
              />
            </Form>
            <p
              style={{
                alignSelf: "center",
                marginLeft: -50,
                zIndex: 0,
                marginTop: 50,
                color: "grey",
              }}
            >
              {tamilDescription.length}/100
            </p>
          </div>
          <div className="customerFormRow">
            <p className="customerFormTitle">Tags :</p>
            <Dropdown
              style={{ width: 500 }}
              placeholder="Add Tags"
              fluid
              multiple
              selection
              options={PRODUCT_TAGS}
              onChange={(event, data) => {
                setTags(data.value);
              }}
            />
          </div>
          <div className="customerFormRow">
            <p className="customerFormTitle">Pricing:</p>
            <form>
              {addpricefields()}
              <Button
                type="button"
                content="Add"
                style={{ backgroundColor: "#a7c777", color: "#fff" }}
                onClick={addprice.bind(this)}
              />
            </form>
          </div>
        </Modal.Content>

        <Modal.Actions style={{ textAlign: "center" }}>
          <Button className="addCustomerCancelBtn" onClick={() => close()}>
            Cancel
          </Button>
          <Button
            className="addCustomersubmitBtn"
            content="Submit"
            onClick={() => handleCreateProduct()}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default AddProductModal;

import React from "react";
import ChequesTable from "./chequeTable.component";

const Cheques = (props) => {
  return (
    <div className="DefaultLayout">
      <Header />
      <Card history={props.history} />
    </div>
  );
};

const Header = () => {
  return (
    <div className="HeaderLayout">
      <div style={{ paddingLeft: 20, paddingBottom: 20 }}>
        <h3>Cheque Clearance</h3>
      </div>
    </div>
  );
};

const Card = (props) => {
  return (
    <div className="CardContainer">
      <ChequesTable history={props.history} />
    </div>
  );
};

export default Cheques;

import React, { useState } from "react";
import { Input, Button, Modal, Image } from "semantic-ui-react";
import toastr from "toastr";
import JOE_PIC from "../../../images/joe.jpg";
import { BASE_URL } from "../../../config";
import "./marketing-executive.css";

const AddExecutiveModal = (props) => {
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(props.openModal);

  const close = () => {
    setOpen(false);
    props.onModalClose(false, "MARKETING_EXECUTIVE");
  };

  const saveMarketingExecutive = () => {
    if ((name, mobileNumber, email)) {
      fetch(`${BASE_URL}/create-marketing-executive`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          mobileNumber,
          email,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.status == 400) {
            toastr.error("Mobile number already exists.");
          } else {
            toastr.success("New Marketing Executive Created Successfully!");
            props.onModalClose(false, "SAVE");
            close();
          }
        });
    } else {
      toastr.error("Please fill all the fields!");
    }
  };

  return (
    <Modal size="small" open={open} onClose={close}>
      <Modal.Header>
        {" "}
        <Image
          src={JOE_PIC}
          inline
          circular
          size="mini"
          style={{ marginRight: 20 }}
        />
        <span>Add Executive</span>
      </Modal.Header>
      <Modal.Content style={{ marginBottom: 20 }}>
        <div className="executiveFormRow">
          <p className="titleExecutiveform">Executive name:</p>
          <Input
            placeholder="Enter Executive name"
            style={{ width: "50%" }}
            maxLength="20"
            onChange={(evt) => setName(evt.target.value)}
          />
          <p
            style={{
              alignSelf: "center",
              marginLeft: -40,
              marginTop: 10,
              zIndex: 0,
              color: "grey",
            }}
          >
            {name.length}/20
          </p>
        </div>

        <div className="executiveFormRow">
          <p className="titleExecutiveform">Mobile number:</p>
          <Input
            placeholder="Enter mobile number"
            style={{ width: "50%" }}
            maxLength="10"
            onChange={(evt) => setMobileNumber(evt.target.value)}
          />
          <p
            style={{
              alignSelf: "center",
              marginLeft: -40,
              marginTop: 10,
              zIndex: 0,
              color: "grey",
            }}
          >
            {mobileNumber.length}/10
          </p>
        </div>
        <div className="executiveFormRow">
          <p className="titleExecutiveform">Email:</p>
          <Input
            placeholder="Enter Email"
            style={{ width: "50%" }}
            maxLength="25"
            onChange={(evt) => setEmail(evt.target.value)}
          />
          <p
            style={{
              alignSelf: "center",
              marginLeft: -40,
              marginTop: 10,
              zIndex: 0,
              color: "grey",
            }}
          >
            {email.length}/25
          </p>
        </div>
      </Modal.Content>
      <Modal.Actions style={{ textAlign: "center" }}>
        <Button className="addCustomerCancelBtn" onClick={close}>
          Cancel
        </Button>
        <Button
          className="addCustomersubmitBtn"
          onClick={() => saveMarketingExecutive(props.history)}
          content="Submit"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default AddExecutiveModal;

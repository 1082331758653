import React, { useState, useEffect } from "react";
import { Card, Statistic } from "semantic-ui-react";
import { getPercentage } from "./assets.api";
import { BASE_URL } from "../../../config";
import "./assets.css";

const AssetsHeader = () => {
  const [assets, setAssets] = useState([]);
  const [assetPerc, setAssetPerc] = useState(0);

  useEffect(() => {
    fetch(`${BASE_URL}/get-assets`)
      .then((res) => res.json())
      .then((json) => {
        const percentage = getPercentage(json.assets);
        setAssetPerc(percentage);
        setAssets(json.assets);
      });
  }, []);

  const getTotalAssetValue = () => {
    const data = assets.reduce(
      (sum, asset, index) => sum + Number(asset.purchase_value),
      0
    );
    const value = data.toLocaleString("en-IN");
    return value;
  };

  const getMaintainanceValue = () => {
    const datas = assets.map((asset) =>
      asset.maintenances.reduce(
        (sum, maintenance, index) =>
          sum + Number(maintenance.cost !== undefined ? maintenance.cost : 0),
        0
      )
    );

    const values = datas.reduce((sum, data, index) => sum + data, 0);

    const value = values.toLocaleString("en-IN");

    return value;
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Card.Group>
        <Card className="TotalAssetsCard">
          <Card.Content>
            <div className="cardContainer">
              <div>
                <Card.Header className="AllVendorsTitle">
                  Total Assets
                </Card.Header>
                {Math.sign(assetPerc) === -1 ? (
                  <span style={{ color: "#21ba45", fontWeight: 600 }}>
                    +{Math.abs(assetPerc) || 0}%
                  </span>
                ) : (
                  <span style={{ color: "#db2828", fontWeight: 600 }}>
                    -{assetPerc || 0}%
                  </span>
                )}
                <span style={{ color: "#00000066", marginLeft: 5 }}>
                  this month
                </span>
              </div>

              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="tiny">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      {assets.length}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>

        <Card className="TotalAssetValueCard">
          <Card.Content>
            <div className="cardContainer">
              <div>
                <Card.Header className="PurchaseValueTitle">
                  Total Asset Value
                </Card.Header>
                {/* <span style={{ color: "#21ba45", fontWeight: 600 }}>+50%</span>
                <span style={{ color: "#00000066", marginLeft: 5 }}>
                  this month
                </span> */}
              </div>
              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="tiny">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      ₹{getTotalAssetValue()}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
        <Card className="MaintenanceCostCard">
          <Card.Content>
            <div className="cardContainer">
              <div>
                <Card.Header className="PurchasesTitle">
                  Maintenance Cost
                </Card.Header>
                {/* <span style={{ color: "#21ba45", fontWeight: 600 }}>+50%</span>
                <span style={{ color: "#00000066", marginLeft: 5 }}>
                  this month
                </span> */}
              </div>
              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="tiny">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      ₹{getMaintainanceValue()}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
      </Card.Group>
    </div>
  );
};

export default AssetsHeader;

import React, { useEffect, useState } from "react";
import {
  Table,
  Label,
  Checkbox,
  Button,
  Modal,
  Icon,
  Input
} from "semantic-ui-react";
import _ from "lodash";
import { HEADING_DATA } from "./weighBridge.constant";
import { BASE_URL } from "../../../config";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import toastr from "toastr";
import TableExport from "tableexport";

const moment = extendMoment(originalMoment);

const WeighBridgeTable = (props) => {
  const today = moment();

  const [weighBridgeTickets, setWeighBridgeTickets] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [size] = useState("tiny");
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);
  const [selectedTicketId, setSelectedTicketId] = useState("");
  const [searchString, setSearchString] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState(
    moment.range(today.clone().subtract(4, "days"), today.clone())
  );

  useEffect(() => {
    fetchWeighTickets();
  }, []);

  const fetchWeighTickets = () => {
    fetch(`${BASE_URL}/get-weight-tickets`)
      .then((res) => res.json())
      .then((json) => {
        setWeighBridgeTickets(json.weigh_tickets);
      });
  };

  const onToggle = () => {
    setOpen(!isOpen);
  };

  const onSelect = (value, states) => {
    const start = value.start.format("DD-MM-YYYY");
    const end = value.end.format("DD-MM-YYYY");

    fetch(`${BASE_URL}/get-weight-tickets-by-range?startDate=${start}&endDate=${end}`)
      .then((res) => (res.json()))
      .then((json) => {
        setWeighBridgeTickets(json.tickets);
      })
    setValue(value);
    setOpen(!isOpen);
  };

  const handleSort = (clickedColumn) => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setWeighBridgeTickets(_.sortBy(weighBridgeTickets, [clickedColumn]));
      setDirection("ascending");

      return;
    }

    setWeighBridgeTickets(weighBridgeTickets.reverse());
    setDirection(direction === "ascending" ? "descending" : "ascending");
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDelete = () => {
    fetch(`${BASE_URL}/delete-weight-ticket?ticket_id=${selectedTicketId}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((json) => {
        fetchWeighTickets();
        handleClose();
      });
  };

  const weigh_tickets_table = weighBridgeTickets.filter((ticket) =>
    Object.keys(ticket).some(
      (key) => ticket[key].toString().search(searchString) !== -1
    )
  );

  console.log(weighBridgeTickets,"FROM TICKETS **(*(*((*((*(*(*(****************(*(*(*((*(*(*(*(*(*(*(*(*(*")

  return (
    <div>
      <div className="PageTitleRow">
        <div style={{ marginTop: 10 }}>
          <Button
            icon
            labelPosition="right"
            style={{
              backgroundColor: "#d9ead3",
              color: "#000",
              marginBottom: 15,
            }}
            onClick={() => {
              toastr.success("Exported Buttons Enabled Successfully");
              const table = new TableExport(
                document.getElementById("weigh-bridge-tickets"),
                {
                  formats: ["csv"],
                  exportButtons: true,
                  position: "top",
                  ignoreCols: [7],
                }
              );
              const link = document.createElement("a");
              link.setAttribute(
                "download",
                table.getExportData()["weigh-bridge-tickets"].csv.filename
              );
              link.click();
            }}
          >
            Export
            <Icon name="share square icon" />
          </Button>
        </div>
        <div style={{ marginTop: 10 }}>
          Select Range:{" "}
          <input
            type="text"
            onClick={onToggle}
            style={{ width: 170 }}
            value={`${value.start.format("DD-MM-YYYY")} - ${value.end.format(
              "DD-MM-YYYY"
            )}`}
          />
          {isOpen && (
            <DateRangePicker
              value={value}
              onSelect={onSelect}
              singleDateRange={true}
            />
          )}
        </div>
        <div style={{ marginTop: 10 }}>
          <Input
            placeholder="Quick Search..."
            icon="search"
            style={{ marginRight: 10 }}
            onChange={(e) => setSearchString(e.target.value)}
          />
        </div>
        <div>
          <Button
            color="green"
            onClick={() => props.history.push("/create-weigh-bridge")}
          >
            Create Weigh Ticket
          </Button>
        </div>
      </div>
      <div>
        <Table
          sortable
          unstackable
          celled
          basic="very"
          className="CustomTableLayout"
          id="weigh-bridge-tickets"
        >
          <Table.Header>
            <Table.Row>
              {HEADING_DATA.map((heading) => (
                <Table.HeaderCell
                  sorted={column === heading ? direction : null}
                  onClick={handleSort(heading)}
                >
                  {heading === "Checkbox" ? (
                    <Checkbox />
                  ) : (
                    <span>{heading}</span>
                  )}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {weigh_tickets_table.map((ticket, index) => (
              <Table.Row>
                <Table.Cell>{index + 1}</Table.Cell>
                <Table.Cell>
                  <Label color="green" style={{ fontSize: 15 }}>
                    {ticket.company_name}
                  </Label>
                </Table.Cell>
                <Table.Cell># {ticket.weigh_challan_no} </Table.Cell>
                <Table.Cell>
                  {moment(ticket.weigh_date).format("DD MMM YYYY hh:mm A")}{" "}
                </Table.Cell>
                <Table.Cell>{ticket.name}</Table.Cell>
                <Table.Cell>{ticket.vehicle_no}</Table.Cell>
                <Table.Cell><Icon name="rupee sign" />{Number(ticket.final_value).toLocaleString('en-IN')}</Table.Cell>
                <Table.Cell>
                  <Button
                    color="green"
                    onClick={() =>
                      props.history.push(
                        `/view-weigh-bridge-ticket/${ticket.weigh_challan_no}`
                      )
                    }
                  >
                    View
                  </Button>
                  {/* <Button color="orange">Print</Button> */}
                  <Button
                    color="red"
                    onClick={() => {
                      setOpenModal(true);
                      setSelectedTicketId(ticket._id);
                    }}
                  >
                    Delete
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Modal size={size} open={openModal} onClose={handleClose}>
          <Modal.Header>Delete Ticket</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete this ticket?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={handleClose}>
              No
            </Button>
            <Button
              positive
              icon="checkmark"
              labelPosition="right"
              content="Delete"
              onClick={() => handleDelete()}
            />
          </Modal.Actions>
        </Modal>
      </div>
    </div>
  );
};

export default WeighBridgeTable;

import React, { useEffect, useState } from "react";
import { Table, Modal, Icon, Checkbox, Button, Image } from "semantic-ui-react";
import _ from "lodash";
import { HEADING_DATA } from "./settings.constant";
import { BASE_URL } from "../../../config";

const InvoiceSettingsTable = (props) => {
  const [selectedSetting, setSettingID] = useState("");
  const [size, setSize] = useState("");
  const [open, setOpen] = useState(false);
  const [settings, setSettings] = useState([]);
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = () => {
    fetch(`${BASE_URL}/get-invoice-settings`)
      .then((res) => res.json())
      .then((json) => {
        setSettings(json.invoice_settings);
      });
  };

  const handleSort = (clickedColumn) => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setSettings(_.sortBy(settings, [clickedColumn]));
      setDirection("ascending");

      return;
    }

    setSettings(settings.reverse());
    setDirection(direction === "ascending" ? "descending" : "ascending");
  };

  const deleteSetting = (size, ID) => () => {
    setSize(size);
    setOpen(true);
    setSettingID(ID);
  };

  const handleDelete = () => {
    fetch(`${BASE_URL}/delete-invoice-settings?settingsID=${selectedSetting}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((json) => {
        fetchSettings();
        close();
      });
  };

  const close = () => {
    setOpen(false);
  };

  const settings_table = settings.filter((setting) =>
    Object.keys(setting).some(
      (key) => setting[key].toString().search(props.searchString) !== -1
    )
  );

  return (
    <div>
      <Table
        sortable
        unstackable
        celled
        basic="very"
        className="CustomTableLayout"
        id="invoice-settings-table"
      >
        <Table.Header>
          <Table.Row>
            {HEADING_DATA.map((heading) => (
              <Table.HeaderCell
                sorted={column === heading ? direction : null}
                onClick={handleSort(heading)}
              >
                {heading === "Checkbox" ? <Checkbox /> : <span>{heading}</span>}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {settings_table.map((setting, index) => (
            <Table.Row>
              <Table.Cell>{index + 1}</Table.Cell>
              <Table.Cell>
                <Image
                  src={setting.invoice_logo}
                  style={{ height: 60, width: 60 }}
                />
              </Table.Cell>
              <Table.Cell>{setting.company_name}</Table.Cell>
              <Table.Cell>{setting.website}</Table.Cell>
              <Table.Cell>{setting.email}</Table.Cell>
              <Table.Cell>{setting.gst_no}</Table.Cell>
              <Table.Cell>{setting.cin_no}</Table.Cell>
              <Table.Cell>{setting.pan_no}</Table.Cell>
              <Table.Cell>
                {setting.address_line1} {setting.address_line2},{" "}
                {setting.address_line3}
              </Table.Cell>

              <Table.Cell>
                <Button
                  style={{ fontSize: 13, backgroundColor: "#f3f3f3" }}
                  onClick={() => props.history.push(`/edit-invoice-settings/${setting.settings_id}`)}
                >
                  <Icon name="edit" />
                </Button>

                <Button
                  style={{ fontSize: 13, backgroundColor: "#f4cccc" }}
                  onClick={deleteSetting("mini", setting._id)}
                >
                  <Icon name="trash" />
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Modal size={size} open={open} onClose={close}>
        <Modal.Header>Delete Company</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete company?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button className="addCustomerCancelBtn" onClick={close}>
            No
          </Button>
          <Button
            className="addCustomersubmitBtn"
            icon="checkmark"
            labelPosition="right"
            content="Yes"
            onClick={() => handleDelete()}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default InvoiceSettingsTable;

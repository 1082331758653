import React, { useState, useEffect } from "react";
import {
  Label,
  List,
  Tab,
  Table,
  Button,
  Modal,
  Popup,
  Input,
} from "semantic-ui-react";
import moment from "moment";
import toastr from "toastr";
import { BASE_URL } from "../../../../config";

const MarketingExecutiveProfileDetails = (props) => {
  const panes = [
    {
      menuItem: "Marketing Executive Details",
      render: () => (
        <Tab.Pane style={{ marginLeft: -10 }}>
          <MarketingExecutiveDetails userDetails={props.executiveDetails} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Trips History",
      render: () => (
        <Tab.Pane style={{ marginLeft: -10 }}>
          <MEHistory userDetails={props.executiveDetails} />
        </Tab.Pane>
      ),
    },
    // {
    //   menuItem: "Converted Merchants Details",
    //   render: () => (
    //     <Tab.Pane style={{ marginLeft: -10 }}>
    //       <ConvertedMerchantsDetails userDetails={props.executiveDetails} />
    //     </Tab.Pane>
    //   ),
    // },
    // {
    //   menuItem: "Activity",
    //   render: () => (
    //     <Tab.Pane style={{ marginLeft: -10 }}>
    //       <Activity userDetails={props.executiveDetails} />
    //     </Tab.Pane>
    //   ),
    // },
    // {
    //   menuItem: "Order Value",
    //   render: () => (
    //     <Tab.Pane style={{ marginLeft: -10 }}>
    //       <OrderValue userDetails={props.executiveDetails} />
    //     </Tab.Pane>
    //   ),
    // },
    // {
    //   menuItem: "Payout",
    //   render: () => (
    //     <Tab.Pane style={{ marginLeft: -10 }}>
    //       <Payout userDetails={props.executiveDetails} />
    //     </Tab.Pane>
    //   ),
    // },
  ];

  return (
    <div>
      <Tab
        menu={{
          fluid: true,
          vertical: true,
          tabular: true,
          pointing: true,
        }}
        panes={panes}
      />
    </div>
  );
};

const MarketingExecutiveDetails = (props) => {
  const { userDetails } = props;
  return (
    <List divided animated>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <h4>#MREX00001</h4>
        </List.Content>
        <List.Content>Marketing Executive ID: </List.Content>
      </List.Item>

      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{userDetails.name}</p>
        </List.Content>
        <List.Content>Customer Name: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{userDetails.email}</p>
        </List.Content>
        <List.Content>Customer email: </List.Content>
      </List.Item>
      {/* <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{userDetails.address}</p>
        </List.Content>
        <List.Content>Address: </List.Content>
      </List.Item> */}
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{moment(userDetails.createdAt).format("ll")}</p>
        </List.Content>
        <List.Content>Registration Date: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>{moment(userDetails.createdAt).format("ll")}</p>
        </List.Content>
        <List.Content>Account Activated on :</List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <Label color="green">active</Label>
        </List.Content>
        <List.Content>Account Status :</List.Content>
      </List.Item>
    </List>
  );
};

const ConvertedMerchantsDetails = () => {
  return (
    <List divided animated>
      <List.Item className="DetailListItems">
        <List.Content>Merchants : </List.Content>
        <Table
          sortable
          unstackable
          celled
          color="grey"
          className="CustomTableLayout"
        >
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>Merchants Name</Table.HeaderCell>
              <Table.HeaderCell>Merchants Phone no</Table.HeaderCell>
              <Table.HeaderCell>Merchants Level</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row textAlign="center">
              <Table.Cell>Ramesh</Table.Cell>
              <Table.Cell>9494025637</Table.Cell>
              <Table.Cell>Gold</Table.Cell>
            </Table.Row>
            <Table.Row textAlign="center">
              <Table.Cell>Vanitha</Table.Cell>
              <Table.Cell>8984756338</Table.Cell>
              <Table.Cell>Silver</Table.Cell>
            </Table.Row>
            <Table.Row textAlign="center">
              <Table.Cell>Naveen</Table.Cell>
              <Table.Cell>9348362754</Table.Cell>
              <Table.Cell>Silver</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </List.Item>
    </List>
  );
};

const OrderValue = () => {
  return (
    <List divided animated>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <Label>₹ 5,00,000</Label>
        </List.Content>
        <List.Content>Total Order Value: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <Label color="green">₹ 2,00,000</Label>
        </List.Content>
        <List.Content>Top Order Value: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <Label color="red">₹ 30,000</Label>
        </List.Content>
        <List.Content>Ordervalue in this month: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content>Order History : </List.Content>
        <Table
          sortable
          unstackable
          celled
          color="grey"
          className="CustomTableLayout"
        >
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>Order Id </Table.HeaderCell>
              <Table.HeaderCell>Date </Table.HeaderCell>
              <Table.HeaderCell>Order Value </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row textAlign="center">
              <Table.Cell>#08142678</Table.Cell>
              <Table.Cell>07-03-2020</Table.Cell>
              <Table.Cell>₹ 1,50,000</Table.Cell>
            </Table.Row>
            <Table.Row textAlign="center">
              <Table.Cell>#08147892</Table.Cell>
              <Table.Cell>27-03-2020</Table.Cell>
              <Table.Cell>₹ 1,00,000</Table.Cell>
            </Table.Row>
            <Table.Row textAlign="center">
              <Table.Cell>#08123456</Table.Cell>
              <Table.Cell>10-04-2020</Table.Cell>
              <Table.Cell>₹ 2,00,000</Table.Cell>
            </Table.Row>
            <Table.Row textAlign="center">
              <Table.Cell>#08123478</Table.Cell>
              <Table.Cell>23-04-2020</Table.Cell>
              <Table.Cell>₹ 20,000</Table.Cell>
            </Table.Row>
            <Table.Row textAlign="center">
              <Table.Cell>#08123423</Table.Cell>
              <Table.Cell>12-05-2020</Table.Cell>
              <Table.Cell>₹ 30,000</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </List.Item>
    </List>
  );
};

const Activity = () => {
  return (
    <List divided animated>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <h4>80</h4>
        </List.Content>
        <List.Content>Lead Genaration: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <p>40</p>
        </List.Content>
        <List.Content>Conversion: </List.Content>
      </List.Item>
      <List.Item className="DetailListItems">
        <List.Content floated="right">
          <Label color="green">50 %</Label>
        </List.Content>
        <List.Content>Conversion Radio: </List.Content>
      </List.Item>
    </List>
  );
};

const Payout = () => {
  return (
    <List divided animated>
      <List.Item className="DetailListItems">
        <List.Content>Payout : </List.Content>
        <Table
          sortable
          unstackable
          celled
          color="grey"
          className="CustomTableLayout"
        >
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>Order Id </Table.HeaderCell>
              <Table.HeaderCell>Name </Table.HeaderCell>
              <Table.HeaderCell>Date </Table.HeaderCell>
              <Table.HeaderCell>Earings </Table.HeaderCell>
              <Table.HeaderCell>Payment type </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row textAlign="center">
              <Table.Cell>#08142678</Table.Cell>
              <Table.Cell>Saravana Vel</Table.Cell>
              <Table.Cell>01-05-2020</Table.Cell>
              <Table.Cell>₹ 1000</Table.Cell>
              <Table.Cell>Cash</Table.Cell>
            </Table.Row>
            <Table.Row textAlign="center">
              <Table.Cell>#08142620</Table.Cell>
              <Table.Cell>Saravana Vel</Table.Cell>
              <Table.Cell>11-05-2020</Table.Cell>
              <Table.Cell>₹ 1500</Table.Cell>
              <Table.Cell>trax_d8fZLnnY8WC</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </List.Item>
    </List>
  );
};

const MEHistory = (props) => {
  const { userDetails } = props;
  const [showStoreDetailsModal, setShowStoreDetailsModal] = useState(false);
  const [trips, setTrips] = useState([]);
  const [allTrips, setAllTrips] = useState([]);
  const [stores, setStores] = useState([]);
  const [expenses, setExpenses] = useState({});
  const [selectedTrip, setSelectedTrip] = useState({});
  const [open, setOpen] = useState(false);
  const [total_exp_amount, setTotalExpAmt] = useState("");
  const [exp_payment_mode, setExpPaymentMode] = useState("CASH");
  const [exp_payment_id, setExpPaymentID] = useState("Nill");
  const [expenses_payment_status, setExpPaymentStatus] = useState("PAID");

  useEffect(() => {
    fetchTrips();
  }, []);

  const fetchTrips = () => {
    fetch(
      `${BASE_URL}/get-completed-executive-trips-by-id?executive_phone=${userDetails.phoneNumber}`
    )
      .then((res) => res.json())
      .then((json) => {
        setTrips(json.executive_trips);
        setAllTrips(json.executive_trips);
      });
  };

  const handleExpPayment = () => {
    if (total_exp_amount.length === 0) {
      toastr.error("Please enter valid amount to make payment!");
    } else {
      const data = {
        expenses_payment_details: {
          total_exp_amount,
          exp_payment_mode,
          exp_payment_id,
        },
        expenses_payment_status,
      };

      fetch(
        `${BASE_URL}/update-executive-trip?executive_trip_id=${selectedTrip._id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      )
        .then((res) => res.json())
        .then((json) => {
          fetchTrips();
          setTotalExpAmt("");
          setExpPaymentMode("");
          setExpPaymentID("");
          setOpen(false);
          toastr.success("Payment Done Successfully!");
        });
    }
  };

  const handleFilter = (value) => {
    const trips_data = allTrips.filter(
      (trip) => trip.date === moment(value).format("DD-MM-YYYY")
    );
    setTrips(trips_data);
  };

  const getTotalExpenses = () => {
    const petrolExp =
      (selectedTrip.expense_details &&
        selectedTrip.expense_details.petrol_expenses) ||
      0;

    const otherExps =
      selectedTrip.expense_details &&
      selectedTrip.expense_details.other_expenses !== undefined
        ? selectedTrip.expense_details.other_expenses
        : [];
    const other_exps = otherExps.reduce(
      (sum, exp, index) => sum + Number(exp.Price),
      0
    );

    const total_exps = Number(petrolExp) + other_exps;

    return total_exps;
  };

  const close = () => {
    setOpen(false);
    setShowStoreDetailsModal(false);
  };

  return (
    <List divided animated>
      <List.Item className="DetailListItems">
        <div className="OHSectionOne">
          <div style={{ marginTop: 5 }}>
            <span>Select Date:</span>
            <input
              type="date"
              className="OHSelectDate"
              onChange={(e) => handleFilter(e.target.value)}
            />
          </div>
        </div>
        <Table
          sortable
          unstackable
          celled
          color="grey"
          className="CustomTableLayout"
        >
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>S.No </Table.HeaderCell>
              <Table.HeaderCell>Date </Table.HeaderCell>
              <Table.HeaderCell>Stores Visted </Table.HeaderCell>
              <Table.HeaderCell>Staring Location - Time </Table.HeaderCell>
              <Table.HeaderCell>Ending Location - Time </Table.HeaderCell>
              <Table.HeaderCell>Expenses</Table.HeaderCell>
              <Table.HeaderCell>Payment Details</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {trips.map((trip, index) => (
              <Table.Row textAlign="center">
                <Table.Cell>{index + 1}</Table.Cell>
                <Table.Cell>{trip.date}</Table.Cell>
                <Table.Cell>
                  <Button
                    color="green"
                    style={{ padding: 7 }}
                    onClick={() => {
                      setStores(trip.stores_visited);
                      setShowStoreDetailsModal(true);
                    }}
                  >
                    {trip.stores_visited.length}
                  </Button>
                </Table.Cell>
                <Table.Cell>
                  {trip.starting_location} -{" "}
                  {moment(trip.start_time).format("MM-DD-YY hh:mm A")}
                </Table.Cell>
                <Table.Cell>
                  {trip.ending_location} -{" "}
                  {moment(trip.end_time).format("MM-DD-YY hh:mm A")}
                </Table.Cell>
                <Table.Cell onClick={() => setExpenses(trip.expense_details)}>
                  <ViewExpenses expense_details={expenses} />
                </Table.Cell>
                <Table.Cell>
                  {trip.expenses_payment_status === "UNPAID" ? (
                    <Button
                      style={{ backgroundColor: "#c9daf8" }}
                      onClick={() => {
                        setSelectedTrip(trip);
                        setOpen(true);
                      }}
                    >
                      Pay Now
                    </Button>
                  ) : (
                    <div>
                      <Popup
                        on="click"
                        pinned
                        style={{ textAlign: "center" }}
                        trigger={<Button color="green">View Details</Button>}
                      >
                        <div>
                          <p
                            className="maintenanceCost"
                            style={{ marginBottom: 10 }}
                          >
                            View Details:
                          </p>
                          <div style={{ display: "flex", marginBottom: 7 }}>
                            <p style={{ marginBottom: 0 }}>Payment Amonut</p>
                            <p>
                              : ₹{" "}
                              {trip.expenses_payment_details.total_exp_amount}
                            </p>
                          </div>
                          <div style={{ display: "flex", marginBottom: 7 }}>
                            <p style={{ marginBottom: 0 }}>Payment Mode</p>
                            <p>
                              : {trip.expenses_payment_details.exp_payment_mode}
                            </p>
                          </div>
                          <div style={{ display: "flex", marginBottom: 7 }}>
                            <p style={{ marginBottom: 0 }}>Payment ID</p>
                            <p>
                              : {trip.expenses_payment_details.exp_payment_id}
                            </p>
                          </div>
                          <div style={{ display: "flex", marginBottom: 7 }}>
                            <p style={{ marginBottom: 0 }}>Payment Status</p>
                            <p>
                              :{" "}
                              <Label color="green">
                                {trip.expenses_payment_status}
                              </Label>
                            </p>
                          </div>
                        </div>
                      </Popup>
                    </div>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </List.Item>

      <div>
        <Modal size={"large"} open={showStoreDetailsModal} onClose={close}>
          <div className="modal-container">
            <h3 className="orderdetailsheading">Store Details</h3>
            <div>
              <div style={{ display: "flex" }}>
                <p style={{ width: "25%" }}>Store Name:</p>
                {stores.length > 0 ? (
                  <div style={{ display: "block", width: "70%" }}>
                    {stores.map((store, idx) => (
                      <div style={{ marginBottom: 15 }}>
                        <p style={{ paddingLeft: 5, marginBottom: 5 }}>
                          {idx + 1}) {store.store_name} - {store.store_status}
                        </p>
                        <p style={{ marginLeft: 15 }}>{store.store_location}</p>
                        <p style={{ marginLeft: 15 }}>
                          Pincode: {store.store_pincode}
                        </p>
                        <a
                          style={{ marginLeft: 15 }}
                          href={`${store.store_image}`}
                          target="blank"
                        >
                          Store Image
                        </a>{" "}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div style={{ display: "block", width: "70%" }}>
                    <div style={{ marginBottom: 15 }}>
                      <p style={{ paddingLeft: 5, marginBottom: 5 }}>
                        No stores visited!
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </div>

      <div>
        <Modal size={"small"} open={open} onClose={close}>
          <Modal.Header>
            Payment for expenses @ {selectedTrip.date}
          </Modal.Header>
          <Modal.Content>
            <div className="driverFormRow">
              <p className="titleDriverform">Petrol Expense:</p>
              <p>
                ₹{" "}
                {(selectedTrip.expense_details &&
                  selectedTrip.expense_details.petrol_expenses) ||
                  0}
              </p>
            </div>
            {selectedTrip.expense_details &&
              selectedTrip.expense_details.other_expenses !== undefined &&
              selectedTrip.expense_details.other_expenses.map((exp) => (
                <div className="driverFormRow">
                  <p className="titleDriverform">{exp.expType}:</p>
                  <p>₹ {exp.Price}</p>
                </div>
              ))}
            <div className="driverFormRow">
              <p className="titleDriverform">Total:</p>
              <p>₹ {getTotalExpenses()}</p>
            </div>
            <div className="driverFormRow">
              <p className="titleDriverform">Payable Amount:</p>
              <Input
                placeholder="Enter Total Amount"
                style={{ width: "50%" }}
                value={total_exp_amount}
                onChange={(e) => setTotalExpAmt(e.target.value)}
              />
            </div>
            <div className="driverFormRow">
              <p className="titleDriverform">Payment Mode:</p>
              <Input
                placeholder="Enter Payment Mode"
                style={{ width: "50%" }}
                value={exp_payment_mode}
                onChange={(e) => setExpPaymentMode(e.target.value)}
              />
            </div>
            <div className="driverFormRow">
              <p className="titleDriverform">Payment ID:</p>
              <Input
                placeholder="Enter Payment ID"
                style={{ width: "50%" }}
                value={exp_payment_id}
                onChange={(e) => setExpPaymentID(e.target.value)}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={close}>
              Cancel
            </Button>
            <Button
              positive
              icon="checkmark"
              labelPosition="right"
              content="Make Payment"
              onClick={() => handleExpPayment()}
            />
          </Modal.Actions>
        </Modal>
      </div>
    </List>
  );
};

const ViewExpenses = (props) => {
  const { expense_details } = props;

  const getTotalExpenses = (petrolExp, otherExps) => {
    const other_exps = otherExps.reduce(
      (sum, exp, index) => sum + Number(exp.Price),
      0
    );

    const total_exps = Number(petrolExp) + other_exps;

    return total_exps;
  };

  return (
    <Popup
      on="click"
      pinned
      style={{ textAlign: "center" }}
      trigger={<Button className="addDriverBtn">View Expenses</Button>}
    >
      <div>
        <p className="maintenanceCost" style={{ marginBottom: 10 }}>
          Expenses List:
        </p>
        <div style={{ display: "flex", marginBottom: 7 }}>
          <p style={{ marginBottom: 0 }}>Petrol</p>
          <p>
            :{"  "}₹ {expense_details.petrol_expenses || 0}
          </p>
        </div>
        {expense_details.other_expenses !== undefined &&
          expense_details.other_expenses.map((exp) => (
            <div style={{ display: "flex", marginBottom: 7 }}>
              <p style={{ marginBottom: 0 }}>{exp.expType}</p>
              <p>
                :{"  "}₹ {exp.Price}
              </p>
            </div>
          ))}
        <div style={{ display: "flex", marginBottom: 7 }}>
          <p style={{ marginBottom: 0 }}>Total</p>
          <p>
            :{"  "}₹{" "}
            {getTotalExpenses(
              expense_details.petrol_expenses || 0,
              expense_details.other_expenses !== undefined
                ? expense_details.other_expenses
                : []
            )}
          </p>
        </div>
      </div>
    </Popup>
  );
};

export default MarketingExecutiveProfileDetails;

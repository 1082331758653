import React from 'react';
import SettingsTabBarMenu from '../../../commons/settingstabbar/settingstabbar.component';
import CustomerDiscount from './customer-discounts.component';
import '../settings.css'

const CustomerSettings = (props) => {
  return (
    <div className='DefaultLayout'>
      <Header history={props.history} />
      <Card />
    </div>
  );
};

const Header = (props) => {
  return (
    <div className='HeaderLayout'>
      <h3>Settings</h3>
      <SettingsTabBarMenu history={props.history} />
    </div>
  );
};

const Card = () => {
  return (
    <div className='CardContainer'>
      <h3>Customer Discounts</h3>
      <CustomerDiscount />
    </div>
  );
};
export default CustomerSettings;

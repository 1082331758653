export const TABLE_DATA = [
  {
    sno: ' 1',
    name: 'Saravan Vel',
    licenseno: 'AP0272015000876',
    mobilenumber: '9874563210',
    email: 'saravan@gmail.com',
    km_driven: '10 km',
  },
  {
    sno: ' 2',
    name: 'Sathya Murthy',
    licenseno: 'AP0072019000975',
    mobilenumber: '9874563210',
    email: 'sathya@gmail.com',
    km_driven: '10 km',
  },
  {
    sno: ' 3',
    name: 'Chandra Bose',
    licenseno: 'AP0312017006776',
    mobilenumber: '9874563210',
    email: 'bose@gmail.com',
    km_driven: '10 km',
  },
];

export const HEADING_DATA = [
  'S.No',
  'Driver Name',
  'License No.',
  'Mobile',
  'E-Mail',
  'KM Driven',
  'Total Salary Given',
  'Actions',
];

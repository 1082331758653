export const HEADING_DATA = [
    'SNO',
    'Particulars',
    'Debit Amount',
    'Credit Amount',
  ];
  
  // export const HEADING_DATA = [
  //   'Date',
  //   'Particulars',
  //   'Voucher Type',
  //   'Voucher No',
  //   'Debit Amount',
  //   'Credit Amount',
  // ];
  
import React from "react";
import ExcessAmountsTable from "./excessAmountsTable.component";

const ExcessAmounts = (props) => {
  return (
    <div className="DefaultLayout">
      <Header />
      <Card history={props.history} />
    </div>
  );
};

const Header = () => {
  return (
    <div className="HeaderLayout">
      <div style={{ paddingLeft: 20, paddingBottom: 20 }}>
        <h3>Excess Amounts</h3>
      </div>
    </div>
  );
};

const Card = (props) => {
  return (
    <div className="CardContainer">
      <ExcessAmountsTable history={props.history} />
    </div>
  );
};

export default ExcessAmounts;

import React, { useState } from "react";
import MerchantsHeader from "./merchants_header.component";
import { Icon, Input, Button } from "semantic-ui-react";
import toastr from "toastr";
import TableExport from "tableexport";
import AddMerchantModal from "./addMerchantModal.component";
import MerchantsTable from "./merchants_table.component";
import TabBar from "../../commons/tabbar/tabbar.component";

const Merchants = (props) => {
  return (
    <div className="DefaultLayout">
      <Header history={props.history} />
      <Card history={props.history} />
    </div>
  );
};

const Header = (props) => {
  const [open, setOpen] = useState(false);

  const handleCloseModal = (value, type) => {
    setOpen(value);
    if (type === "SAVE") {
      window.location.reload(true);
    }
  };

  return (
    <div className="HeaderLayout">
      <div className="AddMemberRow">
        <h3>Merchants</h3>
        <Button className="addMerchantBtn" onClick={() => setOpen(true)}>
          <Icon name="plus" /> Add Merchant
        </Button>
      </div>
      {/* <div className="NavTabs">
        <TabBar history={props.history} />
      </div> */}
      <MerchantsHeader />

      <div>
        {open === true && (
          <AddMerchantModal openModal={open} onModalClose={handleCloseModal} />
        )}
      </div>
    </div>
  );
};

const Card = (props) => {
  const [searchString, setSearchString] = React.useState("");

  return (
    <div className="CardContainer">
      <div className="PageTitleRow">
        <Input
          placeholder="Quick Search..."
          icon="search"
          style={{ marginTop: 10 }}
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
        <div style={{ marginTop: 10 }}>
          <Button
            style={{
              backgroundColor: "#d9ead3",
              color: "#000",
            }}
            onClick={() => props.history.push("/merchant-comparison")}
          >
            <Icon name="plus" />
            Compare
          </Button>
          <Button
            icon
            labelPosition="right"
            onClick={() => {
              toastr.success("Exported Successfully");
              const table = new TableExport(
                document.getElementById("merchant-table"),
                {
                  formats: ["csv"],
                  exportButtons: false,
                }
              );
              const link = document.createElement("a");
              link.setAttribute(
                "href",
                "data:text/csv;charset=utf-8," +
                  table.getExportData()["merchant-table"].csv.data
              );
              link.setAttribute(
                "download",
                table.getExportData()["merchant-table"].csv.filename
              );
              link.click();
            }}
            style={{
              backgroundColor: "#d9ead3",
              color: "#000",
            }}
          >
            Export
            <Icon name="share square" />
          </Button>
        </div>
      </div>
      <MerchantsTable searchString={searchString} history={props.history} />
    </div>
  );
};

export default Merchants;

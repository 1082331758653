import React, { Component } from "react";
import { Button, Card, Form, Image } from "semantic-ui-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../../actions/authActions";
import RYZONE_LOGO from "../../../images/Ryzonelogo.png";
import "./login.css";
import { BASE_URL } from "../../../config";

class LoginComponent extends Component {
  state = {
    email: "",
    password: "",
    redirect: false,
  };

  // componentDidMount = () => {
  //   const { history } = this.props;
  //   fetch(`${BASE_URL}/get-mac-address`)
  //     .then((res) => res.json())
  //     .then((json) => {
  //       if (json.result === "SUCCESS") {
  //         history.push("/");
  //       } else {
  //         console.log(json.result);
  //         history.push("/access-denied");
  //       }
  //     });
  // };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleLogin = () => {
    const { email, password } = this.state;
    const userData = {
      email: email,
      password: password,
    };

    if (email.length == 0 && password.length == 0) {
      console.log("");
    } else {
      this.props.loginUser(userData, this.props.history);
    }
  };

  render() {
    const { email, password } = this.state;

    return (
      <div className="loginPageLayout">
        <div className="LeftHandSection">
          <div>
            <Image src={RYZONE_LOGO} className="imagecontainer" />
          </div>

          <div className="RightHandSection">
            <Card className="loginCardContainer" centered>
              <div style={{ marginBottom: 30 }}>
                <h2
                  style={{
                    fontSize: 30,
                    fontWeight: 700,
                    letterSpacing: "-1px",
                    color: "#a7c777",
                  }}
                >
                  Welcome back!
                </h2>
              </div>
              <Form>
                <Form.Field>
                  <label style={{ fontSize: 18 }}>Your Email</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={this.onChange}
                    required
                    className="loginFormInputField"
                  />
                </Form.Field>
                <Form.Field>
                  <label style={{ fontSize: 18, marginTop: 30 }}>
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={this.onChange}
                    required
                    className="loginFormInputField"
                  />
                </Form.Field>
                <div>
                  <p style={{ textAlign: "right" }}>Forgot Password?</p>
                </div>
                <div style={{ textAlign: "center" }}>
                 
                  <Button
                    type="submit"
                    className="loginFormSubmitBtn"
                    onClick={() => this.handleLogin()}
                  >
                    SIGN IN
                  </Button>
                </div>
              </Form>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

LoginComponent.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(LoginComponent);

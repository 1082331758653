import React, { Component } from 'react';
import { Grid, Checkbox, Responsive,Form, Button } from 'semantic-ui-react';

const LeadsEmailNotificationOptions = () => {
  return (
    <React.Fragment>
      <Grid.Row>
        <Grid.Column>
          <Checkbox label='Won Cashback' />
        </Grid.Column>
        <Grid.Column>
          <Checkbox label='Credited Cashback' />
        </Grid.Column>
       
      </Grid.Row>
     
      
    </React.Fragment>
  );
};

export class EmailLeadsNotifications extends Component {
  render() {
    return (
      <div>
        <div style={{ margin:20 }}>
        </div>

        <div style={{ margin: 20 }}>
          <div>
            <Responsive maxWidth={768}>
              <Grid columns={1} divided>
                <LeadsEmailNotificationOptions />
              </Grid>
            </Responsive>
            <Responsive minWidth={768}>
              <Grid columns={3} divided>
                <LeadsEmailNotificationOptions />
              </Grid>
            </Responsive>
          </div>
          <div>
            <div style={{ marginTop: 50 }}>
              <div className="headerRow">
                <h4 style={{ alignSelf: "center" }}>EMAIL Templates</h4>
              </div>
            </div>
            <div style={{ marginTop: 30 }}>
              <Grid columns={2} divided>
                <Grid.Row>
                  <Grid.Column>
                    <Form>
                      <Form.TextArea
                        label="When won cashback "
                        placeholder="Enter the message.."
                      />
                    </Form>
                  </Grid.Column>
                  <Grid.Column>
                    <Form>
                      <Form.TextArea
                        label="When cashback credited"
                        placeholder="Enter the message.."
                      />
                    </Form>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                      <Button
                        color="green"
                      >
                        Save
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
              </Grid>
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EmailLeadsNotifications;

import React, { useState, useEffect } from "react";
import { Icon, Image, Responsive, Label, Grid } from "semantic-ui-react";
import PROFILE_PIC from "../../../../images/pic.png";
import VendorProfileDetails from "./vendor-profile-details.component";
import { BASE_URL } from "../../../../config";
import "./vendor-profile.css";

const VendorProfilePage = (props) => {
  return (
    <div className="DefaultLayout">
      <Header />
      <Card history={props.history} />
    </div>
  );
};

const Header = (props) => {
  return (
    <div className="HeaderLayout">
      <h3 style={{ paddingLeft: 30 }}>Vendor Profile</h3>
    </div>
  );
};

const Card = (props) => {
  const { location } = props.history;
  const data = location.pathname.split("/");
  const vendor = data[2];

  const [vendorData, setVendorData] = useState({});

  useEffect(() => {
    fetch(`${BASE_URL}/get-vendor-by-id?phoneNumber=${vendor}`)
      .then((res) => res.json())
      .then((json) => {
        setVendorData(json.user);
      });
  }, []);

  return (
    <div>
      <div className="CardContainer" style={{ backgroundColor: "#f8ffff" }}>
        <div style={{ margin: 30 }}>
          <Grid>
            <Grid.Column width={4}>
              <Responsive minWidth={767}>
                <Image src={PROFILE_PIC} inline circular size="small" />
              </Responsive>
            </Grid.Column>

            <Responsive maxWidth={767}>
              <Grid.Column>
                <Image src={PROFILE_PIC} inline circular size="small" />
              </Grid.Column>
            </Responsive>

            <Grid.Column width={8}>
              <Responsive minWidth={767}>
                <h2>{vendorData.name}</h2>
                <div style={{ display: "flex" }}>
                  <Label as="a" basic color="teal">
                    {vendorData.role}
                  </Label>
                </div>

                <div>
                  <Label style={{ marginTop: 20 }}>
                    <Icon name="phone" color="green" /> {vendorData.phoneNumber}
                  </Label>
                  <Label style={{ marginTop: 20 }}>
                    <Icon name="map pin" color="red" /> {vendorData.address}
                  </Label>
                </div>
              </Responsive>
            </Grid.Column>
            <Responsive maxWidth={767}>
              <Grid.Column>
                <h2>{vendorData.name}</h2>
                <div style={{ display: "flex" }}>
                  <Label as="a" basic color="teal">
                    {vendorData.role}
                  </Label>
                </div>

                <div style={{ marginTop: 20 }}>
                  <Label>
                    <Icon name="phone" color="green" /> {vendorData.phoneNumber}
                  </Label>
                  <Label>
                    <Icon name="map pin" color="red" /> {vendorData.address}
                  </Label>
                </div>
              </Grid.Column>
            </Responsive>
          </Grid>
        </div>
      </div>
      <div
        className="CardContainer"
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        <VendorProfileDetails vendorDetails={vendorData} />
      </div>
    </div>
  );
};

export default VendorProfilePage;

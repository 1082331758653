import React, { useState, useEffect } from "react";
import { Card, Statistic, Button, Icon } from "semantic-ui-react";
import { getPercentage } from "./billing.api";
import { BASE_URL } from "../../../config";
import moment from "moment";
import "./billing.css";

const BillingHeader = () => {
  const [invoices, setInvoices] = useState([]);
  const [invoicePerc, setInvoicePerc] = useState(0);

  useEffect(() => {
    fetch(`${BASE_URL}/get-invoices`)
      .then((res) => res.json())
      .then((json) => {
        const percentage = getPercentage(json.invoices);
        setInvoicePerc(percentage);
        setInvoices(json.invoices);
      });
  }, []);

  const getTotalTurnover = () => {
    const invoices_table = invoices.filter((invoice) =>
      Object.keys(invoice).some(
        (key) => invoice[key].toString().search("fullypaid") !== -1
      )
    );

    const data = invoices_table.reduce(
      (sum, invoice, index) => sum + Number(invoice.total_amount),
      0
    );
    const value = data.toLocaleString("en-IN");
    return value;
  };

  const getTodayTurnover = () => {
    const start = new Date();
    start.setHours(0, 0, 0, 0);
    const end = new Date();
    end.setHours(23, 59, 59, 999);

    const invoices_table = invoices.filter((invoice) =>
      Object.keys(invoice).some(
        (key) => invoice[key].toString().search("fullypaid") !== -1
      )
    );

    const today_invoices = invoices_table.filter(
      (invoice) =>
        invoice.invoice_date > moment(start).format() &&
        invoice.invoice_date < moment(end).format()
    );

    const data = today_invoices.reduce(
      (sum, invoice, index) => sum + Number(invoice.total_amount),
      0
    );
    const value = data.toLocaleString("en-IN");
    return value;
  };

  const getPendingPayments = () => {
    const invoices_data = invoices.filter((invoice) =>
      Object.keys(invoice).some(
        (key) => invoice[key].toString().search("DELIVERED") !== -1
      )
    );

    const invoices_table = invoices_data.filter((invoice) =>
      Object.keys(invoice).some(
        (key) => invoice[key].toString().search("unpaid") !== -1
      )
    );

    const data = invoices_table.reduce(
      (sum, invoice, index) => sum + Number(invoice.total_amount),
      0
    );
    const value = data.toLocaleString("en-IN");
    return value;
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 20,
        }}
      >
        <div style={{ paddingTop: 10, paddingLeft: 20 }}>
          <h3>Billing</h3>
        </div>
        <div>
          <Button className="createInvoiceBtn">
            <Icon name="plus" /> Create Invoice
          </Button>
        </div>
      </div>

      {/* Header cardRow-1 */}

      <Card.Group>
        <Card className="TotalTransactionsCard">
          <Card.Content>
            <div className="billingCardContainer">
              <div>
                <Card.Header className="TotalTransactionsTitle">
                  Total Transactions
                </Card.Header>
                {Math.sign(invoicePerc) === -1 ? (
                  <span style={{ color: "#21ba45", fontWeight: 600 }}>
                    +{Math.abs(invoicePerc)}%
                  </span>
                ) : (
                  <span style={{ color: "#db2828", fontWeight: 600 }}>
                    -{invoicePerc}%
                  </span>
                )}
                <span style={{ color: "#00000066", marginLeft: 5 }}>
                  this month
                </span>
              </div>

              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="mini">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      {invoices.length}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>

        <Card className="TotalRevenueCard">
          <Card.Content>
            <div className="billingCardContainer">
              <div>
                <Card.Header className="TotalRevenueTitle">
                  Total Turnover
                </Card.Header>
                {/* <span style={{ color: "#21ba45", fontWeight: 600 }}>+50%</span>
                <span style={{ color: "#00000066", marginLeft: 5 }}>
                  this month
                </span> */}
              </div>
              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="mini">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      ₹{getTotalTurnover()}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>

        <Card className="TotalRevenueCard">
          <Card.Content>
            <div className="billingCardContainer">
              <div>
                <Card.Header className="TotalRevenueTitle">
                  Turnover Today
                </Card.Header>
                {/* <span style={{ color: "#21ba45", fontWeight: 600 }}>+20%</span>
                <span style={{ color: "#00000066", marginLeft: 5 }}>
                  from yesterday
                </span> */}
              </div>
              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="mini">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      ₹{getTodayTurnover()}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>

        <Card className="TotalRevenueCard">
          <Card.Content>
            <div className="billingCardContainer">
              <div>
                <Card.Header className="PendingPaymentsTitle">
                  Pending Payments
                </Card.Header>
                {/* <span style={{ color: "#db2828", fontWeight: 500 }}>+25% </span>
                <span style={{ color: "#00000066", marginLeft: 5 }}>
                  this month
                </span> */}
              </div>
              <Card.Description>
                <div className="MembershipRow">
                  <Statistic inverted size="mini">
                    <Statistic.Value style={{ color: "#22252f" }}>
                      ₹{getPendingPayments()}
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
      </Card.Group>

      {/* Header cardRow-2 */}
      {/* <Card.Group className='HeaderCardRow2'>
        <Card className='TotalTransactionsCard'>
          <Card.Content>
            <div className='billingCardContainer'>
              <div>
                <Card.Header className='TotalTransactionsTitle'>
                  Completed Orders
                </Card.Header>
                <span style={{ color: '#21ba45', fontWeight: 600 }}>+25%</span>
                <span style={{ color: '#00000066', marginLeft: 5 }}>
                  this month
                </span>
              </div>

              <Card.Description>
                <div className='MembershipRow'>
                  <Statistic inverted size='mini'>
                    <Statistic.Value style={{ color: '#22252f' }}>
                      50
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>

        
        <Card className='RefundedOrdersCard'>
          <Card.Content>
            <div className='billingCardContainer'>
              <div>
                <Card.Header className='RefundedOrdersTitle'>
                  Cancelled Orders
                </Card.Header>
                <span style={{ color: '#db2828', fontWeight: 500 }}>+5% </span>
                <span style={{ color: '#00000066', marginLeft: 5 }}>
                  this month
                </span>
              </div>
              <Card.Description>
                <div className='MembershipRow'>
                  <Statistic inverted size='mini'>
                    <Statistic.Value style={{ color: '#22252f' }}>
                      21
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
        <Card className='PendingPaymentCard'>
          <Card.Content>
            <div className='billingCardContainer'>
              <div>
                <Card.Header className='PendingPaymentsTitle'>
                  Pending Orders
                </Card.Header>
                <span style={{ color: '#db2828', fontWeight: 500 }}>+25% </span>
                <span style={{ color: '#00000066', marginLeft: 5 }}>
                  this month
                </span>
              </div>
              <Card.Description>
                <div className='MembershipRow'>
                  <Statistic inverted size='mini'>
                    <Statistic.Value style={{ color: '#22252f' }}>
                      20
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
        <Card className='RefundedOrdersCard'>
          <Card.Content>
            <div className='billingCardContainer'>
              <div>
                <Card.Header className='RefundedOrdersTitle'>
                  Refunded Orders
                </Card.Header>
                <span style={{ color: '#db2828', fontWeight: 500 }}>+5% </span>
                <span style={{ color: '#00000066', marginLeft: 5 }}>
                  this month
                </span>
              </div>
              <Card.Description>
                <div className='MembershipRow'>
                  <Statistic inverted size='mini'>
                    <Statistic.Value style={{ color: '#22252f' }}>
                      25
                    </Statistic.Value>
                  </Statistic>
                </div>
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
      </Card.Group> */}
    </div>
  );
};

export default BillingHeader;

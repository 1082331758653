import React, { Component } from "react";
import { Grid, Checkbox, Responsive, Button } from "semantic-ui-react";
import SmsLeadsTemplates from "./smsleadstemplates.component";
import toastr from "toastr";
import { BASE_URL } from "../../../../config";

export class SmsLeadsNotifications extends Component {
  state = {
    won_cashback: false,
    credited_cashback: false,
    notificationID: "",
    no_data: false,
    sms_data_status: false,
    sms_data: {},
  };

  componentDidMount = () => {
    this.fetchNotifications();
    this.fetchSMSTemplates();
  };

  fetchNotifications = () => {
    fetch(`${BASE_URL}/get-sms-notifications`)
      .then((res) => res.json())
      .then((json) => {
        if (json.sms_notifications[0] === undefined) {
          this.setState({
            no_data: true,
          });
        } else {
          this.setState({
            won_cashback: json.sms_notifications[0].won_cashback,
            credited_cashback: json.sms_notifications[0].credited_cashback,
            notificationID: json.sms_notifications[0]._id,
            no_data: false,
          });
        }
      });
  };

  fetchSMSTemplates = () => {
    fetch(`${BASE_URL}/get-sms-templates`)
      .then((res) => res.json())
      .then((json) => {
        if (json.sms_templates[0] === undefined) {
          this.setState({
            sms_data_status: true,
          });
        } else {
          this.setState({ sms_data: json.sms_templates[0] });
        }
      });
  };

  saveSmsNotifications = () => {
    const { won_cashback, credited_cashback } = this.state;

    fetch(`${BASE_URL}/create-sms-notifications`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        won_cashback,
        credited_cashback,
        notification_type: "sms-notifications",
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        toastr.success("SMS Notification Created Successfully");
      });
  };

  updateSmsNotifications = () => {
    const { won_cashback, credited_cashback, notificationID } = this.state;

    fetch(`${BASE_URL}/update-sms-notifications?diwali_notifications_id=${notificationID}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        won_cashback,
        credited_cashback,
        notification_type: "sms-notifications",
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        toastr.success("SMS Notification Updated Successfully");
      });
  };

  render() {
    const {
      won_cashback,
      credited_cashback,
      no_data,
      sms_data_status,
      sms_data,
    } = this.state;

    return (
      <div>
        <div style={{ margin: 20 }}>
          {" "}
          <h4 style={{ alignSelf: "center" }}>SMS Notifications</h4>
        </div>

        <div style={{ margin: 20 }}>
          <div>
            <Responsive minWidth={768}>
              {sms_data_status !== true ? (
                <Grid columns={3} divided>
                  <React.Fragment>
                    <Grid.Row>
                      <Grid.Column>
                        <Checkbox
                          checked={won_cashback}
                          label="Won Cashback"
                          onChange={() => {
                            if (sms_data.won_cashback.length > 0) {
                              this.setState({
                                won_cashback: !won_cashback,
                              });
                            } else {
                              toastr.error(
                                "Please create sms template to enable notification"
                              );
                            }
                          }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Checkbox
                          checked={credited_cashback}
                          label="Credited Cashback"
                          onChange={() => {
                            if (sms_data.credited_cashback.length > 0) {
                              this.setState({
                                credited_cashback: !credited_cashback,
                              });
                            } else {
                              toastr.error(
                                "Please create sms template to enable notification"
                              );
                            }
                          }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    {no_data === true ? (
                      <Grid.Row>
                        <Grid.Column>
                          <Button
                            color="green"
                            onClick={() => this.saveSmsNotifications()}
                          >
                            Save
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    ) : (
                      <Grid.Row>
                        <Grid.Column>
                          <Button
                            color="green"
                            onClick={() => this.updateSmsNotifications()}
                          >
                            update
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    )}
                  </React.Fragment>
                </Grid>
              ) : (
                <Grid>
                  <div>
                    <p style={{ color: "red", marginLeft: 500 }}>
                      Please Add SMS Templates For Enable Notifications
                    </p>
                  </div>
                </Grid>
              )}
            </Responsive>
            <Responsive maxWidth={768}>
              {sms_data_status !== true ? (
                <Grid columns={1} divided>
                  <React.Fragment>
                    <Grid.Row>
                      <Grid.Column>
                        <Checkbox
                          checked={won_cashback}
                          label="Won Cashback"
                          onChange={() => {
                            if (sms_data.won_cashback.length > 0) {
                              this.setState({
                                won_cashback: !won_cashback,
                              });
                            } else {
                              toastr.error(
                                "Please create sms template to enable notification"
                              );
                            }
                          }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Checkbox
                          checked={credited_cashback}
                          label="Credited Cashback"
                          onChange={() => {
                            if (sms_data.credited_cashback.length > 0) {
                              this.setState({
                                credited_cashback: !credited_cashback,
                              });
                            } else {
                              toastr.error(
                                "Please create sms template to enable notification"
                              );
                            }
                          }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    {no_data === true ? (
                      <Grid.Row>
                        <Grid.Column>
                          <Button
                            color="green"
                            onClick={() => this.saveSmsNotifications()}
                          >
                            Save
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    ) : (
                      <Grid.Row>
                        <Grid.Column>
                          <Button
                            color="green"
                            onClick={() => this.updateSmsNotifications()}
                          >
                            update
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    )}
                  </React.Fragment>
                </Grid>
              ) : (
                <Grid>
                  <div>
                    <p style={{ color: "red", marginLeft: 500 }}>
                      Please Add SMS Templates For Enable Notifications
                    </p>
                  </div>
                </Grid>
              )}
            </Responsive>
          </div>
          <SmsLeadsTemplates />
        </div>
      </div>
    );
  }
}

export default SmsLeadsNotifications;

import React, { Component } from "react";
import {
  Button,
  Card,
  Dropdown,
  Grid,
  CardContent,
  CardHeader,
  Modal,
  Icon,
  Input,
  Form,
  TextArea,
} from "semantic-ui-react";
import toastr from "toastr";
import { PRODUCT_TAGS } from "./products.constant";
import { BASE_URL } from "../../../config";
import Resizer from "react-image-file-resizer";

const generateOptions = (prices) =>
  prices.map((price) => ({
    key: price.weight,
    value: price.merchantPrice,
    text: `${price.weight}kg`,
  }));

class ProductList extends Component {
  state = {
    size: "",
    open: false,
    edit: false,
    name: "",
    mainimage: "",
    description: "",
    tamil_name: "",
    tamil_description: "",
    selectedProduct: "",
    tags: [],
    pricing: [],
    productList: [],
    selectedWeight: [],
    previous_tags: [],
    warehouses: [],
    selected_warehouses: [],
    fileDetails: {},
  };

  componentDidMount() {
    this.fetchProducts();
    this.fetchWarehouses();
  }

  fetchProducts = () => {
    fetch(`${BASE_URL}/get-products`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ productList: json.products });
      });
  };

  fetchWarehouses = () => {
    fetch(`${BASE_URL}/get-warehouses`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ warehouses: json.warehouses });
      });
  };

  addpricefields() {
    return this.state.pricing.map((el, i) => (
      <div key={i} style={{ marginBottom: 10 }}>
        <Input
          value={el.weight}
          onChange={(e) => this.handlePricingChanges(e, i, "weight")}
          placeholder="Kg"
          className="priceinput"
        />
        <Input
          value={el.customerPrice}
          onChange={(e) => this.handlePricingChanges(e, i, "customerPrice")}
          placeholder="Customer price"
          className="priceinput"
        />
        <Input
          value={el.merchantPrice}
          onChange={(e) => this.handlePricingChanges(e, i, "merchantPrice")}
          placeholder="Merchant price"
          className="priceinput"
        />
        <Button
          content="Remove"
          color="red"
          onClick={this.removeClick.bind(this, i)}
        />
      </div>
    ));
  }

  handlePricingChanges = (e, index, type) => {
    let pricing = this.state.pricing;
    let price = pricing[index];
    price[type] = e.target.value;
    pricing[index] = price;
    this.setState({ pricing });
  };

  addprice() {
    this.setState((prevState) => ({ pricing: [...prevState.pricing, {}] }));
  }

  removeClick(i) {
    let pricing = [...this.state.pricing];
    pricing.splice(i, 1);
    this.setState({ pricing });
  }

  handleChange = () => {
    document.getElementById("main_image_uploader").click();
  };

  mainImageUploader = (event) => {
    const file = event.target.files[0];
    var regex = new RegExp("([a-zA-Z0-9s_\\.-:])+(.jpg|.png)$");
    if (regex.test(file.name)) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          150,
          150,
          "JPEG",
          100,
          0,
          (uri) => {
            this.uploadFile(uri, "MAIN_IMAGE");
          },
          "base64",
          150,
          150
        );
      } catch (err) {
        console.log(err);
      }
    } else {
      toastr.error("JPG and PNG Images only allowed!");
      return false;
    }
  };

  uploadFile = (file, imageType) => {
    var url = `https://api.cloudinary.com/v1_1/dgvup74b7/upload`;
    var xhr = new XMLHttpRequest();
    var fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.onreadystatechange = (e) => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var response = JSON.parse(xhr.responseText);
        if (imageType === "MAIN_IMAGE") {
          this.setState({
            mainimage: response.secure_url,
          });
        } else {
          this.setState({
            additionalimages: [
              ...this.state.additionalimages,
              response.secure_url,
            ],
          });
        }
      }
    };

    fd.append("upload_preset", "lomurso1");
    fd.append("tags", "browser_upload"); // Optional - add tag for image admin in Cloudinary
    fd.append("file", file);
    xhr.send(fd);
  };

  edit = (size, product) => {
    fetch(`${BASE_URL}/edit-product?product_id=${product._id}`)
      .then((res) => res.json())
      .then((json) => {
        const product = json.product[0];
        this.setState({
          size,
          open: true,
          edit: true,
          selectedProduct: product._id,
          name: product.name,
          tamil_name: product.tamil_name,
          tamil_description: product.tamil_description,
          mainimage: product.main_image,
          pricing: product.pricing,
          description: product.description,
          previous_tags: product.tags,
        });
      });
  };

  getPricingValidation = () => {
    const data = this.state.pricing.map((price) => {
      if (
        price.weight === undefined ||
        price.customerPrice === undefined ||
        price.merchantPrice === undefined
      ) {
        toastr.error(
          "Please fill Weight, Customer Price and Merchant Price to Proceed!"
        );
        return false;
      } else if (
        price.weight === "" ||
        price.customerPrice === "" ||
        price.merchantPrice === ""
      ) {
        toastr.error(
          "Please fill Weight, Customer Price and Merchant Price to Proceed!"
        );
        return false;
      } else {
        return true;
      }
    });

    const output = data.every((x) => x === true);

    return output;
  };

  updateProduct = () => {
    const {
      selectedProduct,
      name,
      description,
      tamil_name,
      tamil_description,
      mainimage,
      tags,
      pricing,
      selected_warehouses,
    } = this.state;
    if (pricing.length === 0) {
      toastr.error("Please Add the Pricing Details");
    } else if (this.getPricingValidation() === true) {
      fetch(`${BASE_URL}/update-product?productID=${selectedProduct}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mainimage,
          name,
          description,
          tamil_name,
          tamil_description,
          tags,
          pricing,
          warehouse_pincodes: selected_warehouses,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.status == 200) {
            this.fetchProducts();
            toastr.success("Products updated successfully");
            this.close();
            window.location.reload(true);
          }
        });
    }
  };

  deleteProduct = () => {
    fetch(
      `${BASE_URL}/delete-product?productID=${this.state.selectedProduct}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => res.json())
      .then((json) => {
        this.fetchProducts();
        this.setState({ open: false });
        toastr.warning("Product Deleted Successfully!");
      });
  };

  delete = (size, id) =>
    this.setState({ size, open: true, selectedProduct: id });

  close = () => this.setState({ open: false, edit: false, fileDetails: {} });

  render() {
    const {
      productList,
      selectedWeight,
      mainimage,
      name,
      description,
      tamil_name,
      tamil_description,
      previous_tags,
      size,
      open,
    } = this.state;

    const products = productList.filter(
      (product) =>
        product.name.toLowerCase().indexOf(this.props.searchString) !== -1
    );

    return (
      <Grid.Row style={{ justifyContent: "flex-start" }}>
        {products.map((product, index) => (
          <Grid.Column>
            <Card
              style={{
                marginBottom: 20,
                borderRadius: 10,
                overflow: "visible",
              }}
            >
              <div
                style={{
                  paddingTop: 10,
                  paddingRight: 10,
                  textAlign: "end",
                }}
              >
                <Button
                  icon="pencil"
                  className="EditProductBtn"
                  style={{ marginRight: 15 }}
                  onClick={() => this.edit("large", product)}
                />
                <Button
                  icon="trash"
                  className="DeleteProductBtn"
                  onClick={() => this.delete("mini", product._id)}
                />
              </div>
              <div style={{ padding: 25, alignSelf: "center" }}>
                <img src={product.main_image} style={{ width: 150 }} />
              </div>
              <CardContent style={{ backgroundColor: "#f5f6fa" }}>
                <CardHeader>{product.name}</CardHeader>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 20,
                  }}
                >
                  {
                    <Dropdown
                      placeholder={"Select Weight"}
                      selection
                      defaultValue={generateOptions(product.pricing)[0].value}
                      fluid
                      style={{ width: 100 }}
                      onChange={(event, data) => {
                        selectedWeight[index] = data.value;
                        this.setState({ selectedWeight });
                      }}
                      options={generateOptions(product.pricing)}
                    />
                  }
                  <h3 style={{ marginTop: 0 }}>
                    ₹{" "}
                    {selectedWeight[index] || product.pricing[0].merchantPrice}
                  </h3>
                </div>
              </CardContent>
            </Card>
          </Grid.Column>
        ))}
        {this.state.edit === true ? (
          <Modal size={size} open={open} onClose={this.close}>
            <Modal.Header>Edit Product</Modal.Header>
            <Modal.Content>
              <div className="customerFormRow">
                <p className="customerFormTitle">Main Image:</p>
                <input
                  type="file"
                  id="main_image_uploader"
                  placeholder="Enter customer name"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={this.mainImageUploader}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    onClick={this.handleChange}
                    style={{
                      height: 100,
                      width: 100,
                      border: "1px dashed #000",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {mainimage !== undefined ? (
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={mainimage}
                      />
                    ) : (
                      <Icon
                        style={{
                          fontSize: 45,
                          color: "#a7c777",
                        }}
                        name="add"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="customerFormRow">
                <p className="customerFormTitle">Name of the product:</p>
                <Input
                  placeholder="Enter product name"
                  value={name}
                  style={{ width: "60%" }}
                  onChange={(e) => {
                    this.setState({ name: e.target.value });
                  }}
                  maxLength="20"
                />
                <p
                  style={{
                    alignSelf: "center",
                    marginLeft: -40,
                    zIndex: 0,
                    marginTop: 10,
                    color: "grey",
                  }}
                >
                  {name.length}/20
                </p>
              </div>
              <div className="customerFormRow">
                <p className="customerFormTitle">Product name in Tamil:</p>
                <Input
                  value={tamil_name}
                  style={{ width: "60%" }}
                  onChange={(e) => {
                    this.setState({ tamil_name: e.target.value });
                  }}
                  maxLength="25"
                />
                <p
                  style={{
                    alignSelf: "center",
                    marginLeft: -40,
                    zIndex: 0,
                    marginTop: 10,
                    color: "grey",
                  }}
                >
                  {tamil_name.length}/25
                </p>
              </div>
              <div className="customerFormRow">
                <p className="customerFormTitle">Description:</p>
                <Form style={{ width: "60%" }}>
                  <TextArea
                    placeholder="Enter description for product"
                    cols="50"
                    rows={4}
                    maxLength="100"
                    style={{ paddingRight: 50 }}
                    value={description}
                    onChange={(e) => {
                      this.setState({ description: e.target.value });
                    }}
                  />
                </Form>
                <p
                  style={{
                    alignSelf: "center",
                    marginLeft: -50,
                    zIndex: 0,
                    marginTop: 50,
                    color: "grey",
                  }}
                >
                  {description.length}/100
                </p>
              </div>
              <div className="customerFormRow">
                <p className="customerFormTitle">
                  Product description in Tamil:
                </p>
                <Form style={{ width: "60%" }}>
                  <TextArea
                    placeholder="Enter description for product in tamil"
                    cols="50"
                    rows={4}
                    maxLength="100"
                    style={{ paddingRight: 50 }}
                    value={tamil_description}
                    onChange={(e) => {
                      this.setState({ tamil_description: e.target.value });
                    }}
                  />
                </Form>
                <p
                  style={{
                    alignSelf: "center",
                    marginLeft: -50,
                    zIndex: 0,
                    marginTop: 50,
                    color: "grey",
                  }}
                >
                  {tamil_description.length}/100
                </p>
              </div>
              <div className="customerFormRow">
                <p className="customerFormTitle">Tags :</p>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>Previous Tags: {previous_tags.join(", ")}</div>

                  <Dropdown
                    style={{ width: 500 }}
                    placeholder="Add Tags"
                    fluid
                    multiple
                    selection
                    options={PRODUCT_TAGS}
                    onChange={(event, data) => {
                      this.setState({ tags: data.value });
                    }}
                  />
                </div>
              </div>
              <div className="customerFormRow">
                <p className="customerFormTitle">Pricing :</p>
                <form>
                  {this.addpricefields()}
                  <Button
                    type="button"
                    content="Add"
                    style={{ backgroundColor: "#a7c777", color: "#fff" }}
                    onClick={this.addprice.bind(this)}
                  />
                </form>
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button negative onClick={this.close}>
                No
              </Button>
              <Button
                positive
                icon="checkmark"
                labelPosition="right"
                content="Update"
                onClick={this.updateProduct}
              />
            </Modal.Actions>
          </Modal>
        ) : (
          <Modal size={size} open={open} onClose={this.close}>
            <Modal.Header>Delete Product</Modal.Header>
            <Modal.Content>
              <p>Are you sure you want to delete this product?</p>
            </Modal.Content>
            <Modal.Actions>
              <Button negative onClick={this.close}>
                No
              </Button>
              <Button
                positive
                icon="checkmark"
                labelPosition="right"
                content="Delete"
                onClick={this.deleteProduct}
              />
            </Modal.Actions>
          </Modal>
        )}
      </Grid.Row>
    );
  }
}

export default ProductList;

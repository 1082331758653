import React from 'react';
import { Tab } from 'semantic-ui-react';
import SettingsTabBarMenu from '../../../commons/settingstabbar/settingstabbar.component';
import VendorPayoutSettings from './vendorpayoutsettings.component';
import DriverPayoutSettings from './driverpayoutsettings.component';
import MarketingExecutivePayoutSettings from './marketingexecutivepayoutsettings.component';
import '../settings.css';

const PayoutSettings = (props) => {
  return (
    <div className='DefaultLayout'>
      <Header history={props.history} />
      <Card />
    </div>
  );
};

const PayoutSettingsPanes = [
  {
    menuItem: 'Vendor Payout Settings',
    render: () => (
      <Tab.Pane attached={false}>
        <VendorPayoutSettings />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Driver Payout Settings',
    render: () => (
      <Tab.Pane attached={false}>
        <DriverPayoutSettings />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Marketing Executive Payout Settings',
    render: () => (
      <Tab.Pane attached={false}>
        <MarketingExecutivePayoutSettings />
      </Tab.Pane>
    ),
  },
];

const Header = (props) => {
  return (
    <div className='HeaderLayout'>
      <h3>Settings</h3>
      <SettingsTabBarMenu history={props.history} />
    </div>
  );
};

const Card = () => {
  return (
    <div className='CardContainer'>
      <h3>Payout Settings</h3>
      <Tab
        menu={{ borderless: true, pointing: true }}
        panes={PayoutSettingsPanes}
      />
    </div>
  );
};
export default PayoutSettings;

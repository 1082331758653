import React, { useEffect, useState } from "react";
import {
  Table,
  Label,
  Checkbox,
  Button,
  Image,
  Select,
  Icon,
  Modal,
} from "semantic-ui-react";
import _ from "lodash";
import toastr from "toastr";
import moment from "moment";
import TableExport from "tableexport";
import CHEQUES_CLEARED_IMG from "../../../images/All_Cheques_cleared.png";
import { HEADING_DATA } from "./cheques.constant";
import { BASE_URL } from "../../../config";

const OrderType = [
  { key: "af", value: "PENDING", text: "Pending Cheques" },
  { key: "ax", value: "CLEARED", text: "Completed Cheques" },
  { key: "al", value: "", text: "All Orders" },
];

const ChequesTable = (props) => {
  const [companies, setCompanies] = useState([]);
  const [cheques, setCheques] = useState([]);
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);
  const [selectedCheque, setSelectedCheque] = useState({});
  const [transactionAmount, setTransactionAmt] = useState(0);
  const [dueAmount, setDueAmount] = useState(0);
  const [excessAmount, setExcessAmount] = useState(0);
  const [searchString, setSearchString] = useState("PENDING");
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    fetchCompanies();
  }, []);


  const fetchCompanies = () => {
    fetch(`${BASE_URL}/get-companies`)
      .then((res) => res.json())
      .then((json) => {
        if (json.companies.length > 0) {
          setCompanies(json.companies);
          fetch(
            `${BASE_URL}/get-cheques?cname=${json.companies[0].collection_name}_invoices`
          )
            .then((res) => res.json())
            .then((json) => {
              setCheques(json.cheques);
            });
        }
      });
  };

  const handleChangeCompany = (id) => {
    const data = companies.filter((company) => company._id === id);
    const company = data[0];
    fetch(
      `${BASE_URL}/get-cheques?cname=${company.collection_name}_invoices`
    )
      .then((res) => res.json())
      .then((json) => {
        setCheques(json.cheques);
      });
  };

  const handleSort = (clickedColumn) => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setCheques(_.sortBy(cheques, [clickedColumn]));
      setDirection("ascending");

      return;
    }

    setCheques(cheques.reverse());
    setDirection(direction === "ascending" ? "descending" : "ascending");
  };

  const Close = () => {
    setOpenModal(false);
    setExcessAmount(0);
  };

  const handleOpen = (cheque) => {
    setSelectedCheque(cheque);
    setOpenModal(true);
    fetch(
      `${BASE_URL}/get-invoice?invoiceNo=${cheque.invoice_no}&cname=${cheque.cname}`
    )
      .then((res) => res.json())
      .then((json) => {
        const transactions = json.invoice[0].transactions.reduce(
          (sum, transaction, index) =>
            sum + Number(transaction.transaction_amount),
          0
        );

        const due_amount = json.invoice[0].total_amount - transactions;
        const excess_amount = cheque.cheque_amount - due_amount;
        setTransactionAmt(due_amount);
        setDueAmount(due_amount);
        setExcessAmount(excess_amount);
      });
  };

  const handleUpdate = (selectedCheque) => {
    const transaction = {
      transaction_type: "payment",
      payment_type: "CHEQUE",
      transaction_date: new Date(),
      transaction_amount: transactionAmount,
      remarks: selectedCheque.remarks,
      cheque_details: {
        cheque_id: selectedCheque._id,
        cheque_no: selectedCheque.cheque_no,
        cheque_submit_date: selectedCheque.cheque_submit_date,
        cheque_clearing_date: selectedCheque.cheque_clearing_date,
        cheque_amount: selectedCheque.cheque_amount,
        cheque_status: "CLEARED",
        cheque_remarks: selectedCheque.cheque_remarks,
      },
      type: "CHEQUE_CLEARANCE",
    };

    const excess_amount_details = {
      invoice_no: selectedCheque.invoice_no,
      transaction_date: new Date(),
      transaction_type: "payment",
      payment_type: "CHEQUE",
      excess_amount: excessAmount,
      remarks: selectedCheque.cheque_remarks,
      cname: selectedCheque.cname,
    };

    if (excessAmount > 0) {
      transaction.excess_amount_details = excess_amount_details;
      transaction.cheque_details.excess_amount = excessAmount;
    }

    fetch(
      `${BASE_URL}/update-credit-transaction?invoice_no=${selectedCheque.invoice_no}&cname=${selectedCheque.cname}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(transaction),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        setOpenModal(false);
        toastr.success("Cheque Cleared Succesfully!");
      });
  };

  const cheques_table = cheques.filter((cheques) =>
    Object.keys(cheques).some(
      (key) => cheques[key].toString().search(searchString) !== -1
    )
  );

  return (
    <div>
      <div className="PageTitleRow">
        <div style={{ marginTop: 10 }}>
          <Select
            placeholder={
              companies.length > 0
                ? companies[0].company_name
                : "Select Company"
            }
            options={companies.map((company) => ({
              key: company._id,
              value: company._id,
              text: company.company_name,
            }))}
            style={{ margin: "auto" }}
            onChange={(event, data) => handleChangeCompany(data.value)}
          />
        </div>

        <Select
          placeholder="Order Type"
          value={searchString}
          options={OrderType}
          style={{ margin: "auto" }}
          onChange={(event, data) => setSearchString(data.value)}
        />

        <div style={{ marginTop: 10 }}>
          <Button
            icon
            labelPosition="right"
            style={{
              backgroundColor: "#d9ead3",
              color: "#000",
              marginBottom: 15,
            }}
            onClick={() => {
              toastr.success("Exported Buttons Enabled Successfully");
              const table = new TableExport(
                document.getElementById("cheques-table"),
                {
                  formats: ["csv"],
                  exportButtons: true,
                  position: "top",
                }
              );
              const link = document.createElement("a");
              link.setAttribute(
                "download",
                table.getExportData()["cheques-table"].csv.filename
              );
              link.click();
            }}
          >
            Export
            <Icon name="share square icon" />
          </Button>
        </div>
      </div>

      {cheques_table.length > 0 ? (
        <Table
          sortable
          unstackable
          celled
          basic="very"
          className="CustomTableLayout"
          id="cheques-table"
        >
          <Table.Header>
            <Table.Row>
              {HEADING_DATA.map((heading) => (
                <Table.HeaderCell
                  sorted={column === heading ? direction : null}
                  onClick={handleSort(heading)}
                >
                  {heading === "Checkbox" ? (
                    <Checkbox />
                  ) : (
                    <span>{heading}</span>
                  )}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {cheques_table.map((cheque, index) => (
              <Table.Row>
                <Table.Cell>{cheque.cheque_no} </Table.Cell>
                <Table.Cell>{cheque.invoice_no} </Table.Cell>
                <Table.Cell>
                  {moment(cheque.cheque_submit_date).format("DD-MM-YYYY")}
                </Table.Cell>
                <Table.Cell>
                  {moment(cheque.cheque_clearing_date).format("DD-MM-YYYY")}
                </Table.Cell>
                <Table.Cell>₹ {cheque.cheque_amount}</Table.Cell>
                <Table.Cell>{cheque.remarks}</Table.Cell>

                <Table.Cell>
                  {cheque.cheque_status === "PENDING" ? (
                    <Button
                      style={{ fontSize: 13, backgroundColor: "#d9ead3" }}
                      onClick={() => handleOpen(cheque)}
                    >
                      Clear Now
                    </Button>
                  ) : (
                    <Label color="green">{cheque.cheque_status}</Label>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <div>
          <Image
            src={CHEQUES_CLEARED_IMG}
            style={{ width: 300, marginLeft: 400 }}
          />
        </div>
      )}

      <Modal size="tiny" open={openModal} onClose={Close}>
        <Modal.Header>Cheque Clearance</Modal.Header>
        <Modal.Content>
          <h4>Cheque Amount: ₹ {selectedCheque.cheque_amount}</h4>
          <h4>Total Balance Due: ₹ {dueAmount}</h4>
          <h4>Excess Amount: ₹ {excessAmount} (Converted To Excess Amount)</h4>
          <p>
            Are you sure you want to clear cheque #{selectedCheque.cheque_no}?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button className="addCustomerCancelBtn" onClick={Close}>
            No
          </Button>
          <Button
            className="addCustomersubmitBtn"
            icon="checkmark"
            labelPosition="right"
            content="Yes"
            onClick={() => handleUpdate(selectedCheque)}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default ChequesTable;

import React, { useEffect, useState } from "react";
import {
  Select,
  Icon,
  Input,
  Button,
  Modal,
  TextArea,
  Form,
  Image,
  Popup,
} from "semantic-ui-react";
import toastr from "toastr";
import JOE_PIC from "../../../images/joe.jpg";
import { BASE_URL } from "../../../config";

const AddVendorModal = (props) => {
  const [name, setName] = useState("");
  const [vendorType, setVendorType] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [open, setOpen] = useState(props.openModal);
  const [vendorTypes, setVendorTypes] = useState([]);
  const [vendor_type, setNewVendorType] = useState("");
  const [openTypeModal, setOpenTypeModal] = useState(false);

  useEffect(() => {
    fetchVendorTypes();
  }, []);

  const fetchVendorTypes = () => {
    fetch(`${BASE_URL}/get-vendor-types`)
      .then((res) => res.json())
      .then((json) => {
        setVendorTypes(json.vendor_types);
      });
  };

  const close = () => {
    setOpen(false);
    props.onModalClose(false, "VENDOR");
  };

  const typeModalClose = () => {
    setOpenTypeModal(false);
  };

  const validateGst = (g) => {
    let regTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
      g
    );
    if (regTest) {
      let a = 65,
        b = 55,
        c = 36,
        p;
      return Array["from"](g).reduce((i, j, k, g) => {
        p =
          (p =
            (j.charCodeAt(0) < a ? parseInt(j) : j.charCodeAt(0) - b) *
            ((k % 2) + 1)) > c
            ? 1 + (p - c)
            : p;
        return k < 14
          ? i + p
          : j == ((c = c - (i % c)) < 10 ? c : String.fromCharCode(c + b));
      }, 0);
    }
    return toastr.error("Please Provide Valid GST No");
  };

  const saveVendor = () => {
    if ((name, vendorType, mobileNumber, email, address, gstNo)) {
      fetch(`${BASE_URL}/create-vendor`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          vendorType,
          gstNo,
          mobileNumber,
          email,
          address,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.status == 400) {
            toastr.error("Mobile number already exists.");
          } else {
            props.onModalClose(false, "SAVE");
            close();
          }
        });
    } else {
      toastr.error("Please fill all the fields!");
    }
  };

  const saveVendorType = () => {
    if (vendor_type) {
      fetch(`${BASE_URL}/create-vendor-type`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          vendor_type,
          vendor_firm: "No Data",
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          fetchVendorTypes();
          typeModalClose();
          toastr.success("Vendor Type Saved");
        });
    } else {
      toastr.error("Please fill all the fields!");
    }
  };

  return (
    <Modal size="small" open={open} onClose={close}>
      <Modal.Header>
        <Image
          src={JOE_PIC}
          inline
          circular
          size="mini"
          style={{ marginRight: 20 }}
        />
        <span>Add Vendor</span>
      </Modal.Header>
      <Modal.Content>
        <div className="vendorFormRow">
          <p className="titleVendorform">Vendor name:</p>
          <Input
            placeholder="Enter vendor name"
            style={{ width: "50%" }}
            maxLength="20"
            onChange={(evt) => setName(evt.target.value)}
          />
          <p
            style={{
              alignSelf: "center",
              marginLeft: -40,
              marginTop: 10,
              zIndex: 0,
              color: "grey",
            }}
          >
            {name.length}/20
          </p>
        </div>
        <div className="vendorFormRow">
          <p className="titleVendorform">Vendor type:</p>
          <Select
            placeholder="Select vendor type"
            options={vendorTypes.map((type) => ({
              key: type._id,
              value: type.vendor_type,
              text: type.vendor_type,
            }))}
            onChange={(event, data) => setVendorType(data.value)}
            style={{ width: "30%" }}
          />

          <Popup
            on="click"
            pinned
            style={{ textAlign: "center" }}
            open={openTypeModal}
            onClose={typeModalClose}
            trigger={
              <Button
                className="creatVTBtn"
                onClick={() => setOpenTypeModal(true)}
              >
                <Icon name="plus" /> Create{" "}
              </Button>
            }
          >
            <div>
              <p>Create Vendor Type:</p>
              <Input
                placeholder="Create vendor type"
                maxLength="20"
                onChange={(evt) => setNewVendorType(evt.target.value)}
              />
            </div>
            <Button
              style={{
                fontSize: 12,
                marginTop: 10,
                backgroundColor: "#c9daf8",
              }}
              onClick={() => saveVendorType()}
            >
              {" "}
              Submit{" "}
            </Button>
          </Popup>
          {/* <CreateVendorType style={{ width: "20%" }} /> */}
        </div>
        <div className="vendorFormRow">
          <p className="titleVendorform">Mobile number:</p>
          <Input
            placeholder="Enter mobile number"
            style={{ width: "50%" }}
            maxLength="10"
            onChange={(evt) => setMobileNumber(evt.target.value)}
          />
          <p
            style={{
              alignSelf: "center",
              marginLeft: -40,
              marginTop: 10,
              zIndex: 0,
              color: "grey",
            }}
          >
            {mobileNumber.length}/10
          </p>
        </div>
        <div className="vendorFormRow">
          <p className="titleVendorform">Email:</p>
          <Input
            placeholder="Enter Email"
            style={{ width: "50%" }}
            maxLength="25"
            onChange={(evt) => setEmail(evt.target.value)}
          />
          <p
            style={{
              alignSelf: "center",
              marginLeft: -40,
              marginTop: 10,
              zIndex: 0,
              color: "grey",
            }}
          >
            {email.length}/25
          </p>
        </div>
        <div className="vendorFormRow">
          <p className="titleVendorform">Address:</p>
          <Form style={{ width: "50%" }}>
            <TextArea
              placeholder="Enter Address"
              cols="50"
              rows={4}
              maxLength="100"
              style={{ paddingRight: 50 }}
              onChange={(evt) => setAddress(evt.target.value)}
            />
          </Form>
          <p
            style={{
              alignSelf: "center",
              marginLeft: -50,
              marginTop: 50,
              zIndex: 0,
              color: "grey",
            }}
          >
            {address.length}/100
          </p>
        </div>
        <div className="vendorFormRow">
          <p className="titleVendorform">GST IN: </p>
          <Input
            placeholder="Enter GST IN"
            style={{ width: "50%" }}
            maxLength="15"
            onBlur={() => validateGst(gstNo)}
            onChange={(evt) => setGstNo(evt.target.value)}
          />
          <p
            style={{
              alignSelf: "center",
              marginLeft: -40,
              marginTop: 10,
              zIndex: 0,
              color: "grey",
            }}
          >
            {gstNo.length}/15
          </p>
        </div>
      </Modal.Content>
      <Modal.Actions style={{ textAlign: "center" }}>
        <Button className="addCustomerCancelBtn" onClick={close}>
          Cancel
        </Button>
        <Button
          className="addCustomersubmitBtn"
          onClick={() => saveVendor(props.history)}
          content="Submit"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default AddVendorModal;

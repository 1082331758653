import React, { useEffect } from "react";
import moment from "moment";
import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";
import { Card } from "semantic-ui-react";
import ActiveOrdersChart from "./charts/active_orders.chart";
import CompletedOrdersChart from "./charts/completed_orders.chart";
import DashboardMemberDetails from "./dashboard_member_details.componet";
import "./dashboard.css";
import { useState } from "react";

const DashboardStatisticsContainer = () => {
  const [userData, setUserData] = useState("");

  useEffect(() => {
    if (localStorage.jwtToken) {
      const decoded = jwt_decode(localStorage.jwtToken);
      setUserData(decoded);
    }
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          <p className="TitleText">Welcome {userData.name}!</p>
          <p className="SubTitleText">
            <span className="GreenText">
              {moment(new Date()).format("dddd")}
            </span>
            , {moment(new Date()).format("DD MMM YYYY")}
          </p>
        </div>
      </div>

      <div>
        <DashboardMemberDetails userDetails={userData}/>

        <div style={{ marginTop: 10, marginBottom: 20 }}>
          <Card.Group>
            <Card className="DashboardOrdersReportCard">
              <div className="DashboardGrowthRow">
                <ActiveOrdersChart />
              </div>
            </Card>

            <Card className="DashboardOrdersReportCard">
              <div className="DashboardGrowthRow">
                <CompletedOrdersChart />
              </div>
            </Card>
          </Card.Group>
        </div>
      </div>
    </div>
  );
};

export default DashboardStatisticsContainer;

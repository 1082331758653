import React, { useState, useEffect } from 'react';
import { Icon, Menu, Sidebar, Button, Image } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import './sidebar.styles.css';
import {
  MENU,
  ROLE1_MENU,
  ROLE2_MENU,
  ROLE3_MENU,
  ROLE4_MENU,
} from './menu.constant';
import UserDetails from './userDetails.component';
import jwt from 'jsonwebtoken';
import jwt_decode from 'jwt-decode';
import PropTypes from 'prop-types';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { logoutUser } from '../../../actions/authActions';
import KK_LOGO from '../.../../../../images/kk-logo.png';

const minuteSeconds = 60;
const hourSeconds = 3600;

const timerProps = {
  isPlaying: true,
  size: 40,
  strokeWidth: 6,
};

const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;

const renderTime = (time) => {
  return (
    <div className="time-wrapper">
      <div style={{ fontSize: 10 }}>{time}</div>
    </div>
  );
};

const SidebarMenu = (props) => {
  const role = props.userDetails.role;

  var menu = '';

  if (role === 1) {
    menu = ROLE1_MENU.map((menu) => (
      <Menu.Item
        as="a"
        onClick={() => {
          props.history.push(menu.route);
        }}
        style={
          props.history.location.pathname === menu.route
            ? { backgroundColor: '#d9ead3' }
            : { backgroundColor: '#fff' }
        }
      >
        <Icon
          name={menu.iconName}
          size="small"
          style={
            props.history.location.pathname === menu.route
              ? {
                  paddingLeft: 20,
                  float: 'left',
                  color: '#22252f',
                }
              : { paddingLeft: 20, float: 'left', color: '#e2e3e9' }
          }
        />
        <p
          style={{
            paddingLeft: 60,
            paddingTop: 5,
            fontSize: 14,
            fontWeight: '600',
            color: '#22252f',
            textAlign: 'left',
            fontFamily: 'poppins',
          }}
        >
          {menu.name}
        </p>
      </Menu.Item>
    ));
  } else if (role === 2) {
    menu = ROLE2_MENU.map((menu) => (
      <Menu.Item
        as="a"
        onClick={() => {
          props.history.push(menu.route);
        }}
        style={
          props.history.location.pathname === menu.route
            ? { backgroundColor: '#d9ead3' }
            : { backgroundColor: '#fff' }
        }
      >
        <Icon
          name={menu.iconName}
          size="small"
          style={
            props.history.location.pathname === menu.route
              ? {
                  paddingLeft: 20,
                  float: 'left',
                  color: '#22252f',
                }
              : { paddingLeft: 20, float: 'left', color: '#e2e3e9' }
          }
        />
        <p
          style={{
            paddingLeft: 60,
            paddingTop: 5,
            fontSize: 14,
            fontWeight: '600',
            color: '#22252f',
            textAlign: 'left',
            fontFamily: 'poppins',
          }}
        >
          {menu.name}
        </p>
      </Menu.Item>
    ));
  } else if (role === 3) {
    menu = ROLE3_MENU.map((menu) => (
      <Menu.Item
        as="a"
        onClick={() => {
          props.history.push(menu.route);
        }}
        style={
          props.history.location.pathname === menu.route
            ? { backgroundColor: '#d9ead3' }
            : { backgroundColor: '#fff' }
        }
      >
        <Icon
          name={menu.iconName}
          size="small"
          style={
            props.history.location.pathname === menu.route
              ? {
                  paddingLeft: 20,
                  float: 'left',
                  color: '#22252f',
                }
              : { paddingLeft: 20, float: 'left', color: '#e2e3e9' }
          }
        />
        <p
          style={{
            paddingLeft: 60,
            paddingTop: 5,
            fontSize: 14,
            fontWeight: '600',
            color: '#22252f',
            textAlign: 'left',
            fontFamily: 'poppins',
          }}
        >
          {menu.name}
        </p>
      </Menu.Item>
    ));
  } else if (role === 4) {
    menu = ROLE4_MENU.map((menu) => (
      <Menu.Item
        as="a"
        onClick={() => {
          props.history.push(menu.route);
        }}
        style={
          props.history.location.pathname === menu.route
            ? { backgroundColor: '#d9ead3' }
            : { backgroundColor: '#fff' }
        }
      >
        <Icon
          name={menu.iconName}
          size="small"
          style={
            props.history.location.pathname === menu.route
              ? {
                  paddingLeft: 20,
                  float: 'left',
                  color: '#22252f',
                }
              : { paddingLeft: 20, float: 'left', color: '#e2e3e9' }
          }
        />
        <p
          style={{
            paddingLeft: 60,
            paddingTop: 5,
            fontSize: 14,
            fontWeight: '600',
            color: '#22252f',
            textAlign: 'left',
            fontFamily: 'poppins',
          }}
        >
          {menu.name}
        </p>
      </Menu.Item>
    ));
  } else if (role === 5) {
    menu = MENU.map((menu) => (
      <Menu.Item
        as="a"
        onClick={() => {
          props.history.push(menu.route);
        }}
        style={
          props.history.location.pathname === menu.route
            ? { backgroundColor: '#d9ead3' }
            : { backgroundColor: '#fff' }
        }
      >
        <Icon
          name={menu.iconName}
          size="small"
          style={
            props.history.location.pathname === menu.route
              ? {
                  paddingLeft: 20,
                  float: 'left',
                  color: '#22252f',
                }
              : { paddingLeft: 20, float: 'left', color: '#e2e3e9' }
          }
        />
        <p
          style={{
            paddingLeft: 60,
            paddingTop: 5,
            fontSize: 14,
            fontWeight: '600',
            color: '#22252f',
            textAlign: 'left',
            fontFamily: 'poppins',
          }}
        >
          {menu.name}
        </p>
      </Menu.Item>
    ));
  }
  return menu;
};

const SidebarVisible = (props) => {
  const [numTime, setNumTime] = useState(0);
  const [userData, setUserData] = useState(0);
  const startTime = Date.now() / 1000;
  const endTime = startTime + numTime;
  const remainingTime = endTime - startTime;

  useEffect(() => {
    if (localStorage.jwtToken) {
      const decoded = jwt_decode(localStorage.jwtToken);

      setUserData(decoded);

      const currentTime = Date.now() / 1000;

      const remainingTime = decoded.exp - currentTime;

      setNumTime(remainingTime);
    }
  }, []);

  const renewTime = (time) => {
    const decoded = jwt_decode(localStorage.jwtToken);
    const payload = {
      id: decoded._id,
      name: decoded.name,
      email: decoded.email,
      role: decoded.role,
    };

    jwt.sign(payload, 'jwtencode', { expiresIn: time }, (err, token) => {
      const newToken = 'JWT ' + token;
      localStorage.setItem('jwtToken', newToken);
    });

    setNumTime(time);
  };

  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      icon="labeled"
      inverted
      vertical
      visible
      width="thin"
      style={{ width: 240, backgroundColor: '#fff', boxShadow: 'none' }}
    >
      <div style={{ marginBottom: 15, marginTop: 10, alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 30 }}>
          <div>
            <Image src={KK_LOGO} style={{ height: 50, width: 50 }} />
          </div>
          <div>
            <h2 className="title" style={{ marginTop: 12 }}>
              KK Rice Mills
            </h2>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          textAlign: 'center',
          marginBottom: 10,
          marginLeft: 6,
        }}
        key={numTime}
      >
        <div>
          <CountdownCircleTimer
            {...timerProps}
            colors={[['#EF798A']]}
            duration={hourSeconds}
            initialRemainingTime={remainingTime % hourSeconds}
            onComplete={(totalElapsedTime) => {
              props.logoutUser();
              return [remainingTime - totalElapsedTime > minuteSeconds];
            }}
          >
            {({ elapsedTime }) =>
              renderTime(getTimeMinutes(hourSeconds - elapsedTime))
            }
          </CountdownCircleTimer>
          <p style={{ color: '#EF798A' }}>mins</p>
        </div>
        &nbsp;
        <div>
          <CountdownCircleTimer
            {...timerProps}
            colors={[['#218380']]}
            duration={minuteSeconds}
            initialRemainingTime={remainingTime % minuteSeconds}
            onComplete={(totalElapsedTime) => [
              remainingTime - totalElapsedTime > 0,
            ]}
          >
            {({ elapsedTime }) => renderTime(getTimeSeconds(elapsedTime))}
          </CountdownCircleTimer>
          <p style={{ color: '#218380' }}>secs</p>
        </div>
        &nbsp;&nbsp;
        <div style={{ marginTop: 5 }}>
          <Button color="green" onClick={() => renewTime(900)}>
            Renew Logout
          </Button>
        </div>
      </div>
      <SidebarMenu history={props.history} userDetails={userData} />
      <UserDetails userDetails={userData} />
    </Sidebar>
  );
};

SidebarVisible.propTypes = {
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { logoutUser })
)(SidebarVisible);
